import PHASER from 'phaser';
import { Vector2 } from 'three';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { unlockItem, removeItem } from 'store/inventoryReducer/actions';

import { puzzlesSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class CommsPanel extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.COMMS_PANEL,
        });
        this.available_connections = 1;
        this.is_solved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.startPoint = new PHASER.Geom.Point(0, 0);
        this.comms_puzzle = null;

        this.puzzle_state = {
            connections: null,
            status_Light_1: false,
            status_Light_2: false,
            status_Light_3: false,
            status_Light_4: false,
            port_1_connected: null,
            port_2_connected: null,
            port_3_connected: null,
            port_4_connected: null,
            port_5_connected: null,
            port_6_connected: null,
            port_7_connected: null,
            port_8_connected: null,
            port1_port8_connected: false,
            buttonC_pressed: false,
            port6_port7_connected: false,
            buttonA_pressed: false,
            is_solved: false,
        };

        this.isDrawing = false;
        this.connections = [];
        this.connectionsInfo = [];
        this.crossConnections = [];

        this.status_light_1 = false;
        this.status_light_2 = false;
        this.status_light_3 = false;
        this.status_light_4 = false;

        this.port_1_connected = null;
        this.port_2_connected = null;
        this.port_3_connected = null;
        this.port_4_connected = null;
        this.port_5_connected = null;
        this.port_6_connected = null;
        this.port_7_connected = null;
        this.port_8_connected = null;

        this.port1_port8_connected = false;
        this.buttonC_pressed = false;
        this.port6_port7_connected = false;
        this.buttonA_pressed = false;

        this.scaleTween = null;
        this.scaleXIncrease = 0;
        this.scaleYIncrease = 0;

        this.instructions_first_open = false;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;

        puzzlesSocket.onUpdate(this.puzzle_data.id, data => {
            const state = data.payload;
            console.log('State Received');
            console.log(state);
            this.applyState(state);
        });
    }

    preload() {
        this.load.image('comms_panel', '/assets/puzzles/comms-panel/comms-panel.png');
        this.load.image(
            'instructions_small',
            '/assets/puzzles/comms-panel/instructions-small.png'
        );
        this.load.image(
            'instructions_large',
            '/assets/puzzles/comms-panel/instructions-large.png'
        );
        this.load.image('button', '/assets/puzzles/comms-panel/button.png');
        this.load.image('port', '/assets/puzzles/comms-panel/port.png');
        this.load.image('status_light', '/assets/puzzles/comms-panel/status-light.png');
        this.load.image('puzzle_panel', '/assets/puzzles/comms-panel/puzzle-panel.png');
        this.load.image('puzzle_small', '/assets/puzzles/comms-panel/puzzle-small.png');
        this.load.image('cross', '/assets/puzzles/comms-panel/cross.png');
    }

    create() {
        this.graphics = this.add.graphics();
        this.graphics.lineStyle(10, 0xffff00).setDepth(2);

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;
        this.scaleXIncrease = this.scale_factor.x + this.scale_factor.x * 0.2;
        this.scaleYIncrease = this.scale_factor.y + this.scale_factor.y * 0.2;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.comms_panel = this.add
            .image(this.center.x, this.center.y, 'comms_panel')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.outer_small_puzzle = this.add
            .image(this.center.x + this.game_size.x * 0.01, this.center.y, 'puzzle_small')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.showCommsPuzzle();
            });

        this.outer_small_instructions = this.add
            .image(
                this.center.x - this.game_size.x * 0.338,
                this.center.y,
                'instructions_small'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.showInstructions();
            });

        this.large_instructions = this.add
            .image(this.center.x, this.center.y, 'instructions_large')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.createCommsPuzzle();

        if (this.puzzle_data.state) {
            console.log(this.puzzle_data.state);
            this.applyState(this.puzzle_data.state);
        } else {
            console.log(this.puzzle_data.state);
            console.log('No State Available!');
        }

        store.dispatch(setBackButtonCb());
    }

    showInstructions() {
        this.large_instructions.setVisible(true);

        if (!this.instructions_first_open) {
            store.dispatch(unlockItem(ITEM_ID_MAP.COMMS_PANEL_INSTRUCTIONS));
            this.instructions_first_open = true;
        }

        store.dispatch(
            setBackButtonCb(() => {
                this.large_instructions.setVisible(false);
                store.dispatch(setBackButtonCb());
            })
        );
    }

    showCommsPuzzle() {
        this.comms_puzzle.setVisible(true);

        store.dispatch(
            setBackButtonCb(() => {
                this.comms_puzzle.setVisible(false);
                store.dispatch(setBackButtonCb());
            })
        );
    }

    createCommsPuzzle() {
        this.comms_puzzle = this.add.container(0, 0).setVisible(false);

        this.puzzle_panel = this.add
            .image(this.center.x, this.center.y, 'puzzle_panel')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // port 1
        this.port1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.19,
                this.center.y - this.game_size.y * 0.28,
                'port'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port1, 1);
            })
            .on('drag', () => {
                this.onPortDrag(1);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port1, 1);
                // this.scaleDownPorts();
            });

        // port 2
        this.port2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.03,
                this.center.y - this.game_size.y * 0.28,
                'port'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port2, 2);
            })
            .on('drag', () => {
                this.onPortDrag(2);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port2, 2);
                // this.scaleDownPorts();
            });

        // port 3
        this.port3 = this.add
            .image(
                this.center.x + this.game_size.x * 0.12,
                this.center.y - this.game_size.y * 0.28,
                'port'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port3, 3);
            })
            .on('drag', () => {
                this.onPortDrag(3);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port3, 3);
                // this.scaleDownPorts();
            });

        // port 4
        this.port4 = this.add
            .image(
                this.center.x + this.game_size.x * 0.27,
                this.center.y - this.game_size.y * 0.28,
                'port'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port4, 4);
            })
            .on('drag', () => {
                this.onPortDrag(4);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port4, 4);
                // this.scaleDownPorts();
            });

        // port 5
        this.port5 = this.add
            .image(this.center.x - this.game_size.x * 0.19, this.center.y, 'port')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port5, 5);
            })
            .on('drag', () => {
                this.onPortDrag(5);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port5, 5);
                // this.scaleDownPorts();
            });

        // port 6
        this.port6 = this.add
            .image(this.center.x - this.game_size.x * 0.03, this.center.y, 'port')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port6, 6);
            })
            .on('drag', () => {
                this.onPortDrag(6);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port6, 6);
                // this.scaleDownPorts();
            });

        // port 7
        this.port7 = this.add
            .image(this.center.x + this.game_size.x * 0.12, this.center.y, 'port')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port7, 7);
            })
            .on('drag', () => {
                this.onPortDrag(7);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port7, 7);
                // this.scaleDownPorts();
            });

        // port 8
        this.port8 = this.add
            .image(this.center.x + this.game_size.x * 0.27, this.center.y, 'port')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.onPortDragStart(this.port8, 8);
            })
            .on('drag', () => {
                this.onPortDrag(8);
            })
            .on('dragend', () => {
                this.onPortDragEnd(this.port8, 8);
                // this.scaleDownPorts();
            });

        // button A
        this.buttonA = this.add
            .image(
                this.center.x - this.game_size.x * 0.14,
                this.center.y + this.game_size.y * 0.14,
                'button'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.onButtonPressed('A');
            });

        // button B
        this.buttonB = this.add
            .image(
                this.center.x + this.game_size.x * 0.02,
                this.center.y + this.game_size.y * 0.14,
                'button'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.onButtonPressed('B');
            });

        // button C
        this.buttonC = this.add
            .image(
                this.center.x + this.game_size.x * 0.175,
                this.center.y + this.game_size.y * 0.14,
                'button'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.onButtonPressed('C');
            });

        // status 1 light
        this.light1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.196,
                this.center.y + this.game_size.y * 0.28,
                'status_light'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // status 2 light
        this.light2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.07,
                this.center.y + this.game_size.y * 0.28,
                'status_light'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // status3 light
        this.light3 = this.add
            .image(
                this.center.x + this.game_size.x * 0.055,
                this.center.y + this.game_size.y * 0.28,
                'status_light'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // status4 light
        this.light4 = this.add
            .image(
                this.center.x + this.game_size.x * 0.182,
                this.center.y + this.game_size.y * 0.28,
                'status_light'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.comms_puzzle.add([
            this.puzzle_panel,
            this.port1,
            this.port2,
            this.port3,
            this.port4,
            this.port5,
            this.port6,
            this.port7,
            this.port8,
            this.buttonA,
            this.buttonB,
            this.buttonC,
            this.light1,
            this.light2,
            this.light3,
            this.light4,
        ]);
    }

    onPortDragStart(port) {
        if (this.is_solved) return;

        if (this.connections.length < 2) {
            this.startPoint.x = port.x;
            this.startPoint.y = port.y;
        }
    }

    onPortDrag(portNum) {
        if (this.is_solved) return;

        if (this.connections.length < 2 && !this.checkIfPortAlreadyConnected(portNum)) {
            if (!this.input.activePointer.isDown) {
                this.isDrawing = false;
            } else {
                if (!this.isDrawing) {
                    this.isDrawing = true;
                    const lineStart = this.getPortPosition(portNum);
                    this.curve = new PHASER.Curves.Line(lineStart, lineStart);
                } else {
                    this.graphics.clear();
                    this.graphics.lineStyle(10, 0xffff00).setDepth(2);
                    this.curve.p1 = new Vector2(
                        this.input.activePointer.x,
                        this.input.activePointer.y
                    );
                }
                this.curve.draw(this.graphics);
            }
        }
    }

    onPortDragEnd(port, portNum) {
        if (this.is_solved) return;

        if (this.connections.length < 2) {
            const point = new PHASER.Geom.Point(
                this.input.activePointer.x,
                this.input.activePointer.y
            );

            if (PHASER.Geom.Rectangle.ContainsPoint(port.getBounds(), point)) {
                console.log("Can't Connect To Same Port!");
            } else if (this.checkIfPortAlreadyConnected(portNum)) {
                console.log('Port Already Connected!');
            } else {
                if (this.curve !== null) {
                    this.graphics.clear();
                    this.graphics.lineStyle(10, 0xffff00).setDepth(2);
                    this.isDrawing = false;
                }
                if (PHASER.Geom.Rectangle.ContainsPoint(this.port1.getBounds(), point)) {
                    if (this.checkIfPortAlreadyConnected(1)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port1.x,
                            this.port1.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(1)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 1)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(1);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            if (portNum === 8) {
                                this.port1_port8_connected = false;
                                this.wrongFunctionPerformed();
                            }
                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 1);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 1);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port2.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(2)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port2.x,
                            this.port2.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(2)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 2)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(2);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 2);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 2);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port3.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(3)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port3.x,
                            this.port3.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(3)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 3)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(3);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 3);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 3);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port4.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(4)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port4.x,
                            this.port4.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(4)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 4)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(4);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 4);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 4);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port5.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(5)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port5.x,
                            this.port5.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(5)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 5)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(5);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 5);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 5);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port6.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(6)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port6.x,
                            this.port6.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(6)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 6)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(6);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            if (portNum === 7) {
                                this.port6_port7_connected = false;
                                this.status_light_3 = false;
                                this.light3.setVisible(false);
                            }

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 6);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 6);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port7.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(7)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port7.x,
                            this.port7.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(7)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 7)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(7);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            if (portNum === 6) {
                                this.port6_port7_connected = false;
                                this.status_light_3 = false;
                                this.light3.setVisible(false);
                            }

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 7);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 7);
                    this.verifyPortConnections();
                } else if (
                    PHASER.Geom.Rectangle.ContainsPoint(this.port8.getBounds(), point)
                ) {
                    if (this.checkIfPortAlreadyConnected(8)) {
                        return;
                    }

                    let line = this.add
                        .line(
                            0,
                            0,
                            this.startPoint.x,
                            this.startPoint.y,
                            this.port8.x,
                            this.port8.y,
                            0xffff00
                        )
                        .setOrigin(0, 0)
                        .setStrokeStyle(5, 0xffff00)
                        .setLineWidth(5);

                    const centerPoint = this.getLineCenter(
                        this.getPortPosition(portNum),
                        this.getPortPosition(8)
                    );
                    let lineCross = this.add
                        .image(centerPoint.x, centerPoint.y, 'cross')
                        .setDepth(2)
                        .setScale(this.scale_factor.x, this.scale_factor.y)
                        .setInteractive()
                        .on('pointerdown', () => {
                            // remove the line element from connections array
                            this.connections = this.connections.filter(
                                item => item !== line
                            );
                            this.connectionsInfo = this.connectionsInfo.filter(
                                item =>
                                    !(item.startPort === portNum && item.endPort === 8)
                            );
                            this.crossConnections = this.crossConnections.filter(
                                item => item !== lineCross
                            );

                            this.disconnectPort(8);
                            this.disconnectPort(portNum);

                            line.destroy();
                            lineCross.destroy();
                            line = null;
                            lineCross = null;

                            if (portNum === 1) {
                                this.port1_port8_connected = false;
                                this.wrongFunctionPerformed();
                            }

                            this.verifyPortConnections();
                        });

                    this.connections.push(line);
                    this.crossConnections.push(lineCross);
                    const connectionInfo = this.createConnectionInfoObject(portNum, 8);
                    this.connectionsInfo.push(connectionInfo);

                    this.comms_puzzle.add([line, lineCross]);
                    this.makePortConnection(portNum, 8);
                    this.verifyPortConnections();
                } else {
                    this.disconnectPort(portNum);
                }
            }
        }
    }

    getLineCenter(v1, v2) {
        return new Vector2((v1.x + v2.x) / 2, (v1.y + v2.y) / 2);
    }

    createConnectionInfoObject(startPort, endPort) {
        const connection = {
            startPort: startPort,
            endPort: endPort,
        };

        return connection;
    }

    makePortConnection(startPort, endPort) {
        switch (startPort) {
            case 1:
                this.port_1_connected = true;
                break;
            case 2:
                this.port_2_connected = true;
                break;
            case 3:
                this.port_3_connected = true;
                break;
            case 4:
                this.port_4_connected = true;
                break;
            case 5:
                this.port_5_connected = true;
                break;
            case 6:
                this.port_6_connected = true;
                break;
            case 7:
                this.port_7_connected = true;
                break;
            case 8:
                this.port_8_connected = true;
                break;
            // no default
        }
        switch (endPort) {
            case 1:
                this.port_1_connected = true;
                break;
            case 2:
                this.port_2_connected = true;
                break;
            case 3:
                this.port_3_connected = true;
                break;
            case 4:
                this.port_4_connected = true;
                break;
            case 5:
                this.port_5_connected = true;
                break;
            case 6:
                this.port_6_connected = true;
                break;
            case 7:
                this.port_7_connected = true;
                break;
            case 8:
                this.port_8_connected = true;
                break;
            // no default
        }
    }

    checkIfPortAlreadyConnected(portNum) {
        switch (portNum) {
            case 1:
                if (this.port_1_connected === true) {
                    return true;
                }
                break;
            case 2:
                if (this.port_2_connected === true) {
                    return true;
                }
                break;
            case 3:
                if (this.port_3_connected === true) {
                    return true;
                }
                break;
            case 4:
                if (this.port_4_connected === true) {
                    return true;
                }
                break;
            case 5:
                if (this.port_5_connected === true) {
                    return true;
                }
                break;
            case 6:
                if (this.port_6_connected === true) {
                    return true;
                }
                break;
            case 7:
                if (this.port_7_connected === true) {
                    return true;
                }
                break;
            case 8:
                if (this.port_8_connected === true) {
                    return true;
                }
                break;
            // no default
        }

        return false;
    }

    disconnectPort(portNum) {
        switch (portNum) {
            case 1:
                this.port_1_connected = false;
                break;
            case 2:
                this.port_2_connected = false;
                break;
            case 3:
                this.port_3_connected = false;
                break;
            case 4:
                this.port_4_connected = false;
                break;
            case 5:
                this.port_5_connected = false;
                break;
            case 6:
                this.port_6_connected = false;
                break;
            case 7:
                this.port_7_connected = false;
                break;
            case 8:
                this.port_8_connected = false;
                break;
            // no default
        }
    }

    getPortPosition(portNum) {
        const portPos = new Vector2(0, 0);
        switch (portNum) {
            case 1:
                portPos.x = this.port1.x;
                portPos.y = this.port1.y;
                break;

            case 2:
                portPos.x = this.port2.x;
                portPos.y = this.port2.y;
                break;

            case 3:
                portPos.x = this.port3.x;
                portPos.y = this.port3.y;
                break;

            case 4:
                portPos.x = this.port4.x;
                portPos.y = this.port4.y;
                break;

            case 5:
                portPos.x = this.port5.x;
                portPos.y = this.port5.y;
                break;

            case 6:
                portPos.x = this.port6.x;
                portPos.y = this.port6.y;
                break;

            case 7:
                portPos.x = this.port7.x;
                portPos.y = this.port7.y;
                break;

            case 8:
                portPos.x = this.port8.x;
                portPos.y = this.port8.y;
                break;

            // no default
        }
        return portPos;
    }

    wrongFunctionPerformed() {
        for (let i = 0; i < this.connections.length; i += 1) {
            this.connections[i].destroy();
            this.connectionsInfo[i].startPort = 0;
            this.connectionsInfo[i].endPort = 0;
        }
        for (let i = 0; i < this.crossConnections.length; i += 1) {
            this.crossConnections[i].destroy();
        }

        this.connections = [];
        this.connectionsInfo = [];
        this.crossConnections = [];

        this.status_light_1 = false;
        this.status_light_2 = false;
        this.status_light_3 = false;
        this.status_light_4 = false;

        this.light1.setVisible(false);
        this.light2.setVisible(false);
        this.light3.setVisible(false);
        this.light4.setVisible(false);

        this.port_1_connected = false;
        this.port_2_connected = false;
        this.port_3_connected = false;
        this.port_4_connected = false;
        this.port_5_connected = false;
        this.port_6_connected = false;
        this.port_7_connected = false;
        this.port_8_connected = false;

        this.port1_port8_connected = false;
        this.buttonC_pressed = false;
        this.port6_port7_connected = false;
        this.buttonA_pressed = false;

        this.syncState();
    }

    syncState() {
        const connectionObject = { ...this.connectionsInfo };

        this.puzzle_state.connections = connectionObject;

        this.puzzle_state.port_1_connected = this.port_1_connected;
        this.puzzle_state.port_2_connected = this.port_2_connected;
        this.puzzle_state.port_3_connected = this.port_3_connected;
        this.puzzle_state.port_4_connected = this.port_4_connected;
        this.puzzle_state.port_5_connected = this.port_5_connected;
        this.puzzle_state.port_6_connected = this.port_6_connected;
        this.puzzle_state.port_7_connected = this.port_7_connected;
        this.puzzle_state.port_8_connected = this.port_8_connected;

        this.puzzle_state.status_Light_1 = this.status_light_1;
        this.puzzle_state.status_Light_2 = this.status_light_2;
        this.puzzle_state.status_Light_3 = this.status_light_3;
        this.puzzle_state.status_Light_4 = this.status_light_4;

        this.puzzle_state.port1_port8_connected = this.port1_port8_connected;
        this.puzzle_state.port6_port7_connected = this.port6_port7_connected;
        this.puzzle_state.buttonC_pressed = this.buttonC_pressed;
        this.puzzle_state.buttonA_pressed = this.buttonA_pressed;
        this.puzzle_state.is_solved = this.is_solved;

        this.sendCurrentState();
    }

    sendCurrentState() {
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(this.puzzle_data.id, this.puzzle_state, user.id, user);
    }

    applyState(state) {
        // destroying current connections
        for (let i = 0; i < this.connections.length; i += 1) {
            this.connections[i].destroy();
            this.connectionsInfo[i].startPort = 0;
            this.connectionsInfo[i].endPort = 0;
            this.crossConnections[i].destroy();
        }

        this.connections = [];
        this.connectionsInfo = [];
        this.crossConnections = [];

        const tempConnectionsInfo = state.connections;

        if (tempConnectionsInfo) {
            Object.entries(tempConnectionsInfo).forEach(([, value]) => {
                this.connectionsInfo.push(value);
            });

            Object.entries(tempConnectionsInfo).forEach(([, value]) => {
                const { startPort } = value;
                const { endPort } = value;

                const startPos = this.getPortPosition(startPort);
                const endPos = this.getPortPosition(endPort);

                let line = this.add
                    .line(0, 0, startPos.x, startPos.y, endPos.x, endPos.y, 0xffff00)
                    .setOrigin(0, 0)
                    .setStrokeStyle(5, 0xffff00)
                    .setLineWidth(5);

                const centerPoint = this.getLineCenter(startPos, endPos);
                let lineCross = this.add
                    .image(centerPoint.x, centerPoint.y, 'cross')
                    .setDepth(2)
                    .setScale(this.scale_factor.x, this.scale_factor.y)
                    .setInteractive()
                    .on('pointerdown', () => {
                        // remove the line element from connections array
                        this.connections = this.connections.filter(item => item !== line);
                        this.connectionsInfo = this.connectionsInfo.filter(
                            item =>
                                !(
                                    item.startPort === startPort &&
                                    item.endPort === endPort
                                )
                        );
                        this.crossConnections = this.crossConnections.filter(
                            item => item !== lineCross
                        );

                        this.disconnectPort(startPort);
                        this.disconnectPort(endPort);

                        line.destroy();
                        lineCross.destroy();
                        line = null;
                        lineCross = null;

                        if (startPort === 8 || startPort === 1) {
                            this.port1_port8_connected = false;
                            this.wrongFunctionPerformed();
                        } else if (startPort === 6 || startPort === 7) {
                            this.port6_port7_connected = false;
                            this.status_light_3 = false;
                            this.light3.setVisible(false);
                        }

                        this.verifyPortConnections();
                    });

                this.crossConnections.push(lineCross);
                this.connections.push(line);
                this.comms_puzzle.add([line, lineCross]);
            });
        }

        this.port_1_connected = state.port_1_connected;
        this.port_2_connected = state.port_2_connected;
        this.port_3_connected = state.port_3_connected;
        this.port_4_connected = state.port_4_connected;
        this.port_5_connected = state.port_5_connected;
        this.port_6_connected = state.port_6_connected;
        this.port_7_connected = state.port_7_connected;
        this.port_8_connected = state.port_8_connected;

        this.status_light_1 = state.status_Light_1;
        this.status_light_2 = state.status_Light_2;
        this.status_light_3 = state.status_Light_3;
        this.status_light_4 = state.status_Light_4;

        this.light1?.setVisible(this.status_light_1);
        this.light2?.setVisible(this.status_light_2);
        this.light3?.setVisible(this.status_light_3);
        this.light4?.setVisible(this.status_light_4);

        this.port1_port8_connected = state.port1_port8_connected;
        this.port6_port7_connected = state.port6_port7_connected;
        this.buttonA_pressed = state.buttonA_pressed;
        this.buttonC_pressed = state.buttonC_pressed;

        this.is_solved = state.is_solved;
    }

    verifyPortConnections() {
        if (!this.port1_port8_connected) {
            if (
                this.port_1_connected &&
                this.port_8_connected &&
                !this.port_2_connected &&
                !this.port_3_connected &&
                !this.port_4_connected &&
                !this.port_5_connected &&
                !this.port_6_connected &&
                !this.port_7_connected
            ) {
                this.port1_port8_connected = true;
                this.light1.setVisible(true);
                this.status_light_1 = true; // true means on
            } else {
                // wrong function performed
            }
        } else if (
            !this.port6_port7_connected &&
            this.port1_port8_connected &&
            this.buttonC_pressed
        ) {
            if (
                this.port_6_connected &&
                this.port_7_connected &&
                !this.port_2_connected &&
                !this.port_3_connected &&
                !this.port_4_connected &&
                !this.port_5_connected &&
                this.port_1_connected &&
                this.port_8_connected
            ) {
                this.port6_port7_connected = true;
                this.light3.setVisible(true);
                this.status_light_3 = true;
            } else {
                // wrong function performed
            }
        }
        this.syncState();
    }

    onButtonPressed(button) {
        if (this.is_solved) return;

        this.sound.play('comms_button', { volume: 0.2 });
        switch (button) {
            case 'A':
                if (
                    this.port1_port8_connected &&
                    this.port6_port7_connected &&
                    this.buttonC_pressed &&
                    !this.port_2_connected &&
                    !this.port_3_connected &&
                    !this.port_4_connected &&
                    !this.port_5_connected
                ) {
                    this.buttonA_pressed = true;
                    this.light4.setVisible(true);
                    this.status_light_4 = true;

                    store.dispatch(removeItem(ITEM_ID_MAP.COMMS_PANEL_INSTRUCTIONS));
                    store.dispatch(markPuzzleSolved(this.puzzle_data.id));

                    // < --- MIXPANEL ANALYTICS
                    Mixpanel.track('Puzzle Complete', {
                        ...this.puzzle_data,
                        puzzle: 'COMMS PANEL',
                    });
                    // --- >
                    this.is_solved = true;

                    this.syncState();
                } else {
                    // wrong function performed
                    this.wrongFunctionPerformed();
                }
                break;

            case 'B':
                this.wrongFunctionPerformed();
                break;

            case 'C':
                if (
                    this.port1_port8_connected &&
                    !this.port_2_connected &&
                    !this.port_3_connected &&
                    !this.port_4_connected &&
                    !this.port_5_connected &&
                    !this.port_6_connected &&
                    !this.port_7_connected
                ) {
                    this.buttonC_pressed = true;
                    this.light2.setVisible(true);
                    this.status_light_2 = true;

                    this.syncState();
                } else {
                    // wrong function performed
                    this.wrongFunctionPerformed();
                }
                break;

            // no default
        }
    }

    tweenTargetPorts(portNum) {
        this.targetPortsNum = [];
        this.targetPorts = [];
        switch (portNum) {
            case 1:
                this.targetPorts = [
                    this.port2,
                    this.port3,
                    this.port4,
                    this.port5,
                    this.port6,
                    this.port7,
                    this.port8,
                ];
                this.targetPortsNum = [2, 3, 4, 5, 6, 7, 8];
                this.checkTargetPortsConnection();
                break;
            case 2:
                this.targetPorts = [
                    this.port1,
                    this.port3,
                    this.port4,
                    this.port5,
                    this.port6,
                    this.port7,
                    this.port8,
                ];
                this.targetPortsNum = [1, 3, 4, 5, 6, 7, 8];
                this.checkTargetPortsConnection();
                break;
            case 3:
                this.targetPorts = [
                    this.port2,
                    this.port1,
                    this.port4,
                    this.port5,
                    this.port6,
                    this.port7,
                    this.port8,
                ];
                this.targetPortsNum = [2, 1, 4, 5, 6, 7, 8];
                this.checkTargetPortsConnection();
                break;
            case 4:
                this.targetPorts = [
                    this.port2,
                    this.port3,
                    this.port1,
                    this.port5,
                    this.port6,
                    this.port7,
                    this.port8,
                ];
                this.targetPortsNum = [2, 3, 1, 5, 6, 7, 8];
                this.checkTargetPortsConnection();
                break;
            case 5:
                this.targetPorts = [
                    this.port2,
                    this.port3,
                    this.port4,
                    this.port1,
                    this.port6,
                    this.port7,
                    this.port8,
                ];
                this.targetPortsNum = [2, 3, 4, 1, 6, 7, 8];
                this.checkTargetPortsConnection();
                break;
            case 6:
                this.targetPorts = [
                    this.port2,
                    this.port3,
                    this.port4,
                    this.port5,
                    this.port1,
                    this.port7,
                    this.port8,
                ];
                this.targetPortsNum = [2, 3, 4, 5, 1, 7, 8];
                this.checkTargetPortsConnection();
                break;
            case 7:
                this.targetPorts = [
                    this.port2,
                    this.port3,
                    this.port4,
                    this.port5,
                    this.port6,
                    this.port1,
                    this.port8,
                ];
                this.targetPortsNum = [2, 3, 4, 5, 6, 1, 8];
                this.checkTargetPortsConnection();
                break;
            case 8:
                this.targetPorts = [
                    this.port2,
                    this.port3,
                    this.port4,
                    this.port5,
                    this.port6,
                    this.port7,
                    this.port1,
                ];
                this.targetPortsNum = [2, 3, 4, 5, 6, 7, 1];
                this.checkTargetPortsConnection();
                break;
            // no default
        }

        this.scaleTween = this.tweens.add({
            targets: this.targetPorts,
            scaleX: this.scaleXIncrease,
            scaleY: this.scaleYIncrease,
            ease: 'Linear',
            duration: 400,
            repeat: 0,
            onComplete: this.startBlinkSequence,
            onCompleteScope: this,
        });
    }

    scaleDownPorts() {
        if (this.targetPorts.length > 0) {
            this.scaleTween = this.tweens.add({
                targets: this.targetPorts,
                scaleX: this.scale_factor.x,
                scaleY: this.scale_factor.y,
                ease: 'Linear',
                duration: 400,
                repeat: 0,
                onComplete: this.startBlinkSequence,
                onCompleteScope: this,
            });
        }
    }

    checkTargetPortsConnection() {
        for (let i = 0; i < this.targetPortsNum.length; i += 1) {
            if (this.checkIfPortAlreadyConnected(this.targetPortsNum[i])) {
                this.removePortFromTargets(this.targetPortsNum[i]);
            }
        }
    }

    removePortFromTargets(portNum) {
        const ports = [];
        switch (portNum) {
            case 1:
                ports.push(this.port1);
                break;
            case 2:
                ports.push(this.port2);
                break;
            case 3:
                ports.push(this.port3);
                break;
            case 4:
                ports.push(this.port4);
                break;
            case 5:
                ports.push(this.port5);
                break;
            case 6:
                ports.push(this.port6);
                break;
            case 7:
                ports.push(this.port7);
                break;
            case 8:
                ports.push(this.port8);
                break;
            // no default
        }

        this.targetPorts = this.targetPorts.filter(item => !ports.includes(item));
    }
}

import PHASER from 'phaser';

import Map from './scenes/map';
import Loader from './scenes/loader';

export default class MapManager {
    constructor(mapData) {
        this.game = new PHASER.Game(this.config);
        this.createLoader(mapData);

        this.map = null;
    }

    parentElement = document.getElementById('map');

    config = {
        width: 932,
        height: 544,
        type: PHASER.AUTO,
        parent: this.parentElement,
        transparent: true,
        scale: {
            mode: PHASER.Scale.FIT,
            orientation: PHASER.Scale.Orientation.LANDSCAPE,
            parent: this.parentElement,
            autoCenter: PHASER.Scale.CENTER_BOTH,
        },
        callbacks: {
            preBoot: function (game) {
                if (game.device.browser.safari) {
                    game.device.audio.wav = true;
                }
            },
        },
    };

    createLoader(mapData) {
        const loader = new Loader();
        this.game.scene.add('loader', loader, true, mapData);
    }

    createMap(mapData) {
        this.map = new Map();
        this.game.scene.add('map', this.map, Loader.isLoaderComplete, mapData);
    }

    updateMap(mapData) {
        if (this.map === null) {
            this.createMap(mapData);
        } else {
            const map = this.game.scene.getScene('map');
            map.scene.restart(mapData);
        }
    }

    destroy() {
        this.game.destroy(true);
    }
}

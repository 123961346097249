import PHASER from 'phaser';

import store from 'store';
import { puzzleStateSocket } from 'services/websockets/index';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class SatelliteControl extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.SATELLITE_CONTROL,
        });

        this.puzzle_state = {
            currX: 40,
            currY: 40,
            isSolved: false,
        };

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.currX = 40;
        this.currY = 40;
        this.rows = 80;
        this.cols = 80;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image(
            'satellite_control_bg',
            '/assets/puzzles/satellite/satellite-control-bg.png'
        );
        this.load.image('button_up', '/assets/puzzles/satellite/button-up.png');
        this.load.image('button_down', '/assets/puzzles/satellite/button-down.png');
        this.load.image('button_left', '/assets/puzzles/satellite/button-left.png');
        this.load.image('button_right', '/assets/puzzles/satellite/button-right.png');

        this.load.image('submit_ct', '/assets/puzzles/cooling-tubes/submit.png');
    }

    create() {
        const {
            authReducer: { user },
        } = store.getState();
        puzzleStateSocket.joinGame(user.id, user, this.puzzle_state);

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.rover_bg = this.add
            .image(this.center.x, this.center.y, 'satellite_control_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        puzzleStateSocket.onStateUpdate(data => {
            const {
                states: { state },
            } = data;
            this.applyState(state);
        });

        this.createSatelliteButtons();
        store.dispatch(setBackButtonCb());
    }

    createSatelliteButtons() {
        this.button_up = this.add
            .image(
                this.center.x + this.game_size.x * 0.195,
                this.center.y - this.game_size.y * 0.235,
                'button_up'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.applyDirection('up');
            });

        this.button_down = this.add
            .image(
                this.center.x + this.game_size.x * 0.195,
                this.center.y + this.game_size.y * 0.236,
                'button_down'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.applyDirection('down');
            });

        this.button_left = this.add
            .image(this.center.x + this.game_size.x * 0.053, this.center.y, 'button_left')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.applyDirection('left');
            });

        this.button_right = this.add
            .image(
                this.center.x + this.game_size.x * 0.337,
                this.center.y,
                'button_right'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.applyDirection('right');
            });
    }

    applyState(state) {
        if (this.is_solved) return;

        this.currX = state.currX;
        this.currY = state.currY;

        if (state.isSolved) {
            this.is_solved = true;
            store.dispatch(markPuzzleSolved(this.puzzle_data.id));
            const satelliteDish = getPuzzleByKey('satellite-dish', this.puzzle_data.room);

            store.dispatch(markPuzzleSolved(satelliteDish.id));
            Mixpanel.track('Puzzle Complete', {
                ...state,
                puzzle: 'SATELLITE DISH',
            });

            puzzleStateSocket.disconnect();
        }
    }

    applyDirection(direction) {
        if (this.is_solved) return;

        switch (direction) {
            case 'up':
                this.moveLineUp();
                break;
            case 'down':
                this.moveLineDown();
                break;
            case 'left':
                this.moveLineLeft();
                break;
            case 'right':
                this.moveLineRight();
                break;
            // no default
        }
    }

    moveLineUp() {
        if (this.currY - 5 < 5) {
            return;
        }
        this.currY -= 5;
        this.sendState();
    }

    moveLineDown() {
        if (this.currY + 5 > this.rows - 5) {
            return;
        }
        this.currY += 5;

        this.sendState();
    }

    moveLineLeft() {
        if (this.currX - 5 < 5) {
            return;
        }
        this.currX -= 5;
        this.sendState();
    }

    moveLineRight() {
        if (this.currX + 5 > this.cols - 5) {
            return;
        }
        this.currX += 5;
        this.sendState();
    }

    sendState() {
        puzzleStateSocket.update({
            currX: this.currX,
            currY: this.currY,
        });
    }
}

import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { removeItem } from 'store/inventoryReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class CommandDeckKeypad extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.KEYPAD,
        });

        this.isSolved = false;
        this.code = '';
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
        this.maxCharLimit = this.puzzleData.solution.length;
    }

    preload() {
        this.loadAssets();
    }

    create() {
        this.createAssets();
        store.dispatch(
            setBackButtonCb(() => {
                this.isSolved = false;

                if (this.code !== '') {
                    this.code = '';
                }

                const state = getPuzzleByKey('life-support-systems', 'Command Deck');
                this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.LIFE_SUPP_SYSTEMS, state);
            })
        );
    }

    loadAssets() {
        this.load.image(
            'keypad_background',
            '/assets/puzzles/keypads/command-deck/keypad-bg.png'
        );
        this.load.image(
            'keypad_screen',
            '/assets/puzzles/keypads/door-keypad/keypad-screen.png'
        );

        if (this.puzzleData.type === 'power') {
            this.load.image('power', '/assets/puzzles/keypads/command-deck/power.png');
        } else if (this.puzzleData.type === 'comms') {
            this.load.image('comms', '/assets/puzzles/keypads/command-deck/comms.png');
        } else {
            this.load.image('oxygen', '/assets/puzzles/keypads/command-deck/oxygen.png');
        }

        this.loadKeypadAssets();
    }

    createAssets() {
        this.isSolved = false;
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        if (this.puzzleData.type === 'power') {
            this.power = this.add
                .image(this.center.x, this.center.y, 'power')
                .setScale(this.scaleFactor.x, this.scaleFactor.y);
        } else if (this.puzzleData.type === 'comms') {
            this.comms = this.add
                .image(this.center.x, this.center.y, 'comms')
                .setScale(this.scaleFactor.x, this.scaleFactor.y);
        } else {
            this.oxygen = this.add
                .image(this.center.x, this.center.y, 'oxygen')
                .setScale(this.scaleFactor.x, this.scaleFactor.y);
        }

        this.keypad_screen = this.add
            .image(
                this.center.x + this.gameSize.x * 0.17,
                this.center.y - this.gameSize.y * 0.17,
                'keypad_screen'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.codeField = this.add
            .text(
                this.center.x + this.gameSize.x * 0.08,
                this.center.y - this.gameSize.y * 0.2,
                '',
                { fontFamily: 'Ethnocentric', fontSize: 30, align: 'center' }
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setColor('#000000')
            .setFixedSize(150, 150);

        this.createKeypadAssets();

        this.incorrectImage = this.add
            .image(this.center.x, this.center.y, 'incorrect')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setDepth(1)
            .setVisible(false);

        this.incorrectText = this.add
            .text(this.center.x, this.center.y, 'Access Denied', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setDepth(1)
            .setVisible(false)
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.systemRestoredImage = this.add
            .image(this.center.x, this.center.y, 'system_restored')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setDepth(1)
            .setVisible(false);

        this.restoredText = this.add
            .text(this.center.x, this.center.y, 'System Restored', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setDepth(1)
            .setVisible(false)
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        if (this.puzzleData.status === 'completed') {
            this.setSolvedState();
            this.systemRestoredImage.setVisible(true);
            this.restoredText.setVisible(true);
        }
    }

    loadKeypadAssets() {
        this.load.image('key_0', '/assets/puzzles/keypads/door-keypad/key-0.png');
        this.load.image('key_1', '/assets/puzzles/keypads/door-keypad/key-1.png');
        this.load.image('key_2', '/assets/puzzles/keypads/door-keypad/key-2.png');
        this.load.image('key_3', '/assets/puzzles/keypads/door-keypad/key-3.png');
        this.load.image('key_4', '/assets/puzzles/keypads/door-keypad/key-4.png');
        this.load.image('key_5', '/assets/puzzles/keypads/door-keypad/key-5.png');
        this.load.image('key_6', '/assets/puzzles/keypads/door-keypad/key-6.png');
        this.load.image('key_7', '/assets/puzzles/keypads/door-keypad/key-7.png');
        this.load.image('key_8', '/assets/puzzles/keypads/door-keypad/key-8.png');
        this.load.image('key_9', '/assets/puzzles/keypads/door-keypad/key-9.png');
        this.load.image('erase', '/assets/puzzles/keypads/door-keypad/erase.png');
        this.load.image('submit', '/assets/puzzles/keypads/door-keypad/submit.png');
    }

    createKeypadAssets() {
        const columns = [0.097, 0.172, 0.246].map(
            col => this.center.x + this.gameSize.x * col
        );
        const rows = [
            this.center.y - this.gameSize.y * 0.063,
            ...[0.02, 0.103, 0.186].map(row => this.center.y + this.gameSize.y * row),
        ];

        [
            {
                keyName: 'key_1',
                position: [0, 0],
                onPointerDown: () => this.numKeyPressed(1),
            },
            {
                keyName: 'key_2',
                position: [1, 0],
                onPointerDown: () => this.numKeyPressed(2),
            },
            {
                keyName: 'key_3',
                position: [2, 0],
                onPointerDown: () => this.numKeyPressed(3),
            },
            {
                keyName: 'key_4',
                position: [0, 1],
                onPointerDown: () => this.numKeyPressed(4),
            },
            {
                keyName: 'key_5',
                position: [1, 1],
                onPointerDown: () => this.numKeyPressed(5),
            },
            {
                keyName: 'key_6',
                position: [2, 1],
                onPointerDown: () => this.numKeyPressed(6),
            },
            {
                keyName: 'key_7',
                position: [0, 2],
                onPointerDown: () => this.numKeyPressed(7),
            },
            {
                keyName: 'key_8',
                position: [1, 2],
                onPointerDown: () => this.numKeyPressed(8),
            },
            {
                keyName: 'key_9',
                position: [2, 2],
                onPointerDown: () => this.numKeyPressed(9),
            },
            {
                keyName: 'key_0',
                position: [0, 3],
                onPointerDown: () => this.numKeyPressed(0),
            },
            {
                keyName: 'erase',
                position: [1, 3],
                onPointerDown: () => this.eraseKeyPressed(),
            },
            {
                keyName: 'submit',
                position: [2, 3],
                onPointerDown: () => this.submitKeyPressed(),
            },
        ].forEach(key => {
            this[key.keyName] = this.add
                .image(columns[key.position[0]], rows[key.position[1]], key.keyName)
                .setScale(this.scaleFactor.x, this.scaleFactor.y)
                .setInteractive()
                .on('pointerdown', function () {
                    key.onPointerDown();
                    this.setTint(0x8e8e8e);
                })
                .on('pointerout', function () {
                    this.clearTint();
                })
                .on('pointerup', function () {
                    this.clearTint();
                });
        });
    }

    numKeyPressed(value) {
        if (!this.isSolved) {
            this.sound.play('key');
            if (this.code.length < this.maxCharLimit) {
                this.code += value;
                this.codeField.setText(this.code);
            }
        }
    }

    eraseKeyPressed() {
        if (!this.isSolved) {
            this.sound.play('erase');
            if (this.code !== '' && this.code.length > 0) {
                this.code = this.code.slice(0, this.code.length - 1);
                this.codeField.setText(this.code);
            }
        }
    }

    submitKeyPressed() {
        if (this.isSolved) return;

        if (this.code === this.puzzleData.solution) {
            this.isSolved = true;
            this.sound.play('success');
            store.dispatch(markPuzzleSolved(this.puzzleData.id));

            if (this.puzzleData.type === 'power') {
                store.dispatch(removeItem(ITEM_ID_MAP.SOLAR_READOUT));
            } else if (this.puzzleData.type === 'oxygen') {
                store.dispatch(removeItem(ITEM_ID_MAP.PING_PONG_BALLS));
            }

            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzleData,
                puzzle: 'COMMAND DECK KEYPAD',
            });
            // --- >
            this.code = '';

            this.systemRestoredImage.setVisible(true);
            this.restoredText.setVisible(true);
            store.dispatch(
                setBackButtonCb(() => {
                    this.isSolved = false;
                    this.systemRestoredImage.setVisible(false);
                    this.restoredText.setVisible(false);
                    const state = getPuzzleByKey('life-support-systems', 'Command Deck');
                    this.scene.start(
                        CONSTANTS.SCENES.COMMAND_DECK.LIFE_SUPP_SYSTEMS,
                        state
                    );
                })
            );
        } else {
            this.code = '';
            this.codeField.setText(this.code);

            this.sound.play('failure');

            this.incorrectImage.setVisible(true);
            this.incorrectText.setVisible(true);
            this.disableKeys();

            this.timedEvent = this.time.addEvent({
                delay: 3000,
                callback: this.hideIncorrectAnswerImage,
                callbackScope: this,
                repeat: 0,
            });
        }
    }

    hideIncorrectAnswerImage() {
        this.incorrectImage.setVisible(false);
        this.incorrectText.setVisible(false);

        this.enableKeys();
    }

    disableKeys() {
        this.key_0.disableInteractive();
        this.key_1.disableInteractive();
        this.key_2.disableInteractive();
        this.key_3.disableInteractive();
        this.key_4.disableInteractive();
        this.key_5.disableInteractive();
        this.key_6.disableInteractive();
        this.key_7.disableInteractive();
        this.key_8.disableInteractive();
        this.key_9.disableInteractive();
        this.erase.disableInteractive();
        this.submit.disableInteractive();
    }

    enableKeys() {
        this.key_0.setInteractive();
        this.key_1.setInteractive();
        this.key_2.setInteractive();
        this.key_3.setInteractive();
        this.key_4.setInteractive();
        this.key_5.setInteractive();
        this.key_6.setInteractive();
        this.key_7.setInteractive();
        this.key_8.setInteractive();
        this.key_9.setInteractive();
        this.erase.setInteractive();
        this.submit.setInteractive();
    }

    setSolvedState() {
        this.isSolved = true;
        this.disableKeys();
        this.codeField.setText(this.puzzleData.solution);
    }
}

import React from 'react';

import './styles.scss';

const VoteStatement = ({ statement }) => {
    return (
        <span>
            {statement.split(' ').map((word, index) =>
                word[0] === '+' ? (
                    <span key={index} className="different-color">
                        {word.substring(1)}{' '}
                    </span>
                ) : (
                    `${word} `
                )
            )}
        </span>
    );
};

export default VoteStatement;

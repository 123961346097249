import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const ENVIRONMENT = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;

let init;
if (ENVIRONMENT === 'local') {
    init = () => {
        console.log('Sentry not initiated.');
    };
} else {
    // eslint-disable-next-line
    const { SENTRY } = require(`../../deploy/config.${ENVIRONMENT}.json`);

    init = () => {
        if (SENTRY) {
            Sentry.init({
                integrations: [new Integrations.BrowserTracing()],
                ...SENTRY,
            });
        }
    };
}

export default init;

import store from 'store';
import { fetchAllSessions } from 'store/statusReducer/actions';
import { connectSocket, WEBSOCKET_PLAYERS } from './index';

let playerSocket;

let connectionSubscription = false;
let actionSubscription = {};
let disconnectSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    actionSubscription = {};
    disconnectSubscription = false;
};

export const connect = gameId => {
    playerSocket = connectSocket(WEBSOCKET_PLAYERS, gameId);
};

export const onConnect = cb => {
    if (!playerSocket || connectionSubscription) return true;

    playerSocket.on('connect', () => {
        console.log('Player Socket Connected');
        cb();
    });
};

export const isConnected = () => {
    return playerSocket?.connected || false;
};

export const sendAction = (action, user, senderId, text) => {
    if (!playerSocket) return true;

    const { status } = store.getState().statusReducer;
    const currentSenderStatus = status[senderId]?.type;
    if (action === currentSenderStatus) return true;

    playerSocket.emit(action.toLowerCase(), {
        user,
        senderId,
        text,
    });
};

export const onAction = (action, cb) => {
    if (!playerSocket || (action in actionSubscription && actionSubscription[action]))
        return true;

    playerSocket.on(action.toLowerCase(), data => {
        cb({ type: action, ...data });
        store.dispatch(fetchAllSessions());
    });

    actionSubscription[action] = true;
};

export const onDisconnect = () => {
    if (!playerSocket || disconnectSubscription) return true;

    playerSocket.on('disconnect', () => {
        console.log('Player Socket Disconnected');
        resetAllSubscriptions();
        playerSocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!playerSocket) return true;

    console.log('Player Socket Disconnected');
    resetAllSubscriptions();
    playerSocket.removeAllListeners();
    playerSocket.disconnect();
};

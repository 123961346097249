import PHASER from 'phaser';

export default class Loader extends PHASER.Scene {
    constructor() {
        super({
            key: 'loader',
        });
        this.isLoaderComplete = false;
    }

    init() {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
    }

    preload() {
        this.loadMapAssets();
        this.createProgressbar(this.center.x, this.center.y);
    }

    loadMapAssets() {
        this.load.image('blue_border', '/assets/map/blue-border.png');
        this.load.image('map_main', '/assets/map/map-main.png');
        this.load.image('mars_fog', '/assets/map/mars-fog.png');
        this.load.image('cd_fog', '/assets/map/cd-fog.png');
        this.load.image('unlocked', '/assets/map/unlocked.png');
        this.load.image('unlocked_hover', '/assets/map/unlocked-hover.png');
        this.load.image('unlocked_info', '/assets/map/unlocked-info.png');
        this.load.image('locked', '/assets/map/locked.png');
        this.load.image('locked_hover', '/assets/map/locked-hover.png');
        this.load.image('locked_info', '/assets/map/locked-info.png');
        this.load.image('completed', '/assets/map/completed-checkmark.png');
        this.load.image('completed_hover', '/assets/map/completed-checkmark-hover.png');
        this.load.image('completed_info', '/assets/map/completed-info.png');
        this.load.image('360_button', '/assets/map/360-button.png');
        this.load.image('legend', '/assets/map/legend.png');
        this.load.audio('locked', '/assets/sfx/map/locked.wav');
        this.load.audio('unlocked', '/assets/sfx/map/unlocked.wav');
        this.load.audio('completed', '/assets/sfx/map/completed.wav');
    }

    createProgressbar(x, y) {
        const width = 300;
        const height = 20;
        const xStart = x - width / 2;
        const yStart = y - height / 2;

        const borderOffset = 2;

        const borderRect = new PHASER.Geom.Rectangle(
            xStart - borderOffset,
            yStart - borderOffset,
            width + borderOffset * 2,
            height + borderOffset * 2
        );

        const border = this.add.graphics({
            lineStyle: {
                width: 5,
                color: 0xaaaaaa,
            },
        });
        border.strokeRectShape(borderRect);

        const progressbar = this.add.graphics();

        const updateProgressbar = function (percentage) {
            progressbar.clear();
            progressbar.fillStyle(0xffffff, 1);
            progressbar.fillRect(xStart, yStart, percentage * width, height);
        };

        this.load.on('progress', updateProgressbar);

        this.load.once(
            'complete',
            function () {
                this.load.off('progress', updateProgressbar);
                this.isLoaderComplete = true;
                this.scene.start('map');
            },
            this
        );
    }
}

import PHASER from 'phaser';

import store from 'store';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class BreakerPanel extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.BREAKER_PANEL,
        });

        this.isSolved = false;
        this.leftSwitchState = false;
        this.middleSwitchState = false;
        this.rightSwitchState = true;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('bp_bg', '/assets/puzzles/breaker-panel/bp-bg.png');
        this.load.image('switch_down', '/assets/puzzles/breaker-panel/switch-down.png');
        this.load.image('switch_up', '/assets/puzzles/breaker-panel/switch-up.png');
        this.load.image('bp_bg', '/assets/puzzles/breaker-panel/bp-bg.png');
        this.load.image('light_on', '/assets/puzzles/breaker-panel/light-on.png');
        this.load.image(
            'bp_outer_view',
            '/assets/puzzles/breaker-panel/bp-outer-view.png'
        );
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.bp_outer_view = this.add
            .image(this.center.x, this.center.y, 'bp_outer_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setDepth(1);

        this.bp_bg = this.add
            .image(this.center.x, this.center.y, 'bp_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.setupPuzzle();
        store.dispatch(setBackButtonCb());
    }

    setupPuzzle() {
        const state = getPuzzleByKey('fuse-box', this.puzzle_data.room);

        if (state) {
            // fuse-box state should be completed but for now we're checking for unlocked
            if (state.status === 'completed') {
                this.bp_outer_view.setVisible(false);
                this.createBreakerSwitchPanels();

                if (this.puzzle_data.status === 'completed') {
                    this.setSolvedState();
                }
            } else {
                this.bp_outer_view.setVisible(true);
            }
        }
    }

    createBreakerSwitchPanels() {
        this.createLeftSwitchPanel();
        this.createMiddleSwitchPanel();
        this.createRightSwitchPanel();
    }

    createLeftSwitchPanel() {
        this.leftSwitchOff = this.add
            .image(this.center.x, this.center.y + this.game_size.y * 0.26, 'switch_down')
            .setVisible(true)
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.leftSwitchToggled());

        this.leftSwitchOn = this.add
            .image(
                this.center.x + this.game_size.x * 0.005,
                this.center.y - this.game_size.y * 0.13,
                'switch_up'
            )
            .setInteractive()
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.leftSwitchToggled());

        this.leftSwitchLight = this.add
            .image(
                this.center.x + this.game_size.x * 0.001,
                this.center.y - this.game_size.y * 0.374,
                'light_on'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);
    }

    createMiddleSwitchPanel() {
        this.MiddleSwitchOff = this.add
            .image(
                this.center.x + this.game_size.x * 0.17,
                this.center.y + this.game_size.y * 0.26,
                'switch_down'
            )
            .setVisible(true)
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.middleSwitchToggled());

        this.MiddleSwitchOn = this.add
            .image(
                this.center.x + this.game_size.x * 0.175,
                this.center.y - this.game_size.y * 0.13,
                'switch_up'
            )
            .setInteractive()
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.middleSwitchToggled());

        this.MiddleSwitchLight = this.add
            .image(
                this.center.x + this.game_size.x * 0.165,
                this.center.y - this.game_size.y * 0.374,
                'light_on'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);
    }

    createRightSwitchPanel() {
        this.RightSwitchOff = this.add
            .image(
                this.center.x + this.game_size.x * 0.333,
                this.center.y + this.game_size.y * 0.26,
                'switch_down'
            )
            .setInteractive()
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.rightSwitchToggled());

        this.RightSwitchOn = this.add
            .image(
                this.center.x + this.game_size.x * 0.333,
                this.center.y - this.game_size.y * 0.13,
                'switch_up'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.rightSwitchToggled());

        this.RightSwitchLight = this.add
            .image(
                this.center.x + this.game_size.x * 0.33,
                this.center.y - this.game_size.y * 0.374,
                'light_on'
            )
            .setVisible(true)
            .setScale(this.scale_factor.x, this.scale_factor.y);
    }

    leftSwitchToggled() {
        if (this.isSolved) return;

        this.leftSwitchState = !this.leftSwitchState;

        if (this.leftSwitchState) {
            this.leftSwitchLight.setVisible(true);

            this.leftSwitchOn.setVisible(true);
            this.leftSwitchOff.setVisible(false);
        } else {
            this.leftSwitchLight.setVisible(false);

            this.leftSwitchOn.setVisible(false);
            this.leftSwitchOff.setVisible(true);
        }
        this.checkForSolved();
    }

    middleSwitchToggled() {
        if (this.isSolved) return;

        this.middleSwitchState = !this.middleSwitchState;

        if (this.middleSwitchState) {
            this.MiddleSwitchLight.setVisible(true);

            this.MiddleSwitchOn.setVisible(true);
            this.MiddleSwitchOff.setVisible(false);
        } else {
            this.MiddleSwitchLight.setVisible(false);

            this.MiddleSwitchOn.setVisible(false);
            this.MiddleSwitchOff.setVisible(true);
        }
        this.checkForSolved();
    }

    rightSwitchToggled() {
        if (this.isSolved) return;

        this.rightSwitchState = !this.rightSwitchState;

        if (this.rightSwitchState) {
            this.RightSwitchLight.setVisible(true);

            this.RightSwitchOn.setVisible(true);
            this.RightSwitchOff.setVisible(false);
        } else {
            this.RightSwitchLight.setVisible(false);

            this.RightSwitchOn.setVisible(false);
            this.RightSwitchOff.setVisible(true);
        }
        this.checkForSolved();
    }

    checkForSolved() {
        if (this.leftSwitchState && this.middleSwitchState && this.rightSwitchState) {
            this.isSolved = true;
            store.dispatch(markPuzzleSolved(this.puzzle_data.id));
            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzle_data,
                puzzle: 'BREAKER PANEL',
            });
            // --- >
        }
    }

    setSolvedState() {
        this.isSolved = true;

        this.RightSwitchOn.setVisible(true);
        this.RightSwitchOff.setVisible(false);
        this.RightSwitchLight.setVisible(true);

        this.leftSwitchOn.setVisible(true);
        this.leftSwitchOff.setVisible(false);
        this.leftSwitchLight.setVisible(true);
    }
}

import * as ACTIONS from './action_types';

const initialState = {
    fetching: false,
    user: {},
    token: '',
    authState: false,
    gameId: '',
    authMessage: '',
    generatedGameIds: [],
};

const authReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.SET_PROCESSING:
            return {
                ...state,
                fetching: payload,
            };
        case ACTIONS.SET_CURRENT_USER:
            return {
                ...state,
                user: { ...payload, isPlayer: payload.type === 'player' },
            };
        case ACTIONS.AUTH_STATUS:
            return {
                ...state,
                authState: payload,
            };
        case ACTIONS.SET_CURRENT_TOKEN:
            return {
                ...state,
                token: payload,
            };
        case ACTIONS.SET_GAME_ID:
            return {
                ...state,
                gameId: payload,
            };
        case ACTIONS.SET_AUTH_MESSAGE:
            return {
                ...state,
                authMessage: payload,
            };
        case ACTIONS.SET_GENERATED_GAME_ID:
            return {
                ...state,
                generatedGameIds: payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default authReducer;

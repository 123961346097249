import PHASER from 'phaser';

import { GAME_VIDEOS_URL, ITEM_ID_MAP } from 'constants.js';

import { normalizeAngle } from 'utils/normalization';

import store from 'store';
import {
    markPuzzleSolved,
    setBackButtonCb,
    setMissionControlDisplay,
} from 'store/puzzleReducer/actions';
import { setCurrentVideos } from 'store/videosReducer/actions';
import { unlockItem } from 'store/inventoryReducer/actions';
import { blockInteraction, unblockInteraction } from 'store/interactionReducer/actions';

import { puzzleStateSocket, timerSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import PlayerData from './player-data';
import CONSTANTS from '../../../constants';

let dragging = false;
export default class SelfDestruct extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.SELF_DESTRUCT,
        });

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.is_solved = false;
        this.lever_state = false;
        this.switch_state = { 0: false, 1: false };
        this.button_state = false;
        this.myTasks = [];
        this.myPlayerNumber = 0;
        this.totalPlayers = 0;
        this.phase1_complete = false;
        this.phase2_complete = false;
        this.phase3_complete = false;

        this.puzzle_state = {
            PUSH_RED_BUTTON: false,
            FLIP_SWITCH_AB: false,
            TURN_KNOB_4_TO_W: false,
            PULL_GREEN_LEVER_DOWN: false,
            PUSH_YELLOW_BUTTON: false,
            FLIP_SWITCH_CD: false,
            TURN_KNOB_1_TO_S: false,
            PULL_BLUE_LEVER_UP: false,
        };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;

        const {
            authReducer: { user },
            statusReducer: { activePlayers },
        } = store.getState();
        console.log(activePlayers);
        this.myId = user.id;
        this.playerIds = activePlayers.map(player => player.id);
        this.totalPlayers = this.playerIds.length;
        this.myPlayerNumber = this.getMyPlayerNumber();

        puzzleStateSocket.onStateUpdate(data => {
            const {
                states: { state },
            } = data;
            this.applyState(state);
        });

        timerSocket.onPuzzleTimerUpdate(time => this.updateTimer(time));
        timerSocket.onPuzzleTimerStop(() => {
            this.timerFinished();
        });

        store.dispatch(blockInteraction());
    }

    joinGame() {
        const {
            authReducer: { user },
        } = store.getState();
        puzzleStateSocket.joinGame(user.id, user, this.puzzle_state);
    }

    preload() {
        this.load.image('phase_light', '/assets/puzzles/self-destruct/phase-light.png');
        this.load.image(
            'dial_container',
            '/assets/puzzles/self-destruct/dial-container.png'
        );
        this.load.image('timer_info', '/assets/puzzles/self-destruct/timer-info.png');

        this.load.image('sd_bg', '/assets/puzzles/self-destruct/sd-bg.png');
        this.load.image('dial_1', '/assets/puzzles/self-destruct/dial1.png');
        this.load.image('dial_2', '/assets/puzzles/self-destruct/dial2.png');
        this.load.image('dial_3', '/assets/puzzles/self-destruct/dial3.png');
        this.load.image('dial_4', '/assets/puzzles/self-destruct/dial4.png');
        this.load.image('dial_5', '/assets/puzzles/self-destruct/dial5.png');
        this.load.image('dial_6', '/assets/puzzles/self-destruct/dial6.png');
        this.load.image('dial_7', '/assets/puzzles/self-destruct/dial7.png');
        this.load.image('dial_8', '/assets/puzzles/self-destruct/dial8.png');
        this.load.image('dial_9', '/assets/puzzles/self-destruct/dial9.png');

        this.load.image(
            'lever_black_up',
            '/assets/puzzles/self-destruct/lever-black-up.png'
        );
        this.load.image(
            'lever_blue_up',
            '/assets/puzzles/self-destruct/lever-blue-up.png'
        );
        this.load.image(
            'lever_brown_up',
            '/assets/puzzles/self-destruct/lever-brown-up.png'
        );
        this.load.image(
            'lever_green_up',
            '/assets/puzzles/self-destruct/lever-green-up.png'
        );
        this.load.image(
            'lever_purple_up',
            '/assets/puzzles/self-destruct/lever-purple-up.png'
        );
        this.load.image(
            'lever_white_up',
            '/assets/puzzles/self-destruct/lever-white-up.png'
        );
        this.load.image('lever_red_up', '/assets/puzzles/self-destruct/lever-red-up.png');

        this.load.image(
            'lever_red_down',
            '/assets/puzzles/self-destruct/lever-red-down.png'
        );
        this.load.image(
            'lever_black_down',
            '/assets/puzzles/self-destruct/lever-black-down.png'
        );
        this.load.image(
            'lever_blue_down',
            '/assets/puzzles/self-destruct/lever-blue-down.png'
        );
        this.load.image(
            'lever_brown_down',
            '/assets/puzzles/self-destruct/lever-brown-down.png'
        );
        this.load.image(
            'lever_green_down',
            '/assets/puzzles/self-destruct/lever-green-down.png'
        );
        this.load.image(
            'lever_purple_down',
            '/assets/puzzles/self-destruct/lever-purple-down.png'
        );
        this.load.image(
            'lever_white_down',
            '/assets/puzzles/self-destruct/lever-white-down.png'
        );
        this.load.image(
            'lever_yellow_down',
            '/assets/puzzles/self-destruct/lever-yellow-down.png'
        );
        this.load.image(
            'lever_yellow_up',
            '/assets/puzzles/self-destruct/lever-yellow-up.png'
        );

        this.load.image(
            'button_red_off',
            '/assets/puzzles/self-destruct/button-red-off.png'
        );
        this.load.image(
            'button_black_off',
            '/assets/puzzles/self-destruct/button-black-off.png'
        );
        this.load.image(
            'button_blue_off',
            '/assets/puzzles/self-destruct/button-blue-off.png'
        );
        this.load.image(
            'button_green_off',
            '/assets/puzzles/self-destruct/button-green-off.png'
        );
        this.load.image(
            'button_orange_off',
            '/assets/puzzles/self-destruct/button-orange-off.png'
        );
        this.load.image(
            'button_purple_off',
            '/assets/puzzles/self-destruct/button-purple-off.png'
        );
        this.load.image(
            'button_white_off',
            '/assets/puzzles/self-destruct/button-white-off.png'
        );
        this.load.image(
            'button_yellow_off',
            '/assets/puzzles/self-destruct/button-yellow-off.png'
        );

        this.load.image(
            'button_red_on',
            '/assets/puzzles/self-destruct/button-red-on.png'
        );
        this.load.image(
            'button_black_on',
            '/assets/puzzles/self-destruct/button-black-on.png'
        );
        this.load.image(
            'button_blue_on',
            '/assets/puzzles/self-destruct/button-blue-on.png'
        );
        this.load.image(
            'button_green_on',
            '/assets/puzzles/self-destruct/button-green-on.png'
        );
        this.load.image(
            'button_orange_on',
            '/assets/puzzles/self-destruct/button-orange-on.png'
        );
        this.load.image(
            'button_purple_on',
            '/assets/puzzles/self-destruct/button-purple-on.png'
        );
        this.load.image(
            'button_white_on',
            '/assets/puzzles/self-destruct/button-white-on.png'
        );
        this.load.image(
            'button_yellow_on',
            '/assets/puzzles/self-destruct/button-yellow-on.png'
        );

        this.load.image('sd_switch_off', '/assets/puzzles/self-destruct/switch-off.png');
        this.load.image('sd_switch_on', '/assets/puzzles/self-destruct/switch-on.png');

        this.load.image('light-green', '/assets/puzzles/self-destruct/light-green.png');
        this.load.image('light-red', '/assets/puzzles/self-destruct/light-red.png');

        this.load.audio(
            'sd_button',
            '/assets/sfx/puzzles/self-destruct/sd-push-button.wav'
        );
    }

    create() {
        this.joinGame();

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.sd_bg = this.add
            .image(this.center.x, this.center.y, 'sd_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.timer_text = this.add
            .text(
                this.center.x + this.game_size.x * 0.29,
                this.center.y - this.game_size.y * 0.41,
                '00:00',
                {
                    fontFamily: 'Ethnocentric',
                    fontSize: 25,
                    align: 'center',
                }
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.timer_info = this.add
            .image(
                this.center.x + this.game_size.x * 0.15,
                this.center.y - this.game_size.y * 0.38,
                'timer_info'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createPuzzlePieces();
        this.createPhaseLights();
        this.getMyTasks();

        // if I am the first player in the player list then I start the timer
        if (this.myPlayerNumber === 1) {
            this.startTimer();
        }

        store.dispatch(setBackButtonCb());
    }

    createPuzzlePieces() {
        if (this.totalPlayers < 2) return;

        const myAssets =
            PlayerData.PLAYER_SCENARIOS[this.totalPlayers].Players[
                `Player_${this.myPlayerNumber}`
            ].Assets;

        this.dial_container = {};
        this.dial = {};
        myAssets.knobs.forEach((k, i) => {
            this.dial_container[i] = this.add
                .image(
                    this.center.x - this.game_size.x * (0.387 - i * 0.167),
                    this.center.y - this.game_size.y * 0.05,
                    'dial_container'
                )
                .setScale(this.scale_factor.y)
                .setInteractive({ draggable: true })
                .on('dragstart', () => {
                    this.rotateKnob(i);
                })
                .on('drag', () => {
                    this.rotateKnob(i);
                })
                .on('dragend', () => {
                    this.snapToPosition(i, k);
                });

            this.dial[i] = this.add
                .image(
                    this.center.x - this.game_size.x * (0.387 - i * 0.167),
                    this.center.y - this.game_size.y * 0.049,
                    `dial_${k}`
                )
                .setScale(this.scale_factor.x, this.scale_factor.y)
                .setDepth(2);
        });

        // lever
        this.lever_up = this.add
            .image(
                this.center.x - this.game_size.x * 0.1,
                this.center.y - this.game_size.y * 0.14,
                `lever_${myAssets.lever.color}_up`
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.onLeverClicked(myAssets.lever);
            });

        this.lever_down = this.add
            .image(
                this.center.x - this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.05,
                `lever_${myAssets.lever.color}_down`
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false)
            .setInteractive()
            .on('pointerdown', () => {
                this.onLeverClicked(myAssets.lever);
            });

        if (myAssets.lever.state === 'down') {
            this.lever_up.setVisible(false);
            this.lever_down.setVisible(true);

            // true is for lever down while false is for lever up
            this.lever_state = true;
        }

        // switch
        this.sd_switch_off = {};
        this.sd_switch_on = {};
        this.switch_text_1 = {};
        this.switch_text_2 = {};

        myAssets.switches.forEach((s, i) => {
            this.sd_switch_off[i] = this.add
                .image(
                    this.center.x - this.game_size.x * (0.172 - i * 0.1),
                    this.center.y + this.game_size.y * 0.315,
                    'sd_switch_off'
                )
                .setScale(this.scale_factor.x, this.scale_factor.y)
                .setInteractive()
                .on('pointerdown', () => {
                    this.onSwitchClicked(i, s);
                });

            this.sd_switch_on[i] = this.add
                .image(
                    this.center.x - this.game_size.x * (0.172 - i * 0.1),
                    this.center.y + this.game_size.y * 0.315,
                    'sd_switch_on'
                )
                .setScale(this.scale_factor.x, this.scale_factor.y)
                .setInteractive()
                .setVisible(false)
                .on('pointerdown', () => {
                    this.onSwitchClicked(i, s);
                });

            this.switch_text_1[i] = this.add
                .text(
                    this.center.x - this.game_size.x * (0.202 - i * 0.102),
                    this.center.y + this.game_size.y * 0.225,
                    s[0],
                    { fontFamily: 'Ethnocentric', fontSize: 35, align: 'center' }
                )
                .setColor('#ffffff')
                .setScale(this.scale_factor.x, this.scale_factor.y)
                .setFixedSize(50, 50);

            this.switch_text_2[i] = this.add
                .text(
                    this.center.x - this.game_size.x * (0.202 - i * 0.102),
                    this.center.y + this.game_size.y * 0.325,
                    s[1],
                    { fontFamily: 'Ethnocentric', fontSize: 35, align: 'center' }
                )
                .setColor('#ffffff')
                .setScale(this.scale_factor.x, this.scale_factor.y)
                .setFixedSize(50, 50);

            this.green_light = this.add
                .image(
                    this.center.x - this.game_size.x * (0.171 - i * 0.1),
                    this.center.y + this.game_size.y * 0.183,
                    'light-green'
                )
                .setScale(this.scale_factor.x, this.scale_factor.y);

            this.red_light = this.add
                .image(
                    this.center.x - this.game_size.x * (0.171 - i * 0.1),
                    this.center.y + this.game_size.y * 0.445,
                    'light-red'
                )
                .setScale(this.scale_factor.x, this.scale_factor.y);
        });

        // button
        this.button_off = this.add
            .image(
                this.center.x - this.game_size.x * 0.355,
                this.center.y + this.game_size.y * 0.325,
                `button_${myAssets.button}_off`
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.onButtonClicked(myAssets.button);
            });

        this.button_on = this.add
            .image(
                this.center.x - this.game_size.x * 0.355,
                this.center.y + this.game_size.y * 0.325,
                `button_${myAssets.button}_on`
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.onButtonClicked(myAssets.button);
            });

        this.green_light = this.add
            .image(
                this.center.x - this.game_size.x * 0.071,
                this.center.y + this.game_size.y * 0.183,
                'light-green'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.red_light = this.add
            .image(
                this.center.x - this.game_size.x * 0.071,
                this.center.y + this.game_size.y * 0.445,
                'light-red'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);
    }

    createPhaseLights() {
        [0.388, 0.282, 0.176, 0.07].forEach((x, index) => {
            this[`phase_light_${index + 1}`] = this.add
                .image(
                    this.center.x - this.game_size.x * x,
                    this.center.y - this.game_size.y * 0.347,
                    'phase_light'
                )
                .setVisible(false)
                .setScale(this.scale_factor.x, this.scale_factor.y);
        });
    }

    onLeverClicked(lever) {
        const updatedState = {};
        const taskKey = `PULL_${lever.color.toUpperCase()}_LEVER_${
            lever.state === 'down' ? 'UP' : 'DOWN'
        }`;

        if (this.myTasks.includes(taskKey)) {
            if (this.puzzle_state[taskKey] === true) {
                return;
            }

            updatedState[taskKey] = true;
        }

        this.sound.play('sd_switch_lever', { volume: 0.3 });
        this.lever_state = !this.lever_state;

        if (this.lever_state) {
            this.lever_down.setVisible(true);
            this.lever_up.setVisible(false);
        } else {
            this.lever_down.setVisible(false);
            this.lever_up.setVisible(true);
        }

        this.sendState(updatedState);
    }

    onSwitchClicked(index, switchObject) {
        const updatedState = {};
        const taskKey = `FLIP_SWITCH_${switchObject[0]}${switchObject[1]}`;

        this.sound.play('sd_switch_lever', { volume: 0.3 });
        if (this.myTasks.includes(taskKey)) {
            if (this.puzzle_state[taskKey] === true) {
                return;
            }
            updatedState[taskKey] = true;
        }

        this.switch_state[index] = !this.switch_state[index];

        if (this.switch_state[index]) {
            this.sd_switch_on[index].setVisible(true);
            this.sd_switch_off[index].setVisible(false);
        } else {
            this.sd_switch_on[index].setVisible(false);
            this.sd_switch_off[index].setVisible(true);
        }

        this.sendState(updatedState);
    }

    onButtonClicked(button) {
        // we check the tasks assigned to us and their states in the db
        const updatedState = {};
        const taskKey = `PUSH_${button.toUpperCase()}_BUTTON`;

        this.sound.play('sd_button', { volume: 0.2 });
        if (this.myTasks.includes(taskKey)) {
            if (this.puzzle_state[taskKey] === true) {
                return;
            }
            updatedState[taskKey] = true;
        }

        this.button_state = !this.button_state;

        if (this.button_state) {
            this.button_on.setVisible(true);
            this.button_off.setVisible(false);
        } else {
            this.button_on.setVisible(false);
            this.button_off.setVisible(true);
        }

        this.sendState(updatedState);
    }

    verifyKnobSequence(index, knob) {
        const updatedState = {};
        const correctKnobSequence = {
            4: { angle: -90, direction: 'W' },
            7: { angle: 90, direction: 'E' },
            1: { angle: -180, direction: 'S' },
            9: { angle: -90, direction: 'W' },
        };

        const taskKey = `TURN_KNOB_${knob}_TO_${correctKnobSequence[knob].direction}`;

        if (this.myTasks.includes(taskKey)) {
            if (this.dial_container[index].angle === correctKnobSequence[knob].angle) {
                if (this.puzzle_state[taskKey] === true) {
                    return;
                }
                this.puzzle_state[taskKey] = true;
                updatedState[taskKey] = true;
                this.input.setDraggable([this.dial_container[index]], false);
            }
        }

        this.sendState(updatedState);
    }

    rotateKnob(index) {
        if (this.is_solved) return;

        const angle = this.getTargetAngle(this.dial_container[index]);

        if (this.game.input.activePointer.isDown && !dragging) {
            dragging = true;
        } else if (!this.game.input.activePointer.isDown && dragging) {
            dragging = false;
        }

        if (dragging) {
            this.dial_container[index].angle = angle;
        }
    }

    getTargetAngle(dial_container) {
        let targetAngle =
            (360 / (2 * Math.PI)) *
                PHASER.Math.Angle.Between(
                    dial_container.x,
                    dial_container.y,
                    this.game.input.activePointer.x,
                    this.game.input.activePointer.y
                ) +
            90;

        if (targetAngle < 0) targetAngle += 360;

        return targetAngle;
    }

    snapToPosition(index, knob) {
        if (this.isSolved) return;

        this.sound.play('sd_turn_knob', { volume: 0.3 });
        let { angle } = this.dial_container[index];
        if (angle < 0) angle += 360;

        angle = normalizeAngle(angle, 90);
        this.dial_container[index].angle = angle;

        this.verifyKnobSequence(index, knob);
    }

    formatTime(seconds) {
        const minutes = Math.floor(seconds / 60)
            .toString()
            .padStart(2, '0');
        let partInSeconds = seconds % 60;
        partInSeconds = partInSeconds.toString().padStart(2, '0');
        return `${minutes}:${partInSeconds}`;
    }

    getMyPlayerNumber() {
        // get my player number from the players list
        for (let i = 0; i < this.playerIds.length; i += 1) {
            if (this.myId === this.playerIds[i]) {
                const myPlayerNum = i + 1;
                return myPlayerNum;
            }
        }
    }

    getMyTasks() {
        if (this.totalPlayers < 2) return;

        Object.entries(PlayerData.PLAYER_SCENARIOS[this.totalPlayers].Tasks).forEach(
            ([key, value]) => {
                if (value === this.myPlayerNumber) {
                    this.myTasks.push(key);
                }
            }
        );
    }

    startTimer() {
        timerSocket.startPuzzleTimer(120, this.puzzle_data.id);
    }

    updateTimer(time) {
        if (this.timer_text) {
            const formatedTime = this.formatTime(time);
            this.timer_text.setText(formatedTime);
        }
    }

    timerFinished() {
        store.dispatch(unblockInteraction());
        store.dispatch(unlockItem(ITEM_ID_MAP.SOLAR_READOUT));

        const overrideVideoUrl = `${GAME_VIDEOS_URL}SD-Override-BE_hls/index.m3u8`;
        store.dispatch(setCurrentVideos([overrideVideoUrl]));

        setTimeout(function () {
            store.dispatch(setMissionControlDisplay(null));
        }, 5000);
    }

    sendState(updatedState) {
        puzzleStateSocket.update(updatedState);
    }

    applyState(state) {
        this.is_solved = state.isSolved || this.is_solved;
        this.puzzle_state = { ...state };
        this.verifyPhaseCompletion(this.puzzle_state);
    }

    verifyPhaseCompletion(state) {
        // phase 1
        if (
            state.PUSH_RED_BUTTON === true &&
            state.FLIP_SWITCH_AB === true &&
            state.TURN_KNOB_4_TO_W === true
        ) {
            this.phase_light_1?.setVisible(true);
            this.phase1_complete = true;
        } else {
            this.phase_light_1?.setVisible(false);
            this.phase1_complete = false;
        }

        // phase 2
        if (this.phase1_complete) {
            if (
                state.PULL_GREEN_LEVER_DOWN === true &&
                state.TURN_KNOB_7_TO_E === true &&
                state.FLIP_SWITCH_EF
            ) {
                this.phase_light_2?.setVisible(true);
                this.phase2_complete = true;
            } else {
                this.phase_light_2?.setVisible(false);
                this.phase2_complete = false;
            }
        }

        // phase 3
        if (this.phase1_complete && this.phase2_complete) {
            if (
                state.FLIP_SWITCH_CD === true &&
                state.TURN_KNOB_1_TO_S === true &&
                state.PULL_BLUE_LEVER_UP === true
            ) {
                this.phase_light_3?.setVisible(true);
                this.phase3_complete = true;
            } else {
                this.phase_light_3?.setVisible(false);
                this.phase3_complete = false;
            }
        }

        // phase 4
        if (this.phase1_complete && this.phase2_complete && this.phase3_complete) {
            if (
                state.TURN_KNOB_9_TO_W === true &&
                state.FLIP_SWITCH_GH === true &&
                state.PUSH_YELLOW_BUTTON === true
            ) {
                this.phase_light_4?.setVisible(true);
                this.phase4_complete = true;
            } else {
                this.phase_light_4?.setVisible(false);
                this.phase4_complete = false;
            }
        }

        if (this.is_solved) {
            store.dispatch(unblockInteraction());
            timerSocket.puzzleTimerOff();
            return;
        }

        // final check
        if (
            this.phase1_complete &&
            this.phase2_complete &&
            this.phase3_complete &&
            this.phase4_complete
        ) {
            store.dispatch(unblockInteraction());
            timerSocket.puzzleTimerOff();
            this.sendState({ isSolved: true });
            store.dispatch(markPuzzleSolved(this.puzzle_data.id));

            Mixpanel.track('Puzzle Complete', {
                ...this.puzzle_data,
                puzzle: 'SELF DESTRUCT',
            });

            store.dispatch(unlockItem(ITEM_ID_MAP.SOLAR_READOUT));
        }
    }
}

import io from 'socket.io-client';

import * as chatSocket from './chat';
import * as voteSocket from './vote';
import * as inventorySocket from './inventory';
import * as timerSocket from './timer';
import * as playerSocket from './players';
import * as puzzlesSocket from './puzzles';
import * as puzzleStateSocket from './puzzles/state';

import { IP } from '../env';

export const WEBSOCKET_CHAT = `${IP}/chat`;
export const WEBSOCKET_VOTE = `${IP}/vote`;
export const WEBSOCKET_INVENTORY = `${IP}/inventory`;
export const WEBSOCKET_TIMER = `${IP}/timer`;
export const WEBSOCKET_PLAYERS = `${IP}/players`;
export const WEBSOCKET_PUZZLES = `${IP}/puzzles`;
export const WEBSOCKET_PUZZLE_STATE = `${IP}/states`;

export const connectSocket = (url, gameId, puzzleId) => {
    return io(url, {
        path: '/websockets',
        query: `gameId=${gameId}${puzzleId ? `&puzzleId=${puzzleId}` : ''}`,
        transports: ['polling', 'websocket'],
        transportOptions: {
            polling: {
                extraHeaders: {
                    authorization: 'bearer 12123',
                },
            },
        },
    });
};

export {
    chatSocket,
    voteSocket,
    inventorySocket,
    timerSocket,
    playerSocket,
    puzzlesSocket,
    puzzleStateSocket,
};

export default Object.freeze({
    PLAYER_SCENARIOS: {
        2: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'G', 1: 'H' },
                        ],
                        knobs: ['4', '7'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'E', 1: 'F' },
                        ],
                        knobs: ['1', '9'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 1,
                PULL_GREEN_LEVER_DOWN: 2,
                TURN_KNOB_7_TO_E: 1,
                FLIP_SWITCH_EF: 2,
                FLIP_SWITCH_CD: 1,
                TURN_KNOB_1_TO_S: 2,
                PULL_BLUE_LEVER_UP: 1,
                TURN_KNOB_9_TO_W: 2,
                FLIP_SWITCH_GH: 1,
                PUSH_YELLOW_BUTTON: 2,
            },
        },
        3: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'I', 1: 'J' },
                        ],
                        knobs: ['5', '9'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'G', 1: 'H' },
                        ],
                        knobs: ['1', '7'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['2', '4'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 1,
                TURN_KNOB_7_TO_E: 2,
                FLIP_SWITCH_EF: 3,
                FLIP_SWITCH_CD: 1,
                TURN_KNOB_1_TO_S: 2,
                PULL_BLUE_LEVER_UP: 3,
                TURN_KNOB_9_TO_W: 1,
                FLIP_SWITCH_GH: 2,
                PUSH_YELLOW_BUTTON: 3,
            },
        },
        4: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['3', '7'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'red', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'E', 1: 'F' },
                        ],
                        knobs: ['5', '9'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'G', 1: 'H' },
                        ],
                        knobs: ['4', '6'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'O', 1: 'P' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['1', '8'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 1,
                FLIP_SWITCH_EF: 2,
                FLIP_SWITCH_CD: 3,
                TURN_KNOB_1_TO_S: 4,
                PULL_BLUE_LEVER_UP: 1,
                TURN_KNOB_9_TO_W: 2,
                FLIP_SWITCH_GH: 3,
                PUSH_YELLOW_BUTTON: 4,
            },
        },
        5: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'black', state: 'down' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'G', 1: 'H' },
                        ],
                        knobs: ['3', '8'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'red', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'C', 1: 'D' },
                        ],
                        knobs: ['6', '8'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['1', '4'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['2', '9'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['5', '7'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 1,
                FLIP_SWITCH_CD: 2,
                TURN_KNOB_1_TO_S: 3,
                PULL_BLUE_LEVER_UP: 5,
                TURN_KNOB_9_TO_W: 4,
                FLIP_SWITCH_GH: 1,
                PUSH_YELLOW_BUTTON: 2,
            },
        },
        6: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'I', 1: 'J' },
                        ],
                        knobs: ['2', '6'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'red', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['1', '3'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['4', '5'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'O', 1: 'P' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['8', '9'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'black', state: 'down' },
                        switches: [
                            { 0: 'G', 1: 'H' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['3', '7'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['2', '5'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 1,
                TURN_KNOB_1_TO_S: 2,
                PULL_BLUE_LEVER_UP: 3,
                TURN_KNOB_9_TO_W: 4,
                FLIP_SWITCH_GH: 5,
                PUSH_YELLOW_BUTTON: 6,
            },
        },
        7: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'black', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['1', '5'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['6', '8'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'O', 1: 'P' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['4', '9'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'black',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'G', 1: 'H' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['2', '3'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'brown', state: 'down' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['3', '7'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'red', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['5', '8'],
                    },
                },
                Player_7: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['2', '6'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 7,
                TURN_KNOB_1_TO_S: 1,
                PULL_BLUE_LEVER_UP: 2,
                TURN_KNOB_9_TO_W: 3,
                FLIP_SWITCH_GH: 4,
                PUSH_YELLOW_BUTTON: 5,
            },
        },
        8: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'yellow', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['2', '9'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['5', '6'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'G', 1: 'H' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['4', '8'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'O', 1: 'P' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['3', '6'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'red', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['3', '7'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'black',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['3', '5'],
                    },
                },
                Player_7: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'brown', state: 'up' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['2', '6'],
                    },
                },
                Player_8: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['1', '5'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 7,
                TURN_KNOB_1_TO_S: 8,
                PULL_BLUE_LEVER_UP: 2,
                TURN_KNOB_9_TO_W: 1,
                FLIP_SWITCH_GH: 3,
                PUSH_YELLOW_BUTTON: 4,
            },
        },
        9: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'yellow', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['2', '9'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'brown', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'G', 1: 'H' },
                        ],
                        knobs: ['3', '6'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['4', '8'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'black',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['5', '8'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'red', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['2', '7'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['3', '5'],
                    },
                },
                Player_7: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['6', '8'],
                    },
                },
                Player_8: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['1', '3'],
                    },
                },
                Player_9: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['2', '8'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 7,
                TURN_KNOB_1_TO_S: 8,
                PULL_BLUE_LEVER_UP: 9,
                TURN_KNOB_9_TO_W: 1,
                FLIP_SWITCH_GH: 2,
                PUSH_YELLOW_BUTTON: 3,
            },
        },
        10: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'yellow', state: 'down' },
                        switches: [
                            { 0: 'G', 1: 'H' },
                            { 0: 'I', 1: 'J' },
                        ],
                        knobs: ['2', '3'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'brown', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['5', '6'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['4', '5'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'black',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'O', 1: 'P' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['6', '8'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'red', state: 'down' },
                        switches: [
                            { 0: 'Q', 1: 'R' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['7', '8'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['2', '6'],
                    },
                },
                Player_7: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'red', state: 'up' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['3', '8'],
                    },
                },
                Player_8: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'white', state: 'up' },
                        switches: [
                            { 0: 'S', 1: 'T' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['1', '3'],
                    },
                },
                Player_9: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['3', '6'],
                    },
                },
                Player_10: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'Q', 1: 'R' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['5', '9'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 7,
                TURN_KNOB_1_TO_S: 8,
                PULL_BLUE_LEVER_UP: 9,
                TURN_KNOB_9_TO_W: 10,
                FLIP_SWITCH_GH: 1,
                PUSH_YELLOW_BUTTON: 2,
            },
        },
        11: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'yellow', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['2', '3'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'brown', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'I', 1: 'J' },
                        ],
                        knobs: ['3', '5'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['3', '4'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'black',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['5', '6'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'red', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['6', '7'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['5', '8'],
                    },
                },
                Player_7: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['6', '8'],
                    },
                },
                Player_8: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'Q', 1: 'R' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['1', '2'],
                    },
                },
                Player_9: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'U', 1: 'V' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['2', '8'],
                    },
                },
                Player_10: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'red', state: 'up' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['8', '9'],
                    },
                },
                Player_11: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'G', 1: 'H' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['2', '6'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 7,
                TURN_KNOB_1_TO_S: 8,
                PULL_BLUE_LEVER_UP: 9,
                TURN_KNOB_9_TO_W: 10,
                FLIP_SWITCH_GH: 11,
                PUSH_YELLOW_BUTTON: 2,
            },
        },
        12: {
            Players: {
                Player_1: {
                    Assets: {
                        button: 'red',
                        lever: { color: 'yellow', state: 'down' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'O', 1: 'P' },
                        ],
                        knobs: ['2', '3'],
                    },
                },
                Player_2: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'brown', state: 'up' },
                        switches: [
                            { 0: 'A', 1: 'B' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['2', '5'],
                    },
                },
                Player_3: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'white', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'S', 1: 'T' },
                        ],
                        knobs: ['3', '4'],
                    },
                },
                Player_4: {
                    Assets: {
                        button: 'black',
                        lever: { color: 'green', state: 'up' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['2', '6'],
                    },
                },
                Player_5: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'red', state: 'down' },
                        switches: [
                            { 0: 'K', 1: 'L' },
                            { 0: 'W', 1: 'X' },
                        ],
                        knobs: ['2', '7'],
                    },
                },
                Player_6: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'E', 1: 'F' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['3', '5'],
                    },
                },
                Player_7: {
                    Assets: {
                        button: 'orange',
                        lever: { color: 'purple', state: 'up' },
                        switches: [
                            { 0: 'C', 1: 'D' },
                            { 0: 'M', 1: 'N' },
                        ],
                        knobs: ['3', '6'],
                    },
                },
                Player_8: {
                    Assets: {
                        button: 'purple',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'I', 1: 'J' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['1', '8'],
                    },
                },
                Player_9: {
                    Assets: {
                        button: 'white',
                        lever: { color: 'blue', state: 'down' },
                        switches: [
                            { 0: 'O', 1: 'P' },
                            { 0: 'Q', 1: 'R' },
                        ],
                        knobs: ['3', '8'],
                    },
                },
                Player_10: {
                    Assets: {
                        button: 'blue',
                        lever: { color: 'brown', state: 'up' },
                        switches: [
                            { 0: 'S', 1: 'T' },
                            { 0: 'Y', 1: 'Z' },
                        ],
                        knobs: ['3', '9'],
                    },
                },
                Player_11: {
                    Assets: {
                        button: 'green',
                        lever: { color: 'red', state: 'down' },
                        switches: [
                            { 0: 'G', 1: 'H' },
                            { 0: 'K', 1: 'L' },
                        ],
                        knobs: ['5', '6'],
                    },
                },
                Player_12: {
                    Assets: {
                        button: 'yellow',
                        lever: { color: 'black', state: 'up' },
                        switches: [
                            { 0: 'M', 1: 'N' },
                            { 0: 'U', 1: 'V' },
                        ],
                        knobs: ['5', '8'],
                    },
                },
            },
            // numbers represent player number
            Tasks: {
                PUSH_RED_BUTTON: 1,
                FLIP_SWITCH_AB: 2,
                TURN_KNOB_4_TO_W: 3,
                PULL_GREEN_LEVER_DOWN: 4,
                TURN_KNOB_7_TO_E: 5,
                FLIP_SWITCH_EF: 6,
                FLIP_SWITCH_CD: 7,
                TURN_KNOB_1_TO_S: 8,
                PULL_BLUE_LEVER_UP: 9,
                TURN_KNOB_9_TO_W: 10,
                FLIP_SWITCH_GH: 11,
                PUSH_YELLOW_BUTTON: 12,
            },
        },
    },
});

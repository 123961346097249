import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchPuzzleData } from 'store/puzzleReducer/actions';

import MapManager from 'external/phaser/map-manager';

import MapContainerBorder from 'assets/dashboardScreen/Map_ContainerBorder.png';

import './styles.scss';

function Map({ fetchPuzzleData, mapData, isInteractionBlocked }) {
    const [mapObject, setMapObject] = useState(null);

    useEffect(() => {
        fetchPuzzleData();
    }, [fetchPuzzleData]);

    useEffect(() => {
        if (mapData) {
            mapObject
                ? mapObject.updateMap(mapData)
                : setMapObject(new MapManager(mapData));
        }
    }, [mapData, mapObject, isInteractionBlocked]);

    return (
        <div className="map-container">
            <img src={MapContainerBorder} alt="map_container_border" />
            <div className="map-wrapper">
                <div id="map" className="map" />
            </div>
        </div>
    );
}

const mapStateToProps = ({ puzzleReducer, interactionReducer }) => ({
    mapData: puzzleReducer.mapData,
    isInteractionBlocked: interactionReducer.isInteractionBlocked,
});

const mapDispatchToProps = {
    fetchPuzzleData: () => fetchPuzzleData(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

import PHASER from 'phaser';

import store from 'store';
import {
    setCurrentPuzzle,
    updatePuzzleState,
    setCurrentRoom,
    setMissionControlDisplay,
    setBackButtonCb,
} from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

export default class Map extends PHASER.Scene {
    constructor() {
        super({
            key: 'map',
        });
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.info = null;
        this.infoImage = null;
        this.infoText = null;
        this.hotSpots = [];
        this.mars_360_button = null;
        this.airlock_360_button = null;
        this.cd_360_button = null;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.mapData = data;
    }

    create() {
        this.blueBorder = this.add
            .image(this.center.x, this.center.y, 'blue_border')
            .setVisible(false);

        this.setScaleFactor();

        this.mainMap = this.add
            .image(this.center.x, this.center.y, 'map_main')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.hotSpots.length = 0;
        this.createMapPortions();
    }

    createMapPortions() {
        this.MarsText = this.add
            .text(
                this.center.x + this.game_size.x * 0.29,
                this.center.y + this.game_size.y * 0.37,
                'Mars',
                {
                    align: 'center',
                    fontSize: 16,
                    fontFamily: 'Ethnocentric',
                }
            )
            .setColor('#000000')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setResolution(2);

        this.marsFog = this.add
            .image(
                this.center.x + this.game_size.x * 0.231,
                this.center.y + this.game_size.y * 0.185,
                'mars_fog'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0.9);

        this.cdText = this.add
            .text(
                this.center.x - this.game_size.x * 0.225,
                this.center.y - this.game_size.y * 0.415,
                'Command Deck',
                {
                    align: 'center',
                    fontSize: 16,
                    fontFamily: 'Ethnocentric',
                    wordWrap: { width: 120, useAdvancedWrap: true },
                }
            )
            .setColor('#000000')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setResolution(2);

        this.cdFog = this.add
            .image(this.center.x - this.game_size.x * 0.001, this.center.y, 'cd_fog')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0.9);

        this.AirlockText = this.add
            .text(
                this.center.x - this.game_size.x * 0.38,
                this.center.y - this.game_size.y * 0.4,
                'Airlock',
                {
                    align: 'center',
                    fontSize: 16,
                    fontFamily: 'Ethnocentric',
                }
            )
            .setColor('#000000')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setResolution(2);

        this.legend = this.add
            .image(
                this.center.x + this.game_size.x * 0.161,
                this.center.y - this.game_size.y * 0.368,
                'legend'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createHotpotPositionData();
        this.initializeMap(this.mapData);
    }

    deleteMapPortions() {
        this.MarsText.destroy();
        this.AirlockText.destroy();
        this.cdText.destroy();
        this.marsFog.destroy();
        this.cdFog.destroy();
        this.legend.destroy();

        if (this.mars_360_button !== null) this.mars_360_button.destroy();

        if (this.airlock_360_button !== null) this.airlock_360_button.destroy();

        if (this.cd_360_button !== null) this.cd_360_button.destroy();

        this.hotSpots.forEach(hs => hs.destroy());
        this.hotSpots.length = 0;

        if (this.info !== null) {
            this.info.destroy();
            this.info = null;
            this.infoImage = null;
            this.infoText = null;
        }
    }

    initializeMap() {
        this.checkLaunchSequenceState();
        Object.entries(this.mapData).forEach(([key, value]) => {
            const room = key;
            const { status } = value;
            if (status === 'unlocked') {
                this.unlockMapRoom(room);
                this.createLaunchSequenceHotspots(room);
            }
        });
    }

    unlockMapRoom(room) {
        Object.entries(this.mapData[room].puzzles).forEach(([key, value]) => {
            if (this.hotspotPositionData.ROOMS[room][key]) {
                const puzzleKey = value.slug;
                const puzzleName = value.name.toUpperCase();
                const posX = this.hotspotPositionData.ROOMS[room][key].POSX;
                const posY = this.hotspotPositionData.ROOMS[room][key].POSY;
                const puzzleStatus = value.status;
                const puzzleType = value.type;
                const puzzleSolution = value.solution;
                const unlockables = value.unlocks;
                const puzzleId = value.id;
                const puzzleState = value.state;
                const puzzleSolvedVideo = value.video;

                const puzzleData = {
                    posX: posX,
                    posY: posY,
                    baseSprite: puzzleStatus,
                    hoverSprite: `${puzzleStatus}_hover`,
                    infoSprite: `${puzzleStatus}_info`,
                    name: puzzleName,
                    key: puzzleKey,
                    status: puzzleStatus,
                    type: puzzleType,
                    solution: puzzleSolution,
                    unlocks: unlockables,
                    id: puzzleId,
                    state: puzzleState,
                    room: room,
                    solvedVideo: puzzleSolvedVideo,
                };
                this.createHotspot(puzzleData);
            }
        });

        switch (room) {
            case 'Command Deck':
                this.cdFog.setVisible(false);
                break;
            case 'Mars':
                this.marsFog.setVisible(false);
                break;
            default:
                break;
        }
    }

    onPointerDown(puzzle) {
        store.dispatch(setCurrentPuzzle(puzzle));
    }

    createHotspot(puzzleData) {
        const hotspot = this.add
            .image(puzzleData.posX, puzzleData.posY, puzzleData.baseSprite)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ cursor: 'pointer' })
            .on('pointerdown', () => {
                const {
                    interactionReducer: { isInteractionBlocked },
                    authReducer: { user },
                } = store.getState();

                if (!isInteractionBlocked && user.type === 'player') {
                    if (puzzleData.status === 'unlocked') {
                        this.sound.play('unlocked', { volume: 0.3 });
                        this.onPointerDown(puzzleData);
                    } else if (puzzleData.status === 'locked') {
                        this.sound.play('locked', { volume: 0.3 });
                    } else {
                        if (
                            puzzleData.key === 'fuse-box' ||
                            puzzleData.key === 'fog-chamber'
                        ) {
                            this.onPointerDown(puzzleData);
                        }
                        this.sound.play('completed', { volume: 0.3 });
                    }
                } else {
                    this.sound.play('locked', { volume: 0.3 });
                }
            })
            .on('pointerover', function () {
                this.setTexture(puzzleData.hoverSprite);
            })
            .on('pointerout', function () {
                this.setTexture(puzzleData.baseSprite);
            })
            .on('pointerover', () => {
                if (this.info === null) {
                    let scaleX = this.scale_factor.x;
                    let scaleY = this.scale_factor.y;

                    if (this.scale_factor.x > 0.4) {
                        // we clamp the scale factor for container only
                        // and do not scale the image as well as text
                        // in order to achieve visibility
                        let clampMinValue = 0;
                        const clampMaxValue = 1;

                        if (this.scale_factor.x >= 0.5) clampMinValue = 0.6;
                        else clampMinValue = 0.5;

                        scaleX = this.clamp(scaleX, clampMinValue, clampMaxValue);
                        scaleY = this.clamp(scaleY, clampMinValue, clampMaxValue);
                    }

                    const offset = hotspot.displayHeight;

                    this.info = this.add
                        .container(puzzleData.posX, puzzleData.posY)
                        .setScale(scaleX, scaleY);

                    this.infoImage = this.add
                        .image(0, 0, puzzleData.infoSprite)
                        .setOrigin(0.5);

                    this.infoText = this.add
                        .text(0, 0, puzzleData.name, {
                            align: 'center',
                            fontStyle: 'bold',
                            fontFamily: 'Outage',
                            fontSize: 16,
                            wordWrap: { width: 150, useAdvancedWrap: true },
                        })
                        .setColor('#ffffff')
                        .setOrigin(0.5)
                        .setResolution(2);

                    this.info.add([this.infoImage, this.infoText]);

                    let posY;
                    if (puzzleData.posY > 0) {
                        posY = puzzleData.posY - offset;
                    } else {
                        posY = puzzleData.posY + offset;
                    }
                    this.info.y = posY;
                }
            })
            .on('pointerout', () => {
                if (this.info !== null) {
                    this.info.destroy();
                    this.info = null;
                    this.infoImage = null;
                    this.infoText = null;
                }
            });

        this.hotSpots.push(hotspot);
    }

    createHotpotPositionData() {
        this.hotspotPositionData = {
            ROOMS: {
                Airlock: {
                    'fog-chamber': {
                        POSX: this.center.x - this.game_size.x * 0.215,
                        POSY: this.center.y + this.game_size.y * 0.075,
                    },
                    'cloud-chamber': {
                        POSX: this.center.x - this.game_size.x * 0.38,
                        POSY: this.center.y + this.game_size.y * 0.164,
                    },
                    'cooling-tubes': {
                        POSX: this.center.x - this.game_size.x * 0.38,
                        POSY: this.center.y + this.game_size.y * 0.04,
                    },
                    lockers: {
                        POSX: this.center.x - this.game_size.x * 0.38,
                        POSY: this.center.y - this.game_size.y * 0.12,
                    },
                    'door-keypad': {
                        POSX: this.center.x - this.game_size.x * 0.27,
                        POSY: this.center.y - this.game_size.y * 0.195,
                    },
                    'airlock-o2-readout': {
                        POSX: this.center.x - this.game_size.x * 0.27,
                        POSY: this.center.y - this.game_size.y * 0.3,
                    },
                    'launch-sequence-buttons': {
                        1: {
                            POSX: this.center.x - this.game_size.x * 0.37,
                            POSY: this.center.y - this.game_size.y * 0.31,
                            key: 'launch-button',
                            opens: 'A_1',
                            name: 'Launch Sequence Button',
                        },
                    },
                },
                'Command Deck': {
                    'air-chamber': {
                        POSX: this.center.x - this.game_size.x * 0.38,
                        POSY: this.center.y + this.game_size.y * 0.285,
                    },
                    'oxygen-bay-o2-readout': {
                        POSX: this.center.x - this.game_size.x * 0.333,
                        POSY: this.center.y + this.game_size.y * 0.413,
                    },
                    'command-center': {
                        POSX: this.center.x - this.game_size.x * 0.07,
                        POSY: this.center.y + this.game_size.y * 0.3715,
                    },
                    'solar-pedestal': {
                        POSX: this.center.x - this.game_size.x * 0.031,
                        POSY: this.center.y + this.game_size.y * 0.046,
                    },
                    'pressure-gauges': {
                        POSX: this.center.x - this.game_size.x * 0.03,
                        POSY: this.center.y - this.game_size.y * 0.19,
                    },
                    'command-deck-o2-readout': {
                        POSX: this.center.x - this.game_size.x * 0.095,
                        POSY: this.center.y - this.game_size.y * 0.3,
                    },
                    'comms-panel': {
                        POSX: this.center.x + this.game_size.x * 0.053,
                        POSY: this.center.y - this.game_size.y * 0.27,
                    },
                    'life-support-systems': {
                        POSX: this.center.x + this.game_size.x * 0.35,
                        POSY: this.center.y - this.game_size.y * 0.19,
                    },
                    'launch-sequence-buttons': {
                        1: {
                            POSX: this.center.x - this.game_size.x * 0.21,
                            POSY: this.center.y - this.game_size.y * 0.25,
                            key: 'launch-button',
                            opens: 'C_1',
                            name: 'Launch Sequence Button',
                        },
                        2: {
                            POSX: this.center.x - this.game_size.x * 0.2,
                            POSY: this.center.y + this.game_size.y * 0.4,
                            key: 'launch-button',
                            opens: 'C_2',
                            name: 'Launch Sequence Button',
                        },
                        3: {
                            POSX: this.center.x + this.game_size.x * 0.05,
                            POSY: this.center.y - this.game_size.y * 0.06,
                            key: 'launch-button',
                            opens: 'C_3',
                            name: 'Launch Sequence Button',
                        },
                        4: {
                            POSX: this.center.x + this.game_size.x * 0.14,
                            POSY: this.center.y - this.game_size.y * 0.11,
                            key: 'launch-button',
                            opens: 'C_4',
                            name: 'Launch Sequence Button',
                        },
                    },
                },
                Mars: {
                    'satellite-dish': {
                        POSX: this.center.x + this.game_size.x * 0.11,
                        POSY: this.center.y + this.game_size.y * 0.21,
                    },
                    'satellite-control': {
                        POSX: this.center.x + this.game_size.x * 0.153,
                        POSY: this.center.y + this.game_size.y * 0.42,
                    },
                    rover: {
                        POSX: this.center.x + this.game_size.x * 0.312,
                        POSY: this.center.y + this.game_size.y * 0.212,
                    },
                    'breaker-panel': {
                        POSX: this.center.x + this.game_size.x * 0.325,
                        POSY: this.center.y - this.game_size.y * 0.02,
                    },
                    'fuse-box': {
                        POSX: this.center.x + this.game_size.x * 0.18,
                        POSY: this.center.y - this.game_size.y * 0.02,
                    },
                    'launch-sequence-buttons': {
                        1: {
                            POSX: this.center.x + this.game_size.x * 0.1,
                            POSY: this.center.y + this.game_size.y * 0.32,
                            key: 'launch-button',
                            opens: 'M_1',
                            name: 'Launch Sequence Button',
                        },
                    },
                },
            },
        };
    }

    createLaunchSequenceHotspots(room) {
        const puzzleState = getPuzzleByKey('launch-sequence', 'Command Deck');
        const { status } = puzzleState;

        Object.entries(
            this.hotspotPositionData.ROOMS[room]['launch-sequence-buttons']
        ).forEach(([, value]) => {
            const puzzleData = {
                posX: value.POSX,
                posY: value.POSY,
                baseSprite: status,
                hoverSprite: `${status}_hover`,
                infoSprite: `${status}_info`,
                key: value.key,
                data: value.data,
                name: value.name.toUpperCase(),
                state: puzzleState,
                opens: value.opens,
                status: status,
                room: 'Command Deck',
            };
            this.createHotspot(puzzleData);
        });
    }

    checkLaunchSequenceState() {
        const puzzleState = getPuzzleByKey('launch-sequence', 'Command Deck');
        const ls_state = puzzleState.state;
        if (ls_state === null) {
            // create state
            const state = {
                correctSequence: {
                    1: 'M_1',
                    2: 'A_1',
                    3: 'C_4',
                    4: 'C_1',
                    5: 'C_2',
                    6: 'C_3',
                },
                buttonStates: {
                    A_1: false,
                    M_1: false,
                    C_1: false,
                    C_2: false,
                    C_3: false,
                    C_4: false,
                },
            };

            store.dispatch(updatePuzzleState(puzzleState.id, state));
        }
    }

    createAirlock360Button() {
        this.airlock_360_button = this.add
            .image(
                this.center.x - this.game_size.x * 0.3,
                this.center.y - this.game_size.y * 0.06,
                '360_button'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ cursor: 'pointer' })
            .on('pointerdown', () => {
                const {
                    interactionReducer: { isInteractionBlocked },
                } = store.getState();
                if (!isInteractionBlocked) {
                    store.dispatch(setBackButtonCb(null));
                    store.dispatch(setCurrentRoom('Airlock'));
                    store.dispatch(setMissionControlDisplay('room'));
                }
            });
    }

    createCommandDeck360Button() {
        this.cd_360_button = this.add
            .image(
                this.center.x - this.game_size.x * 0.08,
                this.center.y - this.game_size.y * 0.1,
                '360_button'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ cursor: 'pointer' })
            .on('pointerdown', () => {
                const {
                    interactionReducer: { isInteractionBlocked },
                } = store.getState();
                if (!isInteractionBlocked) {
                    store.dispatch(setBackButtonCb(null));
                    store.dispatch(setCurrentRoom('Command Deck'));
                    store.dispatch(setMissionControlDisplay('room'));
                }
            });
    }

    createMars360Button() {
        this.mars_360_button = this.add
            .image(
                this.center.x + this.game_size.x * 0.18,
                this.center.y + this.game_size.y * 0.12,
                '360_button'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ cursor: 'pointer' })
            .on('pointerdown', () => {
                const {
                    interactionReducer: { isInteractionBlocked },
                } = store.getState();
                if (!isInteractionBlocked) {
                    store.dispatch(setBackButtonCb(null));
                    store.dispatch(setCurrentRoom('Mars'));
                    store.dispatch(setMissionControlDisplay('room'));
                }
            });
    }

    // Resizing related methods

    setScaleFactor() {
        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.blueBorder.width;
        this.scale_factor.y = this.game.renderer.height / this.blueBorder.height;
        this.blueBorder.setScale(this.scale_factor.x, this.scale_factor.y);
    }

    clamp(num, min, max) {
        // eslint-disable-next-line no-nested-ternary
        return num <= min ? min : num >= max ? max : num;
    }
}

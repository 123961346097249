import store from 'store';
import { fetchCurrentVideos } from 'store/videosReducer/actions';
import { connectSocket, WEBSOCKET_TIMER } from './index';

let timerSocket;

let connectionSubscription = false;
let updateSubscription = false;
let disconnectSubscription = false;
let puzzleTimerUpdateSubscription = false;
let puzzleTimerStopSubscription = false;
let gameTimerUpdateSubscription = false;
let gameTimerStopSubscription = false;
let gameTimerPauseSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    updateSubscription = false;
    disconnectSubscription = false;
    puzzleTimerUpdateSubscription = false;
    puzzleTimerStopSubscription = false;
    gameTimerUpdateSubscription = false;
    gameTimerStopSubscription = false;
    gameTimerPauseSubscription = false;
};

export const connect = gameId => {
    timerSocket = connectSocket(WEBSOCKET_TIMER, gameId);
};

export const onConnect = cb => {
    if (!timerSocket || connectionSubscription) return true;

    timerSocket.on('connect', () => {
        console.log('Timer Socket Connected');
        cb();
    });
};

export const isConnected = () => {
    return timerSocket?.connected || false;
};

export const updateTime = (updatedValue, ticking, senderId, user) => {
    if (!timerSocket) return true;

    timerSocket.emit('updated', {
        updatedValue,
        ticking,
        senderId,
        user,
    });
};

export const onUpdate = cb => {
    if (!timerSocket || updateSubscription) return true;

    timerSocket.on('updated', ({ updatedValue }) => {
        cb(updatedValue);
    });

    updateSubscription = true;
};

export const playGameTimer = () => {
    if (!timerSocket) return true;

    timerSocket.emit('game_timer_play', {});
};

export const pauseGameTimer = () => {
    if (!timerSocket) return true;

    timerSocket.emit('game_timer_pause', {});
};

export const onGameTimerUpdate = cb => {
    if (!timerSocket || gameTimerUpdateSubscription) return true;

    timerSocket.on('game_timer_update', ({ timerValue }) => {
        cb(timerValue);
    });

    gameTimerUpdateSubscription = true;
};

export const onGameTimerStop = () => {
    if (!timerSocket || gameTimerStopSubscription) return true;

    timerSocket.on('game_timer_stop', () => {
        store.dispatch(fetchCurrentVideos());
    });

    gameTimerStopSubscription = true;
};

export const onGameTimerPause = cb => {
    if (!timerSocket || gameTimerPauseSubscription) return true;

    timerSocket.on('game_timer_pause', ({ timerValue }) => {
        cb(timerValue);
    });

    gameTimerPauseSubscription = true;
};

export const startPuzzleTimer = (initialValue, puzzleId) => {
    if (!timerSocket) return true;

    timerSocket.emit('puzzle_timer_start', {
        initialValue,
        puzzleId,
    });
};

export const onPuzzleTimerUpdate = cb => {
    if (!timerSocket || puzzleTimerUpdateSubscription) return true;

    timerSocket.on('puzzle_timer_update', ({ timerValue }) => {
        cb(timerValue);
    });

    puzzleTimerUpdateSubscription = true;
};

export const puzzleTimerOff = () => {
    if (!timerSocket) return true;

    timerSocket.off('puzzle_timer_update');
    timerSocket.off('puzzle_timer_stop');

    puzzleTimerUpdateSubscription = false;
    puzzleTimerStopSubscription = false;
};

export const onPuzzleTimerStop = cb => {
    if (!timerSocket || puzzleTimerStopSubscription) return true;

    timerSocket.on('puzzle_timer_stop', ({ timerValue }) => {
        puzzleTimerOff();

        cb(timerValue);
    });

    puzzleTimerStopSubscription = true;
};

export const onDisconnect = () => {
    if (!timerSocket || disconnectSubscription) return true;

    timerSocket.on('disconnect', () => {
        console.log('Timer Socket Disconnected');
        resetAllSubscriptions();
        timerSocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!timerSocket) return true;

    console.log('Timer Socket Disconnected');
    resetAllSubscriptions();
    timerSocket.removeAllListeners();
    timerSocket.disconnect();
};

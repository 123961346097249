import PHASER from 'phaser';

import { normalizeAngle } from 'utils/normalization';

import store from 'store';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';
import { setBackButtonCb } from 'store/puzzleReducer/actions';

import { puzzleStateSocket } from 'services/websockets/index';

import CONSTANTS from '../../../constants';

let dragging = false;
export default class RotatingWheel extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.ROTATING_WHEEL,
        });
        this.isSolved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
    }

    preload() {
        this.load.image('wheel_big', '/assets/puzzles/rotating-wheel/wheel-big.png');
        this.load.image('arrow', '/assets/puzzles/rotating-wheel/arrow.png');
        this.load.image('rw_background', '/assets/puzzles/rotating-wheel/rw-bg.png');
        this.load.image('wheel_spokes', '/assets/puzzles/rotating-wheel/rw-bg.png');
        this.load.image('wheel_spoke', '/assets/puzzles/rotating-wheel/wheel-spoke.png');
    }

    create() {
        this.mainBackground = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.mainBackground.width;
        this.scale_factor.y = this.game.renderer.height / this.mainBackground.height;

        this.mainBackground.setScale(this.scale_factor.x, this.scale_factor.y);

        this.rw_bg = this.add
            .image(this.center.x, this.center.y, 'rw_background')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.wheel = this.add
            .image(this.center.x, this.center.y, 'wheel_big')
            .setScale(this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('drag', () => {
                this.rotateWheel();
            })
            .on('dragend', () => {
                this.snapToPosition();
            });

        this.arrow = this.add
            .image(this.center.x - this.game_size.x * 0.35, this.center.y, 'arrow')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.sound.play('comms_button', { volume: 0.2 });
                this.tweenToNextPos();
            });

        this.arrow.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.arrow.on('pointerout', function () {
            this.clearTint();
        });

        this.arrow.on('pointerup', function () {
            this.clearTint();
        });

        this.createWheelSpokes();

        store.dispatch(
            setBackButtonCb(() => {
                const puzzleData = getPuzzleByKey('cloud-chamber', this.puzzleData.room);
                this.scene.start(CONSTANTS.SCENES.AIRLOCK.CLOUD_CHAMBER, {
                    ...this.puzzleData,
                    ...puzzleData,
                });
            })
        );
    }

    createWheelSpokes() {
        this.wheel_spoke1 = this.add
            .image(this.center.x, this.center.y - this.game_size.y * 0.44, 'wheel_spoke')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.wheel_spoke2 = this.add
            .image(
                this.center.x + this.game_size.x * 0.215,
                this.center.y - this.game_size.y * 0.22,
                'wheel_spoke'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.wheel_spoke3 = this.add
            .image(
                this.center.x + this.game_size.x * 0.21,
                this.center.y + this.game_size.y * 0.235,
                'wheel_spoke'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.wheel_spoke4 = this.add
            .image(this.center.x, this.center.y + this.game_size.y * 0.44, 'wheel_spoke')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.wheel_spoke5 = this.add
            .image(
                this.center.x - this.game_size.x * 0.21,
                this.center.y + this.game_size.y * 0.235,
                'wheel_spoke'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.wheel_spoke6 = this.add
            .image(
                this.center.x - this.game_size.x * 0.215,
                this.center.y - this.game_size.y * 0.22,
                'wheel_spoke'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);
    }

    sendUpdatedColor(color) {
        puzzleStateSocket.update({ color });
    }

    getTargetAngle() {
        let targetAngle =
            (360 / (2 * Math.PI)) *
                PHASER.Math.Angle.Between(
                    this.wheel.x,
                    this.wheel.y,
                    this.game.input.activePointer.x,
                    this.game.input.activePointer.y
                ) +
            90;

        if (targetAngle < 0) targetAngle += 360;

        return targetAngle;
    }

    rotateWheel() {
        if (this.isSolved) return;

        const angle = this.getTargetAngle(this.wheel);

        if (this.game.input.activePointer.isDown && !dragging) {
            dragging = true;
        } else if (!this.game.input.activePointer.isDown && dragging) {
            dragging = false;
        }

        if (dragging) {
            this.wheel.angle = angle;
        }
    }

    tweenToNextPos() {
        this.arrow.disableInteractive();
        const currentAngle = this.wheel.angle;
        let targetAngle = currentAngle + 60;
        targetAngle = 360 - (360 - targetAngle);

        this.syncCurrentColor(targetAngle);

        const tween = this.tweens.addCounter({
            from: currentAngle,
            to: targetAngle,
            duration: 1000,
            onUpdate: () => {
                this.wheel.setAngle(tween.getValue());
            },
            onComplete: () => {
                this.arrow.setInteractive();
            },
        });
    }

    snapToPosition() {
        if (this.isSolved) return;

        this.sound.play('comms_button', { volume: 0.2 });

        let { angle } = this.wheel;
        if (angle < 0) angle += 360;

        angle = normalizeAngle(angle, 60);
        this.wheel.angle = angle;
        this.syncCurrentColor(angle);
    }

    syncCurrentColor(angle) {
        let color = 'Green';
        switch (angle) {
            case 60:
                color = 'Orange';
                break;
            case 120:
                color = 'Blue';
                break;
            case 180:
                color = 'Yellow';
                break;
            case 240:
            case -120:
                color = 'Red';
                break;
            case 300:
            case -60:
                color = 'Purple';
                break;
            case 0:
                color = 'Green';
                break;
            default:
                color = 'Green';
                break;
        }

        this.sendUpdatedColor(color);
    }
}

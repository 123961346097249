import api from 'services/apis';
import axios from 'services/axios';
import { puzzlesSocket, playerSocket, timerSocket } from 'services/websockets/index';

import { setMyStatus, STATUS_TYPES } from 'store/statusReducer/actions';
import { fetchCurrentVideos } from 'store/videosReducer/actions';
import {
    fetchAllInventory,
    submitSelfDestructCommand,
} from 'store/inventoryReducer/actions';
import { fetchAllMessages } from 'store/messageReducer/actions';

import { getPuzzleByKey } from './selectors';

import * as ACTIONS from './action_types';

const setProcessing = payload => ({ type: ACTIONS.SET_PROCESSING, payload });

export const setCurrentPuzzle = puzzle => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CURRENT_PUZZLE,
            payload: puzzle,
        });
    };
};

export const fetchPuzzleData = () => {
    const url = api.puzzles.FETCH;
    return (dispatch, getState) => {
        dispatch(setProcessing(true));
        const { gameId, user } = getState().authReducer;
        axios
            .get(`${url}?game=${gameId}`)
            .then(res => {
                const { mapData } = res.data;
                dispatch({
                    type: ACTIONS.SET_PUZZLES_MAP_DATA,
                    payload: mapData,
                });

                if (!user.isPlayer) {
                    const launchSequence = getPuzzleByKey(
                        'launch-sequence',
                        'Command Deck'
                    );
                    if (launchSequence?.status === 'completed') {
                        timerSocket.pauseGameTimer();
                    }
                }

                dispatch(setProcessing(false));
            })
            .catch(error => {
                console.log(error.message);
                dispatch(setProcessing(false));
            });
    };
};

export const updateCurrentPuzzleStatus = () => {
    return dispatch => {
        dispatch({ type: ACTIONS.UPDATE_CURRENT_PUZZLE_STATUS });
    };
};

export const markPuzzleSolved = puzzleId => {
    const url = api.puzzles.SOLVE;
    return (dispatch, getState) => {
        const {
            authReducer: { gameId, user },
            interactionReducer: { isSelfDestructInitialized },
            puzzleReducer: { currentPuzzle },
        } = getState();

        if (puzzleId === 15 && !isSelfDestructInitialized) {
            // submit self destruct command after solar-pedestal
            // video finishes (12 secs) and if not already submitted
            setTimeout(() => {
                dispatch(submitSelfDestructCommand());
            }, 15000);
        }

        if (puzzleId === currentPuzzle?.id) {
            dispatch(updateCurrentPuzzleStatus());
            dispatch(setCurrentPuzzle(null));
        }

        axios
            .post(`${url}/${puzzleId}`, { gameId })
            .then(() => {
                puzzlesSocket.solve(puzzleId, user.id, user);

                dispatch(fetchPuzzleData());
                dispatch(fetchCurrentVideos());
                dispatch(fetchAllInventory());
                dispatch(fetchAllMessages());
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const updatePuzzleState = (puzzleId, state) => {
    const url = api.puzzles.UPDATE;
    return (dispatch, getState) => {
        const { gameId } = getState().authReducer;
        axios
            .post(`${url}/${puzzleId}`, { gameId, state })
            .then(() => {
                dispatch(fetchPuzzleData());
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const setCurrentRoom = room => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CURRENT_PUZZLE_ROOM,
            payload: room,
        });
    };
};

export const setBackButtonCb = cb => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_BACK_BUTTON_CB,
            payload: cb,
        });
    };
};

export const setMissionControlDisplay = display => {
    return dispatch => {
        dispatch({
            type: ACTIONS.CHANGE_MISSION_CONTROL_DISPLAY,
            payload: display,
        });
    };
};

export const setAsPlayingPuzzle = (puzzleId, puzzleName) => {
    const url = api.players.PUZZLE;
    return (dispatch, getState) => {
        axios
            .put(url, { puzzleId })
            .then(() => {
                const { user } = getState().authReducer;
                if (puzzleId && user.isPlayer) {
                    playerSocket.sendAction(
                        STATUS_TYPES.SOLVING_PUZZLE,
                        user,
                        user.id,
                        puzzleName
                    );
                    dispatch(
                        setMyStatus({
                            user: user,
                            senderId: user.id,
                            type: STATUS_TYPES.SOLVING_PUZZLE,
                            text: puzzleName,
                        })
                    );
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const updateLocalPuzzleData = puzzleData => {
    return (dispatch, getState) => {
        const mapData = { ...getState().puzzleReducer.mapData };

        Object.values(mapData).forEach(room => {
            Object.values(room.puzzles).forEach(puzzle => {
                if (puzzle.id === puzzleData.puzzleId) {
                    puzzle.state = puzzleData.payload;
                }
            });
        });

        dispatch({
            type: ACTIONS.SET_PUZZLES_MAP_DATA,
            payload: mapData,
        });
    };
};

export const launchPuzzle = (puzzleKey, roomName) => {
    return dispatch => {
        const puzzleData = getPuzzleByKey(puzzleKey, roomName);
        dispatch(
            setCurrentPuzzle({
                ...puzzleData,
                key: puzzleKey,
                room: roomName,
            })
        );
    };
};

import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import authReducer from './authReducer/reducer';
import interactionReducer from './interactionReducer/reducer';
import inventoryReducer from './inventoryReducer/reducer';
import voteReducer from './voteReducer/reducer';
import messageReducer from './messageReducer/reducer';
import puzzleReducer from './puzzleReducer/reducer';
import timerReducer from './timerReducer/reducer';
import statusReducer from './statusReducer/reducer';
import videosReducer from './videosReducer/reducer';
import gameReducer from './gameReducer/reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        authReducer,
        interactionReducer,
        inventoryReducer,
        voteReducer,
        messageReducer,
        puzzleReducer,
        timerReducer,
        statusReducer,
        videosReducer,
        gameReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
);

export default store;

import * as ACTIONS from './action_types';

const initialState = {
    status: {},
    activePlayers: [],
};

const statusReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.UPDATE_PLAYER_STATUS:
            return {
                ...state,
                status: {
                    ...state.status,
                    [payload.senderId]: payload,
                },
            };
        case ACTIONS.SET_ALL_STATUSES:
            return {
                status: payload,
            };
        case ACTIONS.DELETE_PLAYER_STATUS: {
            const newStatuses = state.status;
            delete newStatuses[payload];

            return {
                ...state,
                status: newStatuses,
            };
        }
        case ACTIONS.SET_ACTIVE_PLAYERS:
            return {
                ...state,
                activePlayers: payload.map(({ user }) => ({
                    id: user.id,
                    name: user.firstName,
                })),
            };
        default:
            return {
                ...state,
            };
    }
};

export default statusReducer;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Loader from 'react-loader-spinner';
import useSound from 'use-sound';

import { isArrayNotEqual } from 'utils/arrayUtils';

import { chatSocket, playerSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';
import { usePrevious } from 'services/customHooks';

import {
    appendMessage,
    retrieveMessages,
    fetchAllMessages,
    sendMessage,
} from 'store/messageReducer/actions';
import { setMyStatus, STATUS_TYPES } from 'store/statusReducer/actions';
import { setCurrentVideos } from 'store/videosReducer/actions';

import ChatboxBorder from 'assets/dashboardScreen/Chat_ContainerBorder.png';
import SeeResult from 'assets/dashboardScreen/SeeResultButton.png';
import ReceiveMessageSound from 'assets/sounds/receive-message.wav';
import SendMessageSound from 'assets/sounds/send-message.wav';

import VoteStatement from '../VoteScreen/components/VoteStatement';

import './styles.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Chatbox = ({
    authenticatedUser,
    onMessageSend,
    retrieveMessages,
    fetchAllMessages,
    messages,
    addMessage,
    isChatSocketReady,
    isPlayerSocketReady,
    setMyStatus,
    setCurrentVideos,
    playerStatus,
}) => {
    const messagesEndRef = useRef(null);
    const messagesStartRef = useRef(null);
    const [currentMessage, setCurrentMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const prevMessages = usePrevious(messages);
    const currentSenderId = authenticatedUser.id;
    const [receiveMessageSound] = useSound(ReceiveMessageSound, { volume: 0.7 });
    const [sendMessageSound] = useSound(SendMessageSound, { volume: 0.7 });

    const messageTypes = {
        MESSAGE: 'message',
        COMMAND: 'command',
    };

    const hardScrollToPrevious = useCallback(() => {
        const prevTopIndex = messages.length - prevMessages.length + 1;
        const previousTopMessage = messagesStartRef.current.children[prevTopIndex];
        messagesStartRef.current.style.scrollBehavior = 'auto';
        messagesStartRef.current.scrollTop = previousTopMessage?.offsetTop - 20;
        messagesStartRef.current.style.scrollBehavior = 'smooth';
    }, [messages?.length, prevMessages?.length]);

    const handleScroll = useCallback(
        async e => {
            if (e.target.scrollTop === 0 && !loading) {
                setLoading(true);
                await retrieveMessages();
                setLoading(false);
            }
        },
        [retrieveMessages, loading]
    );

    const setChattingStatus = () => {
        if (isPlayerSocketReady) {
            if (
                authenticatedUser.isPlayer &&
                playerStatus.type !== STATUS_TYPES.CHATTING
            ) {
                playerSocket.sendAction(
                    STATUS_TYPES.CHATTING,
                    authenticatedUser,
                    authenticatedUser.id
                );
                setMyStatus({
                    user: authenticatedUser,
                    senderId: authenticatedUser.id,
                    type: STATUS_TYPES.CHATTING,
                });
            }
        }
    };

    const onEnterPress = e => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            document.getElementById('chat_submit_button').click();
        }
    };

    const scrollToBottom = () => {
        messagesStartRef.current.scrollTop = messagesStartRef.current.scrollHeight;
    };

    const sendMessage = async () => {
        sendMessageSound();
        if (currentMessage.length !== 0) {
            onMessageSend(currentMessage);
            addMessage({
                text: currentMessage,
                user: authenticatedUser,
                userId: authenticatedUser.id,
            });
            chatSocket.sendMessage(currentMessage, currentSenderId, authenticatedUser);

            setChattingStatus();
            setCurrentMessage('');
            Mixpanel.track('Send Message', { text: currentMessage });
        }
    };

    useEffect(() => {
        if (isChatSocketReady) {
            chatSocket.receiveMessage(addMessage);
            chatSocket.onDisconnect();
        }
    }, [isChatSocketReady, addMessage]);

    useEffect(() => {
        if (isArrayNotEqual(messages, prevMessages)) {
            if (
                !prevMessages?.length ||
                prevMessages?.length === 0 ||
                messages.length - prevMessages?.length <= 1
            ) {
                if (
                    !!prevMessages?.length &&
                    messages[messages.length - 1]?.user?.id !== currentSenderId
                ) {
                    receiveMessageSound();
                }
                scrollToBottom();
            } else {
                hardScrollToPrevious();
            }
        }
    }, [
        currentSenderId,
        hardScrollToPrevious,
        messages,
        prevMessages,
        receiveMessageSound,
    ]);

    useEffect(() => {
        const chatContainer = messagesStartRef.current;
        chatContainer.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            chatContainer.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        fetchAllMessages();
    }, [fetchAllMessages]);

    return (
        <div className="chatbox">
            <img src={ChatboxBorder} alt="chatbox border" />
            <div className="chat-wrapper">
                <div
                    id="chat-container"
                    className="chat-messages-container"
                    ref={messagesStartRef}
                >
                    {loading && (
                        <Loader type="Puff" color="#00BFFF" height={20} width={20} />
                    )}
                    {messages.map((msg, index) => {
                        const isCurrentUserMessage = msg.userId === currentSenderId;
                        const isSameSenderAsPrev =
                            index > 0 &&
                            messages[index - 1].type === messageTypes.MESSAGE &&
                            msg.user?.id === messages[index - 1].user?.id;
                        return (
                            <div
                                key={index}
                                className={cn(
                                    {
                                        'msg-right': isCurrentUserMessage,
                                        'msg-left': !isCurrentUserMessage,
                                    },
                                    'message-item'
                                )}
                            >
                                {msg.type !== messageTypes.COMMAND &&
                                    !isSameSenderAsPrev && (
                                        <div className="sender-name message-item msg-left">
                                            {msg.user.type === 'player'
                                                ? msg.user.firstName
                                                : 'host'}
                                        </div>
                                    )}
                                {msg.type !== messageTypes.COMMAND && (
                                    <div
                                        className={cn(
                                            {
                                                'set-margin-top': isSameSenderAsPrev,
                                            },
                                            'message-bubble'
                                        )}
                                    >
                                        {msg.text}
                                    </div>
                                )}
                                {msg.type === messageTypes.COMMAND && (
                                    <div className="notification-success">
                                        <span>
                                            <VoteStatement statement={msg.text} />
                                        </span>
                                        {msg.payload.video && (
                                            <button
                                                className="result-button"
                                                onClick={() =>
                                                    setCurrentVideos([msg.payload.video])
                                                }
                                            >
                                                <img src={SeeResult} alt="see-result" />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div
                        className="message-item scroll-dummy-scroll"
                        ref={messagesEndRef}
                    />
                </div>
                <div className="response-image">
                    <textarea
                        className="textarea"
                        rows="1"
                        value={currentMessage}
                        onChange={e => setCurrentMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                    />
                    <button
                        id="chat_submit_button"
                        className="chat-submit"
                        onClick={sendMessage}
                        aria-label="Send Message"
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authReducer, messageReducer, statusReducer }) => ({
    authenticatedUser: authReducer.user,
    socketGameId: authReducer.gameId,
    messages: messageReducer.messages,
    playerStatus: statusReducer.status[authReducer.user.id],
});

const mapDispatchToProps = {
    onMessageSend: text => sendMessage(text),
    retrieveMessages: () => retrieveMessages(),
    fetchAllMessages: () => fetchAllMessages(),
    addMessage: message => appendMessage(message),
    setMyStatus: status => setMyStatus(status),
    setCurrentVideos: videos => setCurrentVideos(videos),
};

export default connect(mapStateToProps, mapDispatchToProps)(Chatbox);

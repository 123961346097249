export const getFontSize = (textLength, baseSize, baseFontSize, factor = 0.05) => {
    let units = 'vw';

    if (window.innerWidth > 1500) {
        baseFontSize = (baseFontSize / 100) * 1500;
        units = 'px';
        factor = 0.75;
    }

    let extension = 0;
    if (textLength > baseSize) {
        extension = textLength - baseSize;
    }

    const fontSize = baseFontSize - extension * factor;
    return `${fontSize}${units}`;
};

export const scaleFontSizeByFont = (textElement, maxfontSizeWV, maxfontSizePX) => {
    const maxWidth = textElement.clientWidth * 0.9;
    const actualWidth = textElement.nextSibling.clientWidth;

    if (window.innerWidth > 1500) {
        const fontSize = (maxWidth * 17) / actualWidth;
        textElement.style.fontSize = `${
            fontSize > maxfontSizePX ? maxfontSizePX : fontSize
        }px`;
    } else {
        const fontSize = (maxWidth * maxfontSizeWV) / actualWidth;
        textElement.style.fontSize = `${
            fontSize > maxfontSizeWV ? maxfontSizeWV : fontSize
        }vw`;
    }
};

const COMMON_PREFIX = 'store/voteReducer';

export const SET_CURRENT_VOTE = `${COMMON_PREFIX}/SET_CURRENT_VOTE`;
export const CLEAR_VOTE = `${COMMON_PREFIX}/CLEAR_VOTE`;
export const CURRENT_VOTE_SELECTIONS = `${COMMON_PREFIX}/CURRENT_VOTE_SELECTIONS`;
export const CURRENT_SELECTION = `${COMMON_PREFIX}/CURRENT_SELECTION`;
export const WIN = `${COMMON_PREFIX}/WIN`;
export const OVERWRITE = `${COMMON_PREFIX}/OVERWRITE`;
export const SET_CALLBACK = `${COMMON_PREFIX}/SET_CALLBACK`;
export const INCREMENT_YES = `${COMMON_PREFIX}/INCREMENT_YES`;
export const INCREMENT_NO = `${COMMON_PREFIX}/INCREMENT_NO`;

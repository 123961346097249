const COMMON_PPREFIX = 'store/interactionReducer';

export const DISPLAY_VOTE_SCREEN = `${COMMON_PPREFIX}/DISPLAY_VOTE_SCREEN`;
export const DISPLAY_CHAT_SCREEN = `${COMMON_PPREFIX}/DISPLAY_CHAT_SCREEN`;
export const DISPLAY_RESULT_SCREEN = `${COMMON_PPREFIX}/DISPLAY_RESULT_SCREEN`;
export const CAN_SUBMIT_VOTE = `${COMMON_PPREFIX}/CAN_SUBMIT_VOTE`;
export const SELF_SUBMIT_TRUE = `${COMMON_PPREFIX}/SELF_SUBMIT_TRUE`;
export const DISPLAY_VOTE_ON_RESULT = `${COMMON_PPREFIX}/DISPLAY_VOTE_ON_RESULT`;
export const SET_VOTE_RESULT_TIME = `${COMMON_PPREFIX}/SET_VOTE_RESULT_TIME`;
export const SET_INTERACTION_BLOCKED = `${COMMON_PPREFIX}/SET_INTERACTION_BLOCKED`;
export const SET_SELF_DESTRUCT_INIT = `${COMMON_PPREFIX}/SET_SELF_DESTRUCT_INIT`;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { createGame } from 'store/authReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import './styles.scss';

const GenerateGameId = ({ authenticatedUser, onGameCreate, generatedGameIds }) => {
    const [count, setCount] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);

    if (authenticatedUser.isPlayer) {
        return <Redirect to="/" />;
    }

    if (generatedGameIds.length) {
        Mixpanel.track('Host: New Game Created', { gameHash: generatedGameIds });
        return (
            <div className="passwords-container">
                {generatedGameIds.map(gameId => (
                    <span key={gameId}>{gameId}</span>
                ))}
            </div>
        );
    }

    const handleSubmit = async () => {
        if (count > 30) return;

        setIsProcessing(true);
        await onGameCreate(count);
        setIsProcessing(false);
    };

    return (
        <div className="generate-password-container">
            <span className="heading">Generate Password</span>
            <form className="generate-wrapper" onSubmit={handleSubmit}>
                <div className="input-area">
                    <input
                        type="number"
                        max={30}
                        value={count}
                        onChange={e => setCount(e.target.value)}
                        placeholder="Game Password"
                        required
                    />
                </div>
                <input
                    type="submit"
                    className="submit"
                    onClick={handleSubmit}
                    disabled={isProcessing}
                    value="SUBMIT"
                />
            </form>
        </div>
    );
};

const mapStateToProps = ({ authReducer }) => ({
    generatedGameIds: authReducer.generatedGameIds,
    authenticatedUser: authReducer.user,
});

const mapDispatchToProps = {
    onGameCreate: count => createGame(count),
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateGameId);

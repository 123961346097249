import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import useSound from 'use-sound';

import { getFontSize } from 'utils/stringUtils';
import { isArrayNotEqual } from 'utils/arrayUtils';

import {
    changeState,
    fetchAllInventory,
    submitAction,
} from 'store/inventoryReducer/actions';
import { setMyStatus, STATUS_TYPES } from 'store/statusReducer/actions';
import { setVoteOnResult, setVoteScreenVisible } from 'store/interactionReducer/actions';
import { setVotingData, incrementVote } from 'store/voteReducer/actions';

import { Mixpanel } from 'services/mixpanel';
import { inventorySocket, playerSocket, voteSocket } from 'services/websockets/index';
import { usePrevious } from 'services/customHooks';

import RobotExpandedContainerBorder from 'assets/dashboardScreen/RobotExpanded_ContainerBorder.png';
import RobotExpandedContainerBorder2 from 'assets/dashboardScreen/RobotExpanded_ContainerBorder2.png';
import RobotExpandedRobotIcon from 'assets/dashboardScreen/RobotControl_Icon_New.png';
import HostDashInventoryContainerBorder from 'assets/hostDashboard/HostDash_InventoryContainerBorder.png';
import InventoryAdd from 'assets/hostDashboard/Inventory_Add.png';
import InventorySubtract from 'assets/hostDashboard/Inventory_Subtract.png';
import RCCommandSound from 'assets/sounds/rc-command.wav';
import ItemAddedSound from 'assets/sounds/item-added.wav';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const Inventory = ({
    authenticatedUser,
    inventoryItems,
    fetchInventory,
    submitAction,
    isVoteSubmit,
    changeState,
    isInventorySocketReady,
    isVoteSocketReady,
    setMyStatus,
    setVotingData,
    setVoteScreenVisible,
    setVoteOnResult,
    isGameStarted,
    isInteractionBlocked,
    incrementVote,
}) => {
    const [selectedInventory, setSelectedInventory] = useState(undefined);
    const [selectedAction, setSelectedAction] = useState(undefined);
    const [selectedLocation, setSelectedLocation] = useState(undefined);
    const [isSubmitAllowed, setSubmitAllowed] = useState(false);
    const [rcCommandSound] = useSound(RCCommandSound, { volume: 0.7 });
    const [itemAddedSound] = useSound(ItemAddedSound, { volume: 0.7 });
    const prevInventory = usePrevious(inventoryItems);

    const hostSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
    };

    const playerSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    const resizeFont = useCallback(() => {
        const actions = document.querySelectorAll('.selectable-buttons button');
        actions.forEach(action => {
            action.style.fontSize = getFontSize(action.textContent.length, 15, 1.05);
        });

        const { isPlayer } = authenticatedUser;
        const overlay = document.querySelectorAll('.item-overlay span');
        overlay.forEach(item => {
            const longestText = item.textContent
                .split(' ')
                .reduce((a, b) => (a.length > b.length ? a : b));
            item.style.fontSize = getFontSize(
                longestText.length,
                isPlayer ? 6 : 8,
                isPlayer ? 1.09 : 1.25
            );
        });
    }, [authenticatedUser]);

    useEffect(() => {
        if (isArrayNotEqual(inventoryItems, prevInventory)) {
            if (inventoryItems.length - prevInventory?.length === 1) {
                itemAddedSound();
            }

            if (selectedInventory) {
                const isItemPresent = inventoryItems.some(
                    item => item.item.id === selectedInventory.id
                );

                if (!isItemPresent) {
                    setSelectedInventory(null);
                    setSelectedAction(null);
                    setSelectedLocation(null);
                }
            }
        }
    }, [inventoryItems, prevInventory, selectedInventory, itemAddedSound]);

    useEffect(() => {
        fetchInventory();
        window.addEventListener('resize', resizeFont);

        return () => {
            window.removeEventListener('resize', resizeFont);
        };
    }, [fetchInventory, resizeFont]);

    useEffect(() => {
        if (selectedInventory) {
            if (authenticatedUser.isPlayer) {
                playerSocket.sendAction(
                    STATUS_TYPES.SETTING_COMMAND,
                    authenticatedUser,
                    authenticatedUser.id
                );
                setMyStatus({
                    user: authenticatedUser,
                    senderId: authenticatedUser.id,
                    type: STATUS_TYPES.SETTING_COMMAND,
                });
            }
        }
        setSubmitAllowed(!!selectedAction && !!selectedLocation);
    }, [
        selectedInventory,
        selectedLocation,
        selectedAction,
        authenticatedUser,
        setMyStatus,
    ]);

    const callSubmitAction = () => {
        Mixpanel.track('Robot Control', {
            item: selectedInventory.title,
            action: selectedAction.title,
            location: selectedLocation.title,
        });
        rcCommandSound();
        submitAction(selectedAction.id, selectedLocation.id, authenticatedUser.id);
    };

    useEffect(() => {
        if (!isVoteSubmit) {
            setSelectedInventory(null);
            setSelectedAction(null);
            setSelectedLocation(null);
        }
    }, [isVoteSubmit]);

    useEffect(() => {
        if (isVoteSocketReady) {
            voteSocket.onVoteTimerUpdate(data => {
                if (authenticatedUser.id !== data.startedBy) {
                    setVoteOnResult(true);
                }
                setVotingData(data);
                setVoteScreenVisible();
            });

            voteSocket.onNewVote(data => {
                incrementVote(data.selection);
            });

            voteSocket.onDisconnect();
        }
    }, [
        isVoteSocketReady,
        incrementVote,
        setVotingData,
        authenticatedUser,
        setVoteOnResult,
        setVoteScreenVisible,
    ]);

    useEffect(() => {
        if (isInventorySocketReady) {
            inventorySocket.onUpdate(fetchInventory);
            inventorySocket.onDisconnect();
        }
    }, [isInventorySocketReady, fetchInventory]);

    const callChangeState = (id, status, item) => {
        if (status === 'unused') {
            changeState(id, 'removed');
            Mixpanel.track('Host: Inventory Remove', { ...item });
        } else if (status === 'removed') {
            changeState(id, 'unused');
            Mixpanel.track('Host: Inventory Add', { ...item });
        } else if (status === 'used') {
            changeState(id, 'unused');
            Mixpanel.track('Host: Inventory Add', { ...item });
        } else if (status === 'locked') {
            changeState(id, 'unused');
            Mixpanel.track('Host: Inventory Add', { ...item });
        }
    };

    const actionSelector = action => {
        rcCommandSound();
        if (action.command_locations.filter(location => !location.title).length) {
            setSelectedLocation(action.command_locations[0]);
        }
        setSelectedAction(action);
    };

    const locationSelector = location => {
        rcCommandSound();
        setSelectedLocation(location);
    };

    useLayoutEffect(() => {
        resizeFont();
    }, [inventoryItems, selectedAction, selectedLocation, selectedInventory, resizeFont]);

    useEffect(() => {
        setSelectedAction(null);
        setSelectedLocation(null);
    }, [selectedInventory]);

    const isRobotCommandDisabled =
        !(isVoteSubmit && isSubmitAllowed) || !isGameStarted || isInteractionBlocked;

    return authenticatedUser.isPlayer ? (
        <div className="expanded-inventory-container">
            <div className="items-container">
                <img
                    src={RobotExpandedContainerBorder}
                    alt="Robot expanded container border"
                />
                <span className="items-heading">ITEM</span>
                <div className="items-buttons">
                    <Slider {...playerSettings}>
                        {inventoryItems.map(
                            ({ item }) =>
                                item && (
                                    <button
                                        className={cn(
                                            {
                                                'opacity-full':
                                                    selectedInventory?.id === item.id,
                                            },
                                            'item'
                                        )}
                                        key={item.id}
                                        onClick={() => {
                                            rcCommandSound();
                                            setSelectedInventory(item);
                                        }}
                                    >
                                        <div className="item-text">
                                            <span>{item.title}</span>
                                        </div>
                                        <div className="item-image">
                                            <img src={item.url} alt={item.title} />
                                        </div>
                                        <div className="item-overlay">
                                            <div>
                                                <span>{item.title}</span>
                                            </div>
                                        </div>
                                    </button>
                                )
                        )}
                    </Slider>
                </div>
            </div>
            <div className="selectables-container">
                <img
                    src={RobotExpandedContainerBorder2}
                    alt="Robot expanded container border 2"
                />
                <div className="selectable-wrapper">
                    <span className="selectable-header">ACTION</span>
                    <div className="selectable-buttons">
                        {selectedInventory
                            ? selectedInventory.command_actions.map((action, index) => (
                                  <button
                                      onClick={() => actionSelector(action)}
                                      key={index}
                                      className={cn({
                                          selected: selectedAction === action,
                                      })}
                                  >
                                      {action.title}
                                  </button>
                              ))
                            : null}
                    </div>
                </div>
                <div className="selectable-wrapper">
                    <span className="selectable-header">LOCATION</span>
                    <div className="selectable-buttons">
                        {selectedAction &&
                            selectedAction.command_locations.map(location => {
                                return (
                                    location.title && (
                                        <button
                                            onClick={() => locationSelector(location)}
                                            key={location.id}
                                            className={cn({
                                                selected: selectedLocation === location,
                                            })}
                                        >
                                            <span>{location.title}</span>
                                        </button>
                                    )
                                );
                            })}
                    </div>
                </div>
            </div>
            <button
                className={cn(
                    {
                        'action-not-allowed': isRobotCommandDisabled,
                    },
                    'robot-expanded-submit-command-button'
                )}
                disabled={isRobotCommandDisabled}
                onClick={callSubmitAction}
            >
                <img
                    className="robot-expanded-robot-icon"
                    src={RobotExpandedRobotIcon}
                    alt="Robot Expanded Robot Icon"
                />
                <span className="submit-command">Submit Command</span>
            </button>
        </div>
    ) : (
        <div className="host-inventory-block">
            <div className="expanded-inventory-container">
                <div className="host-items items-container">
                    <img
                        src={HostDashInventoryContainerBorder}
                        alt="HostDash Inventory Container Border"
                    />
                    <div className="items-buttons">
                        <Slider {...hostSettings}>
                            {inventoryItems
                                .filter(
                                    ({ item }) =>
                                        !!item.room_id &&
                                        !item.url?.includes('placeHolder')
                                )
                                .map(item => {
                                    const isAdded = item.status === 'unused';
                                    return (
                                        item.item && (
                                            <div className="item" key={item.item.id}>
                                                <div className="item-text">
                                                    <span>{item.item.title}</span>
                                                </div>
                                                <div className="item-image">
                                                    <div
                                                        className={cn(
                                                            {
                                                                added: isAdded,
                                                                'not-added': !isAdded,
                                                            },
                                                            'item-tag'
                                                        )}
                                                    >
                                                        <span className="item-tag-text">
                                                            {isAdded
                                                                ? 'ADDED'
                                                                : 'NOT ADDED'}
                                                        </span>
                                                    </div>
                                                    <img
                                                        src={item.item.url}
                                                        alt={item.item.title}
                                                    />
                                                </div>
                                                <div className="item-overlay">
                                                    <div>
                                                        <span>{item.item.title}</span>
                                                    </div>
                                                </div>
                                                <button
                                                    className="button-to"
                                                    onClick={() =>
                                                        callChangeState(
                                                            item.item.id,
                                                            item.status,
                                                            item.item
                                                        )
                                                    }
                                                >
                                                    {item.status === 'unused' && (
                                                        <img
                                                            src={InventorySubtract}
                                                            alt="Subtract"
                                                        />
                                                    )}
                                                    {(item.status === 'removed' ||
                                                        item.status === 'locked' ||
                                                        item.status === 'used') && (
                                                        <img
                                                            src={InventoryAdd}
                                                            alt="Add"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        )
                                    );
                                })}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({
    authReducer,
    inventoryReducer,
    interactionReducer,
    voteReducer,
    gameReducer,
}) => ({
    authenticatedUser: authReducer.user,
    inventoryItems: inventoryReducer.inventoryItems,
    isVoteSubmit: interactionReducer.isVoteSubmit,
    isInteractionBlocked: interactionReducer.isInteractionBlocked,
    voteData: voteReducer.voteData,
    isGameStarted: gameReducer.isGameStarted,
});

const mapDispatchToProps = {
    fetchInventory: () => fetchAllInventory(),
    submitAction: (actionId, locationId, authId) =>
        submitAction(actionId, locationId, authId),
    changeState: (id, status) => changeState(id, status),
    setVotingData: data => setVotingData(data),
    setMyStatus: status => setMyStatus(status),
    setVoteScreenVisible: () => setVoteScreenVisible(),
    setVoteOnResult: value => setVoteOnResult(value),
    incrementVote: selection => incrementVote(selection),
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);

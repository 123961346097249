/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import videojs from 'video.js';

import { setBackButtonCb } from 'store/puzzleReducer/actions';

import { Mixpanel } from 'services/mixpanel';
import { usePrevious } from 'services/customHooks';

import 'video.js/dist/video-js.css';

let videoPlayer;
videojs.log.level('error');

function CustomVideoPlayer({
    className,
    videos,
    onVideoEnd,
    onPlaylistEnd,
    isVideoVisible,
    setBackButtonCb,
    backButtonCb,
}) {
    const [currentVideo, setCurrentVideo] = useState(null);
    const [allVideos, _setAllVideos] = useState([]);
    const prevBackButtonCb = useRef(null);
    const allVideosRef = useRef(allVideos);
    const prevVideoVisibile = usePrevious(isVideoVisible);
    const videoNode = useRef();
    const videoJsOptions = useMemo(
        () => ({
            controls: true,
            bigPlayButton: false,
            textTrackDisplay: true,
            posterImage: true,
            errorDisplay: false,
            html5: {
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                nativeTextTracks: false,
                hls: {
                    overrideNative: true,
                },
            },
        }),
        []
    );

    const playNextOrEnd = useCallback(() => {
        videoPlayer.pause();

        const currentVideoIndex = allVideosRef.current.findIndex(
            video => video.src === currentVideo.src && !video.isWatched
        );
        if (currentVideoIndex === allVideosRef.current.length - 1) {
            setCurrentVideo(null);
            onVideoEnd(currentVideo, true);
            onPlaylistEnd();
            setBackButtonCb(prevBackButtonCb.current);
        } else {
            onVideoEnd(currentVideo);
            setCurrentVideo(allVideosRef.current[currentVideoIndex + 1]);
        }
    }, [currentVideo, onPlaylistEnd, onVideoEnd, setBackButtonCb]);

    useEffect(() => {
        videoPlayer = videojs(
            videoNode.current,
            videoJsOptions,
            function onPlayerReady() {
                videoPlayer.autoplay('any');

                videoPlayer.on('click', function (event) {
                    const isPlaying =
                        videoPlayer.currentTime() > 0 &&
                        !videoPlayer.paused() &&
                        !videoPlayer.ended() &&
                        videoPlayer.readyState() > 2;

                    if (!isPlaying) {
                        event.preventDefault();
                    }
                });
            }
        );
    }, [videoJsOptions]);

    useEffect(() => {
        if (!videoPlayer.paused() || currentVideo?.src === videoPlayer.currentSrc())
            return;

        if (currentVideo && isVideoVisible && videoPlayer.paused()) {
            if (currentVideo.isWatched) {
                playNextOrEnd();
            } else {
                videoPlayer.src(currentVideo);
                videoPlayer.volume(0.7);

                if (!currentVideo.src.includes('cinematic')) {
                    if (videoPlayer.textTracks().length) {
                        videoPlayer.removeRemoteTextTrack(videoPlayer.textTracks()[0]);
                    }
                    const captionOption = {
                        kind: 'subtitles',
                        srclang: 'en',
                        label: 'English',
                        default: true,
                        mode: 'showing',
                        src: currentVideo.src.replace('_hls/index.m3u8', '.vtt'),
                    };

                    videoPlayer.addRemoteTextTrack(captionOption, true);
                } else {
                    videoPlayer.removeRemoteTextTrack(videoPlayer.textTracks()[0]);
                }

                videoPlayer.one('ended', playNextOrEnd);
                videoPlayer.one('error', playNextOrEnd);

                videoPlayer.one('loadstart', function () {
                    setBackButtonCb(() => {
                        if (currentVideo.id !== 72 && currentVideo.id !== 71) {
                            playNextOrEnd();
                        } else {
                            console.log('Cannot Skip');
                        }
                    });
                });

                videoPlayer.one('play', function () {
                    Mixpanel.track('Video Started', { ...currentVideo });
                });
            }
        }
    }, [currentVideo, isVideoVisible, playNextOrEnd, setBackButtonCb]);

    const setAllVideos = data => {
        allVideosRef.current = data;
        _setAllVideos(data);
    };

    useEffect(() => {
        setAllVideos(videos);
    }, [videos]);

    useEffect(() => {
        if (!prevVideoVisibile && isVideoVisible) {
            setCurrentVideo(allVideos[0]);
            prevBackButtonCb.current = backButtonCb;
        } else if (!isVideoVisible && prevVideoVisibile) {
            if (videoPlayer) {
                currentVideo && onVideoEnd(currentVideo);
                videoPlayer.reset();
            }
        }
    }, [
        backButtonCb,
        isVideoVisible,
        onVideoEnd,
        allVideos,
        currentVideo,
        prevVideoVisibile,
    ]);

    return (
        <div className={className}>
            <div data-vjs-player>
                <video ref={videoNode} id="video-player" className="video-js" />
            </div>
        </div>
    );
}

const mapStateToProps = ({ videosReducer, puzzleReducer }) => ({
    videos: videosReducer.currentVideos,
    backButtonCb: puzzleReducer.backButtonCb,
});

const mapDispatchToProps = {
    setBackButtonCb: cb => setBackButtonCb(cb),
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomVideoPlayer);

const PUZZLE_SERVICE = '/puzzles';

const FETCH = `${PUZZLE_SERVICE}/`;
const SOLVE = `${PUZZLE_SERVICE}/solve`;
const UPDATE = `${PUZZLE_SERVICE}/update`;

const puzzles = {
    FETCH,
    SOLVE,
    UPDATE,
};

export default puzzles;

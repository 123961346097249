import PHASER from 'phaser';

import CONSTANTS from '../../../constants';

export default class PatchBayInstructions extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.PATCH_BAY_INSTRUCTIONS,
        });
        this.isSolved = false;
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
    }

    init() {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
    }

    preload() {
        this.load.image('main_background_pb', '/assets/puzzles/main-bg.png');
        this.load.image(
            'gradient_background_pb',
            '/assets/puzzles/instructions/gradient-bg.png'
        );
        this.load.image(
            'instructions_background',
            '/assets/puzzles/instructions/instructions-bg.png'
        );
        this.load.image('instructions', '/assets/puzzles/instructions/instructions.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background_pb')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.gradient_background = this.add
            .image(
                this.game.renderer.width / 2,
                this.game.renderer.height / 2,
                'gradient_background_pb'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.instructions_background = this.add
            .image(
                this.game.renderer.width / 2,
                this.game.renderer.height / 2,
                'instructions_background'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.instructions = this.add
            .image(
                this.game.renderer.width / 2,
                this.game.renderer.height / 2,
                'instructions'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y);
    }
}

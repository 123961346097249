import * as ACTIONS from './action_types';

const initialState = {
    fetching: false,
    currentPuzzle: null,
    mapData: null,
    currentRoom: 'Airlock',
    backButtonCb: null,
    missionControlDisplay: null,
};

const authReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.SET_PROCESSING:
            return {
                ...state,
                fetching: payload,
            };
        case ACTIONS.SET_CURRENT_PUZZLE:
            return {
                ...state,
                currentPuzzle: payload,
            };
        case ACTIONS.SET_PUZZLES_MAP_DATA:
            return {
                ...state,
                mapData: payload,
            };
        case ACTIONS.SET_CURRENT_PUZZLE_ROOM:
            return {
                ...state,
                currentRoom: payload,
            };
        case ACTIONS.SET_BACK_BUTTON_CB:
            return {
                ...state,
                backButtonCb: payload,
            };
        case ACTIONS.CHANGE_MISSION_CONTROL_DISPLAY:
            return {
                ...state,
                missionControlDisplay: payload,
            };
        case ACTIONS.UPDATE_CURRENT_PUZZLE_STATUS:
            return {
                ...state,
                currentPuzzle: state.currentPuzzle
                    ? { ...state.currentPuzzle, status: 'completed' }
                    : null,
            };
        default:
            return state;
    }
};

export default authReducer;

import PHASER from 'phaser';
import { Vector2 } from 'three';

import store from 'store';
import { markPuzzleSolved } from 'store/puzzleReducer/actions';
import { blockInteraction, unblockInteraction } from 'store/interactionReducer/actions';

import { puzzleStateSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class SolarPedestal extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.SOLAR_PEDESTAL,
        });

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.cursor_scale = { x: 1, y: 1 };
        this.is_solved = false;
        this.isTimerStarted = false;
        this.isGameStarted = false;
        this.correctHexSequence = [
            'purple',
            'green',
            'lime',
            'yellow',
            'blue',
            'orange',
            'pink',
            'red',
        ];
        this.currentSequence = [];
        this.sequence_colors = [];
        this.cursorContainers = {};
        this.cursors = {
            aqua: '#19D3C5',
            brown: '#BB6125',
            'light-blue': '#00B2E3',
            red: '#DB0032',
            green: '#00AC69',
            peach: '#FFA168',
            blue: '#0082CB',
            purple: '#7E57C5',
            'light-pink': '#F2A8DC',
            pink: '#F45197',
            lime: '#A2D45E',
            lilac: '#C2A6E1',
            magenta: '#CC27B0',
            orange: '#FF5C35',
            yellow: '#FFCD00',
        };
    }

    init(data) {
        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;

        this.center = new PHASER.Geom.Point(this.game_size.x / 2, this.game_size.y / 2);
        this.puzzle_data = data;

        store.dispatch(blockInteraction());
    }

    preload() {
        this.load.image('sp_bg', '/assets/puzzles/solar-pedestal/sp-bg.png');

        this.load.image('hex_white', '/assets/puzzles/solar-pedestal/hex-white.png');
        this.load.image('hex_blue', '/assets/puzzles/solar-pedestal/hex-blue.png');
        this.load.image('hex_green', '/assets/puzzles/solar-pedestal/hex-green.png');
        this.load.image('hex_lime', '/assets/puzzles/solar-pedestal/hex-lime.png');
        this.load.image('hex_orange', '/assets/puzzles/solar-pedestal/hex-orange.png');
        this.load.image('hex_pink', '/assets/puzzles/solar-pedestal/hex-pink.png');
        this.load.image('hex_purple', '/assets/puzzles/solar-pedestal/hex-purple.png');
        this.load.image('hex_red', '/assets/puzzles/solar-pedestal/hex-red.png');
        this.load.image('hex_yellow', '/assets/puzzles/solar-pedestal/hex-yellow.png');

        this.load.image('blink_white', '/assets/puzzles/solar-pedestal/blink-white.png');
        this.load.image('blink_blue', '/assets/puzzles/solar-pedestal/blink-blue.png');
        this.load.image('blink_green', '/assets/puzzles/solar-pedestal/blink-green.png');
        this.load.image('blink_lime', '/assets/puzzles/solar-pedestal/blink-lime.png');
        this.load.image(
            'blink_orange',
            '/assets/puzzles/solar-pedestal/blink-orange.png'
        );
        this.load.image('blink_pink', '/assets/puzzles/solar-pedestal/blink-pink.png');
        this.load.image(
            'blink_purple',
            '/assets/puzzles/solar-pedestal/blink-purple.png'
        );
        this.load.image('blink_red', '/assets/puzzles/solar-pedestal/blink-red.png');
        this.load.image(
            'blink_yellow',
            '/assets/puzzles/solar-pedestal/blink-yellow.png'
        );

        this.load.image(
            'sequence_blue',
            '/assets/puzzles/solar-pedestal/sequence-blue.png'
        );
        this.load.image(
            'sequence_green',
            '/assets/puzzles/solar-pedestal/sequence-green.png'
        );
        this.load.image(
            'sequence_lime',
            '/assets/puzzles/solar-pedestal/sequence-lime.png'
        );
        this.load.image(
            'sequence_orange',
            '/assets/puzzles/solar-pedestal/sequence-orange.png'
        );
        this.load.image(
            'sequence_pink',
            '/assets/puzzles/solar-pedestal/sequence-pink.png'
        );
        this.load.image(
            'sequence_purple',
            '/assets/puzzles/solar-pedestal/sequence-purple.png'
        );
        this.load.image(
            'sequence_red',
            '/assets/puzzles/solar-pedestal/sequence-red.png'
        );
        this.load.image(
            'sequence_yellow',
            '/assets/puzzles/solar-pedestal/sequence-yellow.png'
        );

        Object.keys(this.cursors).forEach(color => {
            this.load.image(`cursor_${color}`, `/assets/cursors/cursor-${color}.png`);
        });
    }

    create() {
        this.joinGame();

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.scale_factor.x = this.game_size.x / this.main_background.width;
        this.scale_factor.y = this.game_size.y / this.main_background.height;

        this.cursor_scale = {
            x:
                this.scale_factor.x > 1
                    ? this.scale_factor.x * 0.8
                    : this.scale_factor.x * 1.25,
            y:
                this.scale_factor.y > 1
                    ? this.scale_factor.y * 0.8
                    : this.scale_factor.y * 1.25,
        };

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.sp_bg = this.add
            .image(this.center.x, this.center.y, 'sp_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createSequenceColors();
        this.createHexButtons();
        this.createHexBlinker();

        const {
            authReducer: { user },
        } = store.getState();

        puzzleStateSocket.onStateUpdate(data => {
            const {
                states: { state },
            } = data;

            this.applyState(state);
        });

        puzzleStateSocket.onCursorsUpdate(data => {
            const {
                states: { cursors, players },
            } = data;
            const cursorStates = [];

            Object.entries(cursors).forEach(([key, value]) => {
                if (Number(key) !== user.id) {
                    cursorStates.push({
                        ...value,
                        name: players[key]?.firstName,
                    });
                }
            });

            this.updateCursorPositions(cursorStates);
        });

        puzzleStateSocket.updateCursor(user.id, this);
    }

    joinGame() {
        // sending state for the first time
        const newState = {
            currentSequence: [],
            isSolved: false,
        };

        const {
            authReducer: { user },
        } = store.getState();
        puzzleStateSocket.joinGame(user.id, user, newState);
    }

    hexButtonClicked(color) {
        if (this.is_solved) return;

        this.sound.play('solar_button', { volume: 0.3 });

        this.currentSequence.push(color);

        if (this.currentSequence.length === this.correctHexSequence.length) {
            if (this.verifySequence(this.currentSequence)) {
                store.dispatch(unblockInteraction());
                this.is_solved = true;
                this.sendState({
                    currentSequence: this.currentSequence,
                    isSolved: this.is_solved,
                });
                store.dispatch(markPuzzleSolved(this.puzzle_data.id));

                // < --- MIXPANEL ANALYTICS
                Mixpanel.track('Puzzle Complete', {
                    ...this.puzzle_data,
                    puzzle: 'SOLAR PEDESTAL',
                });
                // --- >

                return;
            }
            this.currentSequence = [];
            this.hideSequenceColors();
        }

        this.sendState({
            currentSequence: this.currentSequence,
            isSolved: this.is_solved,
        });
    }

    verifySequence(currentSequence) {
        for (let i = 0; i < currentSequence.length; i++) {
            if (currentSequence[i] !== this.correctHexSequence[i]) {
                return false;
            }
        }
        return true;
    }

    showCurrentSequenceColors() {
        for (let i = 0; i < this.currentSequence.length; i++) {
            this.sequence_colors[i]?.setTexture(`sequence_${this.currentSequence[i]}`);
            this.sequence_colors[i]?.setVisible(true);
        }
    }

    createSequenceColors() {
        let offset = 0.413;
        for (let i = 0; i < 8; i++) {
            const sequence_color = this.add
                .image(
                    this.center.x - this.game_size.x * offset,
                    this.center.y + this.game_size.y * 0.167,
                    'sequence_blue'
                )
                .setScale(this.scale_factor.x, this.scale_factor.y)
                .setVisible(false);

            offset -= 0.047;
            this.sequence_colors.push(sequence_color);
        }
    }

    createHexButtons() {
        this.hex_blue = this.add
            .image(
                this.center.x + this.game_size.x * 0.085,
                this.center.y - this.game_size.y * 0.12,
                'hex_blue'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('blue');
            });

        this.hex_red = this.add
            .image(
                this.center.x + this.game_size.x * 0.18,
                this.center.y - this.game_size.y * 0.25,
                'hex_red'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('red');
            });

        this.hex_orange = this.add
            .image(
                this.center.x + this.game_size.x * 0.29,
                this.center.y - this.game_size.y * 0.25,
                'hex_orange'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('orange');
            });

        this.hex_yellow = this.add
            .image(
                this.center.x + this.game_size.x * 0.385,
                this.center.y - this.game_size.y * 0.12,
                'hex_yellow'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('yellow');
            });

        this.hex_pink = this.add
            .image(
                this.center.x + this.game_size.x * 0.085,
                this.center.y + this.game_size.y * 0.1,
                'hex_pink'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('pink');
            });

        this.hex_green = this.add
            .image(
                this.center.x + this.game_size.x * 0.385,
                this.center.y + this.game_size.y * 0.1,
                'hex_green'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('green');
            });

        this.hex_purple = this.add
            .image(
                this.center.x + this.game_size.x * 0.18,
                this.center.y + this.game_size.y * 0.23,
                'hex_purple'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('purple');
            });

        this.hex_lime = this.add
            .image(
                this.center.x + this.game_size.x * 0.29,
                this.center.y + this.game_size.y * 0.23,
                'hex_lime'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.hexButtonClicked('lime');
            });
    }

    hideSequenceColors() {
        for (let i = 0; i < this.sequence_colors.length; i++) {
            this.sequence_colors[i].setVisible(false);
        }
    }

    createHexBlinker() {
        this.hex_blinker = this.add
            .image(
                this.center.x + this.game_size.x * 0.237,
                this.center.y - this.game_size.y * 0.01,
                'blink_white'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.startWhiteHexBlinkTween();
    }

    startBlinkSequenceTimedEvent() {
        this.blinkCounter = -1;
        this.hex_blinker.setAlpha(0);

        this.blinkSequenceTimedEvent = this.time.addEvent({
            delay: 2000,
            callback: this.updateBlinkSequence,
            callbackScope: this,
            repeat: 8,
        });
    }

    startWhiteHexBlinkTween() {
        this.whiteHexBlinkTween = this.tweens.add({
            targets: this.hex_blinker,
            alpha: { from: 0, to: 1 },
            ease: 'Linear',
            duration: 500,
            repeat: 2,
            yoyo: true,
            onComplete: this.startBlinkSequence,
            onCompleteScope: this,
        });
    }

    getPlayerNumber(players, id) {
        // get player number from the players list of given id
        for (let i = 0; i < players.length; i++) {
            if (id === players[i].id) {
                const playerNum = i + 1;
                return playerNum;
            }
        }
    }

    startBlinkSequence() {
        this.hex_blinker.setAlpha(1);
        this.startBlinkSequenceTimedEvent();
    }

    updateBlinkSequence() {
        if (this.blinkCounter === 7) {
            this.blinkCounter = -1;
            this.blinkSequenceTimedEvent.remove();
            this.hex_blinker.setAlpha(0);
            this.hex_blinker.setTexture('blink_white');

            // after completing one blink cycle we add a delay of 3 seconds and restart the blink sequence
            this.delayedRestartEvent = this.time.addEvent({
                delay: 3000,
                callback: this.restartBlinkSequence,
                callbackScope: this,
                repeat: 0,
            });
        } else {
            this.sound.play('solar_light', { volume: 1 });
            this.blinkCounter++;
            this.hex_blinker.setTexture(
                `blink_${this.correctHexSequence[this.blinkCounter]}`
            );

            this.alphaTween = this.tweens.add({
                targets: this.hex_blinker,
                alpha: { from: 0, to: 1 },
                ease: 'Linear',
                duration: 1000,
                repeat: 0,
                yoyo: true,
            });
        }
    }

    restartBlinkSequence() {
        this.hex_blinker.setAlpha(1);
        this.whiteHexBlinkTween.restart();
    }

    sendState(state) {
        puzzleStateSocket.update(state);
    }

    applyState(state) {
        this.is_solved = state.isSolved || false;
        this.currentSequence = state.currentSequence || [];
        this.hideSequenceColors();

        if (this.currentSequence.length > 0) {
            this.showCurrentSequenceColors();
        }

        if (this.is_solved === true) {
            store.dispatch(unblockInteraction());
            puzzleStateSocket.disconnect();
        }
    }

    getMyCursorPercentages() {
        const cursorX = this.game.input.activePointer.x;
        const cursorY = this.game.input.activePointer.y;

        const xPerc = (cursorX / this.game_size.x) * 100;
        const yPerc = (cursorY / this.game_size.y) * 100;

        return new Vector2(xPerc, yPerc);
    }

    getCursorPosition(cursorPercentages) {
        const x = (cursorPercentages.x / 100) * this.game_size.x;
        const y = (cursorPercentages.y / 100) * this.game_size.y;

        return { x, y };
    }

    updatePlayerCursor(player) {
        const pos = this.getCursorPosition(player);
        const offset =
            this.textures.get(`cursor-${player.color}`).getSourceImage().width / 2;
        const containerKey = `cursor_${player.color}_container`;

        this.destroyCursor(player.color);

        if (!this.cursorContainers[containerKey]) {
            this.cursorContainers[containerKey] = this.add
                .container(pos.x, pos.y)
                .setScale(this.cursor_scale.x, this.cursor_scale.y);

            const pointer = this.add
                .image(0, 0, `cursor_${player.color}`)
                .setOrigin(0.5)
                .setScale(this.cursor_scale.x, this.cursor_scale.y);

            pointer.smoothed = false;

            const name = this.add
                .text(0 + offset, 0, player.name, {
                    align: 'left',
                    fontFamily: 'DINNextLTProBoldCondensed',
                    fontSize: 30 * this.cursor_scale.x,
                })
                .setColor(this.cursors[player.color])
                .setOrigin(0, 0.5)
                .setStroke('#FFFFFF', 2)
                .setResolution(2)
                .setScale(this.cursor_scale.x, this.cursor_scale.y);

            this.cursorContainers[containerKey].add([pointer, name]);
        } else {
            this.cursorContainers[containerKey].setPosition(pos.x, pos.y);
        }
    }

    updateCursorPositions(players) {
        const playerCursors = [];
        for (let i = 0; i < players.length; i++) {
            this.updatePlayerCursor(players[i]);
            playerCursors.push(players[i].color);
        }

        const cursNotPresent = Object.keys(this.cursors).filter(
            item => !playerCursors.includes(item)
        );

        if (cursNotPresent.length > 0) {
            this.clearCursors(cursNotPresent);
        }
    }

    clearCursors(cursors) {
        for (let i = 0; i < cursors.length; i++) {
            this.destroyCursor(cursors[i]);
        }
    }

    destroyCursor(cursor) {
        const containerKey = `cursor_${cursor}_container`;
        if (containerKey in this.cursorContainers) {
            this.cursorContainers[containerKey].destroy();
            delete this.cursorContainers[containerKey];
        }
    }
}

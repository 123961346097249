import PHASER from 'phaser';

import store from 'store';
import {
    setCurrentRoom,
    setAsPlayingPuzzle,
    setBackButtonCb,
} from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzleStateSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import LoadScene from './scenes/load-scene';
import DoorKeypad from './scenes/puzzles/keypads/door-keypad';
import RotatingWheel from './scenes/puzzles/rotating-wheel/rotating-wheel';
import FuseBox from './scenes/puzzles/fuse-box/fuse-box';
import BreakerPanel from './scenes/puzzles/breaker-panel/breaker-panel';
import CoolingTubes from './scenes/puzzles/cooling-tubes/cooling-tubes';
import CommandDeckKeypad from './scenes/puzzles/keypads/cd-keypad';
import OxygenReadout from './scenes/puzzles/oxygen-readout/oxygen-readout';
import PatchBayInstructions from './scenes/puzzles/instructions/pb-instructions';
import FogChamber from './scenes/puzzles/fog-chamber/fog-chamber';
import MirrorChamber from './scenes/puzzles/mirror-chamber/mirror-chamber';
import WallPipes from './scenes/puzzles/wall-pipes/wall-pipes';
import CloudChamber from './scenes/puzzles/cloud-chamber/cloud-chamber';
import Lockers from './scenes/puzzles/lockers/lockers';
import LeftLocker from './scenes/puzzles/lockers/left-locker';
import RightLocker from './scenes/puzzles/lockers/right-locker';
import PressureGauges from './scenes/puzzles/pressure-gauges/pressure-gauges';
import LaunchCoordinates from './scenes/puzzles/launch-coordinates/launch-coordinates';
import AirChamber from './scenes/puzzles/air-chamber/air-chamber';
import CommsPanel from './scenes/puzzles/comms-panel/comms-panel';
import LifeSupportSystems from './scenes/puzzles/life-supp-systems/life-supp-systems';
import LaunchButton from './scenes/puzzles/launch-sequence/launch-button';
import LaunchSequence from './scenes/puzzles/launch-sequence/launch-sequence';
import CommandCenter from './scenes/puzzles/command-center/command-center';
import SelfDestruct from './scenes/puzzles/self-destruct/self-destruct';
import RoverExteriorKeypad from './scenes/puzzles/rover-exterior-keypad/rover-exterior-keypad';
import RoverInteriorKeypad from './scenes/puzzles/rover-interior-keypad/rover-interior-keypad';
import RoverPuzzle from './scenes/puzzles/rover-puzzle/rover-puzzle';
import SatelliteControl from './scenes/puzzles/satellite-control/satellite-control';
import SatelliteDish from './scenes/puzzles/satellite-dish/satellite-dish';
import RoverMiniPuzzle from './scenes/puzzles/rover-mini-puzzle/rover-mini-puzzle';
import SolarPedestal from './scenes/puzzles/solar-pedestal/solar-pedestal';

export default class PuzzleManager {
    parentElement = document.getElementById('mission_control');

    config = {
        width: 1221,
        height: 688,
        type: PHASER.AUTO,
        parent: this.parentElement,
        transparent: true,
        enableDebug: false,
        mipmapFilter: 'LINEAR_MIPMAP_LINEAR',
        scale: {
            mode: PHASER.Scale.FIT,
            orientation: PHASER.Scale.Orientation.LANDSCAPE,
            parent: this.parentElement,
            autoCenter: PHASER.Scale.CENTER_BOTH,
        },
        scene: [
            LoadScene,
            DoorKeypad,
            RotatingWheel,
            FuseBox,
            BreakerPanel,
            CoolingTubes,
            CommandDeckKeypad,
            OxygenReadout,
            PatchBayInstructions,
            FogChamber,
            MirrorChamber,
            WallPipes,
            CloudChamber,
            Lockers,
            LeftLocker,
            RightLocker,
            PressureGauges,
            LaunchCoordinates,
            AirChamber,
            CommsPanel,
            LaunchButton,
            LaunchSequence,
            LifeSupportSystems,
            CommandCenter,
            SelfDestruct,
            RoverExteriorKeypad,
            RoverInteriorKeypad,
            RoverPuzzle,
            SatelliteControl,
            SatelliteDish,
            RoverMiniPuzzle,
            SolarPedestal,
        ],
    };

    constructor(puzzle) {
        this.game = new PHASER.Game(this.config);
        this.loaded = false;

        this.loadPuzzle(puzzle);
    }

    loadPuzzle(puzzle) {
        const scenes = this.game.scene.getScenes();
        console.log(`Loading puzzle: ${puzzle.name}`);
        if (scenes.length > 0) {
            Object.entries(scenes).forEach(([, value]) => {
                this.game.scene.stop(value);
            });
        }

        puzzleStateSocket.disconnect();

        if (puzzle.id) {
            const {
                authReducer: { gameId },
            } = store.getState();
            if (puzzle.key === 'satellite-control') {
                const satelliteDish = getPuzzleByKey('satellite-dish', 'Mars');
                puzzleStateSocket.connect(gameId, satelliteDish.id);
            } else {
                puzzleStateSocket.connect(gameId, puzzle.id);
            }
        }

        Mixpanel.track('Puzzle Start', {
            puzzle: puzzle.name,
            status: puzzle.status,
            type: puzzle.type,
            solution: puzzle.solution,
            id: puzzle.id,
            room: puzzle.room,
            state: puzzle.state,
            opens: puzzle.opens,
        });

        this.game.scene.start(puzzle.key, {
            status: puzzle.status,
            type: puzzle.type,
            solution: puzzle.solution,
            id: puzzle.id,
            room: puzzle.room,
            state: puzzle.state,
            opens: puzzle.opens,
        });

        this.loaded = true;
        store.dispatch(setCurrentRoom(puzzle.room));
        store.dispatch(setAsPlayingPuzzle(puzzle.id, puzzle.name));
        store.dispatch(setBackButtonCb());
    }

    destroy() {
        if (this.loaded) {
            puzzleStateSocket.disconnect();
            store.dispatch(setAsPlayingPuzzle(null));
            this.game.destroy(true);
            this.loaded = false;
        }
    }
}

import CONSTANTS from 'external/phaser/constants';
import PHASER from 'phaser';
import { Vector2 } from 'three';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { unlockItem } from 'store/inventoryReducer/actions';
import { puzzleStateSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

export default class AirChamber extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.AIR_CHAMBER,
        });
        this.cols = 30;
        this.rows = 30;

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.tubesDrragging = [];
        this.shouldUpdateState = true;

        this.puzzle_state = {
            leftTubeLong: {
                pos: new Vector2(20, 17),
                fixed: false,
                holes: [],
            },
            leftTubeMedium: {
                pos: new Vector2(26, 20),
                fixed: false,
                holes: [],
            },
            leftTubeShort: {
                pos: new Vector2(22, 22),
                fixed: false,
                holes: [],
            },
            rightTubeLong: {
                pos: new Vector2(20, 17),
                fixed: false,
                holes: [],
            },
            rightTubeMedium: {
                pos: new Vector2(26, 20),
                fixed: false,
                holes: [],
            },
            rightTubeShort: {
                pos: new Vector2(22, 22),
                fixed: false,
                holes: [],
            },
            left: {
                airwavePos: 'A_3',
                airflowActive: true,
                occupiedHoles: [],
            },
            right: {
                airwavePos: '',
                airflowActive: false,
                occupiedHoles: [],
            },
            airflowPoints: [],
            isSolved: false,
        };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('left_chamber', '/assets/puzzles/air-chamber/chamber-left.png');
        this.load.image('right_chamber', '/assets/puzzles/air-chamber/chamber-right.png');
        this.load.image('long_tube', '/assets/puzzles/air-chamber/tube-long.png');
        this.load.image('long_tube_cp', '/assets/puzzles/air-chamber/tube-long-cp.png');
        this.load.image('short_tube', '/assets/puzzles/air-chamber/tube-short.png');
        this.load.image('short_tube_cp', '/assets/puzzles/air-chamber/tube-short-cp.png');
        this.load.image(
            'medium_tube_diagonal',
            '/assets/puzzles/air-chamber/tube-medium-diagonal.png'
        );
        this.load.image(
            'medium_tube_diagonal_cp',
            '/assets/puzzles/air-chamber/tube-medium-diagonal-cp.png'
        );
        this.load.image(
            'medium_tube_straight',
            '/assets/puzzles/air-chamber/tube-medium-straight.png'
        );
        this.load.image('main_view', '/assets/puzzles/air-chamber/main-view.png');
        this.load.image('chamber_circle', '/assets/puzzles/air-chamber/circle.png');
        this.load.image(
            'left_chamber_name',
            '/assets/puzzles/air-chamber/left-chamber-sign.png'
        );
        this.load.image(
            'right_chamber_name',
            '/assets/puzzles/air-chamber/right-chamber-sign.png'
        );
        this.load.image('air_waves', '/assets/puzzles/air-chamber/chamber-air.png');
    }

    create() {
        this.joinGame();

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.createGrid();
        this.createAirflowPositions();
        this.createMainView();

        this.createLeftChamber();
        this.createRightChamber();
        this.createDraggableSpots();

        puzzleStateSocket.onStateUpdate(data => {
            const {
                states: { state },
            } = data;
            this.applyPuzzleState(state);
        });

        store.dispatch(setBackButtonCb());
    }

    joinGame() {
        const {
            authReducer: { user },
        } = store.getState();
        puzzleStateSocket.joinGame(user.id, user, this.puzzle_state);
    }

    createMainView() {
        this.main_view = this.add.container(0, 0);

        this.main_bg = this.add
            .image(this.center.x, this.center.y, 'main_view')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.left_circle = this.add
            .image(
                this.center.x - this.game_size.x * 0.07,
                this.center.y + this.game_size.y * 0.28,
                'chamber_circle'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.showLeftChamber();
            });

        this.right_circle = this.add
            .image(
                this.center.x + this.game_size.x * 0.07,
                this.center.y + this.game_size.y * 0.28,
                'chamber_circle'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.showRightChamber();
            });

        this.left_chamber_name = this.add
            .image(
                this.center.x - this.game_size.x * 0.37,
                this.center.y + this.game_size.y * 0.28,
                'left_chamber_name'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.showLeftChamber();
            });

        this.right_chamber_name = this.add
            .image(
                this.center.x + this.game_size.x * 0.37,
                this.center.y + this.game_size.y * 0.28,
                'right_chamber_name'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.showRightChamber();
            });

        this.main_view.add([this.main_bg, this.left_circle, this.right_circle]);
    }

    createLeftChamber() {
        this.left_chamber = this.add.container(0, 0).setVisible(false);

        this.left_chamber_bg = this.add
            .image(this.center.x, this.center.y, 'left_chamber')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.lc_air_waves = this.add
            .image(
                this.center.x - this.game_size.x * 0.285,
                this.center.y + this.game_size.y * 0.285,
                'air_waves'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // left chamber long tube
        this.lc_tube_long = this.add
            .image(
                this.center.x + this.game_size.x * 0.2,
                this.center.y + this.game_size.y * 0.07,
                'long_tube'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.tubesDrragging.push('lc_tube_long');
            })
            .on('pointerup', () => {
                this.tubesDrragging = this.tubesDrragging.filter(
                    item => item !== 'lc_tube_long'
                );
            });

        this.tube_long_initial_pos = {
            x: 20,
            y: 17,
        };

        this.puzzle_state.leftTubeLong.pos = {
            x: 20,
            y: 17,
        };

        // left chamber short tube
        this.lc_tube_short = this.add
            .image(
                this.center.x + this.game_size.x * 0.28,
                this.center.y + this.game_size.y * 0.25,
                'short_tube'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.tubesDrragging.push('lc_tube_short');
            })
            .on('pointerup', () => {
                this.tubesDrragging = this.tubesDrragging.filter(
                    item => item !== 'lc_tube_short'
                );
            });

        this.lc_tube_short_initial_pos = {
            x: 22,
            y: 22,
        };

        this.puzzle_state.leftTubeShort.pos = {
            x: 22,
            y: 22,
        };

        // left chamber medium diagonal tube
        this.lc_tube_md = this.add
            .image(
                this.center.x + this.game_size.x * 0.375,
                this.center.y + this.game_size.y * 0.17,
                'medium_tube_diagonal'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.tubesDrragging.push('lc_tube_md');
            })
            .on('pointerup', () => {
                this.tubesDrragging = this.tubesDrragging.filter(
                    item => item !== 'lc_tube_md'
                );
            });

        this.lc_tube_md_initial_pos = {
            x: 26,
            y: 20,
        };

        this.puzzle_state.leftTubeMedium.pos = {
            x: 26,
            y: 20,
        };

        // draggable part of tubes
        this.input.setDraggable([this.lc_tube_long, this.lc_tube_md, this.lc_tube_short]);

        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.lc_tube_long.on('dragend', () => {
            this.onLongTubes(this.lc_tube_long);
        });

        this.lc_tube_md.on('dragend', () => {
            this.onMediumDiagonalTubes(this.lc_tube_md);
        });

        this.lc_tube_short.on('dragend', () => {
            this.onShortTubes(this.lc_tube_short);
        });

        this.left_chamber.add([
            this.left_chamber_bg,
            this.lc_tube_long,
            this.lc_tube_short,
            this.lc_tube_md,
            this.lc_air_waves,
        ]);

        this.placeAt(
            this.tube_long_initial_pos.x,
            this.tube_long_initial_pos.y,
            this.lc_tube_long
        );
        this.placeAt(
            this.lc_tube_short_initial_pos.x,
            this.lc_tube_short_initial_pos.y,
            this.lc_tube_short
        );
        this.placeAt(
            this.lc_tube_md_initial_pos.x,
            this.lc_tube_md_initial_pos.y,
            this.lc_tube_md
        );
    }

    createRightChamber() {
        this.right_chamber = this.add.container(0, 0).setVisible(false);

        this.right_chamber_bg = this.add
            .image(this.center.x, this.center.y, 'right_chamber')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.rc_air_waves = this.add
            .image(
                this.rc_airflow_positions.C_3.POSX,
                this.rc_airflow_positions.C_3.POSY,
                'air_waves'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // right chamber long tube
        this.rc_tube_long = this.add
            .image(
                this.center.x + this.game_size.x * 0.2,
                this.center.y + this.game_size.y * 0.07,
                'long_tube'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.tubesDrragging.push('rc_tube_long');
            })
            .on('pointerup', () => {
                this.tubesDrragging = this.tubesDrragging.filter(
                    item => item !== 'rc_tube_long'
                );
            });

        this.puzzle_state.rightTubeLong.pos = {
            x: 20,
            y: 17,
        };

        // right chamber short tube
        this.rc_tube_short = this.add
            .image(
                this.center.x + this.game_size.x * 0.28,
                this.center.y + this.game_size.y * 0.25,
                'short_tube'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.tubesDrragging.push('rc_tube_short');
            })
            .on('pointerup', () => {
                this.tubesDrragging = this.tubesDrragging.filter(
                    item => item !== 'rc_tube_short'
                );
            });

        this.rc_tube_short_initial_pos = {
            x: 22,
            y: 22,
        };

        this.puzzle_state.rightTubeShort.pos = {
            x: 22,
            y: 22,
        };

        // right chamber straight tube
        this.rc_tube_ms = this.add
            .image(
                this.center.x + this.game_size.x * 0.375,
                this.center.y + this.game_size.y * 0.17,
                'medium_tube_straight'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.tubesDrragging.push('rc_tube_ms');
            })
            .on('pointerup', () => {
                this.tubesDrragging = this.tubesDrragging.filter(
                    item => item !== 'rc_tube_ms'
                );
            });

        this.rc_tube_ms_initial_pos = {
            x: 26,
            y: 20,
        };

        this.puzzle_state.rightTubeMedium.pos = {
            x: 26,
            y: 20,
        };

        // draggable part of tubes
        this.input.setDraggable([this.rc_tube_long, this.rc_tube_ms, this.rc_tube_short]);

        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.rc_tube_long.on('dragend', () => {
            this.onLongTubes(this.rc_tube_long);
        });

        this.rc_tube_ms.on('dragend', () => {
            this.onMediumStraightTubes(this.rc_tube_ms);
        });

        this.rc_tube_short.on('dragend', () => {
            this.onShortTubes(this.rc_tube_short);
        });

        this.right_chamber.add([
            this.right_chamber_bg,
            this.rc_tube_long,
            this.rc_tube_short,
            this.rc_tube_ms,
            this.rc_air_waves,
        ]);

        this.placeAt(
            this.tube_long_initial_pos.x,
            this.tube_long_initial_pos.y,
            this.rc_tube_long
        );
        this.placeAt(
            this.rc_tube_short_initial_pos.x,
            this.rc_tube_short_initial_pos.y,
            this.rc_tube_short
        );
        this.placeAt(
            this.rc_tube_ms_initial_pos.x,
            this.rc_tube_ms_initial_pos.y,
            this.rc_tube_ms
        );
    }

    showLeftChamber() {
        this.main_view.setVisible(false);
        this.left_chamber.setVisible(true);
        this.puzzle_data.chamberType = 'left';

        store.dispatch(
            setBackButtonCb(() => {
                this.main_view.setVisible(true);
                this.left_chamber.setVisible(false);

                store.dispatch(setBackButtonCb());
            })
        );
    }

    showRightChamber() {
        this.main_view.setVisible(false);
        this.right_chamber.setVisible(true);
        this.puzzle_data.chamberType = 'right';

        store.dispatch(
            setBackButtonCb(() => {
                this.main_view.setVisible(true);
                this.right_chamber.setVisible(false);

                store.dispatch(setBackButtonCb());
            })
        );
    }

    checkOverlap(Image1, Image2) {
        const bounds1 = Image1.getBounds();
        const bounds2 = Image2.getBounds();
        const rect = PHASER.Geom.Rectangle.Intersection(bounds1, bounds2);
        if (rect.width !== 0) {
            return rect;
        }

        return 0;
    }

    createDraggableSpots() {
        // long tube
        this.tube_long_dest_1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.18,
                this.center.y + this.game_size.y * 0.12,
                'long_tube_cp'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.tube_long_dest_1_pos = {
            x: 9,
            y: 18,
        };
        this.placeAt(
            this.tube_long_dest_1_pos.x,
            this.tube_long_dest_1_pos.y,
            this.tube_long_dest_1
        );

        const offsetScale = new Vector2(
            this.scale_factor.x * 0.5,
            this.scale_factor.y * 0.2
        );
        // short tubes
        this.tube_short_dest_1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.283,
                this.center.y + this.game_size.y * 0.05,
                'short_tube_cp'
            )
            .setScale(
                this.scale_factor.x + offsetScale.x,
                this.scale_factor.y + offsetScale.y
            )
            .setVisible(false);

        this.tube_short_dest_1_pos = {
            x: 6,
            y: 16,
        };
        this.placeAt(
            this.tube_short_dest_1_pos.x,
            this.tube_short_dest_1_pos.y,
            this.tube_short_dest_1
        );

        this.tube_short_dest_2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.186,
                this.center.y + this.game_size.y * 0.05,
                'short_tube_cp'
            )
            .setScale(
                this.scale_factor.x + offsetScale.x,
                this.scale_factor.y + offsetScale.y
            )
            .setVisible(false);

        this.tube_short_dest_2_pos = {
            x: 9,
            y: 16,
        };
        this.placeAt(
            this.tube_short_dest_2_pos.x,
            this.tube_short_dest_2_pos.y,
            this.tube_short_dest_2
        );

        this.tube_short_dest_3 = this.add
            .image(
                this.center.x - this.game_size.x * 0.09,
                this.center.y + this.game_size.y * 0.05,
                'short_tube_cp'
            )
            .setScale(
                this.scale_factor.x + offsetScale.x,
                this.scale_factor.y + offsetScale.y
            )
            .setVisible(false);

        this.tube_short_dest_3_pos = {
            x: 12,
            y: 16,
        };
        this.placeAt(
            this.tube_short_dest_3_pos.x,
            this.tube_short_dest_3_pos.y,
            this.tube_short_dest_3
        );

        this.tube_short_dest_4 = this.add
            .image(
                this.center.x - this.game_size.x * 0.283,
                this.center.y + this.game_size.y * 0.2,
                'short_tube_cp'
            )
            .setScale(
                this.scale_factor.x + offsetScale.x,
                this.scale_factor.y + offsetScale.y
            )
            .setVisible(false);

        this.tube_short_dest_4_pos = {
            x: 6,
            y: 21,
        };
        this.placeAt(
            this.tube_short_dest_4_pos.x,
            this.tube_short_dest_4_pos.y,
            this.tube_short_dest_4
        );

        this.tube_short_dest_5 = this.add
            .image(
                this.center.x - this.game_size.x * 0.186,
                this.center.y + this.game_size.y * 0.2,
                'short_tube_cp'
            )
            .setScale(
                this.scale_factor.x + offsetScale.x,
                this.scale_factor.y + offsetScale.y
            )
            .setVisible(false);

        this.tube_short_dest_5_pos = {
            x: 9,
            y: 21,
        };
        this.placeAt(
            this.tube_short_dest_5_pos.x,
            this.tube_short_dest_5_pos.y,
            this.tube_short_dest_5
        );

        this.tube_short_dest_6 = this.add
            .image(
                this.center.x - this.game_size.x * 0.09,
                this.center.y + this.game_size.y * 0.2,
                'short_tube_cp'
            )
            .setScale(
                this.scale_factor.x + offsetScale.x,
                this.scale_factor.y + offsetScale.y
            )
            .setVisible(false);

        this.tube_short_dest_6_pos = {
            x: 12,
            y: 21,
        };
        this.placeAt(
            this.tube_short_dest_6_pos.x,
            this.tube_short_dest_6_pos.y,
            this.tube_short_dest_6
        );

        // medium diagonal tubes
        this.tube_md_dest_1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.235,
                this.center.y + this.game_size.y * 0.125,
                'medium_tube_diagonal_cp'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.tube_md_dest_1_pos = {
            x: 7,
            y: 18,
        };
        this.placeAt(
            this.tube_md_dest_1_pos.x,
            this.tube_md_dest_1_pos.y,
            this.tube_md_dest_1
        );

        this.tube_md_dest_2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.14,
                this.center.y + this.game_size.y * 0.125,
                'medium_tube_diagonal_cp'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.tube_md_dest_2_pos = {
            x: 10,
            y: 18,
        };
        this.placeAt(
            this.tube_md_dest_2_pos.x,
            this.tube_md_dest_2_pos.y,
            this.tube_md_dest_2
        );

        // medium straight tubes
        this.tube_ms_dest_1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.283,
                this.center.y + this.game_size.y * 0.125,
                'medium_tube_straight'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.tube_ms_dest_1_pos = {
            x: 6,
            y: 18,
        };
        this.placeAt(
            this.tube_ms_dest_1_pos.x,
            this.tube_ms_dest_1_pos.y,
            this.tube_ms_dest_1
        );

        this.tube_ms_dest_2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.19,
                this.center.y + this.game_size.y * 0.125,
                'medium_tube_straight'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.tube_ms_dest_2_pos = {
            x: 9,
            y: 18,
        };
        this.placeAt(
            this.tube_ms_dest_2_pos.x,
            this.tube_ms_dest_2_pos.y,
            this.tube_ms_dest_2
        );

        this.tube_ms_dest_3 = this.add
            .image(
                this.center.x - this.game_size.x * 0.09,
                this.center.y + this.game_size.y * 0.125,
                'medium_tube_straight'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.tube_ms_dest_3_pos = {
            x: 12,
            y: 18,
        };
        this.placeAt(
            this.tube_ms_dest_3_pos.x,
            this.tube_ms_dest_3_pos.y,
            this.tube_ms_dest_3
        );
    }

    onLongTubes(tube) {
        if (this.puzzle_state.isSolved) return;

        let airflowPoint = null;

        if (this.checkOverlap(tube, this.tube_long_dest_1)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(this.tube_long_dest_1_pos.x, this.tube_long_dest_1_pos.y, tube);

            this.clearLongTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('A_3') ||
                    this.puzzle_state.left.occupiedHoles.includes('C_1')
                ) {
                    this.resetLongTube(tube);
                } else {
                    this.puzzle_state.leftTubeLong.pos.x = this.tube_long_dest_1_pos.x;
                    this.puzzle_state.leftTubeLong.pos.y = this.tube_long_dest_1_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'A_3') {
                        this.puzzle_state.leftTubeLong.holes.push('A_3', 'C_1');
                        this.puzzle_state.left.occupiedHoles.push('A_3', 'C_1');

                        airflowPoint = this.createAirflowPoint(
                            'A_3',
                            'C_1',
                            'lc_long_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'C_1') {
                        this.puzzle_state.leftTubeLong.holes.push('C_1', 'A_3');
                        this.puzzle_state.left.occupiedHoles.push('C_1', 'A_3');

                        airflowPoint = this.createAirflowPoint(
                            'C_1',
                            'A_3',
                            'lc_long_tube',
                            'left'
                        );
                    } else {
                        console.log('Happening');
                        this.puzzle_state.leftTubeLong.holes.push('A_3', 'C_1');
                        this.puzzle_state.left.occupiedHoles.push('A_3', 'C_1');

                        airflowPoint = this.createAirflowPoint(
                            'A_3',
                            'C_1',
                            'lc_long_tube',
                            'left'
                        );
                    }

                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeLong.fixed = true;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('C_3') ||
                this.puzzle_state.right.occupiedHoles.includes('A_1')
            ) {
                this.resetLongTube(tube);
            } else {
                this.puzzle_state.rightTubeLong.pos.x = this.tube_long_dest_1_pos.x;
                this.puzzle_state.rightTubeLong.pos.y = this.tube_long_dest_1_pos.y;

                if (this.puzzle_state.right.airwavePos === 'C_3') {
                    this.puzzle_state.rightTubeLong.holes.push('C_3', 'A_1');
                    this.puzzle_state.right.occupiedHoles.push('C_3', 'A_1');

                    airflowPoint = this.createAirflowPoint(
                        'C_3',
                        'A_1',
                        'rc_long_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'A_1') {
                    this.puzzle_state.rightTubeLong.holes.push('A_1', 'C_3');
                    this.puzzle_state.right.occupiedHoles.push('A_1', 'C_3');

                    airflowPoint = this.createAirflowPoint(
                        'A_1',
                        'C_3',
                        'rc_long_tube',
                        'right'
                    );
                } else {
                    console.log('Happening');
                    this.puzzle_state.rightTubeLong.holes.push('C_3', 'A_1');
                    this.puzzle_state.right.occupiedHoles.push('C_3', 'A_1');

                    airflowPoint = this.createAirflowPoint(
                        'C_3',
                        'A_1',
                        'rc_long_tube',
                        'right'
                    );
                }

                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeLong.fixed = true;
            }
        } else {
            this.resetLongTube(tube);
        }

        this.updateFlowState();

        const updatedState = { airflowPoints: this.puzzle_state.airflowPoints };
        if (this.puzzle_data.chamberType === 'left') {
            updatedState.leftTubeLong = this.puzzle_state.leftTubeLong;
        } else {
            updatedState.rightTubeLong = this.puzzle_state.rightTubeLong;
        }

        this.checkAndSendPuzzleState(updatedState);
    }

    onMediumStraightTubes(tube) {
        if (this.puzzle_state.isSolved) return;

        let airflowPoint = null;

        // checking medium straight tube 1
        if (this.checkOverlap(tube, this.tube_ms_dest_1)) {
            if (
                this.puzzle_state.right.occupiedHoles.includes('C_1') ||
                this.puzzle_state.right.occupiedHoles.includes('C_3')
            ) {
                this.resetMediumStraightTube(tube);
            } else {
                this.sound.play('air_chamber_plug', { volume: 0.2 });

                if (this.puzzle_data.chamberType === 'right') {
                    this.clearMediumStraightTubeHoles();

                    this.placeAt(
                        this.tube_ms_dest_1_pos.x,
                        this.tube_ms_dest_1_pos.y,
                        tube
                    );
                    this.puzzle_state.rightTubeMedium.pos.x = this.tube_ms_dest_1_pos.x;
                    this.puzzle_state.rightTubeMedium.pos.y = this.tube_ms_dest_1_pos.y;

                    if (this.puzzle_state.right.airwavePos === 'C_1') {
                        this.puzzle_state.rightTubeMedium.holes.push('C_1', 'C_3');
                        this.puzzle_state.right.occupiedHoles.push('C_1', 'C_3');

                        airflowPoint = this.createAirflowPoint(
                            'C_1',
                            'C_3',
                            'rc_ms_tube',
                            'right'
                        );
                    } else if (this.puzzle_state.right.airwavePos === 'C_3') {
                        this.puzzle_state.rightTubeMedium.holes.push('C_3', 'C_1');
                        this.puzzle_state.right.occupiedHoles.push('C_3', 'C_1');

                        airflowPoint = this.createAirflowPoint(
                            'C_3',
                            'C_1',
                            'rc_ms_tube',
                            'right'
                        );
                    } else {
                        this.puzzle_state.rightTubeMedium.holes.push('C_1', 'C_3');
                        this.puzzle_state.right.occupiedHoles.push('C_1', 'C_3');

                        airflowPoint = this.createAirflowPoint(
                            'C_1',
                            'C_3',
                            'rc_ms_tube',
                            'right'
                        );
                    }

                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.rightTubeMedium.fixed = false;
                }
            }
        }

        // checking medium straight tube 2
        else if (this.checkOverlap(tube, this.tube_ms_dest_2)) {
            if (
                this.puzzle_state.right.occupiedHoles.includes('B_1') ||
                this.puzzle_state.right.occupiedHoles.includes('B_3')
            ) {
                this.resetMediumStraightTube(tube);
            } else {
                this.sound.play('air_chamber_plug', { volume: 0.2 });
                if (this.puzzle_data.chamberType === 'right') {
                    this.clearMediumStraightTubeHoles();

                    this.placeAt(
                        this.tube_ms_dest_2_pos.x,
                        this.tube_ms_dest_2_pos.y,
                        tube
                    );
                    this.puzzle_state.rightTubeMedium.pos.x = this.tube_ms_dest_2_pos.x;
                    this.puzzle_state.rightTubeMedium.pos.y = this.tube_ms_dest_2_pos.y;

                    if (this.puzzle_state.right.airwavePos === 'B_1') {
                        this.puzzle_state.rightTubeMedium.holes.push('B_1', 'B_3');
                        this.puzzle_state.right.occupiedHoles.push('B_1', 'B_3');

                        airflowPoint = this.createAirflowPoint(
                            'B_1',
                            'B_3',
                            'rc_ms_tube',
                            'right'
                        );
                    } else if (this.puzzle_state.right.airwavePos === 'B_3') {
                        this.puzzle_state.rightTubeMedium.holes.push('B_3', 'B_1');
                        this.puzzle_state.right.occupiedHoles.push('B_3', 'B_1');

                        airflowPoint = this.createAirflowPoint(
                            'B_3',
                            'B_1',
                            'rc_ms_tube',
                            'right'
                        );
                    } else {
                        this.puzzle_state.rightTubeMedium.holes.push('B_1', 'B_3');
                        this.puzzle_state.right.occupiedHoles.push('B_1', 'B_3');

                        airflowPoint = this.createAirflowPoint(
                            'B_1',
                            'B_3',
                            'rc_ms_tube',
                            'right'
                        );
                    }

                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.rightTubeMedium.fixed = true;
                }
            }
        }

        // checking medium straight tube 3
        else if (this.checkOverlap(tube, this.tube_ms_dest_3)) {
            if (
                this.puzzle_state.right.occupiedHoles.includes('A_1') ||
                this.puzzle_state.right.occupiedHoles.includes('A_3')
            ) {
                this.resetMediumStraightTube(tube);
            } else {
                this.sound.play('air_chamber_plug', { volume: 0.2 });
                if (this.puzzle_data.chamberType === 'right') {
                    this.clearMediumStraightTubeHoles();

                    this.placeAt(
                        this.tube_ms_dest_3_pos.x,
                        this.tube_ms_dest_3_pos.y,
                        tube
                    );
                    this.puzzle_state.rightTubeMedium.pos.x = this.tube_ms_dest_3_pos.x;
                    this.puzzle_state.rightTubeMedium.pos.y = this.tube_ms_dest_3_pos.y;

                    if (this.puzzle_state.right.airwavePos === 'A_1') {
                        this.puzzle_state.rightTubeMedium.holes.push('A_1', 'A_3');
                        this.puzzle_state.right.occupiedHoles.push('A_1', 'A_3');

                        airflowPoint = this.createAirflowPoint(
                            'A_1',
                            'A_3',
                            'rc_ms_tube',
                            'right'
                        );
                    } else if (this.puzzle_state.right.airwavePos === 'A_3') {
                        this.puzzle_state.rightTubeMedium.holes.push('A_3', 'A_1');
                        this.puzzle_state.right.occupiedHoles.push('A_3', 'A_1');

                        airflowPoint = this.createAirflowPoint(
                            'A_3',
                            'A_1',
                            'rc_ms_tube',
                            'right'
                        );
                    } else {
                        this.puzzle_state.rightTubeMedium.holes.push('A_1', 'A_3');
                        this.puzzle_state.right.occupiedHoles.push('A_1', 'A_3');

                        airflowPoint = this.createAirflowPoint(
                            'A_1',
                            'A_3',
                            'rc_ms_tube',
                            'right'
                        );
                    }
                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.rightTubeMedium.fixed = false;
                }
            }
        } else {
            this.resetMediumStraightTube(tube);
        }

        this.updateFlowState();

        const updatedState = { airflowPoints: this.puzzle_state.airflowPoints };
        if (this.puzzle_data.chamberType === 'left') {
            updatedState.leftTubeMedium = this.puzzle_state.leftTubeMedium;
        } else {
            updatedState.rightTubeMedium = this.puzzle_state.rightTubeMedium;
        }

        this.checkAndSendPuzzleState(updatedState);
    }

    onMediumDiagonalTubes(tube) {
        if (this.puzzle_state.isSolved) return;

        let airflowPoint = null;

        // checking medium diagonal tube 1
        if (this.checkOverlap(tube, this.tube_md_dest_1)) {
            if (
                this.puzzle_state.left.occupiedHoles.includes('A_1') ||
                this.puzzle_state.left.occupiedHoles.includes('B_3')
            ) {
                this.resetMediumDiagonalTube(tube);
            } else {
                this.sound.play('air_chamber_plug', { volume: 0.2 });

                if (this.puzzle_data.chamberType === 'left') {
                    this.clearMediumDiagonalTubeHoles();

                    this.puzzle_state.leftTubeMedium.pos.x = this.tube_md_dest_1_pos.x;
                    this.puzzle_state.leftTubeMedium.pos.y = this.tube_md_dest_1_pos.y;
                    this.placeAt(
                        this.tube_md_dest_1_pos.x,
                        this.tube_md_dest_1_pos.y,
                        tube
                    );

                    if (this.puzzle_state.left.airwavePos === 'A_1') {
                        this.puzzle_state.leftTubeMedium.holes.push('A_1', 'B_3');
                        this.puzzle_state.left.occupiedHoles.push('A_1', 'B_3');

                        airflowPoint = this.createAirflowPoint(
                            'A_1',
                            'B_3',
                            'lc_md_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'B_3') {
                        this.puzzle_state.leftTubeMedium.holes.push('B_3', 'A_1');
                        this.puzzle_state.left.occupiedHoles.push('B_3', 'A_1');

                        airflowPoint = this.createAirflowPoint(
                            'B_3',
                            'A_1',
                            'lc_md_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeMedium.holes.push('A_1', 'B_3');
                        this.puzzle_state.left.occupiedHoles.push('A_1', 'B_3');

                        airflowPoint = this.createAirflowPoint(
                            'A_1',
                            'B_3',
                            'lc_md_tube',
                            'left'
                        );
                    }
                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeMedium.fixed = true;
                }
            }
        }

        // checking medium diagonal tube 2
        else if (this.checkOverlap(tube, this.tube_md_dest_2)) {
            if (
                this.puzzle_state.left.occupiedHoles.includes('B_1') ||
                this.puzzle_state.left.occupiedHoles.includes('C_3')
            ) {
                this.resetMediumDiagonalTube(tube);
            } else {
                this.sound.play('air_chamber_plug', { volume: 0.2 });

                if (this.puzzle_data.chamberType === 'left') {
                    this.clearMediumDiagonalTubeHoles();

                    this.placeAt(
                        this.tube_md_dest_2_pos.x,
                        this.tube_md_dest_2_pos.y,
                        tube
                    );
                    this.puzzle_state.leftTubeMedium.pos.x = this.tube_md_dest_2_pos.x;
                    this.puzzle_state.leftTubeMedium.pos.y = this.tube_md_dest_2_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'B_1') {
                        this.puzzle_state.leftTubeMedium.holes.push('B_1', 'C_3');
                        this.puzzle_state.left.occupiedHoles.push('B_1', 'C_3');

                        airflowPoint = this.createAirflowPoint(
                            'B_1',
                            'C_3',
                            'lc_md_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'C_3') {
                        this.puzzle_state.leftTubeMedium.holes.push('C_3', 'B_1');
                        this.puzzle_state.left.occupiedHoles.push('C_3', 'B_1');

                        airflowPoint = this.createAirflowPoint(
                            'C_3',
                            'B_1',
                            'lc_md_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeMedium.holes.push('B_1', 'C_3');
                        this.puzzle_state.left.occupiedHoles.push('B_1', 'C_3');

                        airflowPoint = this.createAirflowPoint(
                            'B_1',
                            'C_3',
                            'lc_md_tube',
                            'left'
                        );
                    }

                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeMedium.fixed = false;
                }
            }
        } else {
            this.resetMediumDiagonalTube(tube);
        }

        this.updateFlowState();

        const updatedState = { airflowPoints: this.puzzle_state.airflowPoints };
        if (this.puzzle_data.chamberType === 'left') {
            updatedState.leftTubeMedium = this.puzzle_state.leftTubeMedium;
        } else {
            updatedState.rightTubeMedium = this.puzzle_state.rightTubeMedium;
        }

        this.checkAndSendPuzzleState(updatedState);
    }

    onShortTubes(tube) {
        if (this.puzzle_state.isSolved) return;

        let airflowPoint = null;

        // checking short tube 1
        if (this.checkOverlap(tube, this.tube_short_dest_1)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(
                this.tube_short_dest_1_pos.x,
                this.tube_short_dest_1_pos.y,
                tube
            );

            this.clearShortTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('A_1') ||
                    this.puzzle_state.left.occupiedHoles.includes('A_2')
                ) {
                    this.resetShortTube(tube);
                } else {
                    this.puzzle_state.leftTubeShort.pos.x = this.tube_short_dest_1_pos.x;
                    this.puzzle_state.leftTubeShort.pos.y = this.tube_short_dest_1_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'A_1') {
                        this.puzzle_state.leftTubeShort.holes.push('A_1', 'A_2');
                        this.puzzle_state.left.occupiedHoles.push('A_1', 'A_2');

                        airflowPoint = this.createAirflowPoint(
                            'A_1',
                            'A_2',
                            'lc_short_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'A_2') {
                        this.puzzle_state.leftTubeShort.holes.push('A_2', 'A_1');
                        this.puzzle_state.left.occupiedHoles.push('A_2', 'A_1');

                        airflowPoint = this.createAirflowPoint(
                            'A_2',
                            'A_1',
                            'lc_short_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeShort.holes.push('A_1', 'A_2');
                        this.puzzle_state.left.occupiedHoles.push('A_1', 'A_2');

                        airflowPoint = this.createAirflowPoint(
                            'A_1',
                            'A_2',
                            'lc_short_tube',
                            'left'
                        );
                    }
                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeShort.fixed = false;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('C_1') ||
                this.puzzle_state.right.occupiedHoles.includes('C_2')
            ) {
                this.resetShortTube(tube);
            } else {
                this.puzzle_state.rightTubeShort.pos.x = this.tube_short_dest_1_pos.x;
                this.puzzle_state.rightTubeShort.pos.y = this.tube_short_dest_1_pos.y;

                if (this.puzzle_state.right.airwavePos === 'C_1') {
                    this.puzzle_state.rightTubeShort.holes.push('C_1', 'C_2');
                    this.puzzle_state.right.occupiedHoles.push('C_1', 'C_2');

                    airflowPoint = this.createAirflowPoint(
                        'C_1',
                        'C_2',
                        'rc_short_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'C_2') {
                    this.puzzle_state.rightTubeShort.holes.push('C_2', 'C_1');
                    this.puzzle_state.right.occupiedHoles.push('C_2', 'C_1');

                    airflowPoint = this.createAirflowPoint(
                        'C_2',
                        'C_1',
                        'rc_short_tube',
                        'right'
                    );
                } else {
                    this.puzzle_state.rightTubeShort.holes.push('C_1', 'C_2');
                    this.puzzle_state.right.occupiedHoles.push('C_1', 'C_2');

                    airflowPoint = this.createAirflowPoint(
                        'C_1',
                        'C_2',
                        'rc_short_tube',
                        'right'
                    );
                }

                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeShort.fixed = true;
            }
        }

        // checking short tube 2
        else if (this.checkOverlap(tube, this.tube_short_dest_2)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(
                this.tube_short_dest_2_pos.x,
                this.tube_short_dest_2_pos.y,
                tube
            );

            this.clearShortTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('B_1') ||
                    this.puzzle_state.left.occupiedHoles.includes('B_2')
                ) {
                    this.resetShortTube(tube);
                } else {
                    this.puzzle_state.leftTubeShort.pos.x = this.tube_short_dest_2_pos.x;
                    this.puzzle_state.leftTubeShort.pos.y = this.tube_short_dest_2_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'B_1') {
                        // send B2 for right
                        this.puzzle_state.leftTubeShort.holes.push('B_1', 'B_2');
                        this.puzzle_state.left.occupiedHoles.push('B_1', 'B_2');

                        airflowPoint = this.createAirflowPoint(
                            'B_1',
                            'B_2',
                            'lc_short_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'B_2') {
                        // send B1 for right
                        this.puzzle_state.leftTubeShort.holes.push('B_2', 'B_1');
                        this.puzzle_state.left.occupiedHoles.push('B_2', 'B_1');

                        airflowPoint = this.createAirflowPoint(
                            'B_2',
                            'B_1',
                            'lc_short_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeShort.holes.push('B_1', 'B_2');
                        this.puzzle_state.left.occupiedHoles.push('B_1', 'B_2');

                        airflowPoint = this.createAirflowPoint(
                            'B_1',
                            'B_2',
                            'lc_short_tube',
                            'left'
                        );
                    }

                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeShort.fixed = false;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('B_1') ||
                this.puzzle_state.right.occupiedHoles.includes('B_2')
            ) {
                this.resetShortTube(tube);
            } else {
                this.puzzle_state.rightTubeShort.pos.x = this.tube_short_dest_2_pos.x;
                this.puzzle_state.rightTubeShort.pos.y = this.tube_short_dest_2_pos.y;

                if (this.puzzle_state.right.airwavePos === 'B_1') {
                    this.puzzle_state.leftTubeShort.holes.push('B_1', 'B_2');
                    this.puzzle_state.left.occupiedHoles.push('B_1', 'B_2');

                    airflowPoint = this.createAirflowPoint(
                        'B_1',
                        'B_2',
                        'rc_short_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'B_2') {
                    this.puzzle_state.leftTubeShort.holes.push('B_2', 'B_1');
                    this.puzzle_state.left.occupiedHoles.push('B_2', 'B_1');

                    airflowPoint = this.createAirflowPoint(
                        'B_2',
                        'B_1',
                        'rc_short_tube',
                        'right'
                    );
                } else {
                    this.puzzle_state.rightTubeShort.holes.push('B_1', 'B_2');
                    this.puzzle_state.right.occupiedHoles.push('B_1', 'B_2');

                    airflowPoint = this.createAirflowPoint(
                        'B_1',
                        'B_2',
                        'rc_short_tube',
                        'right'
                    );
                }
                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeShort.fixed = false;
            }
        }

        // checking short tube 3
        else if (this.checkOverlap(tube, this.tube_short_dest_3)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(
                this.tube_short_dest_3_pos.x,
                this.tube_short_dest_3_pos.y,
                tube
            );

            this.clearShortTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('C_1') ||
                    this.puzzle_state.left.occupiedHoles.includes('C_2')
                ) {
                    this.resetShortTube(tube);
                } else {
                    this.puzzle_state.leftTubeShort.pos.x = this.tube_short_dest_3_pos.x;
                    this.puzzle_state.leftTubeShort.pos.y = this.tube_short_dest_3_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'C_1') {
                        this.puzzle_state.leftTubeShort.holes.push('C_1', 'C_2');
                        this.puzzle_state.left.occupiedHoles.push('C_1', 'C_2');

                        airflowPoint = this.createAirflowPoint(
                            'C_1',
                            'C_2',
                            'lc_short_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'C_2') {
                        this.puzzle_state.leftTubeShort.holes.push('C_2', 'C_1');
                        this.puzzle_state.left.occupiedHoles.push('C_2', 'C_1');

                        airflowPoint = this.createAirflowPoint(
                            'C_2',
                            'C_1',
                            'lc_short_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeShort.holes.push('C_1', 'C_2');
                        this.puzzle_state.left.occupiedHoles.push('C_1', 'C_2');

                        airflowPoint = this.createAirflowPoint(
                            'C_1',
                            'C_2',
                            'lc_short_tube',
                            'left'
                        );
                    }

                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeShort.fixed = false;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('A_1') ||
                this.puzzle_state.right.occupiedHoles.includes('A_2')
            ) {
                this.resetShortTube(tube);
            } else {
                this.puzzle_state.rightTubeShort.pos.x = this.tube_short_dest_3_pos.x;
                this.puzzle_state.rightTubeShort.pos.y = this.tube_short_dest_3_pos.y;

                if (this.puzzle_state.right.airwavePos === 'A_1') {
                    this.puzzle_state.rightTubeShort.holes.push('A_1', 'A_2');
                    this.puzzle_state.right.occupiedHoles.push('A_1', 'A_2');

                    airflowPoint = this.createAirflowPoint(
                        'A_1',
                        'A_2',
                        'rc_short_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'A_2') {
                    this.puzzle_state.rightTubeShort.holes.push('A_2', 'A_1');
                    this.puzzle_state.right.occupiedHoles.push('A_2', 'A_1');

                    airflowPoint = this.createAirflowPoint(
                        'A_2',
                        'A_1',
                        'rc_short_tube',
                        'right'
                    );
                } else {
                    this.puzzle_state.rightTubeShort.holes.push('A_1', 'A_2');
                    this.puzzle_state.right.occupiedHoles.push('A_1', 'A_2');

                    airflowPoint = this.createAirflowPoint(
                        'A_1',
                        'A_2',
                        'rc_short_tube',
                        'right'
                    );
                }
                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeShort.fixed = false;
            }
        }

        // checking short tube 4
        else if (this.checkOverlap(tube, this.tube_short_dest_4)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(
                this.tube_short_dest_4_pos.x,
                this.tube_short_dest_4_pos.y,
                tube
            );

            this.clearShortTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('A_2') ||
                    this.puzzle_state.left.occupiedHoles.includes('A_3')
                ) {
                    this.resetShortTube(tube);
                } else {
                    this.puzzle_state.leftTubeShort.pos.x = this.tube_short_dest_4_pos.x;
                    this.puzzle_state.leftTubeShort.pos.y = this.tube_short_dest_4_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'A_2') {
                        this.puzzle_state.leftTubeShort.holes.push('A_2', 'A_3');
                        this.puzzle_state.left.occupiedHoles.push('A_2', 'A_3');

                        airflowPoint = this.createAirflowPoint(
                            'A_2',
                            'A_3',
                            'lc_short_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'A_3') {
                        this.puzzle_state.leftTubeShort.holes.push('A_3', 'A_2');
                        this.puzzle_state.left.occupiedHoles.push('A_3', 'A_2');

                        airflowPoint = this.createAirflowPoint(
                            'A_3',
                            'A_2',
                            'lc_short_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeShort.holes.push('A_2', 'A_3');
                        this.puzzle_state.left.occupiedHoles.push('A_2', 'A_3');

                        airflowPoint = this.createAirflowPoint(
                            'A_2',
                            'A_3',
                            'lc_short_tube',
                            'left'
                        );
                    }
                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeShort.fixed = false;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('C_2') ||
                this.puzzle_state.right.occupiedHoles.includes('C_3')
            ) {
                this.resetShortTube(tube);
            } else {
                this.puzzle_state.rightTubeShort.pos.x = this.tube_short_dest_4_pos.x;
                this.puzzle_state.rightTubeShort.pos.y = this.tube_short_dest_4_pos.y;
                if (this.puzzle_state.right.airwavePos === 'C_2') {
                    this.puzzle_state.rightTubeShort.holes.push('C_2', 'C_3');
                    this.puzzle_state.right.occupiedHoles.push('C_2', 'C_3');

                    airflowPoint = this.createAirflowPoint(
                        'C_2',
                        'C_3',
                        'rc_short_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'C_3') {
                    this.puzzle_state.rightTubeShort.holes.push('C_3', 'C_2');
                    this.puzzle_state.right.occupiedHoles.push('C_3', 'C_2');

                    airflowPoint = this.createAirflowPoint(
                        'C_3',
                        'C_2',
                        'rc_short_tube',
                        'right'
                    );
                } else {
                    this.puzzle_state.rightTubeShort.holes.push('C_2', 'C_3');
                    this.puzzle_state.right.occupiedHoles.push('C_2', 'C_3');

                    airflowPoint = this.createAirflowPoint(
                        'C_2',
                        'C_3',
                        'rc_short_tube',
                        'right'
                    );
                }

                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeShort.fixed = false;
            }
        }

        // checking short tube 5
        else if (this.checkOverlap(tube, this.tube_short_dest_5)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(
                this.tube_short_dest_5_pos.x,
                this.tube_short_dest_5_pos.y,
                tube
            );

            this.clearShortTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('B_2') ||
                    this.puzzle_state.left.occupiedHoles.includes('B_3')
                ) {
                    this.resetShortTube(tube);
                } else {
                    this.puzzle_state.leftTubeShort.pos.x = this.tube_short_dest_5_pos.x;
                    this.puzzle_state.leftTubeShort.pos.y = this.tube_short_dest_5_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'B_2') {
                        // send B3 for right
                        this.puzzle_state.leftTubeShort.holes.push('B_2', 'B_3');
                        this.puzzle_state.left.occupiedHoles.push('B_2', 'B_3');

                        airflowPoint = this.createAirflowPoint(
                            'B_2',
                            'B_3',
                            'lc_short_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'B_3') {
                        // send B2 for right
                        this.puzzle_state.leftTubeShort.holes.push('B_3', 'B_2');
                        this.puzzle_state.left.occupiedHoles.push('B_3', 'B_2');

                        airflowPoint = this.createAirflowPoint(
                            'B_3',
                            'B_2',
                            'lc_short_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.rightTubeShort.holes.push('B_2', 'B_3');
                        this.puzzle_state.right.occupiedHoles.push('B_2', 'B_3');

                        airflowPoint = this.createAirflowPoint(
                            'B_2',
                            'B_3',
                            'lc_short_tube',
                            'left'
                        );
                    }
                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeShort.fixed = false;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('B_2') ||
                this.puzzle_state.right.occupiedHoles.includes('B_3')
            ) {
                this.resetShortTube(tube);
            } else {
                this.puzzle_state.rightTubeShort.pos.x = this.tube_short_dest_5_pos.x;
                this.puzzle_state.rightTubeShort.pos.y = this.tube_short_dest_5_pos.y;

                if (this.puzzle_state.right.airwavePos === 'B_2') {
                    this.puzzle_state.rightTubeShort.holes.push('B_2', 'B_3');
                    this.puzzle_state.right.occupiedHoles.push('B_2', 'B_3');

                    airflowPoint = this.createAirflowPoint(
                        'B_2',
                        'B_3',
                        'rc_short_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'B_3') {
                    this.puzzle_state.rightTubeShort.holes.push('B_3', 'B_2');
                    this.puzzle_state.right.occupiedHoles.push('B_3', 'B_2');

                    airflowPoint = this.createAirflowPoint(
                        'B_3',
                        'B_2',
                        'rc_short_tube',
                        'right'
                    );
                } else {
                    this.puzzle_state.rightTubeShort.holes.push('B_2', 'B_3');
                    this.puzzle_state.right.occupiedHoles.push('B_2', 'B_3');

                    airflowPoint = this.createAirflowPoint(
                        'B_2',
                        'B_3',
                        'rc_short_tube',
                        'right'
                    );
                }
                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeShort.fixed = false;
            }
        }

        // checking short tube 6
        else if (this.checkOverlap(tube, this.tube_short_dest_6)) {
            this.sound.play('air_chamber_plug', { volume: 0.2 });

            this.placeAt(
                this.tube_short_dest_6_pos.x,
                this.tube_short_dest_6_pos.y,
                tube
            );

            this.clearShortTubeHoles();
            if (this.puzzle_data.chamberType === 'left') {
                if (
                    this.puzzle_state.left.occupiedHoles.includes('C_2') ||
                    this.puzzle_state.left.occupiedHoles.includes('C_3')
                ) {
                    this.resetShortTube(tube);
                } else {
                    this.puzzle_state.leftTubeShort.holes.length = 0;

                    this.puzzle_state.leftTubeShort.pos.x = this.tube_short_dest_6_pos.x;
                    this.puzzle_state.leftTubeShort.pos.y = this.tube_short_dest_6_pos.y;

                    if (this.puzzle_state.left.airwavePos === 'C_2') {
                        this.puzzle_state.leftTubeShort.holes.push('C_2', 'C_3');
                        this.puzzle_state.left.occupiedHoles.push('C_2', 'C_3');

                        airflowPoint = this.createAirflowPoint(
                            'C_2',
                            'C_3',
                            'lc_short_tube',
                            'left'
                        );
                    } else if (this.puzzle_state.left.airwavePos === 'C_3') {
                        this.puzzle_state.leftTubeShort.holes.push('C_3', 'C_2');
                        this.puzzle_state.left.occupiedHoles.push('C_3', 'C_2');

                        airflowPoint = this.createAirflowPoint(
                            'C_3',
                            'C_2',
                            'lc_short_tube',
                            'left'
                        );
                    } else {
                        this.puzzle_state.leftTubeShort.holes.push('C_2', 'C_3');
                        this.puzzle_state.left.occupiedHoles.push('C_2', 'C_3');

                        airflowPoint = this.createAirflowPoint(
                            'C_2',
                            'C_3',
                            'lc_short_tube',
                            'left'
                        );
                    }
                    this.addTubeToFlow(airflowPoint);
                    this.puzzle_state.leftTubeShort.fixed = true;
                }
            } else if (
                this.puzzle_state.right.occupiedHoles.includes('A_2') ||
                this.puzzle_state.right.occupiedHoles.includes('A_3')
            ) {
                this.resetShortTube(tube);
            } else {
                this.puzzle_state.rightTubeShort.pos.x = this.tube_short_dest_6_pos.x;
                this.puzzle_state.rightTubeShort.pos.y = this.tube_short_dest_6_pos.y;

                if (this.puzzle_state.right.airwavePos === 'A_2') {
                    this.puzzle_state.rightTubeShort.holes.push('A_2', 'A_3');
                    this.puzzle_state.right.occupiedHoles.push('A_2', 'A_3');

                    airflowPoint = this.createAirflowPoint(
                        'A_2',
                        'A_3',
                        'rc_short_tube',
                        'right'
                    );
                } else if (this.puzzle_state.right.airwavePos === 'A_3') {
                    this.puzzle_state.rightTubeShort.holes.push('A_3', 'A_2');
                    this.puzzle_state.right.occupiedHoles.push('A_3', 'A_2');

                    airflowPoint = this.createAirflowPoint(
                        'A_3',
                        'A_2',
                        'rc_short_tube',
                        'right'
                    );
                } else {
                    this.puzzle_state.rightTubeShort.holes.push('A_2', 'A_3');
                    this.puzzle_state.right.occupiedHoles.push('A_2', 'A_3');

                    airflowPoint = this.createAirflowPoint(
                        'A_2',
                        'A_3',
                        'rc_short_tube',
                        'right'
                    );
                }
                this.addTubeToFlow(airflowPoint);
                this.puzzle_state.rightTubeShort.fixed = false;
            }
        } else {
            this.resetShortTube(tube);
        }

        this.updateFlowState();

        const updatedState = { airflowPoints: this.puzzle_state.airflowPoints };
        if (this.puzzle_data.chamberType === 'left') {
            updatedState.leftTubeShort = this.puzzle_state.leftTubeShort;
        } else {
            updatedState.rightTubeShort = this.puzzle_state.rightTubeShort;
        }

        this.checkAndSendPuzzleState(updatedState);
    }

    checkAndSendPuzzleState(updatedState) {
        this.checkForPuzzleComplete();

        if (this.puzzle_state.isSolved) {
            updatedState.isSolved = true;
        }

        this.syncGameState(updatedState);
    }

    syncGameState(updatedState) {
        console.log('Syncing Game State : ');
        console.log(this.puzzle_state);
        this.sendPuzzleState(updatedState);

        this.shouldUpdateState = false;
        setTimeout(() => {
            this.shouldUpdateState = true;
        }, 450);
    }

    applyPuzzleState(state) {
        if (!this.shouldUpdateState || this.puzzle_state.isSolved) return;

        // console.log("Applying State");
        // console.log(state);

        this.puzzle_state = state;
        this.applyLeftChamberState(this.puzzle_state);
        this.applyRightChamberState(this.puzzle_state);

        if (this.puzzle_state.isSolved) {
            this.input.setDraggable(
                [
                    this.lc_tube_long,
                    this.lc_tube_md,
                    this.lc_tube_short,
                    this.rc_tube_long,
                    this.rc_tube_short,
                    this.rc_tube_ms,
                ],
                false
            );
            this.sound.play('success');

            this.lc_air_waves.setVisible(false);
            this.rc_air_waves.setPosition(false);
        }

        this.updateFlowState();
    }

    applyLeftChamberState(state) {
        this.puzzle_state.left.occupiedHoles = [];

        // setting position
        if (!this.tubesDrragging.includes('lc_tube_long')) {
            this.placeAt(
                state.leftTubeLong.pos.x,
                state.leftTubeLong.pos.y,
                this.lc_tube_long
            );
            this.puzzle_state.left.occupiedHoles.push(...state.leftTubeLong.holes);
        }

        if (!this.tubesDrragging.includes('lc_tube_short')) {
            this.placeAt(
                state.leftTubeShort.pos.x,
                state.leftTubeShort.pos.y,
                this.lc_tube_short
            );
            this.puzzle_state.left.occupiedHoles.push(...state.leftTubeShort.holes);
        }

        if (!this.tubesDrragging.includes('lc_tube_md')) {
            this.placeAt(
                state.leftTubeMedium.pos.x,
                state.leftTubeMedium.pos.y,
                this.lc_tube_md
            );
            this.puzzle_state.left.occupiedHoles.push(...state.leftTubeMedium.holes);
        }

        if (state.airflowActive) {
            if (this.puzzle_state.left.airwavePos !== '') {
                if (
                    !this.puzzle_state.left.occupiedHoles.includes(
                        this.puzzle_state.left.airwavePos
                    )
                ) {
                    this.lc_air_waves.setVisible(true);
                    this.lc_air_waves.setPosition(
                        this.lc_airflow_positions[state.airwavePos].POSX,
                        this.lc_airflow_positions[state.airwavePos].POSY
                    );
                }
            }
        } else {
            this.lc_air_waves.setVisible(false);
        }
    }

    applyRightChamberState(state) {
        // setting position
        if (!this.tubesDrragging.includes('rc_tube_long')) {
            this.placeAt(
                state.rightTubeLong.pos.x,
                state.rightTubeLong.pos.y,
                this.rc_tube_long
            );
            this.puzzle_state.right.occupiedHoles.push(...state.rightTubeLong.holes);
        }

        if (!this.tubesDrragging.includes('rc_tube_short')) {
            this.placeAt(
                state.rightTubeShort.pos.x,
                state.rightTubeShort.pos.y,
                this.rc_tube_short
            );
            this.puzzle_state.right.occupiedHoles.push(...state.rightTubeShort.holes);
        }

        if (!this.tubesDrragging.includes('rc_tube_ms')) {
            this.placeAt(
                state.rightTubeMedium.pos.x,
                state.rightTubeMedium.pos.y,
                this.rc_tube_ms
            );
            this.puzzle_state.right.occupiedHoles.push(...state.rightTubeMedium.holes);
        }

        if (state.airflowActive) {
            if (this.puzzle_state.right.airwavePos !== '') {
                if (
                    !this.puzzle_state.right.occupiedHoles.includes(
                        this.puzzle_state.right.airwavePos
                    )
                ) {
                    this.rc_air_waves.setVisible(true);
                    this.rc_air_waves.setPosition(
                        this.rc_airflow_positions[state.airwavePos].POSX,
                        this.rc_airflow_positions[state.airwavePos].POSY
                    );
                }
            }
        } else {
            this.rc_air_waves.setVisible(false);
        }
    }

    sendPuzzleState(updatedState) {
        puzzleStateSocket.update(updatedState);
    }

    checkForPuzzleComplete() {
        if (
            this.puzzle_state.leftTubeLong.fixed &&
            this.puzzle_state.leftTubeMedium.fixed &&
            this.puzzle_state.leftTubeShort.fixed &&
            this.puzzle_state.rightTubeLong.fixed &&
            this.puzzle_state.rightTubeMedium.fixed &&
            this.puzzle_state.rightTubeShort.fixed
        ) {
            this.puzzle_state.isSolved = true;
            this.sound.play('success');
            store.dispatch(markPuzzleSolved(this.puzzle_data.id));
            store.dispatch(unlockItem(ITEM_ID_MAP.PING_PONG_BALLS));
            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzle_data,
                puzzle: 'AIR CHAMBER',
            });
            // --- >

            this.input.setDraggable(
                [
                    this.lc_tube_long,
                    this.lc_tube_md,
                    this.lc_tube_short,
                    this.rc_tube_long,
                    this.rc_tube_short,
                    this.rc_tube_ms,
                ],
                false
            );
        }
    }

    createAirflowPositions() {
        this.lc_airflow_positions = {
            A_1: {
                POSX: this.center.x - this.game_size.x * 0.285,
                POSY: this.center.y - this.game_size.y * 0.03,
            },
            A_2: {
                POSX: this.center.x - this.game_size.x * 0.285,
                POSY: this.center.y + this.game_size.y * 0.125,
            },
            A_3: {
                POSX: this.center.x - this.game_size.x * 0.285,
                POSY: this.center.y + this.game_size.y * 0.285,
            },
            B_1: {
                POSX: this.center.x - this.game_size.x * 0.19,
                POSY: this.center.y - this.game_size.y * 0.03,
            },
            B_2: {
                POSX: this.center.x - this.game_size.x * 0.19,
                POSY: this.center.y + this.game_size.y * 0.125,
            },
            B_3: {
                POSX: this.center.x - this.game_size.x * 0.19,
                POSY: this.center.y + this.game_size.y * 0.285,
            },
            C_1: {
                POSX: this.center.x - this.game_size.x * 0.09,
                POSY: this.center.y - this.game_size.y * 0.03,
            },
            C_2: {
                POSX: this.center.x - this.game_size.x * 0.09,
                POSY: this.center.y + this.game_size.y * 0.125,
            },
            C_3: {
                POSX: this.center.x - this.game_size.x * 0.09,
                POSY: this.center.y + this.game_size.y * 0.285,
            },
        };

        this.rc_airflow_positions = {
            C_1: {
                POSX: this.center.x - this.game_size.x * 0.285,
                POSY: this.center.y - this.game_size.y * 0.03,
            },
            C_2: {
                POSX: this.center.x - this.game_size.x * 0.285,
                POSY: this.center.y + this.game_size.y * 0.125,
            },
            C_3: {
                POSX: this.center.x - this.game_size.x * 0.285,
                POSY: this.center.y + this.game_size.y * 0.285,
            },
            B_1: {
                POSX: this.center.x - this.game_size.x * 0.19,
                POSY: this.center.y - this.game_size.y * 0.03,
            },
            B_2: {
                POSX: this.center.x - this.game_size.x * 0.19,
                POSY: this.center.y + this.game_size.y * 0.125,
            },
            B_3: {
                POSX: this.center.x - this.game_size.x * 0.19,
                POSY: this.center.y + this.game_size.y * 0.285,
            },
            A_1: {
                POSX: this.center.x - this.game_size.x * 0.09,
                POSY: this.center.y - this.game_size.y * 0.03,
            },
            A_2: {
                POSX: this.center.x - this.game_size.x * 0.09,
                POSY: this.center.y + this.game_size.y * 0.125,
            },
            A_3: {
                POSX: this.center.x - this.game_size.x * 0.09,
                POSY: this.center.y + this.game_size.y * 0.285,
            },
        };
    }

    // grid related methods

    createGrid() {
        this.cw = this.game.renderer.width / this.cols;
        this.ch = this.game.renderer.height / this.rows;
    }

    show() {
        for (let i = 0; i < this.game.renderer.width; i += this.cw) {
            this.graphics.moveTo(i, 0);
            this.graphics.lineTo(i, this.game.renderer.height).setDepth(5);
        }
        for (let j = 0; j < this.game.renderer.height; j += this.ch) {
            this.graphics.moveTo(0, j);
            this.graphics.lineTo(this.game.renderer.width, j).setDepth(5);
        }
    }

    getLcoation(x, y) {
        const x2 = this.cw * x + this.cw / 2;
        const y2 = this.ch * y + this.ch / 2;

        return new Vector2(x2, y2);
    }

    placeAt(x, y, obj) {
        const location = this.getLcoation(x, y);
        obj.x = location.x;
        obj.y = location.y;
    }

    // reset methods

    resetLongTube(tube) {
        this.placeAt(this.tube_long_initial_pos.x, this.tube_long_initial_pos.y, tube);

        this.clearLongTubeHoles();
        if (this.puzzle_data.chamberType === 'left') {
            this.removeTubeFromFlow('lc_long_tube', 'left');

            this.puzzle_state.leftTubeLong.pos.x = this.tube_long_initial_pos.x;
            this.puzzle_state.leftTubeLong.pos.y = this.tube_long_initial_pos.y;

            this.puzzle_state.leftTubeLong.fixed = false;
        } else {
            this.removeTubeFromFlow('rc_long_tube', 'right');
            this.puzzle_state.rightTubeLong.pos.x = this.tube_long_initial_pos.x;
            this.puzzle_state.rightTubeLong.pos.y = this.tube_long_initial_pos.y;
            this.puzzle_state.rightTubeLong.fixed = false;
        }
    }

    resetShortTube(tube) {
        this.clearShortTubeHoles();
        if (this.puzzle_data.chamberType === 'left') {
            this.removeTubeFromFlow('lc_short_tube', 'left');
            this.puzzle_state.leftTubeShort.fixed = false;
            this.placeAt(
                this.lc_tube_short_initial_pos.x,
                this.lc_tube_short_initial_pos.y,
                tube
            );
            this.puzzle_state.leftTubeShort.pos.x = this.lc_tube_short_initial_pos.x;
            this.puzzle_state.leftTubeShort.pos.y = this.lc_tube_short_initial_pos.y;
        } else {
            this.removeTubeFromFlow('rc_short_tube', 'right');
            this.puzzle_state.rightTubeShort.fixed = false;
            this.placeAt(
                this.rc_tube_short_initial_pos.x,
                this.rc_tube_short_initial_pos.y,
                tube
            );
            this.puzzle_state.rightTubeShort.pos.x = this.rc_tube_short_initial_pos.x;
            this.puzzle_state.rightTubeShort.pos.y = this.rc_tube_short_initial_pos.y;
        }
    }

    resetMediumStraightTube(tube) {
        this.clearMediumStraightTubeHoles();
        this.removeTubeFromFlow('rc_ms_tube', 'right');
        this.puzzle_state.rightTubeMedium.fixed = false;
        this.placeAt(this.rc_tube_ms_initial_pos.x, this.rc_tube_ms_initial_pos.y, tube);
        this.puzzle_state.rightTubeMedium.pos.x = this.rc_tube_ms_initial_pos.x;
        this.puzzle_state.rightTubeMedium.pos.y = this.rc_tube_ms_initial_pos.y;
    }

    resetMediumDiagonalTube(tube) {
        this.clearMediumDiagonalTubeHoles();
        this.removeTubeFromFlow('lc_md_tube', 'left');
        this.puzzle_state.leftTubeMedium.fixed = false;
        this.placeAt(this.lc_tube_md_initial_pos.x, this.lc_tube_md_initial_pos.y, tube);
        this.puzzle_state.leftTubeMedium.pos.x = this.lc_tube_md_initial_pos.x;
        this.puzzle_state.leftTubeMedium.pos.y = this.lc_tube_md_initial_pos.y;
    }

    clearShortTubeHoles() {
        if (this.puzzle_data.chamberType === 'left') {
            if (this.puzzle_state.leftTubeShort.holes.length > 0) {
                this.puzzle_state.left.occupiedHoles =
                    this.puzzle_state.left.occupiedHoles.filter(
                        item => !this.puzzle_state.leftTubeShort.holes.includes(item)
                    );
                this.puzzle_state.leftTubeShort.holes.length = 0;
            }
        } else if (this.puzzle_state.rightTubeShort.holes.length > 0) {
            this.puzzle_state.right.occupiedHoles =
                this.puzzle_state.right.occupiedHoles.filter(
                    item => !this.puzzle_state.rightTubeShort.holes.includes(item)
                );
            this.puzzle_state.rightTubeShort.holes.length = 0;
        }
    }

    clearLongTubeHoles() {
        if (this.puzzle_data.chamberType === 'left') {
            if (this.puzzle_state.leftTubeLong.holes.length > 0) {
                this.puzzle_state.left.occupiedHoles =
                    this.puzzle_state.left.occupiedHoles.filter(
                        item => !this.puzzle_state.leftTubeLong.holes.includes(item)
                    );
                this.puzzle_state.leftTubeLong.holes.length = 0;
            }
        } else if (this.puzzle_state.rightTubeLong.holes.length > 0) {
            this.puzzle_state.right.occupiedHoles =
                this.puzzle_state.right.occupiedHoles.filter(
                    item => !this.puzzle_state.rightTubeLong.holes.includes(item)
                );
            this.puzzle_state.rightTubeLong.holes.length = 0;
        }
    }

    clearMediumStraightTubeHoles() {
        if (this.puzzle_state.rightTubeMedium.holes.length > 0) {
            this.puzzle_state.right.occupiedHoles =
                this.puzzle_state.right.occupiedHoles.filter(
                    item => !this.puzzle_state.rightTubeMedium.holes.includes(item)
                );
            this.puzzle_state.rightTubeMedium.holes.length = 0;
        }
    }

    clearMediumDiagonalTubeHoles() {
        if (this.puzzle_state.leftTubeMedium.holes.length > 0) {
            this.puzzle_state.left.occupiedHoles =
                this.puzzle_state.left.occupiedHoles.filter(
                    item => !this.puzzle_state.leftTubeMedium.holes.includes(item)
                );
            this.puzzle_state.leftTubeMedium.holes.length = 0;
        }
    }

    // flow methods

    addTubeToFlow(airflowPoint) {
        // if a tube is being placed at exactly same points then don't add
        let i = this.puzzle_state.airflowPoints.findIndex(
            point =>
                point.tubeType === airflowPoint.tubeType &&
                point.start === airflowPoint.start &&
                point.end === airflowPoint.end
        );
        if (i !== -1) {
            // ignore if point already exists
        } else {
            // if a tube is being added at some other points besides the previous ones then remove the first points and add new ones later
            i = this.puzzle_state.airflowPoints.findIndex(
                point => point.tubeType === airflowPoint.tubeType
            );

            if (i !== -1) {
                this.removeTubeFromFlow(airflowPoint.tubeType, airflowPoint.chamber);
            }

            this.puzzle_state.airflowPoints.push(airflowPoint);
        }
    }

    createAirflowPoint(start, end, tubeType, chamber) {
        const point = {
            start: start,
            end: end,
            tubeType: tubeType,
            chamber: chamber,
            marked: false,
        };

        return point;
    }

    removeTubeFromFlow(tubeType, chamber) {
        const i = this.puzzle_state.airflowPoints.findIndex(
            point => point.tubeType === tubeType && point.chamber === chamber
        );
        if (i !== -1) {
            this.puzzle_state.airflowPoints.splice(i, 1);
        }
    }

    updateFlowState() {
        if (this.puzzle_state.isSolved) return;

        let startPoint = 'A_3';
        let airflowPointFound = false;
        let currentChamber = 'left';
        let keyFound = false;

        for (let i = 0; i < this.puzzle_state.airflowPoints.length; i += 1) {
            this.puzzle_state.airflowPoints[i].marked = false;
        }

        while (!airflowPointFound) {
            keyFound = false;

            for (let i = 0; i < this.puzzle_state.airflowPoints.length; i += 1) {
                const currPoint = this.puzzle_state.airflowPoints[i];
                if (currPoint.marked === false) {
                    if (currentChamber === currPoint.chamber) {
                        if (currPoint.start === startPoint) {
                            startPoint = currPoint.end;
                            keyFound = true;
                            this.puzzle_state.airflowPoints[i].marked = true;
                            break;
                        } else if (currPoint.end === startPoint) {
                            startPoint = currPoint.start;
                            keyFound = true;
                            this.puzzle_state.airflowPoints[i].marked = true;
                            break;
                        }
                    }
                }
            }

            if (keyFound === false) {
                airflowPointFound = true;
            } else if (currentChamber === 'left') {
                currentChamber = 'right';
            } else {
                currentChamber = 'left';
            }
        }

        if (currentChamber === 'left') {
            this.lc_air_waves.setVisible(true);
            this.rc_air_waves.setVisible(false);
            this.puzzle_state.left.airflowActive = true;
            this.puzzle_state.left.airwavePos = startPoint;
            this.puzzle_state.right.airflowActive = false;

            if (
                !this.puzzle_state.left.occupiedHoles.includes(
                    this.puzzle_state.left.airwavePos
                )
            ) {
                this.lc_air_waves.setPosition(
                    this.lc_airflow_positions[this.puzzle_state.left.airwavePos].POSX,
                    this.lc_airflow_positions[this.puzzle_state.left.airwavePos].POSY
                );
            }
        } else {
            this.lc_air_waves.setVisible(false);
            this.rc_air_waves.setVisible(true);
            this.puzzle_state.right.airflowActive = true;
            this.puzzle_state.right.airwavePos = startPoint;
            this.puzzle_state.left.airflowActive = false;

            if (
                !this.puzzle_state.right.occupiedHoles.includes(
                    this.puzzle_state.right.airwavePos
                )
            ) {
                this.rc_air_waves.setPosition(
                    this.rc_airflow_positions[this.puzzle_state.right.airwavePos].POSX,
                    this.rc_airflow_positions[this.puzzle_state.right.airwavePos].POSY
                );
            }
        }
    }
}

import api from 'services/apis';
import axios from 'services/axios';

import * as ACTIONS from './action_types';

export const appendMessage = message => {
    return dispatch => {
        dispatch({
            type: ACTIONS.APPEND_MESSAGE,
            payload: message,
        });
    };
};

export const prependMessages = messages => {
    return dispatch => {
        dispatch({
            type: ACTIONS.PREPEND_MESSAGES,
            payload: messages,
        });
    };
};

export const setMessages = messages => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_MESSAGES,
            payload: messages,
        });
    };
};

export const clearMessages = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_MESSAGES,
            payload: [],
        });
    };
};

export const setCurrentOffset = offset => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CURRENT_OFFSET,
            payload: offset,
        });
    };
};

export const fetchAllMessages = () => {
    const url = api.chat.RETRIEVE;

    return (dispatch, getState) => {
        dispatch({
            type: ACTIONS.ALL_MESSAGES_FETCHED,
            payload: false,
        });

        const {
            authReducer: { gameId },
            messageReducer: { limit },
        } = getState();

        axios
            .get(`${url}?game=${gameId}&offset=0&limit=${limit}`)
            .then(res => {
                if (res.data.messages.length < limit) {
                    dispatch({
                        type: ACTIONS.ALL_MESSAGES_FETCHED,
                        payload: true,
                    });
                } else {
                    dispatch({
                        type: ACTIONS.SET_CURRENT_OFFSET,
                        payload: limit,
                    });
                }
                dispatch(setMessages(res.data.messages));
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const retrieveMessages = () => {
    const url = api.chat.RETRIEVE;

    return async (dispatch, getState) => {
        if (getState().messageReducer.allMessagesFetched) {
            console.log('All messages fetched!');
        } else if (!getState().messageReducer.messages?.length) {
            console.log('Initial chat load.');
        } else {
            const {
                authReducer: { gameId },
                messageReducer: { limit, offset },
            } = getState();

            await axios
                .get(`${url}?game=${gameId}&offset=${offset}&limit=${limit}`)
                .then(res => {
                    if (res.data.messages.length < limit) {
                        dispatch({
                            type: ACTIONS.ALL_MESSAGES_FETCHED,
                            payload: true,
                        });
                    } else {
                        dispatch({
                            type: ACTIONS.SET_CURRENT_OFFSET,
                            payload: offset + limit,
                        });
                    }
                    dispatch(prependMessages(res.data.messages));
                })
                .catch(error => {
                    console.log(error.message);
                });
        }
    };
};

export const sendMessage = text => {
    const url = api.chat.SEND;
    return (dispatch, getState) => {
        const { gameId } = getState().authReducer;
        axios.post(url, { gameId, text }).catch(error => {
            console.log(error.message);
        });
    };
};

import * as ACTIONS from './action_types';

let voteResultTimer;

export const setVoteSubmitTrue = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.CAN_SUBMIT_VOTE,
            payload: true,
        });
    };
};

export const setVoteSubmitFalse = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.CAN_SUBMIT_VOTE,
            payload: false,
        });
    };
};

export const setVoteResultTime = value => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_VOTE_RESULT_TIME,
            payload: value,
        });
    };
};

export const setVoteOnResult = value => {
    return (dispatch, getState) => {
        const { isResultScreenVisible } = getState().interactionReducer;

        if (isResultScreenVisible === true && value === true) {
            dispatch({
                type: ACTIONS.DISPLAY_VOTE_ON_RESULT,
                payload: true,
            });
        } else if (isResultScreenVisible === false || value === false) {
            dispatch({
                type: ACTIONS.DISPLAY_VOTE_ON_RESULT,
                payload: false,
            });
        }
    };
};

export const runVoteResultTimer = value => {
    return (dispatch, getState) => {
        if (value) {
            voteResultTimer = setInterval(() => {
                const voteResultTime =
                    getState().interactionReducer.voteResultTime || value;
                dispatch(setVoteResultTime(voteResultTime - 1000));
            }, 1000);
        } else {
            dispatch(setVoteResultTime(null));
            clearInterval(voteResultTimer);
        }
    };
};

export const setChatScreenVisible = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.DISPLAY_VOTE_SCREEN,
            payload: false,
        });
        dispatch({
            type: ACTIONS.DISPLAY_RESULT_SCREEN,
            payload: false,
        });
        dispatch({
            type: ACTIONS.DISPLAY_CHAT_SCREEN,
            payload: true,
        });
        dispatch(setVoteSubmitTrue());
        dispatch(setVoteOnResult(false));
        dispatch(runVoteResultTimer(null));
    };
};

export const setVoteScreenVisible = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.DISPLAY_VOTE_SCREEN,
            payload: true,
        });
        dispatch({
            type: ACTIONS.DISPLAY_CHAT_SCREEN,
            payload: false,
        });
        dispatch({
            type: ACTIONS.DISPLAY_RESULT_SCREEN,
            payload: false,
        });
        dispatch(setVoteSubmitFalse());
    };
};

export const setResultScreenVisible = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.DISPLAY_RESULT_SCREEN,
            payload: true,
        });
        dispatch({
            type: ACTIONS.DISPLAY_CHAT_SCREEN,
            payload: false,
        });
        dispatch({
            type: ACTIONS.DISPLAY_VOTE_SCREEN,
            payload: false,
        });
    };
};

export const setSelfVote = value => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SELF_SUBMIT_TRUE,
            payload: value,
        });
    };
};

export const blockInteraction = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_INTERACTION_BLOCKED,
            payload: true,
        });
    };
};

export const unblockInteraction = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_INTERACTION_BLOCKED,
            payload: false,
        });
    };
};

export const setSelfDestructInitialized = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_SELF_DESTRUCT_INIT,
            payload: true,
        });
    };
};

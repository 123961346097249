const INVENTORY_SERVICE = '/items';

const FETCH_ITEMS = `${INVENTORY_SERVICE}`;
const SUBMIT_ACTION = `${INVENTORY_SERVICE}/action`;
const CHANGE = `${INVENTORY_SERVICE}/change`;
const UNLOCK = `${INVENTORY_SERVICE}/unlock`;
const USE = `${INVENTORY_SERVICE}/use`;

const inventory = {
    FETCH_ITEMS,
    SUBMIT_ACTION,
    CHANGE,
    UNLOCK,
    USE,
};

export default inventory;

import PHASER from 'phaser';
import CONSTANTS from '../constants';

export default class LoadScene extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.LOAD_SCENE,
        });
        this.isLoaderComplete = false;
    }

    init() {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
    }

    preload() {
        this.loadGeneralAssets();
        this.loadAudioAssets();

        this.createProgressbar(this.center.x, this.center.y);
    }

    loadGeneralAssets() {
        this.load.image('main_background', '/assets/puzzles/main-bg.png');
        this.load.image('incorrect', '/assets/puzzles/incorrect.png');
        this.load.image('system_restored', '/assets/puzzles/system-restored.png');
    }

    loadAudioAssets() {
        this.load.audio('key', '/assets/sfx/puzzles/keypad/key.wav');
        this.load.audio('success', '/assets/sfx/puzzles/success.wav');
        this.load.audio('failure', '/assets/sfx/puzzles/failure.wav');
        this.load.audio(
            'spinner_rotated',
            '/assets/sfx/puzzles/fog-chamber/spinner-rotated.wav'
        );
        this.load.audio(
            'dials_solved',
            '/assets/sfx/puzzles/fog-chamber/dials-solved.wav'
        );
        this.load.audio('erase', '/assets/sfx/puzzles/keypad/erase.wav');
        this.load.audio('locker_switch', '/assets/sfx/puzzles/lockers/locker-switch.wav');
        this.load.audio(
            'mirror_chamber_change',
            '/assets/sfx/puzzles/mirror-chamber/mirror-chamber-change.wav'
        );
        this.load.audio(
            'comms_button',
            '/assets/sfx/puzzles/comms-panel/comms-button.wav'
        );
        this.load.audio(
            'puzzle_piece',
            '/assets/sfx/puzzles/rover-puzzle/puzzle-piece.wav'
        );
        this.load.audio(
            'sd_turn_knob',
            '/assets/sfx/puzzles/self-destruct/sd-turn-knob.wav'
        );
        this.load.audio(
            'sd_switch_lever',
            '/assets/sfx/puzzles/self-destruct/sd-switch-lever.wav'
        );
        this.load.audio(
            'solar_light',
            '/assets/sfx/puzzles/solar-pedestal/solar-light.wav'
        );
        this.load.audio(
            'solar_button',
            '/assets/sfx/puzzles/solar-pedestal/solar-button.wav'
        );
        this.load.audio(
            'air_chamber_plug',
            '/assets/sfx/puzzles/air-chamber/air-chamber-plug.wav'
        );
        this.load.audio(
            'tube_clicked',
            '/assets/sfx/puzzles/cooling-tubes/tube-clicked.wav'
        );
    }

    createProgressbar(x, y) {
        const width = 300;
        const height = 20;
        const xStart = x - width / 2;
        const yStart = y - height / 2;

        const borderOffset = 2;

        const borderRect = new PHASER.Geom.Rectangle(
            xStart - borderOffset,
            yStart - borderOffset,
            width + borderOffset * 2,
            height + borderOffset * 2
        );

        const border = this.add.graphics({
            lineStyle: {
                width: 5,
                color: 0xaaaaaa,
            },
        });
        border.strokeRectShape(borderRect);

        const progressbar = this.add.graphics();

        const updateProgressbar = function (percentage) {
            progressbar.clear();
            progressbar.fillStyle(0xffffff, 1);
            progressbar.fillRect(xStart, yStart, percentage * width, height);
        };

        this.load.on('progress', updateProgressbar);

        this.load.once(
            'complete',
            function () {
                this.load.off('progress', updateProgressbar);
                this.isLoaderComplete = true;
            },
            this
        );
    }
}

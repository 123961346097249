import PHASER from 'phaser';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzlesSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class RightLocker extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.RIGHT_LOCKER,
        });
        this.is_solved = false;
        this.in_correct_state = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.switch1_state = false;
        this.switch2_state = false;

        this.final_switch1_state = true;
        this.final_switch2_state = false;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image(
            'right_locker_inside',
            '/assets/puzzles/lockers/right-locker-inside.png'
        );
        this.load.image('right_switch_on', '/assets/puzzles/lockers/switch-on.png');
        this.load.image('right_switch_off', '/assets/puzzles/lockers/switch-off.png');
        this.load.image('right_light_on', '/assets/puzzles/lockers/light-on.png');
        this.load.image('right_light_off', '/assets/puzzles/lockers/light-off.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.lockers_bg = this.add
            .image(this.center.x, this.center.y, 'right_locker_inside')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createLockerPuzzle();

        const puzzleData = getPuzzleByKey('right-locker', 'Airlock');
        if (puzzleData) {
            if (puzzleData.state) {
                this.applyState(puzzleData.state);
            }
        }

        puzzlesSocket.onUpdate(this.puzzle_data.id, data => {
            const state = data.payload;
            this.applyState(state);
        });

        store.dispatch(
            setBackButtonCb(() => {
                const state = getPuzzleByKey('lockers', 'Airlock');
                state.comingFrom = 'right';
                this.scene.start(CONSTANTS.SCENES.AIRLOCK.LOCKERS, state);
            })
        );
    }

    createLockerPuzzle() {
        // switch 1
        this.switch1_light_On = this.add
            .image(
                this.center.x - this.game_size.x * 0.096,
                this.center.y - this.game_size.y * 0.09,
                'right_light_on'
            )
            .setOrigin(0.5)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switch1_light_Off = this.add
            .image(
                this.center.x - this.game_size.x * 0.096,
                this.center.y - this.game_size.y * 0.09,
                'right_light_off'
            )
            .setOrigin(0.5)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switch1_On = this.add
            .image(
                this.center.x - this.game_size.x * 0.095,
                this.center.y + this.game_size.y * 0.065,
                'right_switch_on'
            )
            .setOrigin(0.5)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.switch1Toggled();
            })
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switch1_Off = this.add
            .image(
                this.center.x - this.game_size.x * 0.095,
                this.center.y + this.game_size.y * 0.065,
                'right_switch_off'
            )
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => {
                this.switch1Toggled();
            })
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // center light
        this.centter_light_On = this.add
            .image(
                this.center.x,
                this.center.y - this.game_size.y * 0.09,
                'right_light_off'
            )
            .setOrigin(0.5)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // switch 2
        this.switch2_light_On = this.add
            .image(
                this.center.x + this.game_size.x * 0.095,
                this.center.y - this.game_size.y * 0.09,
                'right_light_on'
            )
            .setOrigin(0.5)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switch2_light_Off = this.add
            .image(
                this.center.x + this.game_size.x * 0.095,
                this.center.y - this.game_size.y * 0.09,
                'right_light_off'
            )
            .setOrigin(0.5)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switch2_On = this.add
            .image(
                this.center.x + this.game_size.x * 0.095,
                this.center.y + this.game_size.y * 0.065,
                'right_switch_on'
            )
            .setOrigin(0.5)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.switch2Toggled();
            })
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switch2_Off = this.add
            .image(
                this.center.x + this.game_size.x * 0.095,
                this.center.y + this.game_size.y * 0.065,
                'right_switch_off'
            )
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => {
                this.switch2Toggled();
            })
            .setScale(this.scale_factor.x, this.scale_factor.y);
    }

    switch1Toggled() {
        if (this.is_solved) return;

        this.sound.play('locker_switch');
        this.switch1_state = !this.switch1_state;

        if (this.switch1_state) {
            this.switch1_On.setVisible(true);
            this.switch1_Off.setVisible(false);

            this.switch1_light_On.setVisible(true);
            this.switch1_light_Off.setVisible(false);
        } else {
            this.switch1_On.setVisible(false);
            this.switch1_Off.setVisible(true);

            this.switch1_light_On.setVisible(false);
            this.switch1_light_Off.setVisible(true);
        }

        this.verifySwitchStates();
    }

    switch2Toggled() {
        if (this.is_solved) return;

        this.sound.play('locker_switch');
        this.switch2_state = !this.switch2_state;

        if (this.switch2_state) {
            this.switch2_On.setVisible(true);
            this.switch2_Off.setVisible(false);

            this.switch2_light_On.setVisible(true);
            this.switch2_light_Off.setVisible(false);
        } else {
            this.switch2_On.setVisible(false);
            this.switch2_Off.setVisible(true);

            this.switch2_light_On.setVisible(false);
            this.switch2_light_Off.setVisible(true);
        }

        this.verifySwitchStates();
    }

    verifySwitchStates() {
        if (
            this.switch1_state === this.final_switch1_state &&
            this.switch2_state === this.final_switch2_state
        ) {
            this.in_correct_state = true;

            const leftLockerState = getPuzzleByKey('left-locker', 'Airlock');
            if (leftLockerState.state) {
                if (leftLockerState.state.inCorrectState === true) {
                    this.is_solved = true;

                    this.sound.play('success');

                    const lockers = getPuzzleByKey('lockers', 'Airlock');
                    store.dispatch(markPuzzleSolved(lockers.id));

                    // < --- MIXPANEL ANALYTICS
                    Mixpanel.track('Puzzle Complete', {
                        ...lockers,
                        puzzle: 'RIGHT LOCKER',
                    });
                    // --- >

                    leftLockerState.state.isSolved = true;
                    this.sendPuzzleState(leftLockerState.state, leftLockerState.id);
                } else {
                    this.sound.play('dials_solved', { volume: 0.3 });
                }
            } else {
                this.sound.play('dials_solved', { volume: 0.3 });
            }
        } else {
            this.in_correct_state = false;
        }

        this.syncState();
    }

    applyState(state) {
        this.in_correct_state = state.inCorrectState;
        this.is_solved = state.isSolved;
        this.switch1_state = state.switch1State;
        this.switch2_state = state.switch2State;

        this.switch1_On.setVisible(this.switch1_state);
        this.switch1_Off.setVisible(!this.switch1_state);
        this.switch1_light_On.setVisible(this.switch1_state);
        this.switch1_light_Off.setVisible(!this.switch1_state);

        this.switch2_On.setVisible(this.switch2_state);
        this.switch2_Off.setVisible(!this.switch2_state);
        this.switch2_light_On.setVisible(this.switch2_state);
        this.switch2_light_Off.setVisible(!this.switch2_state);
    }

    syncState() {
        const state = {
            switch1State: this.switch1_state,
            switch2State: this.switch2_state,
            inCorrectState: this.in_correct_state,
            isSolved: this.is_solved,
        };

        this.sendState(state);
    }

    sendPuzzleState(state, id) {
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(id, state, user.id, user);
    }

    sendState(state) {
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(this.puzzle_data.id, state, user.id, user);
    }
}

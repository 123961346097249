import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import useSound from 'use-sound';

import { isArrayNotEqual } from 'utils/arrayUtils';
import { PUZZLE_ID_MAP } from 'constants.js';

import {
    setMissionControlDisplay,
    fetchPuzzleData,
    updateLocalPuzzleData,
    updateCurrentPuzzleStatus,
    setAsPlayingPuzzle,
    setCurrentPuzzle,
    markPuzzleSolved,
} from 'store/puzzleReducer/actions';
import { unblockInteraction } from 'store/interactionReducer/actions';
import {
    fetchCurrentVideos,
    markVideoWatched,
    clearWatchedVideos,
} from 'store/videosReducer/actions';
import { fetchAllInventory } from 'store/inventoryReducer/actions';
import { fetchAllMessages } from 'store/messageReducer/actions';
import { startGame, setGameStarted } from 'store/gameReducer/actions';

import { Mixpanel } from 'services/mixpanel';
import { puzzlesSocket, puzzleStateSocket, timerSocket } from 'services/websockets/index';
import { usePrevious } from 'services/customHooks';

import PuzzleManager from 'external/phaser';

import KriosLogo from 'assets/dashboardScreen/MissionControl_KriosLogo.png';
import MissionAccomplished from 'assets/dashboardScreen/MissionControl_Win.png';
import MissionFailed from 'assets/dashboardScreen/MissionControl_Loss.png';
import BackButtonSound from 'assets/sounds/back.wav';

import VideoPlayer from './components/VideoPlayer';

import './styles.scss';

function MissionControl({
    currentPuzzle,
    backButtonCb,
    displayType,
    setDisplayType,
    isPuzzleSocketReady,
    fetchPuzzleData,
    fetchCurrentVideos,
    currentVideos,
    markVideoWatched,
    fetchAllInventory,
    authenticatedUser,
    fetchAllMessages,
    startGame,
    isGameStarted,
    setGameStarted,
    updateLocalPuzzleData,
    isInteractionBlocked,
    updateCurrentPuzzleStatus,
    clearWatchedVideos,
    setAsNotPlayingPuzzle,
    setCurrentPuzzle,
    isSelfDestructInitialized,
    markPuzzleSolved,
    unblockInteraction,
}) {
    const [isVideoVisible, setVideoVisible] = useState(false);
    const [gameObject, setGameObject] = useState(null);
    const currentPuzzleRef = useRef(currentPuzzle);
    const [backButtonSound] = useSound(BackButtonSound, { volume: 0.7 });
    const prevPuzzle = usePrevious(currentPuzzle);
    const prevDisplayType = usePrevious(displayType);
    const prevVideos = usePrevious(currentVideos);
    const [showHostSDOverride, setHostSDOverride] = useState(true);

    const displayTypeOptions = useMemo(() => {
        return {
            PUZZLE: 'puzzle',
            WIN: 'win',
            LOSS: 'loss',
        };
    }, []);

    const { isPlayer } = authenticatedUser;

    const loadCurrentPuzzle = useCallback(() => {
        if (!currentPuzzle) return;

        clearWatchedVideos();
        if (displayType !== displayTypeOptions.PUZZLE) {
            return setDisplayType(displayTypeOptions.PUZZLE);
        }

        gameObject
            ? gameObject.loadPuzzle(currentPuzzle)
            : setGameObject(new PuzzleManager(currentPuzzle));
    }, [
        currentPuzzle,
        clearWatchedVideos,
        displayType,
        displayTypeOptions,
        gameObject,
        setDisplayType,
    ]);

    useEffect(() => {
        if (isPlayer) {
            fetchCurrentVideos();
        }
    }, [isPlayer, fetchCurrentVideos]);

    useEffect(() => {
        if (isSelfDestructInitialized && !isPlayer) {
            setHostSDOverride(true);
        }
    }, [isSelfDestructInitialized]);

    useEffect(() => {
        if (isPuzzleSocketReady) {
            puzzlesSocket.onSolve(data => {
                if (data.puzzleId === currentPuzzleRef.current?.id) {
                    updateCurrentPuzzleStatus();
                    setCurrentPuzzle(null);
                }
                if (data.puzzleId === PUZZLE_ID_MAP.SELF_DESTRUCT) {
                    unblockInteraction();
                    timerSocket.puzzleTimerOff();
                }
                fetchPuzzleData();
                fetchCurrentVideos();
                fetchAllInventory();
                fetchAllMessages();
            });

            puzzlesSocket.onUpdate(null, data => {
                updateLocalPuzzleData(data);
            });

            puzzlesSocket.onStartGame(() => {
                setGameStarted();
                fetchCurrentVideos();
                Mixpanel.track('Game Started');
            });
        }
    }, [
        fetchAllInventory,
        fetchAllMessages,
        fetchCurrentVideos,
        fetchPuzzleData,
        setCurrentPuzzle,
        setGameStarted,
        updateCurrentPuzzleStatus,
        updateLocalPuzzleData,
        isPuzzleSocketReady,
    ]);

    useEffect(() => {
        if (!isPlayer || isInteractionBlocked) return;

        if (
            displayType !== prevDisplayType &&
            displayType === displayTypeOptions.PUZZLE
        ) {
            loadCurrentPuzzle();
        } else if (
            displayType !== prevDisplayType &&
            displayType !== displayTypeOptions.PUZZLE
        ) {
            puzzleStateSocket.disconnect();
            setAsNotPlayingPuzzle();
        }
    }, [
        displayType,
        isInteractionBlocked,
        setAsNotPlayingPuzzle,
        isPlayer,
        displayTypeOptions,
        prevDisplayType,
        loadCurrentPuzzle,
    ]);

    useEffect(() => {
        if (!isPlayer || isInteractionBlocked) return;

        currentPuzzleRef.current = currentPuzzle;
        if (
            prevPuzzle?.id !== currentPuzzle?.id ||
            currentPuzzle?.key === 'launch-button'
        ) {
            loadCurrentPuzzle();
        }
    }, [currentPuzzle, loadCurrentPuzzle, isInteractionBlocked, isPlayer, prevPuzzle]);

    useEffect(() => {
        if (!isInteractionBlocked && isArrayNotEqual(prevVideos, currentVideos)) {
            if (currentVideos.length && currentVideos.some(video => !video.isWatched)) {
                Mixpanel.track('Play Videos', { currentVideos });
                setVideoVisible(true);

                if (!currentPuzzle) {
                    setDisplayType(null);
                }
            } else {
                setVideoVisible(false);
            }
        }
    }, [currentPuzzle, currentVideos, isInteractionBlocked, prevVideos, setDisplayType]);

    const handleBack = () => {
        if (!isInteractionBlocked) {
            backButtonSound();
            if (backButtonCb) {
                backButtonCb();
            } else if (isVideoVisible) {
                setVideoVisible(false);
            } else {
                setCurrentPuzzle(null);
                setDisplayType(null);
            }
        }
    };

    const onPlaylistEnd = () => {
        setVideoVisible(false);
        clearWatchedVideos();
    };

    const onVideoEnd = (video, removeWatched) => {
        markVideoWatched(video, removeWatched);
        Mixpanel.track('Video Complete', { ...video });
    };

    const onStartGame = () => {
        startGame();
        setGameStarted();
        Mixpanel.track('Host: Start Game');
    };

    const overrideSelfDestruct = () => {
        markPuzzleSolved(PUZZLE_ID_MAP.SELF_DESTRUCT);
    };

    return (
        <div className="mission-control-container">
            <img src={KriosLogo} alt="mission_control_krios_logo" className="logo" />
            {isPlayer ? (
                <>
                    <button
                        className="back-button"
                        onClick={handleBack}
                        aria-label="Back"
                    />
                    <div className="mission-control-wrapper">
                        <div className={cn({ hide: !isVideoVisible }, 'mission-control')}>
                            <VideoPlayer
                                className="video-player"
                                onVideoEnd={onVideoEnd}
                                onPlaylistEnd={onPlaylistEnd}
                                isVideoVisible={isVideoVisible}
                            />
                        </div>
                        <div
                            id="mission_control"
                            className={cn({ hide: !displayType }, 'mission-control')}
                        >
                            {displayType === displayTypeOptions.WIN && (
                                <img
                                    src={MissionAccomplished}
                                    alt="Mission Accomplished"
                                    className="background"
                                />
                            )}
                            {displayType === displayTypeOptions.LOSS && (
                                <img
                                    src={MissionFailed}
                                    alt="Mission Failure"
                                    className="background"
                                />
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className="mission-control-host-wrapper">
                    <button
                        className="start-game"
                        disabled={isGameStarted}
                        onClick={onStartGame}
                    >
                        Start Game
                    </button>
                    {showHostSDOverride && (
                        <button onClick={overrideSelfDestruct} className="start-game">
                            Self Destruct Kill Switch
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = ({
    puzzleReducer,
    videosReducer,
    authReducer,
    gameReducer,
    interactionReducer,
}) => ({
    currentPuzzle: puzzleReducer.currentPuzzle,
    currentRoom: puzzleReducer.currentRoom,
    backButtonCb: puzzleReducer.backButtonCb,
    displayType: puzzleReducer.missionControlDisplay,
    currentVideos: videosReducer.currentVideos,
    current360Videos: videosReducer.current360Videos,
    authenticatedUser: authReducer.user,
    isGameStarted: gameReducer.isGameStarted,
    isInteractionBlocked: interactionReducer.isInteractionBlocked,
    isSelfDestructInitialized: interactionReducer.isSelfDestructInitialized,
});

const mapDispatchToProps = {
    setDisplayType: display => setMissionControlDisplay(display),
    fetchPuzzleData: () => fetchPuzzleData(),
    fetchCurrentVideos: () => fetchCurrentVideos(),
    markVideoWatched: id => markVideoWatched(id),
    fetchAllInventory: () => fetchAllInventory(),
    startGame: () => startGame(),
    updateLocalPuzzleData: data => updateLocalPuzzleData(data),
    updateCurrentPuzzleStatus: () => updateCurrentPuzzleStatus(),
    fetchAllMessages: () => fetchAllMessages(),
    setGameStarted: () => setGameStarted(),
    clearWatchedVideos: () => clearWatchedVideos(),
    setAsNotPlayingPuzzle: () => setAsPlayingPuzzle(null),
    setCurrentPuzzle: puzzle => setCurrentPuzzle(puzzle),
    markPuzzleSolved: id => markPuzzleSolved(id),
    unblockInteraction: () => unblockInteraction(),
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionControl);

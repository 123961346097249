import React from 'react';

import BlackYellowFooter from 'assets/dashboardScreen/blackyellow.png';

const Footer = () => {
    return (
        <div className="yellow-black-stripes">
            <img src={BlackYellowFooter} alt="Black Yellow Stripped Footer" />
        </div>
    );
};

export default Footer;

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import initializeSentry from './services/sentry';
import * as serviceWorker from './serviceWorker';

import './index.css';

initializeSentry();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

function unlockAudio() {
    const sound = new Audio('assets/sounds/vote-init.wav');

    try {
        const playPromise = sound.play();
        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    sound.pause();
                    sound.currentTime = 0;
                })
                .catch(() => {});
        }
    } catch (e) {}

    document.body.removeEventListener('click', unlockAudio);
    document.body.removeEventListener('touchstart', unlockAudio);
}

document.body.addEventListener('click', unlockAudio);
document.body.addEventListener('touchstart', unlockAudio);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

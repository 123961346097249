import * as ACTIONS from './action_types';

const initialState = {
    inventoryItems: [],
};

const inventoryReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.FETCH_INVENTORY:
            return {
                ...state,
                inventoryItems: payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default inventoryReducer;

import game from './game';
import players from './players';
import vote from './vote';
import chat from './chat';
import puzzles from './puzzles';
import inventory from './inventory';
import hosts from './hosts';

const api = {
    game,
    players,
    vote,
    chat,
    inventory,
    puzzles,
    hosts,
};

export default api;

import PHASER from 'phaser';

import { PUZZLE_VIDEOS_URL, ITEM_ID_MAP } from 'constants.js';

import { normalizeAngle } from 'utils/normalization';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { setCurrentVideos } from 'store/videosReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';
import { unlockItem } from 'store/inventoryReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

let dragging = false;
export default class FogChamber extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.FOG_CHAMBER,
        });
        this.isSolved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.spinner1Angle = 0;
        this.spinner2Angle = 0;
        this.spinner3Angle = 0;
        this.spinner4Angle = 0;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;

        store.dispatch(setBackButtonCb());
    }

    preload() {
        this.load.image('chamber_bg', '/assets/puzzles/fog-chamber/chamber-bg.png');
        this.load.image(
            'chamber_outer_bg_unsolved',
            '/assets/puzzles/fog-chamber/chamber-outer-bg-unsolved.png'
        );
        this.load.image(
            'chamber_outer_bg_solved',
            '/assets/puzzles/fog-chamber/chamber-outer-bg-solved.png'
        );
        this.load.image('unfogged', '/assets/puzzles/fog-chamber/unfogged.png');
        this.load.image(
            'dials_small_unsolved',
            '/assets/puzzles/fog-chamber/dials-small-unsolved.png'
        );
        this.load.image(
            'dials_small_solved',
            '/assets/puzzles/fog-chamber/dials-small-solved.png'
        );
        this.load.image('tubes_nonglow', '/assets/puzzles/fog-chamber/tubes-nonglow.png');
        this.load.image('tubes_glow', '/assets/puzzles/fog-chamber/tubes-glow.png');
        this.load.image('dial1', '/assets/puzzles/fog-chamber/dial1.png');
        this.load.image('dial2', '/assets/puzzles/fog-chamber/dial2.png');
        this.load.image('dial3', '/assets/puzzles/fog-chamber/dial3.png');
        this.load.image('dial4', '/assets/puzzles/fog-chamber/dial4.png');
        this.load.image('dial1_spinner', '/assets/puzzles/fog-chamber/dial1-spinner.png');
        this.load.image('dial2_spinner', '/assets/puzzles/fog-chamber/dial2-spinner.png');
        this.load.image('dial3_spinner', '/assets/puzzles/fog-chamber/dial3-spinner.png');
        this.load.image('dial4_spinner', '/assets/puzzles/fog-chamber/dial4-spinner.png');
    }

    create() {
        this.mainBackground = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.mainBackground.width;
        this.scale_factor.y = this.game.renderer.height / this.mainBackground.height;

        this.mainBackground.setScale(this.scale_factor.x, this.scale_factor.y);

        this.chamberOuterView = this.add.container(0, 0).setDepth(1);

        this.chamberOuterBgUnsolved = this.add
            .image(this.center.x, this.center.y, 'chamber_outer_bg_unsolved')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.chamberOuterBgSolved = this.add
            .image(this.center.x, this.center.y, 'chamber_outer_bg_solved')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.tubes = this.add
            .image(this.center.x, this.center.y - this.game_size.y * 0.355, 'tubes_glow')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                const lightsVideoUrl = `${PUZZLE_VIDEOS_URL}Video-2_hls/index.m3u8`;
                store.dispatch(setCurrentVideos([lightsVideoUrl]));
            });

        this.unfogged = this.add
            .image(this.center.x, this.center.y, 'unfogged')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                const fogVideoUrl = `${PUZZLE_VIDEOS_URL}Video-1_hls/index.m3u8`;
                store.dispatch(setCurrentVideos([fogVideoUrl]));
            });

        this.dialsSmall = this.add
            .image(
                this.center.x,
                this.center.y + this.game_size.y * 0.32,
                'dials_small_unsolved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.chamberOuterView.setVisible(false);

                if (this.puzzleData.status === 'completed') {
                    this.systemRestoredImage.setVisible(true);
                    this.restoredText.setVisible(true);

                    store.dispatch(
                        setBackButtonCb(() => {
                            this.systemRestoredImage.setVisible(false);
                            this.restoredText.setVisible(false);
                            this.chamberOuterView.setVisible(true);

                            store.dispatch(setBackButtonCb());
                        })
                    );
                } else {
                    store.dispatch(
                        setBackButtonCb(() => {
                            this.chamberOuterView.setVisible(true);
                            store.dispatch(setBackButtonCb());
                        })
                    );
                }
            });

        if (this.puzzleData.status === 'completed') {
            this.chamberOuterBgSolved.setVisible(true);
            this.unfogged.setVisible(true);
            this.chamberOuterBgUnsolved.setVisible(false);
            this.dialsSmall.setTexture('dials_small_solved');
            this.tubes.setTexture('tubes_nonglow');
        } else {
            this.chamberOuterBgSolved.setVisible(false);
            this.unfogged.setVisible(false);
            this.chamberOuterBgUnsolved.setVisible(true);
        }

        this.chamberOuterView.add([
            this.chamberOuterBgUnsolved,
            this.chamberOuterBgSolved,
            this.unfogged,
            this.tubes,
            this.dialsSmall,
        ]);

        this.chamberBg = this.add
            .image(this.center.x, this.center.y, 'chamber_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createDials();
        this.timedEvent = this.time.addEvent({
            delay: 500, // ms
            callback: this.updateStatuses,
            callbackScope: this,
            repeat: -1,
        });

        this.systemRestoredImage = this.add
            .image(this.center.x, this.center.y, 'system_restored')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setDepth(1)
            .setVisible(false);

        this.restoredText = this.add
            .text(this.center.x, this.center.y, 'Visibility Restored', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setDepth(1)
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        store.dispatch(setBackButtonCb());

        if (this.puzzleData.status === 'completed') {
            this.setSolvedState();
        }
    }

    createDials() {
        // dial 1
        this.dial1 = this.add
            .image(
                this.game.renderer.width / 2 - this.game_size.x * 0.34,
                this.game.renderer.height / 2,
                'dial1'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateDial(this.dial1Spinner);
            })
            .on('drag', () => {
                this.rotateDial(this.dial1Spinner);
            })
            .on('dragend', () => {
                this.snapSpinner(this.dial1Spinner, 1);
            });

        this.dial1Spinner = this.add
            .image(
                this.center.x - this.game_size.x * 0.33,
                this.center.y + this.game_size.y * 0.015,
                'dial1_spinner'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // dial 2
        this.dial2 = this.add
            .image(this.center.x - this.game_size.x * 0.11, this.center.y, 'dial2')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateDial(this.dial2Spinner);
            })
            .on('drag', () => {
                this.rotateDial(this.dial2Spinner);
            })
            .on('dragend', () => {
                this.snapSpinner(this.dial2Spinner, 2);
            });

        this.dial2Spinner = this.add
            .image(
                this.center.x - this.game_size.x * 0.11,
                this.center.y,
                'dial2_spinner'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // dial 3
        this.dial3 = this.add
            .image(this.center.x + this.game_size.x * 0.115, this.center.y, 'dial3')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateDial(this.dial3Spinner);
            })
            .on('drag', () => {
                this.rotateDial(this.dial3Spinner);
            })
            .on('dragend', () => {
                this.snapSpinner(this.dial3Spinner, 3);
            });

        this.dial3Spinner = this.add
            .image(
                this.center.x + this.game_size.x * 0.115,
                this.center.y,
                'dial3_spinner'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // dial 4
        this.dial4 = this.add
            .image(this.center.x + this.game_size.x * 0.35, this.center.y, 'dial4')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateDial(this.dial4Spinner);
            })
            .on('drag', () => {
                this.rotateDial(this.dial4Spinner);
            })
            .on('dragend', () => {
                this.snapSpinner(this.dial4Spinner, 4);
            });

        this.dial4Spinner = this.add
            .image(
                this.center.x + this.game_size.x * 0.362,
                this.center.y + this.game_size.y * 0.01,
                'dial4_spinner'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);
    }

    getTargetAngle(spinner) {
        let targetAngle =
            (360 / (2 * Math.PI)) *
                PHASER.Math.Angle.Between(
                    spinner.x,
                    spinner.y,
                    this.game.input.activePointer.x,
                    this.game.input.activePointer.y
                ) +
            90;

        if (targetAngle < 0) targetAngle += 360;

        return targetAngle;
    }

    rotateDial(spinner) {
        if (this.isSolved) return;

        const angle = this.getTargetAngle(spinner);

        if (this.game.input.activePointer.isDown && !dragging) {
            dragging = true;
        }
        if (!this.game.input.activePointer.isDown && dragging) {
            dragging = false;
        }

        if (dragging) {
            spinner.angle = angle;
        }
    }

    snapSpinner(spinner, dialNum) {
        if (this.isSolved) return;

        this.sound.play('spinner_rotated');
        let { angle } = spinner;
        if (spinner.angle < 0) angle += 360;

        angle = normalizeAngle(angle, 45);

        switch (dialNum) {
            case 1:
                this.spinner1Angle = angle;
                break;
            case 2:
                this.spinner2Angle = angle;
                break;
            case 3:
                this.spinner3Angle = angle;
                break;
            case 4:
                this.spinner4Angle = angle;
                break;
            // no default
        }

        spinner.angle = angle;

        this.validateDials();
    }

    validateDials() {
        if (
            this.spinner1Angle === 90 &&
            this.spinner2Angle === 135 &&
            this.spinner3Angle === 45 &&
            this.spinner4Angle === 135
        ) {
            this.isSolved = true;
            this.sound.play('dials_solved', { volume: 0.3 });
            store.dispatch(markPuzzleSolved(this.puzzleData.id));
            store.dispatch(unlockItem(ITEM_ID_MAP.FOG_CHAMBER));

            this.systemRestoredImage.setVisible(true);
            this.restoredText.setVisible(true);

            store.dispatch(
                setBackButtonCb(() => {
                    this.systemRestoredImage.setVisible(false);
                    this.restoredText.setVisible(false);
                    this.chamberOuterView.setVisible(true);

                    store.dispatch(setBackButtonCb());
                })
            );

            this.chamberOuterBgSolved.setVisible(true);
            this.chamberOuterBgUnsolved.setVisible(false);
            this.unfogged.setVisible(true);

            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzleData,
                puzzle: 'FOG CHAMBER',
            });
            // --- >
        }
    }

    setSolvedState() {
        this.isSolved = true;
        this.dial1Spinner.angle = 90;
        this.dial2Spinner.angle = 135;
        this.dial3Spinner.angle = 45;
        this.dial4Spinner.angle = 135;
    }

    updateChamberStatus() {
        const state = getPuzzleByKey('fog-chamber', this.puzzleData.room);

        if (state.status === 'completed') {
            this.chamberOuterBgSolved.setVisible(true);
            this.unfogged.setVisible(true);
            this.chamberOuterBgUnsolved.setVisible(false);
        } else {
            this.chamberOuterBgSolved.setVisible(false);
            this.unfogged.setVisible(false);
            this.chamberOuterBgUnsolved.setVisible(true);
        }
    }

    updateStatuses() {
        this.updateChamberStatus();
    }
}

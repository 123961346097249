import api from 'services/apis';
import axios from 'services/axios';

import * as ACTIONS from './action_types';

export const STATUS_TYPES = {
    JOINING_GAME: 'JOINING_GAME',
    LEAVING_GAME: 'LEAVING_GAME',
    CHATTING: 'CHATTING',
    SETTING_COMMAND: 'SETTING_COMMAND',
    ADDING_VOTE: 'ADDING_VOTE',
    SOLVING_PUZZLE: 'SOLVING_PUZZLE',
};

export const getStatusText = status => {
    if (status.user) {
        switch (status.type) {
            case STATUS_TYPES.JOINING_GAME:
                return `${status.user.firstName} has joined the game`;
            case STATUS_TYPES.LEAVING_GAME:
                return `${status.user.firstName} has left the game`;
            case STATUS_TYPES.CHATTING:
                return `${status.user.firstName} is chatting`;
            case STATUS_TYPES.SETTING_COMMAND:
                return 'HAL Control';
            case STATUS_TYPES.ADDING_VOTE:
                return `${status.user.firstName} is adding a vote`;
            case STATUS_TYPES.SOLVING_PUZZLE:
                return status.text
                    ? status.text
                    : `${status.user.firstName} is solving a puzzle`;
            default:
                return '';
        }
    } else {
        return '';
    }
};

const parseSession = ({
    id,
    mediaId,
    playerId,
    puzzle,
    puzzleId,
    status,
    user,
    text,
}) => ({
    id,
    mediaId,
    senderId: playerId,
    puzzle,
    puzzleId,
    type: status,
    text: getStatusText({
        type: status,
        user,
        text: puzzle?.title ? puzzle.title : text,
    }),
    user,
});

const parseSessions = sessions => sessions.map(session => parseSession(session));

export const setStatus = status => {
    return dispatch => {
        dispatch({
            type: ACTIONS.UPDATE_PLAYER_STATUS,
            payload: {
                ...status,
                text: getStatusText(status),
            },
        });
    };
};

export const submitStatus = status => {
    const url = api.players.STATUS;
    return () => {
        axios.put(url, { status: status.type }).catch(error => {
            console.log(error.message);
        });
    };
};

export const setMyStatus = statusData => {
    return (dispatch, getState) => {
        const {
            statusReducer: { status: currentStatuses },
        } = getState();

        if (currentStatuses[statusData.senderId]?.type === statusData.type) return;

        dispatch({
            type: ACTIONS.UPDATE_PLAYER_STATUS,
            payload: {
                ...statusData,
                text: getStatusText(statusData),
            },
        });
        dispatch(submitStatus(statusData));
    };
};

export const setPlayerAsLeft = status => {
    return (dispatch, getState) => {
        const currentStatus = getState().statusReducer.status;
        const playerToDelete = Object.keys(currentStatus).find(s => {
            return currentStatus[s].socketId === status.socketId;
        });
        dispatch({
            type: ACTIONS.DELETE_PLAYER_STATUS,
            payload: playerToDelete,
        });
    };
};

export const fetchAllSessions = () => {
    const url = api.players.SESSIONS;
    return (dispatch, getState) => {
        const { gameId } = getState().authReducer;
        axios
            .get(`${url}?gameId=${gameId}`)
            .then(res => {
                const sessions = parseSessions(res.data.sessions);
                const status = {};
                sessions.forEach(session => {
                    status[session.senderId] = session;
                });
                dispatch({
                    type: ACTIONS.SET_ALL_STATUSES,
                    payload: status,
                });
                dispatch({
                    type: ACTIONS.SET_ACTIVE_PLAYERS,
                    payload: sessions.filter(
                        session => session.type !== STATUS_TYPES.LEAVING_GAME
                    ),
                });
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

import { ASSETS_BUCKET_URL } from 'constants.js';

import api from 'services/apis';
import axios from 'services/axios';
import { voteSocket, inventorySocket } from 'services/websockets/index';

import { setSelfVote } from 'store/interactionReducer/actions';

import * as ACTIONS from './action_types';

export const fetchAllInventory = () => {
    const url = api.inventory.FETCH_ITEMS;
    return (dispatch, getState) => {
        const { gameId } = getState().authReducer;
        axios
            .get(`${url}?game=${gameId}`)
            .then(res => {
                const array = res.data.items.map(itemData => ({
                    ...itemData,
                    item: {
                        ...itemData.item,
                        url: ASSETS_BUCKET_URL + itemData.item.url,
                    },
                }));
                dispatch({
                    type: ACTIONS.FETCH_INVENTORY,
                    payload: array,
                    gameId,
                });
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const submitAction = (actionId, locationId, authId) => {
    const url = api.inventory.SUBMIT_ACTION;
    return (dispatch, getState) => {
        const { gameId } = getState().authReducer;
        axios
            .post(url, { actionId, locationId, gameId })
            .then(res => {
                if (authId && res.data.voting.startedBy === authId) {
                    dispatch(setSelfVote(true));
                }

                voteSocket.createVote(
                    res.data.voting.statement,
                    res.data.voting.id,
                    res.data.voting.startedBy
                );
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const unlockItem = itemId => {
    const url = api.inventory.UNLOCK;
    return (dispatch, getState) => {
        const {
            inventoryReducer: { inventoryItems },
            authReducer: { user },
        } = getState();

        if (inventoryItems.find(item => item.item.id === itemId)) {
            return;
        }

        axios
            .put(`${url}/${itemId}`)
            .then(() => {
                dispatch(fetchAllInventory());
                inventorySocket.update(itemId, user.id, user);
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const removeItem = itemId => {
    const url = api.inventory.USE;
    return (dispatch, getState) => {
        const {
            inventoryReducer: { inventoryItems },
            authReducer: { user },
        } = getState();

        if (!inventoryItems.find(item => item.item.id === itemId)) {
            return;
        }

        axios
            .put(`${url}/${itemId}`)
            .then(() => {
                dispatch(fetchAllInventory());
                inventorySocket.update(itemId, user.id, user);
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const changeState = (itemId, status) => {
    const url = api.inventory.CHANGE;
    return (dispatch, getState) => {
        const {
            authReducer: { user, gameId },
        } = getState();

        axios
            .put(`${url}/${itemId}`, { gameId, status })
            .then(() => {
                dispatch(fetchAllInventory());
                inventorySocket.update(itemId, user.id, user);
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const submitSelfDestructCommand = () => {
    return (dispatch, getState) => {
        const { isSelfDestructInitialized } = getState().interactionReducer;

        if (!isSelfDestructInitialized) {
            const selfDestructActionId = 25;
            const selfDestructLocationId = 70;

            dispatch(submitAction(selfDestructActionId, selfDestructLocationId));
        }
    };
};

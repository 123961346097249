import { connectSocket, WEBSOCKET_INVENTORY } from './index';

let inventorySocket;

let connectionSubscription = false;
let updateSubscription = false;
let disconnectSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    updateSubscription = false;
    disconnectSubscription = false;
};

export const connect = gameId => {
    inventorySocket = connectSocket(WEBSOCKET_INVENTORY, gameId);
};

export const onConnect = cb => {
    if (!inventorySocket || connectionSubscription) return true;

    inventorySocket.on('connect', () => {
        console.log('Inventory Socket Connected');
        cb();
    });
};

export const isConnected = () => {
    return inventorySocket?.connected || false;
};

export const onUpdate = cb => {
    if (!inventorySocket || updateSubscription) return true;

    inventorySocket.on('update', data => {
        cb(data);
    });

    updateSubscription = true;
};

export const update = (itemId, senderId, user) => {
    if (!inventorySocket) return true;

    inventorySocket.emit('update', {
        itemId,
        senderId,
        user,
    });
};

export const onDisconnect = () => {
    if (!inventorySocket || disconnectSubscription) return true;

    inventorySocket.on('disconnect', () => {
        console.log('Inventory Socket Disconnected');
        resetAllSubscriptions();
        inventorySocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!inventorySocket) return true;

    console.log('Inventory Socket Disconnected');
    resetAllSubscriptions();
    inventorySocket.removeAllListeners();
    inventorySocket.disconnect();
};

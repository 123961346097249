import api from 'services/apis';
import axios from 'services/axios';
import { puzzleStateSocket } from 'services/websockets';

import { ASSETS_BUCKET_URL } from 'constants.js';

import { setMissionControlDisplay, launchPuzzle } from 'store/puzzleReducer/actions';

import * as ACTIONS from './action_types';

export const setCurrentVideos = videos => {
    return (dispatch, getState) => {
        const {
            videosReducer: { currentVideos },
        } = getState();
        const manuallyPlayedVideos = currentVideos.filter(video => !video.id);
        const newVideos = [
            ...videos.map(videoData => ({
                isWatched: false,
                src:
                    typeof videoData === 'string'
                        ? ASSETS_BUCKET_URL + videoData
                        : ASSETS_BUCKET_URL + videoData.video.url,
                type: 'application/x-mpegURL',
                ...videoData?.video,
            })),
        ];
        const filteredVideos = newVideos.reduce(
            (set, toAdd) => (set.some(v => v.id === toAdd.id) ? set : [...set, toAdd]),
            []
        );

        dispatch({
            type: ACTIONS.SET_CURRENT_VIDEOS,
            payload: [...manuallyPlayedVideos, ...filteredVideos],
        });
    };
};

export const fetchCurrentVideos = () => {
    const url = api.game.VIDEOS;

    return (dispatch, getState) => {
        const {
            authReducer: {
                user: { isPlayer },
            },
        } = getState();
        if (!isPlayer) return;

        axios
            .get(url)
            .then(res => {
                const { videos } = res.data;
                dispatch(setCurrentVideos(videos));
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const fetchCurrent360Videos = () => {
    const url = api.game.VIDEOS360;

    return (dispatch, getState) => {
        const {
            authReducer: {
                user: { isPlayer },
            },
        } = getState();
        if (!isPlayer) return;

        axios
            .get(url)
            .then(res => {
                const { videos } = res.data;
                dispatch({
                    type: ACTIONS.SET_CURRENT_360_VIDEOS,
                    payload: videos,
                });
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const markVideoWatched = (watchedVideo, removeWatched = false) => {
    const url = api.game.WATCH;

    return async (dispatch, getState) => {
        const {
            authReducer: { gameId },
            videosReducer: { currentVideos },
            puzzleReducer: { currentPuzzle },
        } = getState();

        let newVideoList = [...currentVideos];
        const indexOfWatchedVideo = newVideoList.findIndex(
            video => !video.isWatched && video.src === watchedVideo.src
        );

        if (indexOfWatchedVideo !== -1) {
            newVideoList[indexOfWatchedVideo].isWatched = true;
        }

        if (removeWatched) {
            newVideoList = newVideoList.filter(video => !video.isWatched);
        }

        dispatch({
            type: ACTIONS.SET_CURRENT_VIDEOS,
            payload: newVideoList,
        });

        if (!watchedVideo.id) return;
        const videoId = watchedVideo.id;

        if (videoId === 72) {
            // launch self-destruct override puzzle after
            // self-destruct video finishes
            dispatch(launchPuzzle('self-destruct-override', 'Command Deck'));
        } else if (videoId === 71) {
            // launch solar-pedestal puzzle after
            // solar cells rc command video finishes
            dispatch(launchPuzzle('solar-pedestal', 'Command Deck'));
        } else if (videoId === 79) {
            // show mission accomplished screen after victory video
            dispatch(setMissionControlDisplay('win'));
        } else if (videoId === 80) {
            // show mission failed screen after loss video
            dispatch(setMissionControlDisplay('loss'));
        } else if (videoId === 20) {
            // update cloud-chamber state if puzzle socket is connected
            if (currentPuzzle?.key === 'cloud-chamber') {
                puzzleStateSocket.update({ doorOpen: true });
            }
        }

        try {
            await axios.post(`${url}/${videoId}`, { gameId });
        } catch (error) {
            console.log(error.message);
        }
    };
};

export const clearWatchedVideos = () => {
    return (dispatch, getState) => {
        const {
            videosReducer: { currentVideos },
        } = getState();

        dispatch({
            type: ACTIONS.SET_CURRENT_VIDEOS,
            payload: currentVideos.filter(video => !video.isWatched),
        });
    };
};

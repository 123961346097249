/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { playerLogin } from 'store/authReducer/actions';
import { setToastNotification } from 'store/gameReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import Footer from 'components/Footer';

import SpaceRaceLogo from 'assets/loginScreen/SpaceRaceLogo.png';
import LoginBackground from 'assets/loginScreen/Login_Background.png';
import EscapeGames from 'assets/loginScreen/TEGlogo.png';

import './styles.scss';
import 'react-toastify/dist/ReactToastify.min.css';

const Login = ({ onLoginSubmit, authenticatedUser, notification, resetNotification }) => {
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [checkboxEmail, setCheckboxEmail] = useState(true);
    const [checkboxPolicy, setCheckboxPolicy] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);

    const showToast = message => {
        toast.error(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        if (notification) {
            setIsProcessing(false);
            showToast(notification);

            resetNotification();
        }
    }, [notification, resetNotification]);

    const isInputValid = () => {
        const isValidEmail = new RegExp(
            /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g
        ).test(email);
        if (!isValidEmail) {
            showToast('Invalid Email Address.');
            return false;
        }

        const maxCharLimit = 12;
        if (name.length > maxCharLimit) {
            showToast(`Name is too long. Maximum of ${maxCharLimit} characters allowed.`);
            return false;
        }

        if (!checkboxPolicy) {
            const policyToast = () => (
                <span>
                    You must agree to our{' '}
                    <a
                        href="https://theescapegame.com/privacy-policy/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        privacy policy
                    </a>{' '}
                    to play
                </span>
            );
            showToast(policyToast);
            return false;
        }

        return true;
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (isInputValid()) {
            setIsProcessing(true);

            const nameArray = name.trim().split(' ');
            const firstName = nameArray[0];
            const lastName = nameArray[1];

            try {
                await onLoginSubmit({
                    firstName,
                    lastName,
                    password,
                    email,
                    checkboxEmail,
                });
            } catch (error) {
                setIsProcessing(false);
            }
        }
    };

    if (authenticatedUser.id !== undefined) {
        Mixpanel.identify(authenticatedUser.id);
        Mixpanel.people.set({
            $first_name: authenticatedUser.firstName,
            $last_name: authenticatedUser.lastName,
            $email: authenticatedUser.email,
            $game_id: authenticatedUser.gameId,
            $x_event_id: authenticatedUser.gameId,
        });

        Mixpanel.track('Successful login', { gameId: authenticatedUser.gameId });
        return <Redirect to="/" />;
    }

    return (
        <div className="login-wrapper wrapper">
            <div className="background">
                <div className="login-background">
                    <img
                        src={LoginBackground}
                        alt="login_background"
                        className="hidden background-image"
                    />
                    <span className="login-heading">USER LOGIN</span>
                    <div className="mission-mars-logo-login">
                        <img src={SpaceRaceLogo} alt="logo" />
                    </div>
                    <div className="escape-games-logo-login">
                        <img src={EscapeGames} alt="logo" />
                    </div>
                    <div className="login-container">
                        <form className="login-wrapper" onSubmit={handleSubmit}>
                            <div className="login-area">
                                <input
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    placeholder="Game Password"
                                    required
                                />
                            </div>
                            <div className="login-area">
                                <input
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    placeholder="Player Name"
                                    required
                                />
                            </div>
                            <div className="login-area">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder="Player Email"
                                    required
                                />
                            </div>
                            <div className="terms-container">
                                <label className="checkbox-text" htmlFor="email-checkbox">
                                    <input
                                        className="checkboxes"
                                        type="checkbox"
                                        id="email-checkbox"
                                        defaultChecked={checkboxEmail}
                                        onClick={() => setCheckboxEmail(!checkboxEmail)}
                                    />
                                    <span>
                                        Sign me up to receive email updates from The
                                        Escape Game
                                    </span>
                                </label>
                                <label
                                    className="checkbox-text"
                                    htmlFor="policy-checkbox"
                                >
                                    <input
                                        className="checkboxes"
                                        type="checkbox"
                                        id="policy-checkbox"
                                        defaultChecked={checkboxPolicy}
                                        onClick={() => setCheckboxPolicy(!checkboxPolicy)}
                                    />
                                    <span>
                                        I agree to The Escape Game&apos;s{' '}
                                        <a
                                            href="https://theescapegame.com/privacy-policy/"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            privacy policy
                                        </a>
                                    </span>
                                </label>
                            </div>
                            <input
                                type="submit"
                                className="submit"
                                onClick={handleSubmit}
                                disabled={isProcessing}
                                value="SUBMIT"
                            />
                        </form>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

const mapStateToProps = ({ authReducer, gameReducer }) => ({
    authenticatedUser: authReducer.user,
    notification: gameReducer.notification,
});

const mapDispatchToProps = {
    onLoginSubmit: loginData => playerLogin(loginData),
    resetNotification: () => setToastNotification(''),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import PHASER from 'phaser';

import store from 'store';
import { setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzleStateSocket } from 'services/websockets/index';

import CONSTANTS from '../../../constants';

export default class CloudChamber extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.CLOUD_CHAMBER,
        });
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
        this.doorOpen = false;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
    }

    preload() {
        this.load.image(
            'cc_background',
            '/assets/puzzles/cloud-chamber/cloud-chamber-bg.png'
        );
        this.load.image(
            'mc_open',
            '/assets/puzzles/cloud-chamber/mirror-chamber-open.png'
        );
        this.load.image(
            'mc_closed',
            '/assets/puzzles/cloud-chamber/mirror-chamber-closed.png'
        );
        this.load.image('wheel', '/assets/puzzles/cloud-chamber/wheel.png');
    }

    create() {
        this.joinGame();

        if (this.puzzleData.state?.doorOpen) {
            this.doorOpen = true;
        }

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.cc_bg = this.add
            .image(this.center.x, this.center.y, 'cc_background')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.mc_open = this.add
            .image(
                this.center.x + this.gameSize.x * 0.02,
                this.center.y - this.gameSize.y * 0.15,
                'mc_open'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(this.doorOpen)
            .on('pointerdown', () => {
                const puzzleData = getPuzzleByKey('cloud-chamber', this.puzzleData.room);
                this.scene.start(CONSTANTS.SCENES.AIRLOCK.MIRROR_CHAMBER, {
                    ...this.puzzleData,
                    ...puzzleData,
                });
            });

        this.mc_closed = this.add
            .image(
                this.center.x + this.gameSize.x * 0.025,
                this.center.y - this.gameSize.y * 0.135,
                'mc_closed'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(!this.doorOpen);

        this.wheel = this.add
            .image(
                this.center.x + this.gameSize.x * 0.171,
                this.center.y + this.gameSize.y * 0.35,
                'wheel'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.scene.start(
                    CONSTANTS.SCENES.AIRLOCK.ROTATING_WHEEL,
                    this.puzzleData
                );
            });

        if (!this.doorOpen) {
            puzzleStateSocket.onStateUpdate(data => {
                const {
                    states: { state },
                } = data;
                this.setDoorStatus(state);
            });
        }

        store.dispatch(setBackButtonCb());
    }

    joinGame() {
        // sending state for the first time
        const newState = {
            color: 'Green',
        };

        const {
            authReducer: { user },
        } = store.getState();
        puzzleStateSocket.joinGame(user.id, user, newState);
    }

    setDoorStatus(state) {
        this.doorOpen = state.doorOpen || false;
        this.mc_closed.setVisible(!this.doorOpen);
        this.mc_open.setVisible(this.doorOpen);

        if (this.doorOpen) {
            puzzleStateSocket.offStateUpdate();
        }
    }
}

import api from 'services/apis';
import axios from 'services/axios';
import { puzzlesSocket } from 'services/websockets/index';

import { setTimeInReducer } from 'store/timerReducer/actions';

import * as ACTIONS from './action_types';

export const setGameStarted = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_GAME_STARTED,
            payload: true,
        });
    };
};

export const getGameInfo = gameId => {
    const url = api.game.GET;

    return dispatch => {
        axios
            .get(`${url}fetch/${gameId}`)
            .then(res => {
                dispatch(setTimeInReducer(res.data.game.timer, res.data.game.state));

                if (res.data.game.state !== 'waiting') {
                    dispatch(setGameStarted());
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const startGame = () => {
    const url = api.game.START;

    return (dispatch, getState) => {
        const { user, gameId } = getState().authReducer;
        axios
            .post(`${url}/${gameId}`)
            .then(() => {
                puzzlesSocket.startGame(user.id, user);
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const setToastNotification = message => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_TOAST_NOTIFICATION,
            payload: message,
        });
    };
};

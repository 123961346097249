const PLAYERS_SERVICE = '/players';

const CURRENT = `${PLAYERS_SERVICE}/current`;
const SESSIONS = `${PLAYERS_SERVICE}/sessions`;
const STATUS = `${PLAYERS_SERVICE}/status`;
const PUZZLE = `${PLAYERS_SERVICE}/puzzle`;

const players = {
    CURRENT,
    SESSIONS,
    STATUS,
    PUZZLE,
};

export default players;

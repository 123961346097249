import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { markPuzzleSolved } from 'store/puzzleReducer/actions';
import { removeItem } from 'store/inventoryReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class CoolingTubes extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.COOLING_TUBES,
        });

        this.isSolved = false;
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
        this.tube1State = true;
        this.tube2State = false;
        this.tube3State = false;
        this.tube4State = false;
        this.tube5State = false;
        this.tube6State = false;
        this.tube7State = false;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
    }

    preload() {
        this.load.image('submit_ct', '/assets/puzzles/cooling-tubes/submit.png');
        this.load.image('dark_background', '/assets/puzzles/cooling-tubes/dark-bg.png');
        this.load.image('white_tube', '/assets/puzzles/cooling-tubes/tube-white.png');
        this.load.image('blue_tube', '/assets/puzzles/cooling-tubes/tube-blue.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.dark_background = this.add
            .image(this.center.x, this.center.y, 'dark_background')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.submit = this.add
            .image(this.center.x, this.center.y + this.gameSize.y * 0.22, 'submit_ct')
            .setInteractive()
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .on('pointerdown', () => this.submitPressed());

        this.submit.on('pointerout', function () {
            this.clearTint();
        });
        this.submit.on('pointerup', function () {
            this.clearTint();
        });
        this.submit.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.createTubes();

        this.incorrectImage = this.add
            .image(this.center.x, this.center.y, 'incorrect')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.incorrectText = this.add
            .text(this.center.x, this.center.y, 'Access Denied', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setVisible(false)
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        if (this.puzzleData.status === 'completed') {
            this.setSolvedState();
        }
    }

    createTubes() {
        // tube 1
        this.whiteTube1 = this.add
            .image(this.center.x - this.gameSize.x * 0.22, this.center.y, 'white_tube')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(1);
            });

        this.blueTube1 = this.add
            .image(this.center.x - this.gameSize.x * 0.22, this.center.y, 'blue_tube')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(1);
            });

        // tube 2
        this.whiteTube2 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.071,
                this.center.y,
                'white_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(2);
            });

        this.blueTube2 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.071,
                this.center.y,
                'blue_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.tubeClicked(2);
            });

        // tube 3
        this.whiteTube3 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.142,
                this.center.y,
                'white_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(3);
            });

        this.blueTube3 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.142,
                this.center.y,
                'blue_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.tubeClicked(3);
            });

        // tube 4
        this.whiteTube4 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.213,
                this.center.y,
                'white_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(4);
            });

        this.blueTube4 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.213,
                this.center.y,
                'blue_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.tubeClicked(4);
            });

        // tube 5
        this.whiteTube5 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.284,
                this.center.y,
                'white_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(5);
            });

        this.blueTube5 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.284,
                this.center.y,
                'blue_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.tubeClicked(5);
            });

        // tube 6
        this.whiteTube6 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.355,
                this.center.y,
                'white_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(6);
            });

        this.blueTube6 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.355,
                this.center.y,
                'blue_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.tubeClicked(6);
            });

        // tube 7
        this.whiteTube7 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.426,
                this.center.y,
                'white_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.tubeClicked(7);
            });

        this.blueTube7 = this.add
            .image(
                this.center.x - this.gameSize.x * 0.22 + this.gameSize.x * 0.426,
                this.center.y,
                'blue_tube'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.tubeClicked(7);
            });
    }

    tubeClicked(tubeNum) {
        // false state is for white tube while true state is for blue tube
        if (this.isSolved) return;

        this.sound.play('tube_clicked');
        switch (tubeNum) {
            case 1:
                this.tube1State = !this.tube1State;
                if (this.tube1State) {
                    this.whiteTube1.setVisible(false);
                    this.blueTube1.setVisible(true);
                } else {
                    this.whiteTube1.setVisible(true);
                    this.blueTube1.setVisible(false);
                }
                break;
            case 2:
                this.tube2State = !this.tube2State;
                if (this.tube2State) {
                    this.whiteTube2.setVisible(false);
                    this.blueTube2.setVisible(true);
                } else {
                    this.whiteTube2.setVisible(true);
                    this.blueTube2.setVisible(false);
                }
                break;
            case 3:
                this.tube3State = !this.tube3State;
                if (this.tube3State) {
                    this.whiteTube3.setVisible(false);
                    this.blueTube3.setVisible(true);
                } else {
                    this.whiteTube3.setVisible(true);
                    this.blueTube3.setVisible(false);
                }
                break;
            case 4:
                this.tube4State = !this.tube4State;
                if (this.tube4State) {
                    this.whiteTube4.setVisible(false);
                    this.blueTube4.setVisible(true);
                } else {
                    this.whiteTube4.setVisible(true);
                    this.blueTube4.setVisible(false);
                }
                break;
            case 5:
                this.tube5State = !this.tube5State;
                if (this.tube5State) {
                    this.whiteTube5.setVisible(false);
                    this.blueTube5.setVisible(true);
                } else {
                    this.whiteTube5.setVisible(true);
                    this.blueTube5.setVisible(false);
                }
                break;
            case 6:
                this.tube6State = !this.tube6State;
                if (this.tube6State) {
                    this.whiteTube6.setVisible(false);
                    this.blueTube6.setVisible(true);
                } else {
                    this.whiteTube6.setVisible(true);
                    this.blueTube6.setVisible(false);
                }
                break;
            case 7:
                this.tube7State = !this.tube7State;
                if (this.tube7State) {
                    this.whiteTube7.setVisible(false);
                    this.blueTube7.setVisible(true);
                } else {
                    this.whiteTube7.setVisible(true);
                    this.blueTube7.setVisible(false);
                }
                break;
            default:
                break;
        }
    }

    submitPressed() {
        if (this.isSolved) return;

        if (
            !this.tube1State &&
            this.tube2State &&
            !this.tube3State &&
            !this.tube4State &&
            this.tube5State &&
            !this.tube6State &&
            this.tube7State
        ) {
            this.isSolved = true;
            this.sound.play('success');
            this.submit.setVisible(false);

            store.dispatch(markPuzzleSolved(this.puzzleData.id));
            store.dispatch(removeItem(ITEM_ID_MAP.COOLING_TUBES));

            Mixpanel.track('Puzzle Complete', {
                ...this.puzzleData,
                puzzle: 'COOLING TUBES',
            });
        } else {
            this.sound.play('failure');
            this.incorrectImage.setVisible(true);
            this.incorrectText.setVisible(true);

            this.disableTubes();

            this.timedEvent = this.time.addEvent({
                delay: 3000,
                callback: this.hideIncorrectAnswerImage,
                callbackScope: this,
                repeat: 0,
            });
        }
    }

    hideIncorrectAnswerImage() {
        this.incorrectImage.setVisible(false);
        this.incorrectText.setVisible(false);

        this.enableTubes();
    }

    disableTubes() {
        this.whiteTube1.disableInteractive();
        this.blueTube1.disableInteractive();
        this.whiteTube2.disableInteractive();
        this.blueTube2.disableInteractive();
        this.whiteTube3.disableInteractive();
        this.blueTube3.disableInteractive();
        this.whiteTube4.disableInteractive();
        this.blueTube4.disableInteractive();
        this.whiteTube5.disableInteractive();
        this.blueTube5.disableInteractive();
        this.whiteTube6.disableInteractive();
        this.blueTube6.disableInteractive();
        this.whiteTube7.disableInteractive();
        this.blueTube7.disableInteractive();
        this.submit.disableInteractive();
    }

    enableTubes() {
        this.whiteTube1.setInteractive();
        this.blueTube1.setInteractive();
        this.whiteTube2.setInteractive();
        this.blueTube2.setInteractive();
        this.whiteTube3.setInteractive();
        this.blueTube3.setInteractive();
        this.whiteTube4.setInteractive();
        this.blueTube4.setInteractive();
        this.whiteTube5.setInteractive();
        this.blueTube5.setInteractive();
        this.whiteTube6.setInteractive();
        this.blueTube6.setInteractive();
        this.whiteTube7.setInteractive();
        this.blueTube7.setInteractive();
        this.submit.setInteractive();
    }

    setSolvedState() {
        this.isSolved = true;
        this.submit.setVisible(false);

        this.whiteTube1.setVisible(true);
        this.blueTube1.setVisible(false);

        this.whiteTube2.setVisible(false);
        this.blueTube2.setVisible(true);

        this.whiteTube5.setVisible(false);
        this.blueTube5.setVisible(true);

        this.whiteTube7.setVisible(false);
        this.blueTube7.setVisible(true);
    }
}

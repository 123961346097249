export let IP;

const environment = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;

if (window.IP === undefined) {
    switch (environment) {
        case 'production':
            IP = 'https://spacerace-api.theescapegame.com'; // "http://3.22.39.227:8000";
            break;
        case 'staging':
            IP = 'http://18.190.87.108:8000';
            break;
        case 'development':
            IP = 'http://18.190.87.108:8000';
            break;
        case 'local':
            IP = 'http://localhost:8000';
            break;
        default:
            IP = 'http://18.190.87.108:8000';
    }
} else {
    IP = window.IP;
}

export const URL = `${IP}/api/v1`;

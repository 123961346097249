import PHASER from 'phaser';

import store from 'store';
import { setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import CONSTANTS from '../../../constants';

export default class Lockers extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.LOCKERS,
        });
        this.is_solved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('lockers_bg', '/assets/puzzles/lockers/lockers-main-bg.png');

        // left and righ lockers
        this.load.image(
            'locker_left_door_closed',
            '/assets/puzzles/lockers/locker-left-door-closed.png'
        );
        this.load.image(
            'locker_left_door_open',
            '/assets/puzzles/lockers/locker-left-door-open.png'
        );
        this.load.image(
            'locker_right_door_closed',
            '/assets/puzzles/lockers/locker-right-door-closed.png'
        );
        this.load.image(
            'locker_right_door_open',
            '/assets/puzzles/lockers/locker-right-door-open.png'
        );

        this.load.image(
            'locker_left_closed_view',
            '/assets/puzzles/lockers/locker-left-closed-view.png'
        );
        this.load.image(
            'locker_left_open_view',
            '/assets/puzzles/lockers/locker-left-open-view.png'
        );
        this.load.image(
            'locker_right_closed_view',
            '/assets/puzzles/lockers/locker-right-closed-view.png'
        );
        this.load.image(
            'locker_right_open_view',
            '/assets/puzzles/lockers/locker-right-open-view.png'
        );

        this.load.image(
            'locker_lc_access',
            '/assets/puzzles/lockers/locker-lc-access.png'
        );
        this.load.image(
            'locker_rc_access',
            '/assets/puzzles/lockers/locker-rc-access.png'
        );

        // center ones
        this.load.image(
            'locker_rc_door_closed',
            '/assets/puzzles/lockers/locker-rc-door-closed.png'
        );
        this.load.image(
            'locker_lc_door_closed',
            '/assets/puzzles/lockers/locker-lc-door-closed.png'
        );
        this.load.image(
            'locker_lc_closed_view',
            '/assets/puzzles/lockers/locker-lc-closed-view.png'
        );
        this.load.image(
            'locker_rc_closed_view',
            '/assets/puzzles/lockers/locker-rc-closed-view.png'
        );
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.lockers_bg = this.add
            .image(this.center.x, this.center.y, 'lockers_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createLeftLocker();
        this.createRightLocker();
        this.createCenterLockers();

        if (this.puzzle_data.comingFrom === 'left') {
            this.locker_left_open_view.setVisible(true);
            store.dispatch(
                setBackButtonCb(() => {
                    this.locker_left_open_view.setVisible(false);
                    store.dispatch(setBackButtonCb());
                })
            );
        } else if (this.puzzle_data.comingFrom === 'right') {
            this.locker_right_open_view.setVisible(true);
            store.dispatch(
                setBackButtonCb(() => {
                    this.locker_right_open_view.setVisible(false);
                    store.dispatch(setBackButtonCb());
                })
            );
        } else {
            store.dispatch(setBackButtonCb());
        }
    }

    createLeftLocker() {
        // left locker closed door
        this.locker_left_door_closed = this.add
            .image(
                this.center.x - this.game_size.x * 0.3,
                this.center.y + this.game_size.y * 0.1,
                'locker_left_door_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.locker_left_closed_view.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.locker_left_closed_view.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        // left locker closed view
        this.locker_left_closed_view = this.add
            .image(this.center.x, this.center.y, 'locker_left_closed_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setDepth(1)
            .setInteractive()
            .setVisible(false);

        // left locker open door
        this.locker_left_door_open = this.add
            .image(
                this.center.x - this.game_size.x * 0.3,
                this.center.y + this.game_size.y * 0.1,
                'locker_left_door_open'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(true)
            .on('pointerdown', () => {
                this.locker_left_open_view.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.locker_left_open_view.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        // left locker open view container
        this.locker_left_open_view = this.add
            .container(0, 0)
            .setDepth(1)
            .setVisible(false);

        this.locker_left_open_view_img = this.add
            .image(this.center.x, this.center.y, 'locker_left_open_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setOrigin(0.5)
            .setInteractive();

        this.locker_left_open_view_access = this.add
            .image(
                this.center.x + this.game_size.x * 0.21,
                this.center.y,
                'locker_lc_access'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setOrigin(0.5)
            .on('pointerdown', () => {
                this.leftCenterLockerOpened();
            });

        this.locker_left_open_view.add([
            this.locker_left_open_view_img,
            this.locker_left_open_view_access,
        ]);

        this.updateLeftLockerStatus();

        this.timedEvent = this.time.addEvent({
            delay: 500, // ms
            callback: this.updateStatuses,
            callbackScope: this,
            repeat: -1,
        });
    }

    createRightLocker() {
        // right locker door closed
        this.locker_right_door_closed = this.add
            .image(
                this.center.x + this.game_size.x * 0.3,
                this.center.y + this.game_size.y * 0.1,
                'locker_right_door_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.locker_right_closed_view.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.locker_right_closed_view.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        // right locker closed view
        this.locker_right_closed_view = this.add
            .image(this.center.x, this.center.y, 'locker_right_closed_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setDepth(1)
            .setInteractive()
            .setVisible(false);

        // right locker door open
        this.locker_right_door_open = this.add
            .image(
                this.center.x + this.game_size.x * 0.3,
                this.center.y + this.game_size.y * 0.1,
                'locker_right_door_open'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(true)
            .on('pointerdown', () => {
                this.locker_right_open_view.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.locker_right_open_view.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        // right locker open view container
        this.locker_right_open_view = this.add
            .container(0, 0)
            .setDepth(1)
            .setVisible(false);

        this.locker_right_open_view_img = this.add
            .image(this.center.x, this.center.y, 'locker_right_open_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setOrigin(0.5)
            .setInteractive();

        this.locker_right_open_view_access = this.add
            .image(
                this.center.x - this.game_size.x * 0.22,
                this.center.y,
                'locker_rc_access'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setOrigin(0.5)
            .on('pointerdown', () => {
                this.rightCenterLockerOpened();
            });

        this.locker_right_open_view.add([
            this.locker_right_open_view_img,
            this.locker_right_open_view_access,
        ]);

        this.updateRightLockerStatus();
    }

    createCenterLockers() {
        this.locker_lc_door_closed = this.add
            .image(
                this.center.x - this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.1,
                'locker_lc_door_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.disableInteraction();
                this.locker_lc_closed_view.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.enableInteraction();
                        this.locker_lc_closed_view.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        this.locker_lc_closed_view = this.add
            .image(this.center.x, this.center.y, 'locker_lc_closed_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setDepth(1)
            .setVisible(false);

        this.locker_rc_door_closed = this.add
            .image(
                this.center.x + this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.1,
                'locker_rc_door_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.disableInteraction();
                this.locker_rc_closed_view.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.enableInteraction();
                        this.locker_rc_closed_view.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        this.locker_rc_closed_view = this.add
            .image(this.center.x, this.center.y, 'locker_rc_closed_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setDepth(1)
            .setVisible(false);
    }

    disableInteraction() {
        this.locker_rc_door_closed.disableInteractive();
        this.locker_lc_door_closed.disableInteractive();
        this.locker_left_door_open.disableInteractive();
        this.locker_right_door_open.disableInteractive();
        this.locker_left_door_closed.disableInteractive();
        this.locker_right_door_closed.disableInteractive();
    }

    enableInteraction() {
        this.locker_rc_door_closed.setInteractive();
        this.locker_lc_door_closed.setInteractive();
        this.locker_left_door_open.setInteractive();
        this.locker_right_door_open.setInteractive();
        this.locker_left_door_closed.setInteractive();
        this.locker_right_door_closed.setInteractive();
    }

    resetLockerPuzzle() {
        this.is_solved = false;

        this.final_switch1_state = false;
        this.final_switch2_state = false;
        this.final_switch3_state = false;
    }

    leftCenterLockerOpened() {
        const state = getPuzzleByKey('left-locker', 'Airlock');
        this.scene.start(CONSTANTS.SCENES.AIRLOCK.LEFT_LOCKER, state);
    }

    rightCenterLockerOpened() {
        const state = getPuzzleByKey('right-locker', 'Airlock');
        this.scene.start(CONSTANTS.SCENES.AIRLOCK.RIGHT_LOCKER, state);
    }

    updateLeftLockerStatus() {
        const leftLockerState = getPuzzleByKey('left-locker', 'Airlock');

        if (leftLockerState) {
            if (leftLockerState.status === 'unlocked') {
                this.locker_left_door_closed.setVisible(false);
                this.locker_left_door_open.setVisible(true);
            } else {
                this.locker_left_door_closed.setVisible(true);
                this.locker_left_door_open.setVisible(false);
            }
        }
    }

    updateRightLockerStatus() {
        const rightLockerState = getPuzzleByKey('right-locker', 'Airlock');

        if (rightLockerState) {
            if (rightLockerState.status === 'unlocked') {
                this.locker_right_door_closed.setVisible(false);
                this.locker_right_door_open.setVisible(true);
            } else {
                this.locker_right_door_closed.setVisible(true);
                this.locker_right_door_open.setVisible(false);
            }
        }
    }

    updateStatuses() {
        this.updateLeftLockerStatus();
    }
}

export const sessionStorageWithExpiry = {
    setItem: (key, value) => {
        let expiry = new Date();
        expiry = expiry.setHours(expiry.getHours() + 6);
        sessionStorage.setItem(key, JSON.stringify({ value, expiry }));
    },
    getItem: key => {
        const data = sessionStorage.getItem(key);
        if (data) {
            const { value, expiry } = JSON.parse(data);
            if (expiry > new Date().getTime()) {
                return value;
            }
        }
        return null;
    },
};

import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import GenerateGameId from 'components/HostLogin/components/GenerateGameId';

import store from './store';

import Dashboard from './components/Dashboard';
import Login from './components/Login';
import MarsRoute from './components/MarsRoute';

import './App.scss';

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <MarsRoute exact path="/" component={Dashboard} />
                        <MarsRoute exact path="/host" component={Dashboard} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/host/generate" component={GenerateGameId} />
                    </Switch>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;

import store from 'store';
import api from 'services/apis';
import axios from 'services/axios';

export const getPuzzleByKey = (puzzleKey, roomName) => {
    const {
        puzzleReducer: { mapData },
    } = store.getState();
    return mapData ? mapData[roomName]?.puzzles[puzzleKey] : null;
};

export const fetchPuzzleById = puzzleId => {
    const url = api.puzzles.FETCH;
    return axios
        .get(`${url}/${puzzleId}`)
        .then(res => {
            const { puzzle } = res.data;
            return puzzle;
        })
        .catch(error => {
            console.log(error.message);
        });
};

const MESSAGE_SERVICE = '/messages';

const SEND = `${MESSAGE_SERVICE}/send`;
const RETRIEVE = `${MESSAGE_SERVICE}/`;

const chat = {
    SEND,
    RETRIEVE,
};

export default chat;

import { connectSocket, WEBSOCKET_VOTE } from './index';

let voteSocket;

let connectionSubscription = false;
let createVoteSubscription = false;
let disconnectSubscription = false;
let newVoteSubscription = false;
let voteTimerUpdateSubscription = false;
let voteEndSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    createVoteSubscription = false;
    disconnectSubscription = false;
    newVoteSubscription = false;
    voteTimerUpdateSubscription = false;
    voteEndSubscription = false;
};

export const connect = gameId => {
    voteSocket = connectSocket(WEBSOCKET_VOTE, gameId);
};

export const onConnect = cb => {
    if (!voteSocket || connectionSubscription) return true;

    voteSocket.on('connect', () => {
        console.log('Vote Socket Connected');
        cb();
    });
};

export const isConnected = () => {
    return voteSocket?.connected || false;
};

export const createVote = (statement, voteId, userId) => {
    if (!voteSocket) return true;

    voteSocket.emit('create_vote', {
        statement,
        voteId,
        userId,
    });
};

export const overrideVote = () => {
    if (!voteSocket) return true;

    voteSocket.emit('override_vote');
};

export const onCreateVote = cb => {
    if (!voteSocket || createVoteSubscription) return true;

    voteSocket.on('on_create_vote', data => {
        cb(data);
    });

    createVoteSubscription = true;
};

export const castVote = (selection, voteId, userId) => {
    if (!voteSocket) return true;

    voteSocket.emit('cast_vote', {
        selection,
        voteId,
        userId,
    });
};

export const onVoteTimerUpdate = cb => {
    if (!voteSocket || voteTimerUpdateSubscription) return;

    voteSocket.on('vote_timer_update', data => {
        cb(data);
    });

    voteTimerUpdateSubscription = true;
};

export const onVoteEnd = cb => {
    if (!voteSocket || voteEndSubscription) return;

    voteSocket.on('vote_end', data => {
        cb(data);
        voteSocket.off('vote_end');
        voteEndSubscription = false;
    });

    voteEndSubscription = true;
};

export const onNewVote = cb => {
    if (!voteSocket || newVoteSubscription) return true;

    voteSocket.on('on_new_vote', data => {
        cb(data);
    });

    newVoteSubscription = true;
};

export const onDisconnect = () => {
    if (!voteSocket || disconnectSubscription) return true;

    voteSocket.on('disconnect', () => {
        console.log('Vote Socket Disconnected');
        resetAllSubscriptions();
        voteSocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!voteSocket) return true;

    console.log('Vote Socket Disconnected');
    resetAllSubscriptions();
    voteSocket.removeAllListeners();
    voteSocket.disconnect();
};

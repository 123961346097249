import PHASER from 'phaser';

import { PUZZLE_VIDEOS_URL, ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { setBackButtonCb, setAsPlayingPuzzle } from 'store/puzzleReducer/actions';
import { unlockItem } from 'store/inventoryReducer/actions';
import { setCurrentVideos } from 'store/videosReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import CONSTANTS from '../../../constants';

export default class RoverPuzzle extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.ROVER_PUZZLE,
        });

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.mini_puzzle_state = {
            status: false,
        };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('rover_bg', '/assets/puzzles/rover-puzzle/rover-bg.png');

        this.load.image('aux_bg', '/assets/puzzles/rover-puzzle/aux-bg.png');
        this.load.image('aux_open', '/assets/puzzles/rover-puzzle/aux-open.png');
        this.load.image('aux_closed', '/assets/puzzles/rover-puzzle/aux-closed.png');

        this.load.image('ek_open', '/assets/puzzles/rover-puzzle/ek-open.png');
        this.load.image('ek_closed', '/assets/puzzles/rover-puzzle/ek-closed.png');
        this.load.image(
            'ek_closed_glow',
            '/assets/puzzles/rover-puzzle/ek-closed-glow.png'
        );

        this.load.image(
            'morse_code_lit',
            '/assets/puzzles/rover-puzzle/morse-code-lit.png'
        );
        this.load.image(
            'morse_code_unlit',
            '/assets/puzzles/rover-puzzle/morse-code-unlit.png'
        );

        this.load.image(
            'mini_puzzle_solved',
            '/assets/puzzles/rover-puzzle/mini-puzzle-solved.png'
        );
        this.load.image(
            'mini_puzzle_unsolved',
            '/assets/puzzles/rover-puzzle/mini-puzzle-unsolved.png'
        );

        this.load.image('door_closed', '/assets/puzzles/rover-puzzle/door-closed.png');
        this.load.image('door_open', '/assets/puzzles/rover-puzzle/door-open.png');
        this.load.image(
            'door_open_nonGlow',
            '/assets/puzzles/rover-puzzle/door-open-nonglow.png'
        );

        this.load.image('rover_inside', '/assets/puzzles/rover-puzzle/rover-inside.png');
        this.load.image(
            'interior_keypad',
            '/assets/puzzles/rover-puzzle/interior-keypad.png'
        );
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.rover_bg = this.add
            .image(this.center.x, this.center.y, 'rover_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.setupMorseCode();
        this.setupExteriorKeypad();
        this.setupAuxCable();
        this.setupDoor();
        this.setupMiniPuzzle();

        this.timedEvent = this.time.addEvent({
            delay: 500, // ms
            callback: this.updateStatuses,
            callbackScope: this,
            repeat: -1,
        });
        store.dispatch(setBackButtonCb());
    }

    setupMorseCode() {
        this.morse_code_unlit = this.add
            .image(
                this.center.x - this.game_size.x * 0.01,
                this.center.y - this.game_size.y * 0.418,
                'morse_code_unlit'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.morse_code_lit = this.add
            .image(
                this.center.x - this.game_size.x * 0.01,
                this.center.y - this.game_size.y * 0.418,
                'morse_code_lit'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                const morseCodeVideoUrl = `${PUZZLE_VIDEOS_URL}Video-14_hls/index.m3u8`;
                store.dispatch(setCurrentVideos([morseCodeVideoUrl]));
            });

        this.updateMorseCodeStatus();
    }

    setupExteriorKeypad() {
        this.ek_closed = this.add
            .image(
                this.center.x + this.game_size.x * 0.1,
                this.center.y - this.game_size.y * 0.06,
                'ek_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.ek_closed_glow = this.add
            .image(
                this.center.x + this.game_size.x * 0.1,
                this.center.y - this.game_size.y * 0.06,
                'ek_closed_glow'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                const state = getPuzzleByKey(
                    'rover-exterior-keypad',
                    this.puzzle_data.room
                );
                if (state.status === 'unlocked') {
                    store.dispatch(setAsPlayingPuzzle(state.id, state.name));
                    this.scene.start(CONSTANTS.SCENES.MARS.ROVER_EXTERIOR_KEYPAD, state);
                }
            });

        this.ek_open = this.add
            .image(
                this.center.x + this.game_size.x * 0.1,
                this.center.y - this.game_size.y * 0.06,
                'ek_open'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.updateExteriorKeypadStatus();
    }

    setupAuxCable() {
        const state = getPuzzleByKey('rover-exterior-keypad', this.puzzle_data.room);
        this.aux_bg = this.add
            .image(this.center.x, this.center.y, 'aux_bg')
            .setVisible(false)
            .setDepth(1)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.aux_closed = this.add
            .image(
                this.center.x + this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.178,
                'aux_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                if (state.status !== 'completed') {
                    this.aux_bg.setVisible(true);
                    store.dispatch(
                        setBackButtonCb(() => {
                            this.aux_bg.setVisible(false);
                            store.dispatch(setBackButtonCb());
                        })
                    );
                }
            });

        this.aux_open = this.add
            .image(
                this.center.x + this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.178,
                'aux_open'
            )
            .setVisible(true)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.updateAuxStatus();
    }

    setupDoor() {
        this.rover_inside = this.add.container(0, 0).setVisible(false).setDepth(1);

        this.rover_inside_bg = this.add
            .image(this.center.x, this.center.y, 'rover_inside')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.interior_keypad = this.add
            .image(
                this.center.x - this.game_size.x * 0.35,
                this.center.y + this.game_size.y * 0.22,
                'interior_keypad'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                const state = getPuzzleByKey(
                    'rover-interior-keypad',
                    this.puzzle_data.room
                );
                store.dispatch(setAsPlayingPuzzle(state.id, state.name));
                this.scene.start(CONSTANTS.SCENES.MARS.ROVER_INTERIOR_KEYPAD, state);
            });

        this.rover_inside.add([this.rover_inside_bg, this.interior_keypad]);

        this.door_closed = this.add
            .image(
                this.center.x - this.game_size.x * 0.007,
                this.center.y + this.game_size.y * 0.15,
                'door_closed'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.door_open = this.add
            .image(
                this.center.x - this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.15,
                'door_open'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                store.dispatch(unlockItem(ITEM_ID_MAP.ROVER_BACKGROUND));
                this.rover_inside.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.rover_inside.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        this.door_open_nonGlow = this.add
            .image(
                this.center.x - this.game_size.x * 0.1,
                this.center.y + this.game_size.y * 0.15,
                'door_open_nonGlow'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.rover_inside.setVisible(true);
                store.dispatch(
                    setBackButtonCb(() => {
                        this.rover_inside.setVisible(false);
                        store.dispatch(setBackButtonCb());
                    })
                );
            });

        this.updateDoorStatus();
    }

    setupMiniPuzzle() {
        this.mini_puzzle_solved = this.add
            .image(
                this.center.x - this.game_size.x * 0.007,
                this.center.y - this.game_size.y * 0.137,
                'mini_puzzle_solved'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.mini_puzzle_unsolved = this.add
            .image(
                this.center.x - this.game_size.x * 0.007,
                this.center.y - this.game_size.y * 0.137,
                'mini_puzzle_unsolved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                const state = getPuzzleByKey('rover-mini-puzzle', this.puzzle_data.room);
                this.scene.start(CONSTANTS.SCENES.MARS.ROVER_MINI_PUZZLE, state);
            });

        this.updateMiniPuzzleStatus();
    }

    updateMorseCodeStatus() {
        const state = getPuzzleByKey('rover-interior-keypad', this.puzzle_data.room);
        if (state.status === 'completed') {
            this.morse_code_lit.setVisible(true);
            this.morse_code_unlit.setVisible(false);
        } else {
            this.morse_code_lit.setVisible(false);
            this.morse_code_unlit.setVisible(true);
        }
    }

    updateExteriorKeypadStatus() {
        const state = getPuzzleByKey('rover-exterior-keypad', this.puzzle_data.room);
        if (state.status === 'completed') {
            this.ek_open.setVisible(true);
            this.ek_closed.setVisible(false);
            this.ek_closed_glow.setVisible(false);
        } else if (state.status === 'unlocked') {
            this.ek_closed_glow.setVisible(true);
            this.ek_open.setVisible(false);
            this.ek_closed.setVisible(false);
        } else {
            this.ek_closed.setVisible(true);
            this.ek_closed_glow.setVisible(false);
            this.ek_open.setVisible(false);
        }
    }

    updateAuxStatus() {
        const state = getPuzzleByKey('rover-exterior-keypad', this.puzzle_data.room);
        if (state.status === 'unlocked' || state.status === 'completed') {
            this.aux_closed.setVisible(false);
            this.aux_open.setVisible(true);
        } else {
            this.aux_closed.setVisible(true);
            this.aux_open.setVisible(false);
        }
    }

    updateDoorStatus() {
        let state = getPuzzleByKey('rover-exterior-keypad', this.puzzle_data.room);
        if (state.status === 'completed') {
            this.door_closed.setVisible(false);
            this.door_open.setVisible(true);
            this.door_open_nonGlow.setVisible(false);
        } else {
            this.door_closed.setVisible(true);
            this.door_open.setVisible(false);
            this.door_open_nonGlow.setVisible(false);
        }

        state = getPuzzleByKey('rover-interior-keypad', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.door_closed.setVisible(false);
            this.door_open.setVisible(false);
            this.door_open_nonGlow.setVisible(true);
        }
    }

    updateMiniPuzzleStatus() {
        const state = getPuzzleByKey('rover-mini-puzzle', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.mini_puzzle_solved.setVisible(true);
            this.mini_puzzle_unsolved.setVisible(false);
        } else {
            this.mini_puzzle_solved.setVisible(false);
            this.mini_puzzle_unsolved.setVisible(true);
        }
    }

    updateStatuses() {
        this.updateMorseCodeStatus();
        this.updateExteriorKeypadStatus();
        this.updateAuxStatus();
        this.updateDoorStatus();
        this.updateMiniPuzzleStatus();
    }
}

export const isArrayNotEqual = (arrayA, arrayB) => {
    if (!arrayA || !arrayB) return false;

    const setB = new Set(arrayB);
    const differenceB = [...arrayA].filter(x => !setB.has(x));
    if (differenceB.length) return true;

    const setA = new Set(arrayA);
    const differenceA = [...arrayB].filter(x => !setA.has(x));
    if (differenceA.length) return true;

    return false;
};

import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { unlockItem } from 'store/inventoryReducer/actions';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { Mixpanel } from 'services/mixpanel';
import { puzzlesSocket } from 'services/websockets/index';

import CONSTANTS from '../../../constants';

export default class RoverMiniPuzzle extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.ROVER_MINI_PUZZLE,
        });

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.is_solved = false;

        this.piece_2_1_fixed = false;
        this.piece_2_2_fixed = false;
        this.piece_2_3_fixed = false;

        this.piece_0_up_1_fixed = false;
        this.piece_0_up_2_fixed = false;
        this.piece_0_up_3_fixed = false;

        this.piece_0_down_1_fixed = false;
        this.piece_0_down_2_fixed = false;
        this.piece_0_down_3_fixed = false;

        this.piece_1_1_fixed = false;
        this.piece_1_2_fixed = false;
        this.piece_1_3_fixed = false;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('puzzle_bg', '/assets/puzzles/rover-mini-puzzle/puzzle-bg.png');
        this.load.image(
            'puzzle_frame',
            '/assets/puzzles/rover-mini-puzzle/puzzle-frame.png'
        );

        this.load.image('piece_2_1', '/assets/puzzles/rover-mini-puzzle/piece-2-1.png');
        this.load.image('piece_2_2', '/assets/puzzles/rover-mini-puzzle/piece-2-2.png');
        this.load.image('piece_2_3', '/assets/puzzles/rover-mini-puzzle/piece-2-3.png');

        this.load.image(
            'piece_0_up_1',
            '/assets/puzzles/rover-mini-puzzle/piece-0-up-1.png'
        );
        this.load.image(
            'piece_0_up_2',
            '/assets/puzzles/rover-mini-puzzle/piece-0-up-2.png'
        );
        this.load.image(
            'piece_0_up_3',
            '/assets/puzzles/rover-mini-puzzle/piece-0-up-3.png'
        );

        this.load.image(
            'piece_0_down_1',
            '/assets/puzzles/rover-mini-puzzle/piece-0-down-1.png'
        );
        this.load.image(
            'piece_0_down_2',
            '/assets/puzzles/rover-mini-puzzle/piece-0-down-2.png'
        );
        this.load.image(
            'piece_0_down_3',
            '/assets/puzzles/rover-mini-puzzle/piece-0-down-3.png'
        );

        this.load.image('piece_1_1', '/assets/puzzles/rover-mini-puzzle/piece-1-1.png');
        this.load.image('piece_1_2', '/assets/puzzles/rover-mini-puzzle/piece-1-2.png');
        this.load.image('piece_1_3', '/assets/puzzles/rover-mini-puzzle/piece-1-3.png');

        this.load.image('anchor', '/assets/puzzles/rover-mini-puzzle/anchor.png');
        this.load.image('mid', '/assets/puzzles/rover-mini-puzzle/mid.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.mini_puzzle_bg = this.add
            .image(this.center.x, this.center.y, 'puzzle_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.mini_puzzle_frame = this.add
            .image(
                this.center.x - this.game_size.x * 0.2,
                this.center.y - this.game_size.y * 0.015,
                'puzzle_frame'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.createDraggableSpots();

        this.createTwoDigitPieces();
        this.createZeroUpDigitPieces();
        this.createZeroDownDigitPieces();
        this.createOneDigitPieces();
        this.scaledown();

        store.dispatch(
            setBackButtonCb(() => {
                const state = getPuzzleByKey('rover-puzzle', this.puzzle_data.room);
                this.scene.start(CONSTANTS.SCENES.MARS.ROVER_PUZZLE, state);
            })
        );
    }

    createTwoDigitPieces() {
        // piece 2-1
        this.piece_2_1_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_2_1 = this.add
            .image(0, 0, 'piece_2_1')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_2_1_container = this.add.container(
            this.center.x + this.game_size.x * 0.17,
            this.center.y + this.game_size.y * 0.15
        );

        this.piece_2_1_container.add([this.piece_2_1, this.piece_2_1_mid]);
        this.piece_2_1_container.setSize(this.piece_2_1.width, this.piece_2_1.height);
        this.piece_2_1_container.setInteractive();

        this.piece_2_1_initial_pos = {
            x: this.center.x + this.game_size.x * 0.17,
            y: this.center.y + this.game_size.y * 0.15,
        };

        // piece 2-2
        this.piece_2_2_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_2_2 = this.add
            .image(0, 0, 'piece_2_2')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_2_2_container = this.add.container(
            this.center.x + this.game_size.x * 0.18,
            this.center.y - this.game_size.y * 0.32
        );

        this.piece_2_2_container.add([this.piece_2_2, this.piece_2_2_mid]);
        this.piece_2_2_container.setSize(this.piece_2_2.width, this.piece_2_2.height);
        this.piece_2_2_container.setInteractive();

        this.piece_2_2_initial_pos = {
            x: this.center.x + this.game_size.x * 0.18,
            y: this.center.y - this.game_size.y * 0.32,
        };

        // piece 2-3
        this.piece_2_3_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_2_3 = this.add
            .image(0, 0, 'piece_2_3')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_2_3_container = this.add.container(
            this.center.x + this.game_size.x * 0.35,
            this.center.y
        );

        this.piece_2_3_container.add([this.piece_2_3, this.piece_2_3_mid]);
        this.piece_2_3_container.setSize(this.piece_2_3.width, this.piece_2_3.height);
        this.piece_2_3_container.setInteractive();

        this.piece_2_3_initial_pos = {
            x: this.center.x + this.game_size.x * 0.35,
            y: this.center.y,
        };

        this.input.setDraggable([
            this.piece_2_1_container,
            this.piece_2_2_container,
            this.piece_2_3_container,
        ]);

        // draggable part
        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.piece_2_1_container.on('dragstart', () => {
            this.piece_2_1_container.setDepth(1);
            this.piece_2_1.setScale(
                this.piece_2_1_dest.scaleX,
                this.piece_2_1_dest.scaleY
            );
        });
        this.piece_2_1_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_2_1_mid, this.piece_2_1_dest)) {
                this.piece_2_1_fixed = true;
                this.piece_2_1_container.setPosition(
                    this.piece_2_1_dest.x,
                    this.piece_2_1_dest.y
                );
            } else {
                this.piece_2_1_fixed = false;
                this.piece_2_1_container.setPosition(
                    this.piece_2_1_initial_pos.x,
                    this.piece_2_1_initial_pos.y
                );
                this.piece_2_1.setScale(0.8, 0.8);
            }
            this.piece_2_1_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_2_2_container.on('dragstart', () => {
            this.piece_2_2_container.setDepth(1);
            this.piece_2_2.setScale(
                this.piece_2_2_dest.scaleX,
                this.piece_2_2_dest.scaleY
            );
        });
        this.piece_2_2_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_2_2_mid, this.piece_2_2_dest)) {
                this.piece_2_2_fixed = true;
                this.piece_2_2_container.setPosition(
                    this.piece_2_2_dest.x,
                    this.piece_2_2_dest.y
                );
            } else {
                this.piece_2_2_fixed = false;
                this.piece_2_2_container.setPosition(
                    this.piece_2_2_initial_pos.x,
                    this.piece_2_2_initial_pos.y
                );
                this.piece_2_2.setScale(0.8, 0.8);
            }
            this.piece_2_2_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_2_3_container.on('dragstart', () => {
            this.piece_2_3_container.setDepth(1);
            this.piece_2_3.setScale(
                this.piece_2_3_dest.scaleX,
                this.piece_2_3_dest.scaleY
            );
        });
        this.piece_2_3_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_2_3_mid, this.piece_2_3_dest)) {
                this.piece_2_3_fixed = true;
                this.piece_2_3_container.setPosition(
                    this.piece_2_3_dest.x,
                    this.piece_2_3_dest.y
                );
            } else {
                this.piece_2_3_fixed = false;
                this.piece_2_3_container.setPosition(
                    this.piece_2_3_initial_pos.x,
                    this.piece_2_3_initial_pos.y
                );
                this.piece_2_3.setScale(0.8, 0.8);
            }
            this.piece_2_3_container.setDepth(0);
            this.verifyPuzzleComplete();
        });
    }

    createZeroUpDigitPieces() {
        // piece 0-up-1
        this.piece_0_up_1_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_0_up_1 = this.add
            .image(0, 0, 'piece_0_up_1')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_0_up_1_container = this.add.container(
            this.center.x + this.game_size.x * 0.34,
            this.center.y - this.game_size.y * 0.3
        );

        this.piece_0_up_1_container.add([this.piece_0_up_1, this.piece_0_up_1_mid]);
        this.piece_0_up_1_container.setSize(
            this.piece_0_up_1.width,
            this.piece_0_up_1.height
        );
        this.piece_0_up_1_container.setInteractive();

        this.piece_0_up_1_initial_pos = {
            x: this.center.x + this.game_size.x * 0.34,
            y: this.center.y - this.game_size.y * 0.3,
        };

        // piece 0-up-2
        this.piece_0_up_2_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_0_up_2 = this.add
            .image(0, 0, 'piece_0_up_2')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_0_up_2_container = this.add.container(
            this.center.x + this.game_size.x * 0.36,
            this.center.y - this.game_size.y * 0.17
        );

        this.piece_0_up_2_container.add([this.piece_0_up_2, this.piece_0_up_2_mid]);
        this.piece_0_up_2_container.setSize(
            this.piece_0_up_2.width,
            this.piece_0_up_2.height
        );
        this.piece_0_up_2_container.setInteractive();

        this.piece_0_up_2_initial_pos = {
            x: this.center.x + this.game_size.x * 0.36,
            y: this.center.y - this.game_size.y * 0.17,
        };

        // piece 0-up-3\
        this.piece_0_up_3_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_0_up_3 = this.add
            .image(0, 0, 'piece_0_up_3')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_0_up_3_container = this.add.container(
            this.center.x + this.game_size.x * 0.24,
            this.center.y + this.game_size.y * 0.25
        );

        this.piece_0_up_3_container.add([this.piece_0_up_3, this.piece_0_up_3_mid]);
        this.piece_0_up_3_container.setSize(
            this.piece_0_up_3.width,
            this.piece_0_up_3.height
        );
        this.piece_0_up_3_container.setInteractive();

        this.piece_0_up_3_initial_pos = {
            x: this.center.x + this.game_size.x * 0.24,
            y: this.center.y + this.game_size.y * 0.25,
        };

        this.input.setDraggable([
            this.piece_0_up_1_container,
            this.piece_0_up_2_container,
            this.piece_0_up_3_container,
        ]);

        // draggable part
        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.piece_0_up_1_container.on('dragstart', () => {
            this.piece_0_up_1_container.setDepth(1);
            this.piece_0_up_1.setScale(
                this.piece_0_up_1_dest.scaleX,
                this.piece_0_up_1_dest.scaleY
            );
        });
        this.piece_0_up_1_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_0_up_1, this.piece_0_up_1_dest)) {
                this.piece_0_up_1_fixed = true;
                this.piece_0_up_1_container.setPosition(
                    this.piece_0_up_1_dest.x,
                    this.piece_0_up_1_dest.y
                );
            } else {
                this.piece_0_up_1_fixed = false;
                this.piece_0_up_1_container.setPosition(
                    this.piece_0_up_1_initial_pos.x,
                    this.piece_0_up_1_initial_pos.y
                );
                this.piece_0_up_1.setScale(0.8, 0.8);
            }
            this.piece_0_up_1_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_0_up_2_container.on('dragstart', () => {
            this.piece_0_up_2_container.setDepth(1);
            this.piece_0_up_2.setScale(
                this.piece_0_up_2_dest.scaleX,
                this.piece_0_up_2_dest.scaleY
            );
        });
        this.piece_0_up_2_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_0_up_2, this.piece_0_up_2_dest)) {
                this.piece_0_up_2_fixed = true;
                this.piece_0_up_2_container.setPosition(
                    this.piece_0_up_2_dest.x,
                    this.piece_0_up_2_dest.y
                );
            } else {
                this.piece_0_up_2_fixed = false;
                this.piece_0_up_2_container.setPosition(
                    this.piece_0_up_2_initial_pos.x,
                    this.piece_0_up_2_initial_pos.y
                );
                this.piece_0_up_2.setScale(0.8, 0.8);
            }
            this.piece_0_up_2_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_0_up_3_container.on('dragstart', () => {
            this.piece_0_up_3_container.setDepth(1);
            this.piece_0_up_3.setScale(
                this.piece_0_up_3_dest.scaleX,
                this.piece_0_up_3_dest.scaleY
            );
        });
        this.piece_0_up_3_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_0_up_3, this.piece_0_up_3_dest)) {
                this.piece_0_up_3_fixed = true;
                this.piece_0_up_3_container.setPosition(
                    this.piece_0_up_3_dest.x,
                    this.piece_0_up_3_dest.y
                );
            } else {
                this.piece_0_up_3_fixed = false;
                this.piece_0_up_3_container.setPosition(
                    this.piece_0_up_3_initial_pos.x,
                    this.piece_0_up_3_initial_pos.y
                );
                this.piece_0_up_3.setScale(0.8, 0.8);
            }
            this.piece_0_up_3_container.setDepth(0);
            this.verifyPuzzleComplete();
        });
    }

    createZeroDownDigitPieces() {
        // piece 0-down-1
        this.piece_0_down_1_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_0_down_1 = this.add
            .image(0, 0, 'piece_0_down_1')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_0_down_1_container = this.add.container(
            this.center.x + this.game_size.x * 0.38,
            this.center.y + this.game_size.y * 0.365
        );

        this.piece_0_down_1_container.add([this.piece_0_down_1, this.piece_0_down_1_mid]);
        this.piece_0_down_1_container.setSize(
            this.piece_0_down_1.width,
            this.piece_0_down_1.height
        );
        this.piece_0_down_1_container.setInteractive();

        this.piece_0_down_1_initial_pos = {
            x: this.center.x + this.game_size.x * 0.38,
            y: this.center.y + this.game_size.y * 0.365,
        };

        // piece 0-down-2
        this.piece_0_down_2_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_0_down_2 = this.add
            .image(0, 0, 'piece_0_down_2')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_0_down_2_container = this.add.container(
            this.center.x + this.game_size.x * 0.3,
            this.center.y + this.game_size.y * 0.16
        );

        this.piece_0_down_2_container.add([this.piece_0_down_2, this.piece_0_down_2_mid]);
        this.piece_0_down_2_container.setSize(
            this.piece_0_down_2.width,
            this.piece_0_down_2.height
        );
        this.piece_0_down_2_container.setInteractive();

        this.piece_0_down_2_initial_pos = {
            x: this.center.x + this.game_size.x * 0.3,
            y: this.center.y + this.game_size.y * 0.16,
        };

        // piece 0-down-3
        this.piece_0_down_3_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_0_down_3 = this.add
            .image(0, 0, 'piece_0_down_3')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_0_down_3_container = this.add.container(
            this.center.x + this.game_size.x * 0.2,
            this.center.y - this.game_size.y * 0.16
        );

        this.piece_0_down_3_container.add([this.piece_0_down_3, this.piece_0_down_3_mid]);
        this.piece_0_down_3_container.setSize(
            this.piece_0_down_3.width,
            this.piece_0_down_3.height
        );
        this.piece_0_down_3_container.setInteractive();

        this.piece_0_down_3_initial_pos = {
            x: this.center.x + this.game_size.x * 0.2,
            y: this.center.y - this.game_size.y * 0.16,
        };

        this.input.setDraggable([
            this.piece_0_down_1_container,
            this.piece_0_down_2_container,
            this.piece_0_down_3_container,
        ]);

        // draggable part
        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.piece_0_down_1_container.on('dragstart', () => {
            this.piece_0_down_1_container.setDepth(1);
            this.piece_0_down_1.setScale(
                this.piece_0_down_1_dest.scaleX,
                this.piece_0_down_1_dest.scaleY
            );
        });
        this.piece_0_down_1_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_0_down_1, this.piece_0_down_1_dest)) {
                this.piece_0_down_1_fixed = true;
                this.piece_0_down_1_container.setPosition(
                    this.piece_0_down_1_dest.x,
                    this.piece_0_down_1_dest.y
                );
            } else {
                this.piece_0_down_1_fixed = false;
                this.piece_0_down_1_container.setPosition(
                    this.piece_0_down_1_initial_pos.x,
                    this.piece_0_down_1_initial_pos.y
                );
                this.piece_0_down_1.setScale(0.8, 0.8);
            }
            this.piece_0_down_1_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_0_down_2_container.on('dragstart', () => {
            this.piece_0_down_2_container.setDepth(1);
            this.piece_0_down_2.setScale(
                this.piece_0_down_2_dest.scaleX,
                this.piece_0_down_2_dest.scaleY
            );
        });
        this.piece_0_down_2_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_0_down_2, this.piece_0_down_2_dest)) {
                this.piece_0_down_2_fixed = true;
                this.piece_0_down_2_container.setPosition(
                    this.piece_0_down_2_dest.x,
                    this.piece_0_down_2_dest.y
                );
            } else {
                this.piece_0_down_2_fixed = false;
                this.piece_0_down_2_container.setPosition(
                    this.piece_0_down_2_initial_pos.x,
                    this.piece_0_down_2_initial_pos.y
                );
                this.piece_0_down_2.setScale(0.8, 0.8);
            }
            this.piece_0_down_2_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_0_down_3_container.on('dragstart', () => {
            this.piece_0_down_3_container.setDepth(1);
            this.piece_0_down_3.setScale(
                this.piece_0_down_3_dest.scaleX,
                this.piece_0_down_3_dest.scaleY
            );
        });
        this.piece_0_down_3_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_0_down_3, this.piece_0_down_3_dest)) {
                this.piece_0_down_3_fixed = true;
                this.piece_0_down_3_container.setPosition(
                    this.piece_0_down_3_dest.x,
                    this.piece_0_down_3_dest.y
                );
            } else {
                this.piece_0_down_3_fixed = false;
                this.piece_0_down_3_container.setPosition(
                    this.piece_0_down_3_initial_pos.x,
                    this.piece_0_down_3_initial_pos.y
                );
                this.piece_0_down_3.setScale(0.8, 0.8);
            }
            this.piece_0_down_3_container.setDepth(0);
            this.verifyPuzzleComplete();
        });
    }

    createOneDigitPieces() {
        // piece 1-1
        this.piece_1_1_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_1_1 = this.add
            .image(0, 0, 'piece_1_1')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_1_1_container = this.add.container(
            this.center.x + this.game_size.x * 0.23,
            this.center.y - this.game_size.y * 0.06
        );

        this.piece_1_1_container.add([this.piece_1_1, this.piece_1_1_mid]);
        this.piece_1_1_container.setSize(this.piece_1_1.width, this.piece_1_1.height);
        this.piece_1_1_container.setInteractive();

        this.piece_1_1_initial_pos = {
            x: this.center.x + this.game_size.x * 0.23,
            y: this.center.y - this.game_size.y * 0.06,
        };

        // piece 1-2
        this.piece_1_2_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_1_2 = this.add
            .image(0, 0, 'piece_1_2')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_1_2_container = this.add.container(
            this.center.x + this.game_size.x * 0.18,
            this.center.y + this.game_size.y * 0.37
        );

        this.piece_1_2_container.add([this.piece_1_2, this.piece_1_2_mid]);
        this.piece_1_2_container.setSize(this.piece_1_2.width, this.piece_1_2.height);
        this.piece_1_2_container.setInteractive();

        this.piece_1_2_initial_pos = {
            x: this.center.x + this.game_size.x * 0.18,
            y: this.center.y + this.game_size.y * 0.37,
        };

        // piece 1-3
        this.piece_1_3_mid = this.add
            .image(0, 0, 'mid')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setAlpha(0);

        this.piece_1_3 = this.add
            .image(0, 0, 'piece_1_3')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.piece_1_3_container = this.add.container(
            this.center.x + this.game_size.x * 0.24,
            this.center.y - this.game_size.y * 0.39
        );

        this.piece_1_3_container.add([this.piece_1_3, this.piece_1_3_mid]);
        this.piece_1_3_container.setSize(this.piece_1_3.width, this.piece_1_3.height);
        this.piece_1_3_container.setInteractive();

        this.piece_1_3_initial_pos = {
            x: this.center.x + this.game_size.x * 0.24,
            y: this.center.y - this.game_size.y * 0.39,
        };

        this.input.setDraggable([
            this.piece_1_1_container,
            this.piece_1_2_container,
            this.piece_1_3_container,
        ]);

        // draggable part
        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.piece_1_1_container.on('dragstart', () => {
            this.piece_1_1_container.setDepth(1);
            this.piece_1_1.setScale(
                this.piece_1_1_dest.scaleX,
                this.piece_1_1_dest.scaleY
            );
        });
        this.piece_1_1_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_1_1, this.piece_1_1_dest)) {
                this.piece_1_1_fixed = true;
                this.piece_1_1_container.setPosition(
                    this.piece_1_1_dest.x,
                    this.piece_1_1_dest.y
                );
            } else {
                this.piece_1_1_fixed = false;
                this.piece_1_1_container.setPosition(
                    this.piece_1_1_initial_pos.x,
                    this.piece_1_1_initial_pos.y
                );
                this.piece_1_1.setScale(0.8, 0.8);
            }
            this.piece_1_1_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_1_2_container.on('dragstart', () => {
            this.piece_1_2_container.setDepth(1);
            this.piece_1_2.setScale(
                this.piece_1_2_dest.scaleX,
                this.piece_1_2_dest.scaleY
            );
        });
        this.piece_1_2_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_1_2, this.piece_1_2_dest)) {
                this.piece_1_2_fixed = true;
                this.piece_1_2_container.setPosition(
                    this.piece_1_2_dest.x,
                    this.piece_1_2_dest.y
                );
            } else {
                this.piece_1_2_fixed = false;
                this.piece_1_2_container.setPosition(
                    this.piece_1_2_initial_pos.x,
                    this.piece_1_2_initial_pos.y
                );
                this.piece_1_2.setScale(0.8, 0.8);
            }
            this.piece_1_2_container.setDepth(0);
            this.verifyPuzzleComplete();
        });

        this.piece_1_3_container.on('dragstart', () => {
            this.piece_1_3_container.setDepth(1);
            this.piece_1_3.setScale(
                this.piece_1_3_dest.scaleX,
                this.piece_1_3_dest.scaleY
            );
        });
        this.piece_1_3_container.on('dragend', () => {
            this.sound.play('puzzle_piece');
            if (this.checkOverlap(this.piece_1_3, this.piece_1_3_dest)) {
                this.piece_1_3_fixed = true;
                this.piece_1_3_container.setPosition(
                    this.piece_1_3_dest.x,
                    this.piece_1_3_dest.y
                );
            } else {
                this.piece_1_3_fixed = false;
                this.piece_1_3_container.setPosition(
                    this.piece_1_3_initial_pos.x,
                    this.piece_1_3_initial_pos.y
                );
                this.piece_1_3.setScale(0.8, 0.8);
            }
            this.piece_1_3_container.setDepth(0);
            this.verifyPuzzleComplete();
        });
    }

    createDraggableSpots() {
        // 2
        this.piece_2_1_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.28,
                this.center.y - this.game_size.y * 0.115,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_2_2_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.28,
                this.center.y - this.game_size.y * 0.065,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_2_3_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.238,
                this.center.y - this.game_size.y * 0.114,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // 0 up
        this.piece_0_up_1_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.12,
                this.center.y - this.game_size.y * 0.115,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_0_up_2_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.162,
                this.center.y - this.game_size.y * 0.115,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_0_up_3_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.12,
                this.center.y - this.game_size.y * 0.064,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // 0 down
        this.piece_0_down_1_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.28,
                this.center.y + this.game_size.y * 0.084,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_0_down_2_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.281,
                this.center.y + this.game_size.y * 0.035,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_0_down_3_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.238,
                this.center.y + this.game_size.y * 0.0865,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // 1
        // 0 down
        this.piece_1_1_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.12,
                this.center.y + this.game_size.y * 0.085,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_1_2_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.16,
                this.center.y + this.game_size.y * 0.085,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.piece_1_3_dest = this.add
            .image(
                this.center.x - this.game_size.x * 0.12,
                this.center.y + this.game_size.y * 0.035,
                'anchor'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);
    }

    scaledown() {
        this.piece_2_1.setScale(0.8, 0.8);
        this.piece_2_2.setScale(0.8, 0.8);
        this.piece_2_3.setScale(0.8, 0.8);

        this.piece_1_1.setScale(0.8, 0.8);
        this.piece_1_2.setScale(0.8, 0.8);
        this.piece_1_3.setScale(0.8, 0.8);

        this.piece_0_up_1.setScale(0.8, 0.8);
        this.piece_0_up_2.setScale(0.8, 0.8);
        this.piece_0_up_3.setScale(0.8, 0.8);

        this.piece_0_down_1.setScale(0.8, 0.8);
        this.piece_0_down_2.setScale(0.8, 0.8);
        this.piece_0_down_3.setScale(0.8, 0.8);
    }

    verifyPuzzleComplete() {
        if (
            this.piece_2_1_fixed &&
            this.piece_2_2_fixed &&
            this.piece_2_3_fixed &&
            this.piece_1_1_fixed &&
            this.piece_1_2_fixed &&
            this.piece_1_3_fixed &&
            this.piece_0_up_1_fixed &&
            this.piece_0_up_2_fixed &&
            this.piece_0_up_3_fixed &&
            this.piece_0_down_1_fixed &&
            this.piece_0_down_2_fixed &&
            this.piece_0_down_3_fixed
        ) {
            this.is_solved = true;
            store.dispatch(markPuzzleSolved(this.puzzle_data.id));
            store.dispatch(unlockItem(ITEM_ID_MAP.ROVER_PUZZLE));
            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzle_data,
                puzzle: 'ROVER MINI PUZZLE',
            });
            // --- >
            this.input.setDraggable(
                [
                    this.piece_2_1_container,
                    this.piece_2_2_container,
                    this.piece_2_3_container,
                    this.piece_0_down_1_container,
                    this.piece_0_down_2_container,
                    this.piece_0_down_3_container,
                    this.piece_0_up_1_container,
                    this.piece_0_up_2_container,
                    this.piece_0_up_3_container,
                    this.piece_1_1_container,
                    this.piece_1_2_container,
                    this.piece_1_3_container,
                ],
                false
            );
        }
    }

    hideDraggablePieces() {
        this.piece_2_1.setVisible(false);
        this.piece_2_2.setVisible(false);
        this.piece_2_3.setVisible(false);
        this.piece_1_1.setVisible(false);
        this.piece_1_2.setVisible(false);
        this.piece_1_3.setVisible(false);
        this.piece_0_up_1.setVisible(false);
        this.piece_0_up_2.setVisible(false);
        this.piece_0_up_3.setVisible(false);
        this.piece_0_down_1.setVisible(false);
        this.piece_0_down_2.setVisible(false);
        this.piece_0_down_3.setVisible(false);
    }

    showDestinationPieces() {
        this.piece_2_1_dest.setVisible(true);
        this.piece_2_2_dest.setVisible(true);
        this.piece_2_3_dest.setVisible(true);
        this.piece_1_1_dest.setVisible(true);
        this.piece_1_2_dest.setVisible(true);
        this.piece_1_3_dest.setVisible(true);
        this.piece_0_up_1_dest.setVisible(true);
        this.piece_0_up_2_dest.setVisible(true);
        this.piece_0_up_3_dest.setVisible(true);
        this.piece_0_down_1_dest.setVisible(true);
        this.piece_0_down_2_dest.setVisible(true);
        this.piece_0_down_3_dest.setVisible(true);

        this.piece_2_1_dest.setAlpha(1);
        this.piece_2_2_dest.setAlpha(1);
        this.piece_2_3_dest.setAlpha(1);
        this.piece_1_1_dest.setAlpha(1);
        this.piece_1_2_dest.setAlpha(1);
        this.piece_1_3_dest.setAlpha(1);
        this.piece_0_up_1_dest.setAlpha(1);
        this.piece_0_up_2_dest.setAlpha(1);
        this.piece_0_up_3_dest.setAlpha(1);
        this.piece_0_down_1_dest.setAlpha(1);
        this.piece_0_down_2_dest.setAlpha(1);
        this.piece_0_down_3_dest.setAlpha(1);
    }

    checkOverlap(Image1, Image2) {
        const bounds1 = Image1.getBounds();
        const bounds2 = Image2.getBounds();
        const rect = PHASER.Geom.Rectangle.Intersection(bounds1, bounds2);
        if (rect.width !== 0) {
            return rect;
        }
        return 0;
    }

    sendState(status) {
        const roverState = getPuzzleByKey('rover-puzzle', 'Mars');
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(roverState.id, status, user.id, user);
    }
}

import PHASER from 'phaser';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzlesSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class LaunchCoordinates extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_COORDINATES,
        });

        this.is_solved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.coord1_value = 0;
        this.coord2_value = 0;
        this.coord3_value = 0;
        this.coord4_value = 0;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;

        puzzlesSocket.onUpdate(this.puzzle_data.id, data => {
            const state = data.payload;
            this.applyState(state);
        });
    }

    preload() {
        this.load.image('launch', '/assets/puzzles/launch-coordinates/launch.png');
        this.load.image('lc_bg', '/assets/puzzles/launch-coordinates/lc-background.png');
        this.load.image(
            'counter_up',
            '/assets/puzzles/launch-coordinates/counter-up.png'
        );
        this.load.image(
            'counter_down',
            '/assets/puzzles/launch-coordinates/counter-down.png'
        );
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.lc_bg = this.add
            .image(this.center.x, this.center.y, 'lc_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.launch = this.add
            .image(
                this.center.x + this.game_size.x * 0.22,
                this.center.y + this.game_size.y * 0.03,
                'launch'
            )
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => this.launchPressed());

        this.launch.on('pointerout', function () {
            this.clearTint();
        });
        this.launch.on('pointerup', function () {
            this.clearTint();
        });
        this.launch.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.createCoordinatesButtons();
        this.createCoordinatesTexts();

        this.incorrectImage = this.add
            .image(this.center.x, this.center.y, 'incorrect')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.incorrectText = this.add
            .text(this.center.x, this.center.y, 'Incorrect Answer', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        store.dispatch(
            setBackButtonCb(() => {
                const state = getPuzzleByKey('command-center', this.puzzle_data.room);
                this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.COMMAND_CENTER, state);
            })
        );
    }

    createCoordinatesButtons() {
        // cord 1
        this.cord1_counter_up = this.add
            .image(
                this.center.x - this.game_size.x * 0.3,
                this.center.y - this.game_size.y * 0.15,
                'counter_up'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord1ButtonPressed(1);
            });

        this.cord1_counter_down = this.add
            .image(
                this.center.x - this.game_size.x * 0.3,
                this.center.y + this.game_size.y * 0.22,
                'counter_down'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord1ButtonPressed(-1);
            });

        // cord 2
        this.cord2_counter_up = this.add
            .image(
                this.center.x - this.game_size.x * 0.19,
                this.center.y - this.game_size.y * 0.15,
                'counter_up'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord2ButtonPressed(1);
            });

        this.cord2_counter_down = this.add
            .image(
                this.center.x - this.game_size.x * 0.19,
                this.center.y + this.game_size.y * 0.22,
                'counter_down'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord2ButtonPressed(-1);
            });

        // cord 3
        this.cord3_counter_up = this.add
            .image(
                this.center.x - this.game_size.x * 0.08,
                this.center.y - this.game_size.y * 0.15,
                'counter_up'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord3ButtonPressed(1);
            });

        this.cord3_counter_down = this.add
            .image(
                this.center.x - this.game_size.x * 0.08,
                this.center.y + this.game_size.y * 0.22,
                'counter_down'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord3ButtonPressed(-1);
            });

        // cord 4
        this.cord4_counter_up = this.add
            .image(
                this.center.x + this.game_size.x * 0.03,
                this.center.y - this.game_size.y * 0.15,
                'counter_up'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord4ButtonPressed(1);
            });

        this.cord4_counter_down = this.add
            .image(
                this.center.x + this.game_size.x * 0.03,
                this.center.y + this.game_size.y * 0.22,
                'counter_down'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.coord4ButtonPressed(-1);
            });
    }

    createCoordinatesTexts() {
        this.cord1_text = this.add
            .text(
                this.center.x - this.game_size.x * 0.357,
                this.center.y - this.game_size.y * 0.04,
                this.coord1_value.toString(),
                {
                    fontFamily: 'Ethnocentric',
                    fontSize: 80,
                    align: 'center',
                }
            )
            .setFixedSize(100, 100)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.cord2_text = this.add
            .text(
                this.center.x - this.game_size.x * 0.248,
                this.center.y - this.game_size.y * 0.04,
                this.coord2_value.toString(),
                {
                    fontFamily: 'Ethnocentric',
                    fontSize: 80,
                    align: 'center',
                }
            )
            .setFixedSize(100, 100)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.cord3_text = this.add
            .text(
                this.center.x - this.game_size.x * 0.137,
                this.center.y - this.game_size.y * 0.04,
                this.coord3_value.toString(),
                {
                    fontFamily: 'Ethnocentric',
                    fontSize: 80,
                    align: 'center',
                }
            )
            .setFixedSize(100, 100)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.cord4_text = this.add
            .text(
                this.center.x - this.game_size.x * 0.027,
                this.center.y - this.game_size.y * 0.04,
                this.coord4_value.toString(),
                {
                    fontFamily: 'Ethnocentric',
                    fontSize: 80,
                    align: 'center',
                }
            )
            .setFixedSize(100, 100)
            .setScale(this.scale_factor.x, this.scale_factor.y);
    }

    coord1ButtonPressed(value) {
        if (this.is_solved) return;

        if (this.coord1_value === 0 && value < 0) {
            this.coord1_value = 9;
        } else if (this.coord1_value === 9 && value > 0) {
            this.coord1_value = 0;
        } else {
            this.coord1_value += value;
        }

        this.cord1_text.setText(this.coord1_value.toString());
    }

    coord2ButtonPressed(value) {
        if (this.is_solved) return;

        if (this.coord2_value === 0 && value < 0) {
            this.coord2_value = 9;
        } else if (this.coord2_value === 9 && value > 0) {
            this.coord2_value = 0;
        } else {
            this.coord2_value += value;
        }

        this.cord2_text.setText(this.coord2_value.toString());
    }

    coord3ButtonPressed(value) {
        if (this.is_solved) return;

        if (this.coord3_value === 0 && value < 0) {
            this.coord3_value = 9;
        } else if (this.coord3_value === 9 && value > 0) {
            this.coord3_value = 0;
        } else {
            this.coord3_value += value;
        }

        this.cord3_text.setText(this.coord3_value.toString());
    }

    coord4ButtonPressed(value) {
        if (this.is_solved) return;

        if (this.coord4_value === 0 && value < 0) {
            this.coord4_value = 9;
        } else if (this.coord4_value === 9 && value > 0) {
            this.coord4_value = 0;
        } else {
            this.coord4_value += value;
        }

        this.cord4_text.setText(this.coord4_value.toString());
    }

    resetCoordinates() {
        this.coord1_value = 0;
        this.coord2_value = 0;
        this.coord3_value = 0;
        this.coord4_value = 0;

        this.cord1_text.setText(this.coord1_value.toString());
        this.cord2_text.setText(this.coord2_value.toString());
        this.cord3_text.setText(this.coord3_value.toString());
        this.cord4_text.setText(this.coord4_value.toString());
    }

    launchPressed() {
        if (this.is_solved) return;

        if (
            this.coord1_value === 2 &&
            this.coord2_value === 3 &&
            this.coord3_value === 5 &&
            this.coord4_value === 6
        ) {
            this.is_solved = true;
            this.sound.play('success');

            const state = {
                isSolved: true,
            };
            this.sendState(state);

            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzleData,
                puzzle: 'LAUNCH COORDINATES',
            });
            // --- >

            store.dispatch(markPuzzleSolved(this.puzzle_data.id));

            const launchSequenceState = getPuzzleByKey('launch-sequence', 'Command Deck');
            this.scene.start(
                CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_SEQUENCE,
                launchSequenceState
            );
        } else {
            this.sound.play('failure');
            this.incorrectImage.setVisible(true);
            this.incorrectText.setVisible(true);
            this.resetCoordinates();
            this.disableCoordinates();

            this.timedEvent = this.time.addEvent({
                delay: 3000,
                callback: this.hideIncorrectAnswerImage,
                callbackScope: this,
                repeat: 0,
            });
        }
    }

    enableCoordinates() {
        this.cord1_counter_up.setInteractive();
        this.cord1_counter_down.setInteractive();
        this.cord2_counter_up.setInteractive();
        this.cord2_counter_down.setInteractive();
        this.cord3_counter_up.setInteractive();
        this.cord3_counter_down.setInteractive();
        this.cord4_counter_up.setInteractive();
        this.cord4_counter_down.setInteractive();
        this.launch.setInteractive();
    }

    disableCoordinates() {
        this.cord1_counter_up.disableInteractive();
        this.cord1_counter_down.disableInteractive();
        this.cord2_counter_up.disableInteractive();
        this.cord2_counter_down.disableInteractive();
        this.cord3_counter_up.disableInteractive();
        this.cord3_counter_down.disableInteractive();
        this.cord4_counter_up.disableInteractive();
        this.cord4_counter_down.disableInteractive();
        this.launch.disableInteractive();
    }

    hideIncorrectAnswerImage() {
        this.incorrectImage.setVisible(false);
        this.incorrectText.setVisible(false);

        this.enableCoordinates();
    }

    applyState(state) {
        if (state.isSolved) {
            this.is_solved = true;

            this.sound.play('success');

            const launchSequenceState = getPuzzleByKey('launch-sequence', 'Command Deck');
            this.scene.start(
                CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_SEQUENCE,
                launchSequenceState
            );
        }
    }

    sendState(state) {
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(this.puzzle_data.id, state, user.id, user);
    }
}

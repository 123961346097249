const GAME_SERVICE = '/game';

const LOGIN = `${GAME_SERVICE}/login`;
const JOIN = `${GAME_SERVICE}/join`;
const CREATE = `${GAME_SERVICE}/create`;
const TIMER = `${GAME_SERVICE}/timer`;
const GET = `${GAME_SERVICE}/`;
const VIDEOS = `${GAME_SERVICE}/videos`;
const VIDEOS360 = `${GAME_SERVICE}/360videos`;
const WATCH = `${GAME_SERVICE}/video/watch`;
const START = `${GAME_SERVICE}/start`;

const game = {
    LOGIN,
    JOIN,
    CREATE,
    TIMER,
    GET,
    VIDEOS,
    VIDEOS360,
    WATCH,
    START,
};

export default game;

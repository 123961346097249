import React, { useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import { scaleFontSizeByFont } from 'utils/stringUtils';

import {
    setPlayerAsLeft,
    setMyStatus,
    setStatus,
    STATUS_TYPES,
} from 'store/statusReducer/actions';

import { playerSocket } from 'services/websockets/index';

import PlayerStatusContainerBorder from 'assets/dashboardScreen/PlayerStatus_Border.png';

import './styles.scss';

const PlayerStatus = ({
    authenticatedUser,
    isPlayerSocketReady,
    status,
    setStatus,
    setPlayerAsLeft,
    setMyStatus,
}) => {
    const resizeFont = () => {
        const statuses = document.querySelectorAll('.status-message:not(.ref)');
        statuses.forEach(message => scaleFontSizeByFont(message, 1.1, 17));

        const names = document.querySelectorAll('.player-name:not(.ref)');
        names.forEach(name => scaleFontSizeByFont(name, 1.15, 18));
    };

    useEffect(() => {
        window.addEventListener('resize', resizeFont);

        return () => {
            window.removeEventListener('resize', resizeFont);
        };
    }, []);

    useEffect(() => {
        if (isPlayerSocketReady) {
            if (authenticatedUser.isPlayer) {
                playerSocket.sendAction(
                    STATUS_TYPES.JOINING_GAME,
                    authenticatedUser,
                    authenticatedUser.id
                );
                setMyStatus({
                    user: authenticatedUser,
                    senderId: authenticatedUser.id,
                    type: STATUS_TYPES.JOINING_GAME,
                });
            }

            playerSocket.onAction(STATUS_TYPES.CHATTING, setStatus);
            playerSocket.onAction(STATUS_TYPES.SETTING_COMMAND, setStatus);
            playerSocket.onAction(STATUS_TYPES.ADDING_VOTE, setStatus);
            playerSocket.onAction(STATUS_TYPES.SOLVING_PUZZLE, setStatus);
            playerSocket.onAction(STATUS_TYPES.JOINING_GAME, setStatus);
            playerSocket.onAction(STATUS_TYPES.LEAVING_GAME, setPlayerAsLeft);
            playerSocket.onDisconnect();
        }
    }, [authenticatedUser, isPlayerSocketReady, setMyStatus, setPlayerAsLeft, setStatus]);

    useLayoutEffect(() => {
        resizeFont();
    }, [status]);

    return (
        !authenticatedUser.isPlayer && (
            <div className="player-status-wrapper">
                <div className="player-status-container">
                    <img
                        src={PlayerStatusContainerBorder}
                        alt="Player Status Container Border"
                    />
                    <div className="status-messages-container">
                        {Object.keys(status).map((playerStatus, index) => {
                            return (
                                status[playerStatus].user &&
                                status[playerStatus].type !==
                                    STATUS_TYPES.LEAVING_GAME && (
                                    <div key={index} className="each-player-status">
                                        <span className="player-name">
                                            {status[playerStatus].user.firstName}{' '}
                                            {status[playerStatus].user.lastName}
                                        </span>
                                        <span className="player-name ref">
                                            {status[playerStatus].user.firstName}{' '}
                                            {status[playerStatus].user.lastName}
                                        </span>
                                        <span className="status-message">
                                            {status[playerStatus].text}
                                        </span>
                                        <span className="status-message ref">
                                            {status[playerStatus].text}
                                        </span>
                                    </div>
                                )
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    );
};

const mapStateToProps = ({ authReducer, statusReducer }) => ({
    status: statusReducer.status,
    authenticatedUser: authReducer.user,
});

const mapDispatchToProps = {
    setStatus: status => setStatus(status),
    setMyStatus: status => setMyStatus(status),
    setPlayerAsLeft: socketId => setPlayerAsLeft(socketId),
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatus);

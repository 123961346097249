import { connectSocket, WEBSOCKET_PUZZLE_STATE } from '../index';

let puzzleStateSocket;
let cursorInterval;
let currentSocketPuzzleId;

let connectionSubscription = false;
let stateUpdateSubscription = false;
let disconnectSubscription = false;
let accessDeniedSubscription = false;
let cursorsUpdateSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    stateUpdateSubscription = false;
    disconnectSubscription = false;
    accessDeniedSubscription = false;
    cursorsUpdateSubscription = false;
};

export const onAccessDenied = () => {
    if (!puzzleStateSocket || accessDeniedSubscription) return true;

    puzzleStateSocket.on('access_denied', () => {
        puzzleStateSocket = null;
    });

    accessDeniedSubscription = true;
};

export const connect = (gameId, puzzleId) => {
    currentSocketPuzzleId = puzzleId;
    puzzleStateSocket = connectSocket(WEBSOCKET_PUZZLE_STATE, gameId, puzzleId);

    onAccessDenied();
};

export const onConnect = cb => {
    if (!puzzleStateSocket || connectionSubscription) return true;

    puzzleStateSocket.on('connect', () => {
        console.log('Puzzle State Socket Connected for puzzle', currentSocketPuzzleId);
        cb();
    });

    connectionSubscription = true;
};

export const isConnected = () => {
    return puzzleStateSocket?.connected || false;
};

export const joinGame = (playerId, player, state) => {
    if (!puzzleStateSocket) return true;

    if (!puzzleStateSocket.connected) {
        onConnect(() => joinGame(playerId, player, state));
        return true;
    }

    puzzleStateSocket.emit('join_puzzle', {
        playerId,
        player,
        state,
    });
};

export const onStateUpdate = cb => {
    if (!puzzleStateSocket || stateUpdateSubscription) return true;

    puzzleStateSocket.on('state_update', data => {
        cb(JSON.parse(data));
    });

    stateUpdateSubscription = true;
};

export const offStateUpdate = () => {
    if (!puzzleStateSocket || !stateUpdateSubscription) return true;

    puzzleStateSocket.off('state_update');

    stateUpdateSubscription = false;
};

export const onCursorsUpdate = cb => {
    if (!puzzleStateSocket || cursorsUpdateSubscription) return true;

    puzzleStateSocket.on('cursors_update', data => {
        cb(JSON.parse(data));
    });

    cursorsUpdateSubscription = true;
};

export const update = updatedState => {
    if (!puzzleStateSocket) return true;

    puzzleStateSocket.emit('send_update', {
        updatedState,
    });
};

export const updateCursor = (playerId, puzzleObject) => {
    if (!puzzleStateSocket) return true;

    cursorInterval = setInterval(() => {
        puzzleStateSocket.emit('send_cursor_update', {
            playerId,
            updatedCursor: puzzleObject.getMyCursorPercentages(),
        });
    }, 400);
};

export const onDisconnect = () => {
    if (!puzzleStateSocket || disconnectSubscription) return true;

    puzzleStateSocket.on('disconnect', () => {
        console.log('Puzzle State Socket Disconnected');
        clearInterval(cursorInterval);
        resetAllSubscriptions();
        puzzleStateSocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!puzzleStateSocket?.connected) return true;

    console.log('Puzzle State Socket Disconnected for puzzle', currentSocketPuzzleId);
    clearInterval(cursorInterval);
    resetAllSubscriptions();
    puzzleStateSocket.removeAllListeners();
    puzzleStateSocket.disconnect();
};

import * as ACTIONS from './action_types';

const initialState = {
    messages: [],
    offset: 0,
    limit: 10,
    allMessagesFetched: false,
};

const messageReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.APPEND_MESSAGE:
            return {
                ...state,
                messages: [
                    ...state.messages,
                    {
                        type: payload.type ? payload.type : 'message',
                        ...payload,
                    },
                ],
            };
        case ACTIONS.PREPEND_MESSAGES:
            return {
                ...state,
                messages: [...payload, ...state.messages],
            };
        case ACTIONS.CLEAR_MESSAGES:
            return {
                ...state,
                messages: [],
            };
        case ACTIONS.SET_MESSAGES:
            return {
                ...state,
                messages: payload,
            };
        case ACTIONS.ALL_MESSAGES_FETCHED:
            return {
                ...state,
                allMessagesFetched: payload,
            };
        case ACTIONS.SET_CURRENT_OFFSET:
            return {
                ...state,
                offset: payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default messageReducer;

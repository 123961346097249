import React, { useEffect, Fragment, useRef } from 'react';
import Timer from 'react-compound-timer';
import { connect } from 'react-redux';

import {
    setChatScreenVisible,
    runVoteResultTimer,
    setVoteSubmitTrue,
} from 'store/interactionReducer/actions';

import VoteBorder from 'assets/dashboardScreen/Chat_ContainerBorder.png';
import VoteFailed from 'assets/dashboardScreen/VoteFailed_X.png';

import './styles.scss';

const VoteResult = ({
    displayChatScreen,
    setVoteSubmitTrue,
    voteData,
    win,
    authenticatedUser,
    runVoteResultTimer,
    voteResultTime,
    activePlayers,
}) => {
    const startTimerButton = useRef(null);
    const voteStartedByCurrentUser = authenticatedUser.id === voteData?.startedBy;

    useEffect(() => {
        if (voteStartedByCurrentUser) {
            startTimerButton.current.click();
        }
    }, [voteStartedByCurrentUser]);

    const handleTimeEnd = () => {
        setVoteSubmitTrue();
        displayChatScreen();
    };

    return (
        <div>
            {win && voteResultTime === null ? null : (
                <div className="failed-container">
                    <img src={VoteBorder} alt="chatbox border" />
                    <img className="cross-image" src={VoteFailed} alt="cross" />
                    <div className="heading result">
                        <span>VOTE FAILED</span>
                    </div>
                    <div className="notification">
                        <div className="fail-notification">
                            <div>
                                {voteResultTime !== null
                                    ? authenticatedUser.firstName
                                    : activePlayers?.find(
                                          p => p.id === voteData?.startedBy
                                      )?.name}{' '}
                                can submit a new command in 30 seconds.
                            </div>
                        </div>
                    </div>
                    {voteStartedByCurrentUser || voteResultTime !== null ? (
                        <div className="time-remaining">
                            <div className="time-heading">TIME REMAINING</div>
                            <div className="time-value">
                                <Timer
                                    initialTime={voteResultTime || 30500}
                                    direction="backward"
                                    formatValue={value =>
                                        `${value < 10 ? `0${value}` : value}`
                                    }
                                    checkpoints={[
                                        {
                                            time: 0,
                                            callback: () => handleTimeEnd(),
                                        },
                                    ]}
                                >
                                    {({ getTime }) => (
                                        <>
                                            <Timer.Minutes />
                                            :
                                            <Timer.Seconds />
                                            <div>
                                                <button
                                                    aria-label="Run Voter"
                                                    aria-hidden="true"
                                                    hidden
                                                    ref={startTimerButton}
                                                    onClick={() =>
                                                        runVoteResultTimer(getTime())
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </Timer>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Timer
                                initialTime={4000}
                                direction="backward"
                                formatValue={value =>
                                    `${value < 10 ? `0${value}` : value}`
                                }
                                checkpoints={[
                                    {
                                        time: 0,
                                        callback: () => handleTimeEnd(),
                                    },
                                ]}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({
    authReducer,
    voteReducer,
    interactionReducer,
    statusReducer,
}) => ({
    authenticatedUser: authReducer.user,
    win: voteReducer.win,
    voteData: voteReducer.voteData,
    voteResultTime: interactionReducer.voteResultTime,
    activePlayers: statusReducer.activePlayers,
});

const mapDispatchToProps = {
    displayChatScreen: () => setChatScreenVisible(true),
    setVoteSubmitTrue: () => setVoteSubmitTrue(true),
    runVoteResultTimer: value => runVoteResultTimer(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(VoteResult);

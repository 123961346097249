const isDevelopment = process.env.NODE_ENV === 'development';

export const ASSETS_BUCKET_URL = isDevelopment
    ? 'https://mars-remote-assets.s3.us-east-2.amazonaws.com/media'
    : '/media';
export const PUZZLE_VIDEOS_URL = `/videos/cinematic-videos/`;
export const GAME_VIDEOS_URL = `/videos/game-videos/`;
export const ITEM_IMAGE_URL = `${ASSETS_BUCKET_URL}/items/`;
export const ITEM_ID_MAP = {
    AIRLOCK_PRESSURE: 10,
    OXYGEN_BAY_PRESSURE: 11,
    PING_PONG_BALLS: 12,
    ROVER_PUZZLE: 13,
    ROVER_BACKGROUND: 14,
    COMMAND_DECK_PRESSURE: 15,
    COMMS_PANEL_INSTRUCTIONS: 16,
    FOG_CHAMBER: 17,
    SOLAR_READOUT: 18,
    PRESSURE_GAUGES_MAP: 19,
    COOLING_TUBES: 20,
};
export const PUZZLE_ID_MAP = {
    SELF_DESTRUCT: 16,
};

import * as ACTIONS from './action_types';

const initialState = {
    isGameStarted: false,
    notification: '',
};

const gameReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.SET_GAME_STARTED:
            return {
                ...state,
                isGameStarted: payload,
            };
        case ACTIONS.SET_TOAST_NOTIFICATION:
            return {
                ...state,
                notification: payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default gameReducer;

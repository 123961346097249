import PHASER from 'phaser';
import { Vector2 } from 'three';

import store from 'store';
import { setBackButtonCb } from 'store/puzzleReducer/actions';

import { puzzleStateSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class SatelliteDish extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.SATELLITE_DISH,
        });
        this.is_solved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.start_point = new PHASER.Geom.Point(0, 0);
        this.puzzle_state = {
            currX: 40,
            currY: 40,
            isSolved: false,
        };

        this.targetX = 20;
        this.targetY = 15;
        this.currX = 40;
        this.currY = 40;
        this.rows = 80;
        this.cols = 80;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image(
            'satellite_dish_bg',
            '/assets/puzzles/satellite/satellite-dish-bg.png'
        );
        this.load.image('antenna', '/assets/puzzles/satellite/antenna.png');
        this.load.image('satellite', '/assets/puzzles/satellite/satellite.png');
        this.load.image(
            'satellite_point',
            '/assets/puzzles/satellite/satellite-point.png'
        );
        this.load.audio(
            'laser_move',
            '/assets/sfx/puzzles/satellite-control/laser-move.wav'
        );
    }

    create() {
        const {
            authReducer: { user },
        } = store.getState();
        puzzleStateSocket.joinGame(user.id, user, this.puzzle_state);

        this.laserMoveSound = this.sound.add('laser_move', { volume: 0.3 });
        this.createGrid();
        this.graphics = this.add.graphics();
        this.graphics.lineStyle(4, 0xffff00, 1).setDepth(5);

        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        const pos = this.getLocation(this.currX, this.currY);

        this.start_point.x = this.center.x + this.game_size.x * 0.325;
        this.start_point.y = this.center.y + this.game_size.y * 0.175;

        this.satellite_dish_bg = this.add
            .image(this.center.x, this.center.y, 'satellite_dish_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.satellite_antenna = this.add
            .image(
                this.center.x + this.game_size.x * 0.38,
                this.center.y + this.game_size.y * 0.3,
                'antenna'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.satellite = this.add
            .image(
                this.center.x - this.game_size.x * 0.35,
                this.center.y - this.game_size.y * 0.22,
                'satellite'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.satellite_point = this.add
            .image(
                this.center.x - this.game_size.x * 0.28,
                this.center.y - this.game_size.y * 0.35,
                'satellite_point'
            )
            .setDepth(1)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.line = this.add
            .line(0, 0, this.start_point.x, this.start_point.y, pos.x, pos.y, 0xff0000)
            .setOrigin(0, 0)
            .setLineWidth(2);

        puzzleStateSocket.onStateUpdate(data => {
            const {
                states: { state },
            } = data;
            this.applyState(state);
        });

        this.updateLine(this.currX, this.currY);
        this.placeAt(14, 23, this.satellite);
        this.placeAt(20, 15, this.satellite_point);

        store.dispatch(setBackButtonCb());
    }

    applyState(state) {
        if (this.is_solved) return;

        this.is_solved = state.isSolved;

        if (this.is_solved) {
            Mixpanel.track('Final State For Satellite Dish', {
                ...this.puzzle_data,
                puzzle: 'SATELLITE DISH',
            });

            this.destroyLine();
            this.createNewLine(this.satellite_point.x, this.satellite_point.y);
            puzzleStateSocket.disconnect();
        } else {
            this.updateLine(state.currX, state.currY);
        }
    }

    updateLine(currX, currY) {
        if (currX !== this.currX || currY !== this.currY) {
            this.laserMoveSound.play();
        }

        this.currX = currX;
        this.currY = currY;

        this.destroyLine();
        const nextPos = this.getLocation(this.currX, this.currY);
        this.createNewLine(nextPos.x, nextPos.y);

        this.puzzle_state.currX = this.currX;
        this.puzzle_state.currY = this.currY;

        if (this.currX === this.targetX && this.currY === this.targetY) {
            this.destroyLine();
            this.createNewLine(this.satellite_point.x, this.satellite_point.y);
            puzzleStateSocket.update({
                isSolved: true,
            });
        }
    }

    createNewLine(newX, newY) {
        this.line = this.add
            .line(0, 0, this.start_point.x, this.start_point.y, newX, newY, 0xff0000)
            .setOrigin(0, 0)
            .setLineWidth(2)
            .setDepth(2);
    }

    destroyLine() {
        this.line.destroy();
    }

    createGrid() {
        this.cw = this.game.renderer.width / this.cols;
        this.ch = this.game.renderer.height / this.rows;
    }

    getLocation(x, y) {
        const x2 = this.cw * x + this.cw / 2;
        const y2 = this.ch * y + this.ch / 2;

        return new Vector2(x2, y2);
    }

    placeAt(x, y, obj) {
        const location = this.getLocation(x, y);
        obj.x = location.x;
        obj.y = location.y;
    }
}

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import useSound from 'use-sound';
import Loader from 'react-loader-spinner';
import { saveAs } from 'file-saver';

import { ASSETS_BUCKET_URL } from 'constants.js';

import { Mixpanel } from 'services/mixpanel';
import {
    inventorySocket,
    playerSocket,
    timerSocket,
    voteSocket,
    puzzlesSocket,
    chatSocket,
} from 'services/websockets/index';

import BackgroundPanels from 'assets/dashboardScreen/BackgroundPanels.png';
import RobotIcon from 'assets/dashboardScreen/RobotControl_Icon_New.png';
import RobotIconBorder from 'assets/dashboardScreen/RobotControl_ContainerBorder.png';
import RCExpandSound from 'assets/sounds/rc-expand.wav';
import RCContractSound from 'assets/sounds/rc-contract.wav';

import Timer from './components/Timer';
import ExpandedInventory from './components/ExpandedInventory';
import Chatbox from './components/Chatbox';
import Map from './components/Map';
import MissionControl from './components/MissionControl';
import PlayerStatus from './components/PlayerStatus';
import UnexpandedInventory from './components/UnexpandedInventory';
import VoteScreen from './components/VoteScreen';
import VoteResult from './components/VoteScreen/components/VoteResult';

import './styles.scss';

const Dashboard = ({
    authenticatedUser,
    gameId,
    isVoteScreenVisible,
    isResultScreenVisible,
    isChatScreenVisible,
}) => {
    const [loading, setLoading] = useState(true);
    const [inventoryExpanded, setInventoryExpanded] = useState(false);
    const [isInventorySocketReady, setInventorySocketReady] = useState(false);
    const [isTimerSocketReady, setTimerSocketReady] = useState(false);
    const [isVoteSocketReady, setVoteSocketReady] = useState(false);
    const [isPlayerSocketReady, setPlayerSocketReady] = useState(false);
    const [isPuzzleSocketReady, setPuzzleSocketReady] = useState(false);
    const [isChatSocketReady, setChatSocketReady] = useState(false);
    const [rcExpandSound] = useSound(RCExpandSound, { volume: 0.7 });
    const [rcContractSound] = useSound(RCContractSound, { volume: 0.7 });

    useEffect(() => {
        const allSocketsReady = [
            isInventorySocketReady,
            isTimerSocketReady,
            isVoteSocketReady,
            isPlayerSocketReady,
            isPuzzleSocketReady,
            isChatSocketReady,
        ].reduce((a, b) => a && b);

        if (allSocketsReady) setLoading(false);
    }, [
        isInventorySocketReady,
        isTimerSocketReady,
        isVoteSocketReady,
        isPuzzleSocketReady,
        isChatSocketReady,
        isPlayerSocketReady,
    ]);

    useEffect(() => {
        if (voteSocket.isConnected()) return;

        voteSocket.connect(gameId);
        voteSocket.onConnect(() => setVoteSocketReady(true));

        return () => {
            voteSocket.disconnect();
            setVoteSocketReady(false);
        };
    }, [gameId]);

    useEffect(() => {
        if (inventorySocket.isConnected()) return;

        inventorySocket.connect(gameId);
        inventorySocket.onConnect(() => setInventorySocketReady(true));

        return () => {
            inventorySocket.disconnect();
            setInventorySocketReady(false);
        };
    }, [gameId]);

    useEffect(() => {
        if (timerSocket.isConnected()) return;

        timerSocket.connect(gameId);
        timerSocket.onConnect(() => setTimerSocketReady(true));

        return () => {
            timerSocket.disconnect();
            setTimerSocketReady(false);
        };
    }, [gameId]);

    useEffect(() => {
        if (playerSocket.isConnected()) return;

        playerSocket.connect(gameId);
        playerSocket.onConnect(() => setPlayerSocketReady(true));

        return () => {
            playerSocket.disconnect();
            setPlayerSocketReady(false);
        };
    }, [gameId]);

    useEffect(() => {
        if (puzzlesSocket.isConnected()) return;

        puzzlesSocket.connect(gameId);
        puzzlesSocket.onConnect(() => setPuzzleSocketReady(true));

        return () => {
            puzzlesSocket.disconnect();
            setPuzzleSocketReady(false);
        };
    }, [gameId]);

    useEffect(() => {
        if (chatSocket.isConnected()) return;

        chatSocket.connect(gameId);
        chatSocket.onConnect(() => setChatSocketReady(true));

        return () => {
            chatSocket.disconnect();
            setChatSocketReady(false);
        };
    }, [gameId]);

    const toggleInventory = () => {
        inventoryExpanded ? rcContractSound() : rcExpandSound();
        setInventoryExpanded(!inventoryExpanded);
    };

    const onZoomButtonClick = () => {
        saveAs(
            `${ASSETS_BUCKET_URL}/Zoom-Backgrounds.zip`,
            'SpaceRaceVirtualBackgrounds.zip'
        );
        Mixpanel.track('Zoom Background Download');
    };

    if (loading)
        return (
            <div className="app-center">
                <Loader type="Grid" color="#7cf5fc" height={100} width={100} />
                {!authenticatedUser.isPlayer && <span>Logging in...</span>}
            </div>
        );

    return (
        <div className="wrapper">
            <div className="background">
                <div
                    className={cn(
                        {
                            player: authenticatedUser.isPlayer && !inventoryExpanded,
                            'player-expanded':
                                authenticatedUser.isPlayer && inventoryExpanded,
                            host: !authenticatedUser.isPlayer,
                        },
                        'background-panels'
                    )}
                >
                    <img
                        src={BackgroundPanels}
                        alt="background_panel"
                        className="hidden background-image"
                    />
                    <div className="zoom-background-button">
                        <button onClick={onZoomButtonClick}>
                            Download Virtual Backgrounds
                        </button>
                    </div>
                    <Timer isTimerSocketReady={isTimerSocketReady} />
                    {isVoteScreenVisible && (
                        <VoteScreen isPlayerSocketReady={isPlayerSocketReady} />
                    )}
                    {isChatScreenVisible && (
                        <Chatbox
                            isChatSocketReady={isChatSocketReady}
                            isPlayerSocketReady={isPlayerSocketReady}
                        />
                    )}
                    {isResultScreenVisible && <VoteResult />}
                    {authenticatedUser.isPlayer ? (
                        <div>
                            <button
                                className={cn(
                                    {
                                        hidden: inventoryExpanded,
                                    },
                                    'robot-button-container'
                                )}
                                onClick={toggleInventory}
                            >
                                <img src={RobotIconBorder} alt="" />
                                <img src={RobotIcon} className="icon" alt="" />
                            </button>
                            {inventoryExpanded ? (
                                <ExpandedInventory
                                    isVoteSocketReady={isVoteSocketReady}
                                    isInventorySocketReady={isInventorySocketReady}
                                />
                            ) : (
                                <UnexpandedInventory
                                    isVoteSocketReady={isVoteSocketReady}
                                    isInventorySocketReady={isInventorySocketReady}
                                />
                            )}
                            <button
                                className={cn(
                                    {
                                        expand: !inventoryExpanded,
                                        contract: inventoryExpanded,
                                    },
                                    'arrow-button'
                                )}
                                aria-label="Toggle Inventory"
                                onClick={toggleInventory}
                            />
                        </div>
                    ) : (
                        <ExpandedInventory
                            isVoteSocketReady={isVoteSocketReady}
                            isInventorySocketReady={isInventorySocketReady}
                        />
                    )}
                    <MissionControl isPuzzleSocketReady={isPuzzleSocketReady} />
                    <Map />
                    <PlayerStatus isPlayerSocketReady={isPlayerSocketReady} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ interactionReducer, authReducer }) => ({
    isChatScreenVisible: interactionReducer.isChatScreenVisible,
    isVoteScreenVisible: interactionReducer.isVoteScreenVisible,
    isResultScreenVisible: interactionReducer.isResultScreenVisible,
    isVoteSubmit: interactionReducer.isVoteSubmit,
    authenticatedUser: authReducer.user,
    gameId: authReducer.gameId,
});

export default connect(mapStateToProps)(Dashboard);

export default Object.freeze({
    SCENES: {
        AIRLOCK: {
            DOOR_KEYPAD: 'door-keypad',
            ROTATING_WHEEL: 'rotating-wheel',
            COOLING_TUBES: 'cooling-tubes',
            FOG_CHAMBER: 'fog-chamber',
            MIRROR_CHAMBER: 'mirror-chamber',
            CLOUD_CHAMBER: 'cloud-chamber',
            LOCKERS: 'lockers',
            LEFT_LOCKER: 'left-locker',
            RIGHT_LOCKER: 'right-locker',
            WALL_PIPES: 'wall-pipes',
        },
        COMMAND_DECK: {
            KEYPAD: 'KEYPAD',
            OXYGEN_READOUT: 'o2-readout',
            PRESSURE_GAUGES: 'pressure-guages',
            LAUNCH_COORDINATES: 'launch-coordinates',
            AIR_CHAMBER: 'air-chamber',
            COMMS_PANEL: 'comms-panel',
            LIFE_SUPP_SYSTEMS: 'life-support-systems',
            LAUNCH_BUTTON: 'launch-button',
            LAUNCH_SEQUENCE: 'launch-sequence',
            COMMAND_CENTER: 'command-center',
            SELF_DESTRUCT: 'self-destruct-override',
            SOLAR_PEDESTAL: 'solar-pedestal',
        },
        MARS: {
            FUSE_BOX: 'fuse-box',
            BREAKER_PANEL: 'breaker-panel',
            PATCH_BAY_INSTRUCTIONS: 'PATCH_BAY_INSTRUCTIONS',
            ROVER_INTERIOR_KEYPAD: 'rover-interior-keypad',
            ROVER_EXTERIOR_KEYPAD: 'rover-exterior-keypad',
            ROVER_PUZZLE: 'rover-puzzle',
            ROVER_MINI_PUZZLE: 'rover-mini-puzzle',
            SATELLITE_CONTROL: 'satellite-control',
            SATELLITE_DISH: 'satellite-dish',
        },
        LOAD_SCENE: 'LOAD_SCENE',
    },
});

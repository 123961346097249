import PHASER from 'phaser';

import store from 'store';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzlesSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class LaunchButton extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_BUTTON,
        });
        this.is_solved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('a1_big_on', '/assets/puzzles/launch-sequence/a1-big-on.png');
        this.load.image('a1_big_off', '/assets/puzzles/launch-sequence/a1-big-off.png');
        this.load.image('c1_big_on', '/assets/puzzles/launch-sequence/c1-big-on.png');
        this.load.image('c1_big_off', '/assets/puzzles/launch-sequence/c1-big-off.png');
        this.load.image('c2_big_on', '/assets/puzzles/launch-sequence/c2-big-on.png');
        this.load.image('c2_big_off', '/assets/puzzles/launch-sequence/c2-big-off.png');
        this.load.image('c3_big_on', '/assets/puzzles/launch-sequence/c3-big-on.png');
        this.load.image('c3_big_off', '/assets/puzzles/launch-sequence/c3-big-off.png');
        this.load.image('c4_big_on', '/assets/puzzles/launch-sequence/c4-big-on.png');
        this.load.image('c4_big_off', '/assets/puzzles/launch-sequence/c4-big-off.png');
        this.load.image('m1_big_on', '/assets/puzzles/launch-sequence/m1-big-on.png');
        this.load.image('m1_big_off', '/assets/puzzles/launch-sequence/m1-big-off.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        // A1
        this.a1_off = this.add
            .image(this.center.x, this.center.y, 'a1_big_off')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.onSwitchA1Button(true);
            });

        this.a1_on = this.add
            .image(this.center.x, this.center.y, 'a1_big_on')
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C1
        this.c1_off = this.add
            .image(this.center.x, this.center.y, 'c1_big_off')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.onSwitchC1Button(true);
            });

        this.c1_on = this.add
            .image(this.center.x, this.center.y, 'c1_big_on')
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C2
        this.c2_off = this.add
            .image(this.center.x, this.center.y, 'c2_big_off')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.onSwitchC2Button(true);
            });

        this.c2_on = this.add
            .image(this.center.x, this.center.y, 'c2_big_on')
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C3
        this.c3_off = this.add
            .image(this.center.x, this.center.y, 'c3_big_off')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.onSwitchC3Button(true);
            });

        this.c3_on = this.add
            .image(this.center.x, this.center.y, 'c3_big_on')
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C4
        this.c4_off = this.add
            .image(this.center.x, this.center.y, 'c4_big_off')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.onSwitchC4Button(true);
            });

        this.c4_on = this.add
            .image(this.center.x, this.center.y, 'c4_big_on')
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // M1
        this.m1_off = this.add
            .image(this.center.x, this.center.y, 'm1_big_off')
            .setInteractive()
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .on('pointerdown', () => {
                this.onSwitchM1Button(true);
            });

        this.m1_on = this.add
            .image(this.center.x, this.center.y, 'm1_big_on')
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.switchCurrentButton();

        puzzlesSocket.onUpdate(this.puzzle_data.state.id, data => {
            const state = data.payload;
            this.applyState(state);
        });

        store.dispatch(setBackButtonCb());
    }

    onSwitchA1Button(switchOn) {
        this.a1_off.setVisible(!switchOn);
        this.a1_on.setVisible(switchOn);

        this.updateState('A_1', switchOn);
    }

    onSwitchM1Button(switchOn) {
        this.m1_off.setVisible(!switchOn);
        this.m1_on.setVisible(switchOn);

        this.updateState('M_1', switchOn);
    }

    onSwitchC1Button(switchOn) {
        this.c1_off.setVisible(!switchOn);
        this.c1_on.setVisible(switchOn);

        this.updateState('C_1', switchOn);
    }

    onSwitchC2Button(switchOn) {
        this.c2_off.setVisible(!switchOn);
        this.c2_on.setVisible(switchOn);

        this.updateState('C_2', switchOn);
    }

    onSwitchC3Button(switchOn) {
        this.c3_off.setVisible(!switchOn);
        this.c3_on.setVisible(switchOn);

        this.updateState('C_3', switchOn);
    }

    onSwitchC4Button(switchOn) {
        this.c4_off.setVisible(!switchOn);
        this.c4_on.setVisible(switchOn);

        this.updateState('C_4', switchOn);
    }

    switchCurrentButton(state) {
        let puzzleState;
        let myButtonState;
        const button = this.puzzle_data.opens;
        if (state) {
            puzzleState = state;
            myButtonState = state.buttonStates[button];
        } else {
            puzzleState = this.puzzle_data.state;
            myButtonState = puzzleState.state.buttonStates[button];
        }

        this.disableAllButtons();
        switch (button) {
            case 'A_1':
                this.a1_off.setVisible(!myButtonState);
                this.a1_on.setVisible(myButtonState);
                break;
            case 'C_1':
                this.c1_off.setVisible(!myButtonState);
                this.c1_on.setVisible(myButtonState);
                break;
            case 'C_2':
                this.c2_off.setVisible(!myButtonState);
                this.c2_on.setVisible(myButtonState);
                break;
            case 'C_3':
                this.c3_off.setVisible(!myButtonState);
                this.c3_on.setVisible(myButtonState);
                break;
            case 'C_4':
                this.c4_off.setVisible(!myButtonState);
                this.c4_on.setVisible(myButtonState);
                break;
            case 'M_1':
                this.m1_off.setVisible(!myButtonState);
                this.m1_on.setVisible(myButtonState);
                break;
            // no default
        }
    }

    disableAllButtons() {
        this.a1_on.setVisible(false);
        this.a1_off.setVisible(false);

        this.c1_on.setVisible(false);
        this.c1_off.setVisible(false);

        this.c2_on.setVisible(false);
        this.c2_off.setVisible(false);

        this.c3_on.setVisible(false);
        this.c3_off.setVisible(false);

        this.c4_on.setVisible(false);
        this.c4_off.setVisible(false);

        this.m1_on.setVisible(false);
        this.m1_off.setVisible(false);
    }

    updateState(button, state) {
        const puzzleState = getPuzzleByKey('launch-sequence', 'Command Deck');
        const ls_state = puzzleState.state;

        const currBtnStates = ls_state.buttonStates;
        switch (button) {
            case 'A_1':
                if (
                    currBtnStates.M_1 === true &&
                    currBtnStates.C_1 === false &&
                    currBtnStates.C_2 === false &&
                    currBtnStates.C_3 === false &&
                    currBtnStates.C_4 === false
                ) {
                    currBtnStates.A_1 = state;
                    // save state
                } else {
                    // reset state
                    currBtnStates.A_1 = false;
                    currBtnStates.M_1 = false;
                    currBtnStates.C_1 = false;
                    currBtnStates.C_2 = false;
                    currBtnStates.C_3 = false;
                    currBtnStates.C_4 = false;
                }
                break;
            case 'M_1':
                if (
                    currBtnStates.A_1 === false &&
                    currBtnStates.C_1 === false &&
                    currBtnStates.C_3 === false &&
                    currBtnStates.C_2 === false &&
                    currBtnStates.C_4 === false
                ) {
                    currBtnStates.M_1 = state;

                    // save state
                } else {
                    // reset state
                    currBtnStates.A_1 = false;
                    currBtnStates.M_1 = false;
                    currBtnStates.C_1 = false;
                    currBtnStates.C_2 = false;
                    currBtnStates.C_3 = false;
                    currBtnStates.C_4 = false;
                }
                break;
            case 'C_1':
                if (
                    currBtnStates.M_1 === true &&
                    currBtnStates.A_1 === true &&
                    currBtnStates.C_4 === true &&
                    currBtnStates.C_3 === false &&
                    currBtnStates.C_2 === false
                ) {
                    currBtnStates.C_1 = state;
                    // save state
                } else {
                    // reset state
                    currBtnStates.A_1 = false;
                    currBtnStates.M_1 = false;
                    currBtnStates.C_1 = false;
                    currBtnStates.C_2 = false;
                    currBtnStates.C_3 = false;
                    currBtnStates.C_4 = false;
                }
                break;
            case 'C_2':
                if (
                    currBtnStates.M_1 === true &&
                    currBtnStates.A_1 === true &&
                    currBtnStates.C_4 === true &&
                    currBtnStates.C_3 === false &&
                    currBtnStates.C_1 === true
                ) {
                    currBtnStates.C_2 = state;

                    // save state
                } else {
                    // reset state
                    currBtnStates.A_1 = false;
                    currBtnStates.M_1 = false;
                    currBtnStates.C_1 = false;
                    currBtnStates.C_2 = false;
                    currBtnStates.C_3 = false;
                    currBtnStates.C_4 = false;
                }
                break;
            case 'C_3':
                if (
                    currBtnStates.M_1 === true &&
                    currBtnStates.A_1 === true &&
                    currBtnStates.C_4 === true &&
                    currBtnStates.C_2 === true &&
                    currBtnStates.C_1 === true
                ) {
                    currBtnStates.C_3 = state;
                    // save state

                    // solve launch sequence here!
                    const launchSequenceState = getPuzzleByKey(
                        'launch-sequence',
                        'Command Deck'
                    );
                    store.dispatch(markPuzzleSolved(launchSequenceState.id));
                    // < --- MIXPANEL ANALYTICS
                    Mixpanel.track('Puzzle Complete', {
                        ...this.puzzle_data,
                        ...launchSequenceState,
                        puzzle: 'LAUNCH SEQUENCE BUTTON',
                    });
                    // --- >
                } else {
                    // reset state
                    currBtnStates.A_1 = false;
                    currBtnStates.M_1 = false;
                    currBtnStates.C_1 = false;
                    currBtnStates.C_2 = false;
                    currBtnStates.C_3 = false;
                    currBtnStates.C_4 = false;
                }
                break;
            case 'C_4':
                if (
                    currBtnStates.M_1 === true &&
                    currBtnStates.A_1 === true &&
                    currBtnStates.C_1 === false &&
                    currBtnStates.C_3 === false &&
                    currBtnStates.C_2 === false
                ) {
                    currBtnStates.C_4 = state;
                    // save state
                } else {
                    // reset state
                    currBtnStates.A_1 = false;
                    currBtnStates.M_1 = false;
                    currBtnStates.C_1 = false;
                    currBtnStates.C_2 = false;
                    currBtnStates.C_3 = false;
                    currBtnStates.C_4 = false;
                }
                break;
            // no default
        }
        ls_state.buttonStates = currBtnStates;
        this.sendState(ls_state);
    }

    sendState(state) {
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(this.puzzle_data.state.id, state, user.id, user);
    }

    applyState(state) {
        this.switchCurrentButton(state);
    }
}

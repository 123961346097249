const COMMON_PPREFIX = 'store/authReducer';

export const SET_PROCESSING = `${COMMON_PPREFIX}/SET_PROCESSING`;
export const SET_CURRENT_USER = `${COMMON_PPREFIX}/SET_CURRENT_USER`;
export const SET_CURRENT_TOKEN = `${COMMON_PPREFIX}/SET_CURRENT_TOKEN`;
export const SET_CURRENT_SESSION = `${COMMON_PPREFIX}/SET_CURRENT_SESSION`;
export const SET_GAME_ID = `${COMMON_PPREFIX}/SET_GAME_ID`;
export const AUTH_STATUS = `${COMMON_PPREFIX}/AUTH_STATUS`;
export const SET_AUTH_MESSAGE = `${COMMON_PPREFIX}/SET_AUTH_MESSAGE`;
export const SET_GENERATED_GAME_ID = `${COMMON_PPREFIX}/SET_GENERATED_GAME_ID`;

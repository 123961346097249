import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { removeItem } from 'store/inventoryReducer/actions';
import { markPuzzleSolved } from 'store/puzzleReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class DoorKeypad extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.DOOR_KEYPAD,
        });

        this.isSolved = false;
        this.code = '';
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.keyStartPos = new PHASER.Geom.Point(this.center.x + 104, this.center.y - 28);
        this.puzzleData = data;
        this.maxCharLimit = this.puzzleData.solution.length;
    }

    preload() {
        this.loadAssets();
    }

    create() {
        this.createAssets();
    }

    loadAssets() {
        this.load.image(
            'keypad_background',
            '/assets/puzzles/keypads/door-keypad/keypad-bg.png'
        );
        this.load.image(
            'keypad_screen',
            '/assets/puzzles/keypads/door-keypad/keypad-screen.png'
        );
        this.loadKeypadAssets();
    }

    createAssets() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.keypad_background = this.add
            .image(this.center.x, this.center.y, 'keypad_background')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.keypad_screen = this.add
            .image(
                this.center.x + this.gameSize.x * 0.216,
                this.center.y - this.gameSize.y * 0.17,
                'keypad_screen'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.codeField = this.add
            .text(
                this.center.x + this.gameSize.x * 0.154,
                this.center.y - this.gameSize.y * 0.2,
                '',
                { fontFamily: 'Ethnocentric', fontSize: 26, align: 'center' }
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setColor('#000000')
            .setFixedSize(100, 100);

        this.createKeypadAssets();

        this.incorrectImage = this.add
            .image(this.center.x, this.center.y, 'incorrect')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.incorrectText = this.add
            .text(this.center.x, this.center.y, 'Access Denied', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setVisible(false)
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        if (this.puzzleData.status === 'completed') {
            this.setSolvedState();
        }
    }

    loadKeypadAssets() {
        this.load.image('key_0', '/assets/puzzles/keypads/door-keypad/key-0.png');
        this.load.image('key_1', '/assets/puzzles/keypads/door-keypad/key-1.png');
        this.load.image('key_2', '/assets/puzzles/keypads/door-keypad/key-2.png');
        this.load.image('key_3', '/assets/puzzles/keypads/door-keypad/key-3.png');
        this.load.image('key_4', '/assets/puzzles/keypads/door-keypad/key-4.png');
        this.load.image('key_5', '/assets/puzzles/keypads/door-keypad/key-5.png');
        this.load.image('key_6', '/assets/puzzles/keypads/door-keypad/key-6.png');
        this.load.image('key_7', '/assets/puzzles/keypads/door-keypad/key-7.png');
        this.load.image('key_8', '/assets/puzzles/keypads/door-keypad/key-8.png');
        this.load.image('key_9', '/assets/puzzles/keypads/door-keypad/key-9.png');
        this.load.image('erase', '/assets/puzzles/keypads/door-keypad/erase.png');
        this.load.image('submit', '/assets/puzzles/keypads/door-keypad/submit.png');
    }

    createKeypadAssets() {
        this.key_1 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.144,
                this.center.y - this.gameSize.y * 0.063,
                'key_1'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(1);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_1.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_2 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.2185,
                this.center.y - this.gameSize.y * 0.063,
                'key_2'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(2);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_2.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_3 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.291,
                this.center.y - this.gameSize.y * 0.063,
                'key_3'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(3);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_3.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_4 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.144,
                this.center.y + this.gameSize.y * 0.02,
                'key_4'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(4);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_4.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_5 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.2185,
                this.center.y + this.gameSize.y * 0.02,
                'key_5'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(5);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_5.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_6 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.291,
                this.center.y + this.gameSize.y * 0.02,
                'key_6'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(6);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_6.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_7 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.144,
                this.center.y + this.gameSize.y * 0.103,
                'key_7'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(7);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_7.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_8 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.2185,
                this.center.y + this.gameSize.y * 0.103,
                'key_8'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(8);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_8.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_9 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.291,
                this.center.y + this.gameSize.y * 0.103,
                'key_9'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(9);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_9.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_0 = this.add
            .image(
                this.center.x + this.gameSize.x * 0.144,
                this.center.y + this.gameSize.y * 0.186,
                'key_0'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(0);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_0.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.erase = this.add
            .image(
                this.center.x + this.gameSize.x * 0.2185,
                this.center.y + this.gameSize.y * 0.186,
                'erase'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.eraseKeyPressed();
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.erase.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.submit = this.add
            .image(
                this.center.x + this.gameSize.x * 0.291,
                this.center.y + this.gameSize.y * 0.186,
                'submit'
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.submitKeyPressed();
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.submit.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        if (this.puzzleData.isCompleted) {
            this.setSolvedState();
        }
    }

    numKeyPressed(value) {
        if (!this.isSolved) {
            this.sound.play('key');
            if (this.code.length < this.maxCharLimit) {
                this.code += value;
                this.codeField.setText(this.code);
            }
        }
    }

    eraseKeyPressed() {
        if (!this.isSolved) {
            this.sound.play('erase');
            if (this.code !== '' && this.code.length > 0) {
                this.code = this.code.slice(0, this.code.length - 1);
                this.codeField.setText(this.code);
            }
        }
    }

    submitKeyPressed() {
        if (this.isSolved) return;

        if (this.code === this.puzzleData.solution) {
            this.isSolved = true;
            this.sound.play('success');

            store.dispatch(markPuzzleSolved(this.puzzleData.id));
            store.dispatch(removeItem(ITEM_ID_MAP.FOG_CHAMBER));
            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzleData,
                puzzle: 'DOOR KEYPAD',
            });
            // --- >
        } else {
            this.code = '';
            this.codeField.setText(this.code);

            this.sound.play('failure');

            this.incorrectImage.setVisible(true);
            this.incorrectText.setVisible(true);
            this.disableKeys();

            this.timedEvent = this.time.addEvent({
                delay: 3000,
                callback: this.hideIncorrectAnswerImage,
                callbackScope: this,
                repeat: 0,
            });
        }
    }

    hideIncorrectAnswerImage() {
        this.incorrectImage.setVisible(false);
        this.incorrectText.setVisible(false);

        this.enableKeys();
    }

    disableKeys() {
        this.key_0.disableInteractive();
        this.key_1.disableInteractive();
        this.key_2.disableInteractive();
        this.key_3.disableInteractive();
        this.key_4.disableInteractive();
        this.key_5.disableInteractive();
        this.key_6.disableInteractive();
        this.key_7.disableInteractive();
        this.key_8.disableInteractive();
        this.key_9.disableInteractive();
        this.erase.disableInteractive();
        this.submit.disableInteractive();
    }

    enableKeys() {
        this.key_0.setInteractive();
        this.key_1.setInteractive();
        this.key_2.setInteractive();
        this.key_3.setInteractive();
        this.key_4.setInteractive();
        this.key_5.setInteractive();
        this.key_6.setInteractive();
        this.key_7.setInteractive();
        this.key_8.setInteractive();
        this.key_9.setInteractive();
        this.erase.setInteractive();
        this.submit.setInteractive();
    }

    setSolvedState() {
        this.isSolved = true;
        this.codeField.setText(this.puzzleData.solution);
    }
}

import * as ACTIONS from './action_types';

const initialState = {
    timeValue: null,
    currentState: null,
    ticking: false,
};

const timerReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.SET_TIMER_VALUE:
            return {
                ...state,
                timeValue: payload,
            };
        case ACTIONS.SET_TIMER_STATE:
            return {
                ...state,
                currentState: payload,
                ticking: payload === 'playing',
            };

        default:
            return {
                ...state,
            };
    }
};

export default timerReducer;

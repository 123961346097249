import PHASER from 'phaser';

import store from 'store';
import { markPuzzleSolved } from 'store/puzzleReducer/actions';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class WallPipes extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.WALL_PIPES,
        });
        this.isSolved = false;
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
    }

    preload() {
        this.load.image('main_background_wp', '/assets/puzzles/main-bg.png');
        this.load.image('grey_background_wp', '/assets/puzzles/fuse-box/grey-bg.png');
        this.load.image('submit_wp', '/assets/puzzles/cooling-tubes/submit.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background_wp')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.grey_background = this.add
            .image(this.center.x, this.center.y, 'grey_background_wp')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.submit = this.add
            .image(this.center.x, this.center.y + this.gameSize.y * 0.22, 'submit_wp')
            .setInteractive()
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .on('pointerdown', () => this.submitPressed());

        this.name = this.add
            .text(
                this.center.x - this.gameSize.x * 0.22,
                this.center.y - this.gameSize.y * 0.1,
                'Wall Pipes',
                { fontFamily: 'Ethnocentric', fontSize: 35, align: 'center' }
            )
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setColor('FFFFFF')
            .setFixedSize(500, 100);

        if (this.puzzleData.status === 'completed') {
            this.isSolved = true;
        }
    }

    submitPressed() {
        if (!this.isSolved) {
            this.isSolved = true;
            store.dispatch(markPuzzleSolved(this.puzzleData.id));
            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzleData,
                puzzle: 'WALL PIPES',
            });
            // --- >
        }
    }
}

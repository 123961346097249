import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { unlockItem } from 'store/inventoryReducer/actions';

import CONSTANTS from '../../../constants';

export default class OxygenReadout extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.OXYGEN_READOUT,
        });

        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
    }

    preload() {
        this.load.image('readout_cd', '/assets/puzzles/readouts/readout-cd.png');
        this.load.image(
            'readout_airlock',
            '/assets/puzzles/readouts/readout-airlock.png'
        );
        this.load.image('readout_ob', '/assets/puzzles/readouts/readout-ob.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.main_background.width;
        this.scaleFactor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.gradient_background = this.add
            .image(this.center.x, this.center.y, 'gradient_background')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        if (this.puzzleData.type === 'command-deck') {
            store.dispatch(unlockItem(ITEM_ID_MAP.COMMAND_DECK_PRESSURE));
            this.readout = this.add
                .image(this.center.x, this.center.y, 'readout_cd')
                .setScale(this.scaleFactor.x, this.scaleFactor.y);
        } else if (this.puzzleData.type === 'airlock') {
            store.dispatch(unlockItem(ITEM_ID_MAP.AIRLOCK_PRESSURE));
            this.readout = this.add
                .image(this.center.x, this.center.y, 'readout_airlock')
                .setScale(this.scaleFactor.x, this.scaleFactor.y);
        } else if (this.puzzleData.type === 'oxygen-bay') {
            store.dispatch(unlockItem(ITEM_ID_MAP.OXYGEN_BAY_PRESSURE));
            this.readout = this.add
                .image(this.center.x, this.center.y, 'readout_ob')
                .setScale(this.scaleFactor.x, this.scaleFactor.y);
        }
    }
}

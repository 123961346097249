import * as Sentry from '@sentry/react';

import api from 'services/apis';
import axios from 'services/axios';
import { Mixpanel } from 'services/mixpanel';

import { getGameInfo, setToastNotification } from 'store/gameReducer/actions';
import { fetchAllSessions } from 'store/statusReducer/actions';

import { sessionStorageWithExpiry } from 'utils/storage';

import * as ACTIONS from './action_types';

const setProcessing = payload => ({ type: ACTIONS.SET_PROCESSING, payload });

export const setNewToken = token => {
    return dispatch => {
        sessionStorage.removeItem('token');
        sessionStorage.setItem('token', token);

        dispatch({
            type: ACTIONS.SET_CURRENT_TOKEN,
            payload: token,
        });
    };
};

export const setSessionData = data => {
    sessionStorageWithExpiry.setItem('currentSession', data);
};

export const fetchCurrentUser = () => {
    const url = '/auth';
    return async dispatch => {
        dispatch(setProcessing(true));
        try {
            const res = await axios.get(url);
            const { user } = res.data;
            dispatch({
                type: ACTIONS.SET_CURRENT_USER,
                payload: user,
            });

            // Set user for logging Sentry errors
            Sentry.setUser(user);

            return dispatch(setProcessing(false));
        } catch (error) {
            console.log(error.message);
            return dispatch(setProcessing(false));
        }
    };
};

export const hostLogin = (_token, password) => {
    const url = api.hosts.LOGIN;

    return async dispatch => {
        dispatch(setProcessing(true));
        dispatch({
            type: ACTIONS.SET_AUTH_MESSAGE,
            payload: '',
        });
        try {
            const res = await axios.post(url, { token: _token, password });
            const { host, token, gameId } = res.data;
            if (host && token && gameId) {
                Mixpanel.identify(host.id);
                Mixpanel.people.set({
                    $first_name: 'HOST',
                    $game_id: host.gameId,
                });
                Mixpanel.track('Host Login', { password });

                dispatch(setNewToken(token));
                dispatch({
                    type: ACTIONS.SET_GAME_ID,
                    payload: gameId,
                });

                dispatch(fetchAllSessions());
                await Promise.all([
                    dispatch(fetchCurrentUser()),
                    dispatch(getGameInfo(gameId)),
                ]);
            }
            return dispatch(setProcessing(false));
        } catch (error) {
            console.log(error.message);
            return dispatch(setProcessing(false));
        }
    };
};

export const createGame = gameCount => {
    const url = api.game.CREATE;
    return async dispatch => {
        dispatch(setProcessing(true));

        try {
            const res = await axios.post(
                `${url}${gameCount ? `?count=${gameCount}` : ''}`
            );
            const { games } = res.data;
            dispatch({
                type: ACTIONS.SET_GENERATED_GAME_ID,
                payload: games,
            });
            dispatch(setProcessing(false));
        } catch (error) {
            console.log(error.message);
            dispatch(setProcessing(false));
        }
    };
};

export const playerLogin = ({ firstName, lastName, password, email, checkboxEmail }) => {
    const url = api.game.LOGIN;
    return async dispatch => {
        dispatch(setProcessing(true));
        try {
            const res = await axios.post(url, {
                firstName,
                lastName,
                gameId: password,
                email,
                emailOptIn: checkboxEmail,
            });
            const { session, token, player } = res.data;

            dispatch(setNewToken(token));
            setSessionData({ firstName, lastName, password, email });
            dispatch({
                type: ACTIONS.SET_GAME_ID,
                payload: session.gameId,
            });
            dispatch(getGameInfo(session.gameId));
            dispatch(fetchAllSessions());
            await dispatch(fetchCurrentUser());
            dispatch(setProcessing(false));

            Mixpanel.identify(player.id);
            Mixpanel.people.set({
                $first_name: player.firstName,
                $last_name: player.lastName,
                $email: player.email,
                $game_id: player.gameId,
                $x_event_id: player.gameId,
            });

            Mixpanel.track('Successful login', { gameId: player.gameId });
        } catch (error) {
            console.log(error.message);
            if (error.response?.status === 400) {
                dispatch(setToastNotification(error.response.data?.message));
            } else if (error.response?.status === 403) {
                dispatch(setToastNotification('Incorrect or invalid password'));
            }
            dispatch(setProcessing(false));
        }
    };
};

export const isAuthenticated = getState => {
    return getState().authReducer.user.id !== undefined;
};

import { connectSocket, WEBSOCKET_CHAT } from './index';

let chatSocket;

let connectionSubscription = false;
let receiveMessageSubscription = false;
let disconnectSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    receiveMessageSubscription = false;
    disconnectSubscription = false;
};

export const connect = gameId => {
    chatSocket = connectSocket(WEBSOCKET_CHAT, gameId);
};

export const onConnect = cb => {
    if (!chatSocket || connectionSubscription) return true;

    chatSocket.on('connect', () => {
        console.log('Chat Socket Connected');
        cb();
    });
};

export const isConnected = () => {
    return chatSocket?.connected || false;
};

export const receiveMessage = cb => {
    if (!chatSocket || receiveMessageSubscription) return true;

    chatSocket.on('receive_message', data => {
        cb(data);
    });

    receiveMessageSubscription = true;
};

export const sendMessage = (message, id, user) => {
    if (!chatSocket) return true;

    chatSocket.emit('send_message', {
        text: message,
        userId: id,
        user: user,
    });
};

export const onDisconnect = () => {
    if (!chatSocket || disconnectSubscription) return true;

    chatSocket.on('disconnect', () => {
        console.log('Chat Socket Disconnected');
        resetAllSubscriptions();
        chatSocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!chatSocket) return true;

    console.log('Chat Socket Disconnected');
    resetAllSubscriptions();
    chatSocket.removeAllListeners();
    chatSocket.disconnect();
};

import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import { normalizeAngle } from 'utils/normalization';

import store from 'store';
import { removeItem } from 'store/inventoryReducer/actions';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzlesSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

let dragging = false;
let tween = null;
export default class FogChamber extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.PRESSURE_GAUGES,
        });
        this.is_solved = false;
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;

        puzzlesSocket.onUpdate(this.puzzle_data.id, data => {
            const state = data.payload;
            this.applyState(state);
        });
    }

    preload() {
        this.load.image(
            'pressureGauges_bg',
            '/assets/puzzles/pressure-gauges/pressureGauges-bg.png'
        );
        this.load.image('needle', '/assets/puzzles/pressure-gauges/needle.png');
        this.load.image('knob', '/assets/puzzles/pressure-gauges/knob.png');
        this.load.image('meter_left', '/assets/puzzles/pressure-gauges/meter-left.png');
        this.load.image('meter_right', '/assets/puzzles/pressure-gauges/meter-right.png');
        this.load.image('submit_pg', '/assets/puzzles/pressure-gauges/submit.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.pressureGauges_bg = this.add
            .image(this.center.x, this.center.y, 'pressureGauges_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.submit_pg = this.add
            .image(this.center.x, this.center.y + this.game_size.y * 0.38, 'submit_pg')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.verifyPuzzleComplete();
            });

        this.createMeterControls();

        this.incorrectImage = this.add
            .image(this.center.x, this.center.y, 'incorrect')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.incorrectText = this.add
            .text(this.center.x, this.center.y, 'Incorrect Answer', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        const puzzleData = getPuzzleByKey('pressure-gauges', this.puzzle_data.room);
        if (puzzleData.state) {
            this.applyState(puzzleData.state);
        }

        store.dispatch(setBackButtonCb());
    }

    createMeterControls() {
        this.createButtonControls();
        this.createKnobControls();
    }

    createButtonControls() {
        // meter 1 controls
        this.meter1_left = this.add
            .image(
                this.center.x - this.game_size.x * 0.375,
                this.center.y + this.game_size.y * 0.07,
                'meter_left'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.meter1ButtonControlClicked(-23);
            });

        this.meter1_right = this.add
            .image(
                this.center.x - this.game_size.x * 0.24,
                this.center.y + this.game_size.y * 0.07,
                'meter_right'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.meter1ButtonControlClicked(23);
            });

        this.needle1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.313,
                this.center.y - this.game_size.y * 0.01,
                'needle'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setOrigin(0.5, 1);

        // meter 2 controls
        this.meter2_left = this.add
            .image(
                this.center.x - this.game_size.x * 0.069,
                this.center.y + this.game_size.y * 0.07,
                'meter_left'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.meter2ButtonControlClicked(-23);
            });

        this.meter2_right = this.add
            .image(
                this.center.x + this.game_size.x * 0.069,
                this.center.y + this.game_size.y * 0.07,
                'meter_right'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.meter2ButtonControlClicked(23);
            });

        this.needle2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.006,
                this.center.y - this.game_size.y * 0.01,
                'needle'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setOrigin(0.5, 1);

        // meter 3 controls
        this.meter3_left = this.add
            .image(
                this.center.x + this.game_size.x * 0.24,
                this.center.y + this.game_size.y * 0.07,
                'meter_left'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.meter3ButtonControlClicked(-23);
            });

        this.meter3_right = this.add
            .image(
                this.center.x + this.game_size.x * 0.375,
                this.center.y + this.game_size.y * 0.07,
                'meter_right'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.meter3ButtonControlClicked(23);
            });

        this.needle3 = this.add
            .image(
                this.center.x + this.game_size.x * 0.303,
                this.center.y - this.game_size.y * 0.01,
                'needle'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setOrigin(0.5, 1);
    }

    createKnobControls() {
        this.knob1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.31,
                this.center.y + this.game_size.y * 0.24,
                'knob'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateKnob(this.knob1);
            })
            .on('drag', () => {
                this.rotateKnob(this.knob1, 1);
            })
            .on('dragend', () => {
                this.snapKnob(this.knob1, 1);
            });

        this.knob2 = this.add
            .image(this.center.x, this.center.y + this.game_size.y * 0.24, 'knob')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateKnob(this.knob2);
            })
            .on('drag', () => {
                this.rotateKnob(this.knob2, 2);
            })
            .on('dragend', () => {
                this.snapKnob(this.knob2, 2);
            });

        this.knob3 = this.add
            .image(
                this.center.x + this.game_size.x * 0.31,
                this.center.y + this.game_size.y * 0.24,
                'knob'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive({ draggable: true })
            .on('dragstart', () => {
                this.rotateKnob(this.knob3);
            })
            .on('drag', () => {
                this.rotateKnob(this.knob3, 3);
            })
            .on('dragend', () => {
                this.snapKnob(this.knob3, 3);
            });
    }

    meter1ButtonControlClicked(angleDiff) {
        if (this.isSolved) return;

        this.tweenNeedle('needle1', this.needle1.angle, this.needle1.angle + angleDiff);
    }

    meter2ButtonControlClicked(angleDiff) {
        if (this.isSolved) return;

        this.tweenNeedle('needle2', this.needle2.angle, this.needle2.angle + angleDiff);
    }

    meter3ButtonControlClicked(angleDiff) {
        if (this.isSolved) return;

        this.tweenNeedle('needle3', this.needle3.angle, this.needle3.angle + angleDiff);
    }

    tweenNeedle(needle, from, to) {
        if (to < -69 || to > 69) return;

        this.disableMeterControls();
        switch (needle) {
            case 'needle1':
                this.tweenToAngle(this.needle1, 'needle1', from, to);
                break;
            case 'needle2':
                this.tweenToAngle(this.needle2, 'needle2', from, to);
                break;
            case 'needle3':
                this.tweenToAngle(this.needle3, 'needle3', from, to);
                break;
            // no default
        }
    }

    rotateKnob(knob, knobNum) {
        if (this.isSolved) return;

        const angle = this.getTargetAngle(knob);

        if ((angle >= 300 && angle <= 359) || (angle >= 0 && angle <= 69)) {
            if (this.game.input.activePointer.isDown && !dragging) {
                dragging = true;
            }
            if (!this.game.input.activePointer.isDown && dragging) {
                dragging = false;
            }

            if (dragging) {
                knob.angle = angle;
            }

            switch (knobNum) {
                case 1:
                    this.needle1.angle = angle;
                    break;
                case 2:
                    this.needle2.angle = angle;
                    break;
                case 3:
                    this.needle3.angle = angle;
                    break;
                // no default
            }
        }
    }

    getTargetAngle(knob) {
        let targetAngle =
            (360 / (2 * Math.PI)) *
                PHASER.Math.Angle.Between(
                    knob.x,
                    knob.y,
                    this.game.input.activePointer.x,
                    this.game.input.activePointer.y
                ) +
            90;

        if (targetAngle < 0) targetAngle += 360;

        return targetAngle;
    }

    snapKnob(knob, knobNum) {
        if (this.isSolved) return;

        let { angle } = knob;
        if (knob.angle < 0) angle += 360;

        angle = normalizeAngle(angle, 20);

        knob.angle = angle;

        let needleAngle = 0;
        switch (knobNum) {
            case 1:
                needleAngle = this.getAngleMapping(angle);
                this.needle1.angle = needleAngle;
                break;
            case 2:
                needleAngle = this.getAngleMapping(angle);
                this.needle2.angle = needleAngle;
                break;
            case 3:
                needleAngle = this.getAngleMapping(angle);
                this.needle3.angle = needleAngle;
                break;
            // no default
        }
        this.syncState();
    }

    tweenToAngle(needleObject, needleType, currentAngle, targetAngle) {
        tween = this.tweens.addCounter({
            from: currentAngle,
            to: targetAngle,
            duration: 1000,
            onUpdate: () => {
                needleObject.setAngle(tween.getValue());
            },
            onComplete: () => {
                this.enableMeterControls();
                this.syncState();
            },
        });
    }

    getAngleMapping(angle) {
        let finalAngle;
        switch (angle) {
            case 340:
                finalAngle = -23;
                break;
            case 320:
                finalAngle = -46;
                break;
            case 300:
                finalAngle = -69;
                break;
            case 20:
                finalAngle = 23;
                break;
            case 40:
                finalAngle = 46;
                break;
            case 60:
                finalAngle = 69;
                break;
            default:
                finalAngle = 0;
        }

        return finalAngle;
    }

    verifyPuzzleComplete() {
        if (
            this.needle1.angle === 0 &&
            this.needle2.angle === 46 &&
            this.needle3.angle === -46
        ) {
            this.sound.play('success');
            this.isSolved = true;
            this.submit_pg.setVisible(false);

            store.dispatch(markPuzzleSolved(this.puzzle_data.id));

            store.dispatch(removeItem(ITEM_ID_MAP.AIRLOCK_PRESSURE));
            store.dispatch(removeItem(ITEM_ID_MAP.OXYGEN_BAY_PRESSURE));
            store.dispatch(removeItem(ITEM_ID_MAP.COMMAND_DECK_PRESSURE));
            store.dispatch(removeItem(ITEM_ID_MAP.PRESSURE_GAUGES_MAP));

            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzle_data,
                puzzle: 'PRESSURE GAUGES',
            });
            // --- >
        } else {
            this.sound.play('failure');
            this.incorrectImage.setVisible(true);
            this.incorrectText.setVisible(true);
            this.needle1.angle = 0;
            this.needle2.angle = 0;
            this.needle3.angle = 0;
            this.knob1.angle = 0;
            this.knob2.angle = 0;
            this.knob3.angle = 0;

            this.disableButtons();

            this.timedEvent = this.time.addEvent({
                delay: 3000,
                callback: this.hideIncorrectAnswerImage,
                callbackScope: this,
                repeat: 0,
            });
        }
    }

    hideIncorrectAnswerImage() {
        this.incorrectImage.setVisible(false);
        this.incorrectText.setVisible(false);

        this.enableButtons();
    }

    enableButtons() {
        this.meter1_left.setInteractive();
        this.meter1_right.setInteractive();
        this.meter2_left.setInteractive();
        this.meter2_right.setInteractive();
        this.meter3_left.setInteractive();
        this.meter3_right.setInteractive();

        this.input.setDraggable([this.knob1, this.knob2, this.knob3], true);
    }

    disableButtons() {
        this.meter1_left.disableInteractive();
        this.meter1_right.disableInteractive();
        this.meter2_left.disableInteractive();
        this.meter2_right.disableInteractive();
        this.meter3_left.disableInteractive();
        this.meter3_right.disableInteractive();

        this.input.setDraggable([this.knob1, this.knob2, this.knob3], false);
    }

    syncState() {
        const state = {
            needle1Angle: this.needle1.angle,
            needle2Angle: this.needle2.angle,
            needle3Angle: this.needle3.angle,
            knob1Angle: this.knob1.angle,
            knob2Angle: this.knob2.angle,
            knob3Angle: this.knob3.angle,
            isSolved: this.is_solved,
        };
        this.sendCurrentState(state);
    }

    sendCurrentState(state) {
        const {
            authReducer: { user },
        } = store.getState();
        puzzlesSocket.update(this.puzzle_data.id, state, user.id, user);
    }

    applyState(state) {
        if (tween !== null) {
            if (tween.isPlaying()) {
                tween.remove();
                tween.destroy();
                tween = null;
            }
        }

        this.needle1.angle = state.needle1Angle;
        this.needle2.angle = state.needle2Angle;
        this.needle3.angle = state.needle3Angle;
        this.knob1.angle = state.knob1Angle;
        this.knob2.angle = state.knob2Angle;
        this.knob3.angle = state.knob3Angle;
        this.is_solved = state.isSolved;
    }

    enableMeterControls() {
        this.meter1_left.setInteractive();
        this.meter1_right.setInteractive();
        this.meter2_left.setInteractive();
        this.meter2_right.setInteractive();
        this.meter3_left.setInteractive();
        this.meter3_right.setInteractive();
    }

    disableMeterControls() {
        this.meter1_left.disableInteractive();
        this.meter1_right.disableInteractive();
        this.meter2_left.disableInteractive();
        this.meter2_right.disableInteractive();
        this.meter3_left.disableInteractive();
        this.meter3_right.disableInteractive();
    }
}

import React, { useRef, useEffect } from 'react';
import Timer from 'react-compound-timer';
import { connect } from 'react-redux';

import { updateTime, setTimerState, setTimerValue } from 'store/timerReducer/actions';

import { Mixpanel } from 'services/mixpanel';
import { timerSocket } from 'services/websockets/index';

import PlayLogo from 'assets/hostDashboard/HostDash_PlayPause.png';

import './styles.scss';

const CustomTimer = ({
    authenticatedUser,
    isTimerSocketReady,
    timerValue,
    timerState,
    updateTime,
    setTimerState,
    setTimerValue,
    ticking,
}) => {
    const timeSetter = useRef(null);

    const sendTimeInSocket = (updatedTime, ticker) => {
        if (isTimerSocketReady) {
            timerSocket.updateTime(
                updatedTime,
                ticker,
                authenticatedUser.id,
                authenticatedUser
            );
        }
    };

    useEffect(() => {
        if (isTimerSocketReady) {
            timerSocket.onGameTimerUpdate(time => {
                setTimerValue(time);
            });

            timerSocket.onUpdate(time => {
                setTimerValue(time);
            });

            timerSocket.onGameTimerStop();

            timerSocket.onDisconnect();
        }
    }, [isTimerSocketReady, setTimerValue]);

    useEffect(() => {
        timeSetter.current.click();
    }, [timerValue]);

    const addMinutes = () => {
        if (timerValue < 3540) {
            const updatedTime = timerValue + 60;
            setTimerValue(updatedTime);
            sendTimeInSocket(updatedTime, ticking);
            updateTime(updatedTime, timerState);
            Mixpanel.track('Host: Timer Change', { updatedTime });
        }
    };

    const subtractMinutes = () => {
        if (timerValue > 60) {
            const updatedTime = timerValue - 60;
            setTimerValue(updatedTime);
            sendTimeInSocket(updatedTime, ticking);
            updateTime(updatedTime, timerState);
            Mixpanel.track('Host: Timer Change', { updatedTime });
        }
    };

    const addSeconds = () => {
        if (timerValue < 3599) {
            const updatedTime = timerValue + 1;
            setTimerValue(updatedTime);
            sendTimeInSocket(updatedTime, ticking);
            Mixpanel.track('Host: Timer Change', { updatedTime });
        }
    };

    const subtractSeconds = () => {
        if (timerValue > 1) {
            const updatedTime = timerValue - 1;
            setTimerValue(updatedTime);
            sendTimeInSocket(updatedTime, ticking);
            Mixpanel.track('Host: Timer Change', { updatedTime });
        }
    };

    const formatTime = value => {
        return `${value < 10 ? `0${value}` : value}`;
    };

    const playPauseTimer = () => {
        if (ticking) {
            timerSocket.pauseGameTimer();
            setTimerState('paused');
            Mixpanel.track('Host: Pause Timer', { timerValue });
        } else {
            timerSocket.playGameTimer();
            Mixpanel.track('Host: Play Timer', { timerValue });
            setTimerState('playing');
        }
    };

    return (
        <div>
            {authenticatedUser.isPlayer ? (
                <div className="time-remaining-container">
                    <Timer
                        initialTime={timerValue !== null ? timerValue * 1000 : 0}
                        lastUnit="m"
                        formatValue={formatTime}
                        startImmediately={false}
                    >
                        {({ setTime }) => (
                            <>
                                <Timer.Minutes />
                                :
                                <Timer.Seconds />
                                <button
                                    aria-label="Set Time"
                                    aria-hidden="true"
                                    hidden
                                    ref={timeSetter}
                                    onClick={() => setTime(timerValue * 1000)}
                                />
                            </>
                        )}
                    </Timer>
                </div>
            ) : (
                <div className="time-remaining-container host">
                    <Timer
                        initialTime={timerValue !== null ? timerValue * 1000 : 0}
                        lastUnit="m"
                        formatValue={formatTime}
                        startImmediately={false}
                    >
                        {({ setTime }) => (
                            <>
                                <div className="host-time-container">
                                    <div className="host-time">
                                        <Timer.Minutes />
                                        :
                                        <Timer.Seconds />
                                        <button
                                            aria-label="Set Time"
                                            aria-hidden="true"
                                            hidden
                                            ref={timeSetter}
                                            onClick={() => setTime(timerValue * 1000)}
                                        />
                                    </div>
                                    <button
                                        className="play-pause"
                                        id="play-button"
                                        onClick={playPauseTimer}
                                    >
                                        <img src={PlayLogo} alt="Play logo" />
                                    </button>
                                </div>
                                <div className="controls">
                                    <div className="change minutes">
                                        <span className="heading">MIN</span>
                                        <button
                                            aria-label="Add Minutes"
                                            className="increment"
                                            onClick={addMinutes}
                                        />
                                        <button
                                            aria-label="Subtract Minutes"
                                            className="decrement"
                                            onClick={subtractMinutes}
                                        />
                                    </div>
                                    <div className="change seconds">
                                        <span className="heading">SEC</span>
                                        <button
                                            aria-label="Add Seconds"
                                            className="increment"
                                            onClick={addSeconds}
                                        />
                                        <button
                                            aria-label="Sutract Seconds"
                                            className="decrement"
                                            onClick={subtractSeconds}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Timer>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ authReducer, timerReducer }) => ({
    authenticatedUser: authReducer.user,
    timerValue: timerReducer.timeValue,
    timerState: timerReducer.currentState,
    ticking: timerReducer.ticking,
});

const mapDispatchToProps = {
    updateTime: (value, state) => updateTime(value, state),
    setTimerState: (value, state) => setTimerState(value, state),
    setTimerValue: value => setTimerValue(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTimer);

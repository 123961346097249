import api from 'services/apis';
import axios from 'services/axios';
import { voteSocket } from 'services/websockets';

import { ITEM_ID_MAP } from 'constants.js';

import {
    setVoteSubmitFalse,
    setResultScreenVisible,
    setSelfVote,
    setChatScreenVisible,
    setVoteOnResult,
    setSelfDestructInitialized,
} from 'store/interactionReducer/actions';
import { fetchAllInventory, unlockItem } from 'store/inventoryReducer/actions';
import { fetchPuzzleData } from 'store/puzzleReducer/actions';
import { fetchCurrentVideos } from 'store/videosReducer/actions';

import * as ACTIONS from './action_types';

export const setVotingData = voteData => {
    return dispatch => {
        const { statement } = voteData;
        if (statement.includes('+big +red +button')) {
            dispatch(setSelfDestructInitialized());
        }

        dispatch({
            type: ACTIONS.SET_CURRENT_VOTE,
            payload: voteData,
        });
    };
};

export const castVote = (voteID, selection) => {
    const url = api.vote.CAST;
    return dispatch => {
        axios
            .post(`${url}/${voteID}`, { selection })
            .then(res => {
                dispatch({
                    type: ACTIONS.CURRENT_VOTE_SELECTIONS,
                    payload: res.data.playerVotes,
                });
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const clearVote = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.CLEAR_VOTE,
        });
    };
};

export const calculateResultApi = voteID => {
    const url = api.vote.GET_CURRENT_SESSION_VOTE_DATA;

    return (dispatch, getState) => {
        const {
            interactionReducer: { voteOnResult },
            voteReducer: { callback },
        } = getState();

        axios
            .get(`${url}/${voteID}`)
            .then(res => {
                const {
                    data: { voting },
                } = res;
                const isWin = voting.result === 'yes';

                callback && callback(isWin);
                dispatch({
                    type: ACTIONS.WIN,
                    payload: isWin,
                });
                dispatch(setSelfVote(false));

                if (isWin) {
                    dispatch(fetchPuzzleData());
                    dispatch(fetchAllInventory());
                    dispatch(fetchCurrentVideos());
                    dispatch(setVoteOnResult(false));

                    if (voteOnResult) {
                        dispatch(setVoteSubmitFalse());
                        dispatch(setResultScreenVisible());
                    } else {
                        dispatch(setChatScreenVisible());
                    }
                } else if (voting.statement.includes('+big +red +button')) {
                    dispatch(fetchCurrentVideos());
                    dispatch(setChatScreenVisible());
                    dispatch(unlockItem(ITEM_ID_MAP.SOLAR_READOUT));
                } else {
                    dispatch(setResultScreenVisible());
                    dispatch(setVoteSubmitFalse());
                }

                dispatch(clearVote());
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const checkWin = voteID => {
    const url = api.vote.RESULT;
    return dispatch => {
        axios
            .get(`${url}/${voteID}`)
            .then(() => {
                dispatch(calculateResultApi(voteID));
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const overwrite = (voteID, selection) => {
    const url = api.vote.OVERWRITE;
    return () => {
        axios
            .post(`${url}/${voteID}`, { selection })
            .then(() => {
                voteSocket.overrideVote();
            })
            .catch(error => {
                console.log(error.message);
            });
    };
};

export const setVoteCallback = callback => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CALLBACK,
            payload: callback,
        });
    };
};

export const incrementVote = selection => {
    return dispatch => {
        if (selection === 'yes') {
            dispatch({
                type: ACTIONS.INCREMENT_YES,
            });
        } else {
            dispatch({
                type: ACTIONS.INCREMENT_NO,
            });
        }
    };
};

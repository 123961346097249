import PHASER from 'phaser';

import store from 'store';
import { setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import CONSTANTS from '../../../constants';

export default class LifeSupportSystems extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.LIFE_SUPP_SYSTEMS,
        });

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
        this.isCommsDone = false;
        this.isPowerDone = false;
        this.isOxygenDone = false;
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('lss_bg', '/assets/puzzles/life-support-systems/lss-bg.png');
        this.load.image('keypad_power', '/assets/puzzles/life-support-systems/power.png');
        this.load.image('keypad_comms', '/assets/puzzles/life-support-systems/comms.png');
        this.load.image(
            'keypad_oxygen',
            '/assets/puzzles/life-support-systems/oxygen.png'
        );
        this.load.image(
            'comms_shroud',
            '/assets/puzzles/life-support-systems/comms-shroud.png'
        );
        this.load.image(
            'oxygen_shroud',
            '/assets/puzzles/life-support-systems/oxygen-shroud.png'
        );
        this.load.image(
            'power_shroud',
            '/assets/puzzles/life-support-systems/power-shroud.png'
        );
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.lss_bg = this.add
            .image(this.center.x, this.center.y, 'lss_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.keypad_comms = this.add
            .image(
                this.center.x - this.game_size.x * 0.24,
                this.center.y + this.game_size.y * 0.393,
                'keypad_comms'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.openCommsKeypad();
            });

        this.keypad_power = this.add
            .image(
                this.center.x + this.game_size.x * 0.01,
                this.center.y + this.game_size.y * 0.4,
                'keypad_power'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.openPowerKeypad();
            });

        this.keypad_oxygen = this.add
            .image(
                this.center.x + this.game_size.x * 0.26,
                this.center.y + this.game_size.y * 0.39,
                'keypad_oxygen'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.openOxygenKeypad();
            });

        this.comms_shroud = this.add
            .image(
                this.center.x - this.game_size.x * 0.235,
                this.center.y - this.game_size.y * 0.05,
                'comms_shroud'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.power_shroud = this.add
            .image(
                this.center.x + this.game_size.x * 0.009,
                this.center.y - this.game_size.y * 0.05,
                'power_shroud'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.oxygen_shroud = this.add
            .image(
                this.center.x + this.game_size.x * 0.25,
                this.center.y - this.game_size.y * 0.05,
                'oxygen_shroud'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.setCommsShroudStatus();
        this.setOxygenShroudStatus();
        this.setPowerShroudStatus();
        this.timedEvent = this.time.addEvent({
            delay: 500,
            callback: this.updateShrouds,
            callbackScope: this,
            repeat: -1,
        });

        store.dispatch(setBackButtonCb());
    }

    openPowerKeypad() {
        const state = getPuzzleByKey('power-keypad', 'Command Deck');
        this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.KEYPAD, state);
    }

    openCommsKeypad() {
        const state = getPuzzleByKey('comms-keypad', 'Command Deck');
        this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.KEYPAD, state);
    }

    openOxygenKeypad() {
        const state = getPuzzleByKey('oxygen-keypad', 'Command Deck');
        this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.KEYPAD, state);
    }

    setCommsShroudStatus() {
        const state = getPuzzleByKey('comms-keypad', 'Command Deck');
        if (state.status === 'completed') {
            this.isCommsDone = true;
            this.comms_shroud.setVisible(true);
        }
    }

    setPowerShroudStatus() {
        const state = getPuzzleByKey('power-keypad', 'Command Deck');
        if (state.status === 'completed') {
            this.isPowerDone = true;
            this.power_shroud.setVisible(true);
        }
    }

    setOxygenShroudStatus() {
        const state = getPuzzleByKey('oxygen-keypad', 'Command Deck');
        if (state.status === 'completed') {
            this.isOxygenDone = true;
            this.oxygen_shroud.setVisible(true);
        }
    }

    updateShrouds() {
        if (this.isCommsDone && this.isOxygenDone && this.isPowerDone) {
            this.timedEvent.remove();
            return;
        }

        if (!this.isCommsDone) {
            this.setCommsShroudStatus();
        }
        if (!this.isOxygenDone) {
            this.setOxygenShroudStatus();
        }
        if (!this.isPowerDone) {
            this.setPowerShroudStatus();
        }
    }
}

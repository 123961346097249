import PHASER from 'phaser';

import store from 'store';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';
import { setBackButtonCb } from 'store/puzzleReducer/actions';

import { puzzleStateSocket } from 'services/websockets/index';

import CONSTANTS from '../../../constants';

export default class HexagonChamber extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.AIRLOCK.MIRROR_CHAMBER,
        });
        this.scaleFactor = { x: 1, y: 1 };
        this.gameSize = { x: 1, y: 1 };
        this.color = 'Green';
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzleData = data;
    }

    preload() {
        this.load.image(
            'background_chamber',
            '/assets/puzzles/mirror-chamber/chamber-bg.png'
        );
        this.load.image(
            'hexagon-green',
            '/assets/puzzles/mirror-chamber/hexagon-green.png'
        );
        this.load.image(
            'hexagon-orange',
            '/assets/puzzles/mirror-chamber/hexagon-orange.png'
        );
        this.load.image(
            'hexagon-blue',
            '/assets/puzzles/mirror-chamber/hexagon-blue.png'
        );
        this.load.image(
            'hexagon-yellow',
            '/assets/puzzles/mirror-chamber/hexagon-yellow.png'
        );
        this.load.image('hexagon-red', '/assets/puzzles/mirror-chamber/hexagon-red.png');
        this.load.image(
            'hexagon-purple',
            '/assets/puzzles/mirror-chamber/hexagon-purple.png'
        );
        this.load.image(
            'hexagon-grey',
            '/assets/puzzles/mirror-chamber/hexagon-grey.png'
        );
    }

    create() {
        this.mainBackground = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.gameSize.x = this.game.renderer.width;
        this.gameSize.y = this.game.renderer.height;
        this.scaleFactor.x = this.game.renderer.width / this.mainBackground.width;
        this.scaleFactor.y = this.game.renderer.height / this.mainBackground.height;

        this.mainBackground.setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.chamberBackground = this.add
            .image(this.center.x, this.center.y, 'background_chamber')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.hexagonGrey = this.add
            .image(this.center.x, this.center.y, 'hexagon-grey')
            .setScale(this.scaleFactor.x, this.scaleFactor.y);

        this.hexagonGreen = this.add
            .image(this.center.x, this.center.y, 'hexagon-green')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.hexagonOrange = this.add
            .image(this.center.x, this.center.y, 'hexagon-orange')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.hexagonBlue = this.add
            .image(this.center.x, this.center.y, 'hexagon-blue')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.hexagonYellow = this.add
            .image(this.center.x, this.center.y, 'hexagon-yellow')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.hexagonRed = this.add
            .image(this.center.x, this.center.y, 'hexagon-red')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.hexagonPurple = this.add
            .image(this.center.x, this.center.y, 'hexagon-purple')
            .setScale(this.scaleFactor.x, this.scaleFactor.y)
            .setVisible(false);

        this.showHexagonsOfColor(this.puzzleData.state?.color);

        store.dispatch(
            setBackButtonCb(() => {
                const puzzleData = getPuzzleByKey('cloud-chamber', 'Airlock');
                this.scene.start(CONSTANTS.SCENES.AIRLOCK.CLOUD_CHAMBER, puzzleData);
            })
        );

        puzzleStateSocket.onStateUpdate(data => {
            const {
                states: { state },
            } = data;
            this.showHexagonsOfColor(state.color);
        });
    }

    showHexagonsOfColor(color) {
        this.hideAllHexagons();
        if (color !== this.color) {
            this.sound.play('mirror_chamber_change');
            this.color = color;
        }

        switch (color) {
            case 'Grey':
                this.hexagonGrey.setVisible(true);
                break;
            case 'Blue':
                this.hexagonBlue.setVisible(true);
                break;
            case 'Green':
                this.hexagonGreen.setVisible(true);
                break;
            case 'Red':
                this.hexagonRed.setVisible(true);
                break;
            case 'Yellow':
                this.hexagonYellow.setVisible(true);
                break;
            case 'Purple':
                this.hexagonPurple.setVisible(true);
                break;
            case 'Orange':
                this.hexagonOrange.setVisible(true);
                break;
            default:
                this.hexagonGreen.setVisible(true);
                break;
        }
    }

    hideAllHexagons() {
        this.hexagonBlue.setVisible(false);
        this.hexagonGreen.setVisible(false);
        this.hexagonGrey.setVisible(false);
        this.hexagonOrange.setVisible(false);
        this.hexagonPurple.setVisible(false);
        this.hexagonRed.setVisible(false);
        this.hexagonYellow.setVisible(false);
    }
}

import PHASER from 'phaser';

import { ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { removeItem } from 'store/inventoryReducer/actions';
import { markPuzzleSolved, setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { Mixpanel } from 'services/mixpanel';

import CONSTANTS from '../../../constants';

export default class RoverInteriorKeypad extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.ROVER_INTERIOR_KEYPAD,
        });

        this.is_solved = false;
        this.code = '';
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
        this.max_char_limit = this.puzzle_data.solution.length;
    }

    preload() {
        this.loadAssets();
    }

    create() {
        this.createAssets();
        store.dispatch(
            setBackButtonCb(() => {
                this.code = '';
                const state = getPuzzleByKey('rover-puzzle', this.puzzle_data.room);
                this.scene.start(CONSTANTS.SCENES.MARS.ROVER_PUZZLE, state);
            })
        );
    }

    loadAssets() {
        this.load.image('re_bg', '/assets/puzzles/keypads/mars/interior/keypad-bg.png');

        if (this.puzzle_data.type === 'power') {
            this.load.image('power', '/assets/puzzles/keypads/command-deck/power.png');
        } else if (this.puzzle_data.type === 'comms') {
            this.load.image('comms', '/assets/puzzles/keypads/command-deck/comms.png');
        } else {
            this.load.image('oxygen', '/assets/puzzles/keypads/command-deck/oxygen.png');
        }

        this.loadKeypadAssets();
    }

    createAssets() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.re_bg = this.add
            .image(this.center.x, this.center.y, 're_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.codeField = this.add
            .text(
                this.center.x - this.game_size.x * 0.09,
                this.center.y - this.game_size.y * 0.31,
                '',
                { fontFamily: 'Ethnocentric', fontSize: 35, align: 'center' }
            )
            .setDepth(1)
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setFixedSize(150, 150);

        this.createKeypadAssets();

        this.incorrectImage = this.add
            .image(this.center.x, this.center.y, 'incorrect')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.incorrectText = this.add
            .text(this.center.x, this.center.y, 'Access Denied', {
                fontFamily: 'Ethnocentric',
                fontSize: 50,
                align: 'center',
                wordWrap: { width: 400, useAdvancedWrap: true },
            })
            .setOrigin(0.5)
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        if (this.puzzle_data.status === 'completed') {
            this.setSolvedState();
        }
    }

    loadKeypadAssets() {
        this.load.image('ri_key_0', '/assets/puzzles/keypads/mars/interior/key-0.png');
        this.load.image('ri_key_1', '/assets/puzzles/keypads/mars/interior/key-1.png');
        this.load.image('ri_key_2', '/assets/puzzles/keypads/mars/interior/key-2.png');
        this.load.image('ri_key_3', '/assets/puzzles/keypads/mars/interior/key-3.png');
        this.load.image('ri_key_4', '/assets/puzzles/keypads/mars/interior/key-4.png');
        this.load.image('ri_key_5', '/assets/puzzles/keypads/mars/interior/key-5.png');
        this.load.image('ri_key_6', '/assets/puzzles/keypads/mars/interior/key-6.png');
        this.load.image('ri_key_7', '/assets/puzzles/keypads/mars/interior/key-7.png');
        this.load.image('ri_key_8', '/assets/puzzles/keypads/mars/interior/key-8.png');
        this.load.image('ri_key_9', '/assets/puzzles/keypads/mars/interior/key-9.png');
        this.load.image('ri_erase', '/assets/puzzles/keypads/mars/interior/erase.png');
        this.load.image('ri_submit', '/assets/puzzles/keypads/mars/interior/submit.png');
    }

    createKeypadAssets() {
        this.key_1 = this.add
            .image(
                this.center.x - this.game_size.x * 0.085,
                this.center.y - this.game_size.y * 0.12,
                'ri_key_1'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(1);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_1.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_2 = this.add
            .image(
                this.center.x - this.game_size.x * 0.004,
                this.center.y - this.game_size.y * 0.12,
                'ri_key_2'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(2);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_2.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_3 = this.add
            .image(
                this.center.x + this.game_size.x * 0.08,
                this.center.y - this.game_size.y * 0.12,
                'ri_key_3'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(3);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_3.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_4 = this.add
            .image(
                this.center.x - this.game_size.x * 0.085,
                this.center.y + this.game_size.y * 0.02,
                'ri_key_4'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(4);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_4.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_5 = this.add
            .image(
                this.center.x - this.game_size.x * 0.004,
                this.center.y + this.game_size.y * 0.02,
                'ri_key_5'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(5);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_5.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_6 = this.add
            .image(
                this.center.x + this.game_size.x * 0.08,
                this.center.y + this.game_size.y * 0.02,
                'ri_key_6'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(6);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_6.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_7 = this.add
            .image(
                this.center.x - this.game_size.x * 0.085,
                this.center.y + this.game_size.y * 0.16,
                'ri_key_7'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(7);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_7.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_8 = this.add
            .image(
                this.center.x - this.game_size.x * 0.004,
                this.center.y + this.game_size.y * 0.16,
                'ri_key_8'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(8);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_8.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_9 = this.add
            .image(
                this.center.x + this.game_size.x * 0.08,
                this.center.y + this.game_size.y * 0.16,
                'ri_key_9'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(9);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_9.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.key_0 = this.add
            .image(
                this.center.x - this.game_size.x * 0.085,
                this.center.y + this.game_size.y * 0.3,
                'ri_key_0'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.numKeyPressed(0);
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.key_0.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.erase = this.add
            .image(
                this.center.x - this.game_size.x * 0.004,
                this.center.y + this.game_size.y * 0.3,
                'ri_erase'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.eraseKeyPressed();
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.erase.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });

        this.submit = this.add
            .image(
                this.center.x + this.game_size.x * 0.08,
                this.center.y + this.game_size.y * 0.3,
                'ri_submit'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .on('pointerdown', () => {
                this.submitKeyPressed();
            })
            .on('pointerout', function () {
                this.clearTint();
            })
            .on('pointerup', function () {
                this.clearTint();
            });
        this.submit.on('pointerdown', function () {
            this.setTint(0x8e8e8e);
        });
    }

    numKeyPressed(value) {
        if (!this.is_solved) {
            this.sound.play('key');
            if (this.code.length < this.max_char_limit) {
                this.code += value;
                this.codeField.setText(this.code);
            }
        }
    }

    eraseKeyPressed() {
        if (!this.is_solved) {
            this.sound.play('erase');
            if (this.code !== '' && this.code.length > 0) {
                this.code = this.code.slice(0, this.code.length - 1);
                this.codeField.setText(this.code);
            }
        }
    }

    submitKeyPressed() {
        if (this.is_solved) return;

        if (this.code === this.puzzle_data.solution) {
            this.is_solved = true;
            this.sound.play('success');
            store.dispatch(markPuzzleSolved(this.puzzle_data.id));
            store.dispatch(removeItem(ITEM_ID_MAP.ROVER_BACKGROUND));
            // < --- MIXPANEL ANALYTICS
            Mixpanel.track('Puzzle Complete', {
                ...this.puzzle_data,
                puzzle: 'ROVER INTERIOR',
            });
            // --- >
        } else {
            this.code = '';
            this.codeField.setText(this.code);

            this.sound.play('failure');

            this.incorrectImage.setVisible(true);
            this.incorrectText.setVisible(true);
            this.disableKeys();

            this.timedEvent = this.time.addEvent({
                delay: 3000,
                callback: this.hideIncorrectAnswerImage,
                callbackScope: this,
                repeat: 0,
            });
        }
    }

    hideIncorrectAnswerImage() {
        this.incorrectImage.setVisible(false);
        this.incorrectText.setVisible(false);

        this.enableKeys();
    }

    disableKeys() {
        this.key_0.disableInteractive();
        this.key_1.disableInteractive();
        this.key_2.disableInteractive();
        this.key_3.disableInteractive();
        this.key_4.disableInteractive();
        this.key_5.disableInteractive();
        this.key_6.disableInteractive();
        this.key_7.disableInteractive();
        this.key_8.disableInteractive();
        this.key_9.disableInteractive();
        this.erase.disableInteractive();
        this.submit.disableInteractive();
    }

    enableKeys() {
        this.key_0.setInteractive();
        this.key_1.setInteractive();
        this.key_2.setInteractive();
        this.key_3.setInteractive();
        this.key_4.setInteractive();
        this.key_5.setInteractive();
        this.key_6.setInteractive();
        this.key_7.setInteractive();
        this.key_8.setInteractive();
        this.key_9.setInteractive();
        this.erase.setInteractive();
        this.submit.setInteractive();
    }

    setSolvedState() {
        this.is_solved = true;
        this.codeField.setText(this.puzzle_data.solution);
    }
}

import PHASER from 'phaser';

import store from 'store';
import { setBackButtonCb } from 'store/puzzleReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import { puzzlesSocket } from 'services/websockets/index';

import CONSTANTS from '../../../constants';

export default class LaunchSequence extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_SEQUENCE,
        });
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;

        puzzlesSocket.onUpdate(this.puzzle_data.id, data => {
            const state = data.payload;
            this.showButtonsInSequence(state);
        });
    }

    preload() {
        this.load.image('ls_bg', '/assets/puzzles/launch-sequence/ls-bg.png');
        this.load.image('a1_on', '/assets/puzzles/launch-sequence/a1.png');
        this.load.image('c1_on', '/assets/puzzles/launch-sequence/c1.png');
        this.load.image('c2_on', '/assets/puzzles/launch-sequence/c2.png');
        this.load.image('c3_on', '/assets/puzzles/launch-sequence/c3.png');
        this.load.image('c4_on', '/assets/puzzles/launch-sequence/c4.png');
        this.load.image('m1_on', '/assets/puzzles/launch-sequence/m1.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.ls_bg = this.add
            .image(this.center.x, this.center.y, 'ls_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // A1
        this.a1_on = this.add
            .image(
                this.center.x - this.game_size.x * 0.17,
                this.center.y + this.game_size.y * 0.205,
                'a1_on'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C1
        this.c1_on = this.add
            .image(
                this.center.x + this.game_size.x * 0.053,
                this.center.y + this.game_size.y * 0.205,
                'c1_on'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C2
        this.c2_on = this.add
            .image(
                this.center.x + this.game_size.x * 0.164,
                this.center.y + this.game_size.y * 0.205,
                'c2_on'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C3
        this.c3_on = this.add
            .image(
                this.center.x + this.game_size.x * 0.275,
                this.center.y + this.game_size.y * 0.205,
                'c3_on'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // C4
        this.c4_on = this.add
            .image(
                this.center.x - this.game_size.x * 0.058,
                this.center.y + this.game_size.y * 0.205,
                'c4_on'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // M1
        this.m1_on = this.add
            .image(
                this.center.x - this.game_size.x * 0.282,
                this.center.y + this.game_size.y * 0.205,
                'm1_on'
            )
            .setVisible(false)
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.showButtonsInSequence();

        store.dispatch(
            setBackButtonCb(() => {
                const state = getPuzzleByKey('command-center', this.puzzle_data.room);
                this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.COMMAND_CENTER, state);
            })
        );
    }

    showButtonsInSequence(state) {
        let puzzleState;
        let buttonStates;
        if (state) {
            buttonStates = state.buttonStates;
        } else {
            console.log('State Not Available!');
            puzzleState = getPuzzleByKey('launch-sequence', 'Command Deck');
            buttonStates = puzzleState.state.buttonStates;
        }
        if (buttonStates) {
            Object.entries(buttonStates).forEach(([key, value]) => {
                this.SetButtonVisibility(key, value);
            });
        }
    }

    SetButtonVisibility(button, state) {
        switch (button) {
            case 'A_1':
                this.a1_on.setVisible(state);
                break;
            case 'C_1':
                this.c1_on.setVisible(state);
                break;
            case 'C_2':
                this.c2_on.setVisible(state);
                break;
            case 'C_3':
                if (state) {
                    this.c3_on.setVisible(state);
                }
                this.c3_on.setVisible(state);
                break;
            case 'C_4':
                this.c4_on.setVisible(state);
                break;
            case 'M_1':
                this.m1_on.setVisible(state);
                break;
            // no default
        }
    }
}

import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Loader from 'react-loader-spinner';

import { hostLogin, playerLogin } from 'store/authReducer/actions';

import { sessionStorageWithExpiry } from 'utils/storage';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MarsRoute = ({
    authenticatedUser,
    component: Component,
    hostLogin,
    playerLogin,
    ...rest
}) => {
    const [loading, setLoading] = useState(true);
    const query = useQuery();

    // Get login details from query parameters for host
    const token = query.get('token');
    const password = query.get('password');

    // Get current session login info from sessionStorage for player
    const playerSession = sessionStorageWithExpiry.getItem('currentSession');

    useEffect(() => {
        if (authenticatedUser.id) {
            setLoading(false);
            return;
        }

        async function onLoginHost() {
            await hostLogin(token, password);
            setLoading(false);
        }

        async function onLoginPlayer() {
            await playerLogin(playerSession);
            setLoading(false);
        }

        if (token && token !== '' && password && password !== '') {
            onLoginHost();
        } else if (playerSession) {
            onLoginPlayer();
        } else {
            setLoading(false);
        }
    }, [hostLogin, password, playerLogin, playerSession, token, authenticatedUser.id]);

    if (!authenticatedUser.id && !token && !loading) return <Redirect to="/login" />;
    if (loading)
        return (
            <div className="app-center">
                <Loader type="Grid" color="#7cf5fc" height={100} width={100} />
                <span>Logging in...</span>
            </div>
        );
    if (password === 'generate') return <Redirect to="/host/generate" />;

    return authenticatedUser.id ? (
        <Route {...rest} render={props => <Component {...props} />} />
    ) : (
        <div className="app-center">Loading...</div>
    );
};

const mapStateToProps = ({ authReducer }) => ({
    authenticatedUser: authReducer.user,
});

const mapDispatchToProps = {
    hostLogin: (token, password) => hostLogin(token, password),
    playerLogin: player => playerLogin(player),
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MarsRoute);

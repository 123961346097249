import mixpanel from 'mixpanel-browser';

mixpanel.init('dfe563d5b027d358e996bd262665545e');

const environment = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;
const isValidEnvironment = environment === 'production' || environment === 'local';

const actions = {
    identify: id => {
        if (isValidEnvironment) mixpanel.identify(id);
    },
    alias: id => {
        if (isValidEnvironment) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (isValidEnvironment) mixpanel.track(name, props);
    },
    people: {
        set: props => {
            if (isValidEnvironment) mixpanel.people.set(props);
        },
    },
};

export const Mixpanel = actions;

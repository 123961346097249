import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Timer from 'react-compound-timer';
import { connect } from 'react-redux';
import cn from 'classnames';
import useSound from 'use-sound';

import {
    castVote,
    checkWin,
    overwrite,
    setVoteCallback,
    incrementVote,
} from 'store/voteReducer/actions';
import { setMyStatus, STATUS_TYPES } from 'store/statusReducer/actions';

import { playerSocket, voteSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';
import { usePrevious } from 'services/customHooks';

import VoteBorder from 'assets/dashboardScreen/YellowStripes.png';
import VoteButtonSound from 'assets/sounds/vote.wav';
import VoteSuccessSound from 'assets/sounds/vote-success.wav';
import VoteFailSound from 'assets/sounds/vote-failure.wav';
import VoteInitSound from 'assets/sounds/vote-init.wav';

import VoteStatement from './components/VoteStatement';

import './styles.scss';

const VoteScreen = ({
    authenticatedUser,
    voteData,
    castVote,
    checkWin,
    selfVote,
    overwrite,
    isPlayerSocketReady,
    setMyStatus,
    setVoteCallback,
    voteCountYes,
    voteCountNo,
    incrementVote,
}) => {
    const [yesWidth, setYesWidth] = useState(0);
    const [noWidth, setNoWidth] = useState(0);
    const [currentVote, _setCurrentVote] = useState(null);
    const [voteNotDone, _setVoteNotDone] = useState(true);
    const [disableOverrideYes, setOverRideDisableYes] = useState(false);
    const [disableOverrideNo, setOverRideDisableNo] = useState(false);
    const currentVoteRef = useRef(currentVote);
    const voteNotDoneRef = useRef(voteNotDone);
    const timeSetter = useRef(null);
    const [voteButtonSound] = useSound(VoteButtonSound, { volume: 0.7 });
    const prevVoteData = usePrevious(voteData);

    const voteOptions = useMemo(() => {
        return {
            YES: 'yes',
            NO: 'no',
        };
    }, []);

    const yesStyle = {
        width: `${yesWidth}%`,
    };

    const noStyle = {
        width: `${noWidth}%`,
    };

    const setCurrentVote = vote => {
        currentVoteRef.current = vote;
        _setCurrentVote(vote);
    };

    const setVoteNotDone = value => {
        voteNotDoneRef.current = value;
        _setVoteNotDone(value);
    };

    const playAudio = audioName => {
        const audio = new Audio(audioName);
        const promise = audio.play();
        audio.volume = 0.7;

        if (promise !== undefined) {
            promise.then(() => {}).catch(console.error);
        }
    };

    const calculateResult = useCallback(() => {
        if (voteData.voteId) {
            checkWin(voteData.voteId);
        } else if (voteData.id) {
            checkWin(voteData.id);
        }
    }, [checkWin, voteData]);

    const handleSubmit = useCallback(
        vote => {
            voteButtonSound();
            const voteId = voteData.voteId ? voteData.voteId : voteData.id || null;
            const selection = vote;
            if (voteId && selection) {
                castVote(voteId, selection);
                voteSocket.castVote(selection, voteId, authenticatedUser.id);
                incrementVote(vote);
            }
            Mixpanel.track('Cast Vote', { statement: voteData.statement, selection });
            setVoteNotDone(false);
        },
        [authenticatedUser, castVote, incrementVote, voteButtonSound, voteData]
    );

    const onSubmitPress = useCallback(() => {
        if (currentVoteRef.current && voteNotDoneRef.current)
            handleSubmit(currentVoteRef.current);
    }, [handleSubmit]);

    useEffect(() => {
        playAudio(VoteInitSound);
        setVoteCallback(voteSuccess => {
            if (voteSuccess) {
                playAudio(VoteSuccessSound);
                Mixpanel.track('Vote Successful');
            } else {
                playAudio(VoteFailSound);
                Mixpanel.track('Vote Unsuccessful');
            }
        });
    }, [setVoteCallback]);

    useEffect(() => {
        if (isPlayerSocketReady) {
            if (authenticatedUser.isPlayer) {
                playerSocket.sendAction(
                    STATUS_TYPES.ADDING_VOTE,
                    authenticatedUser,
                    authenticatedUser.id
                );
                setMyStatus({
                    user: authenticatedUser,
                    senderId: authenticatedUser.id,
                    type: STATUS_TYPES.ADDING_VOTE,
                });
            }
        }

        voteSocket.onVoteEnd(calculateResult);
    }, [authenticatedUser, calculateResult, isPlayerSocketReady, setMyStatus]);

    useEffect(() => {
        const totalYeses = voteCountYes;
        const totalNos = voteCountNo;
        const totalVotes = totalNos + totalYeses;
        const calculatedYesWidth = (totalYeses / totalVotes) * 100;
        const calculatedNoWidth = (totalNos / totalVotes) * 100;
        setNoWidth(calculatedNoWidth);
        setYesWidth(calculatedYesWidth);
    }, [voteCountNo, voteCountYes]);

    useEffect(() => {
        if (prevVoteData?.voteId !== voteData.voteId) {
            if (selfVote === true) {
                handleSubmit(voteOptions.YES);
            }
        }
    }, [handleSubmit, selfVote, voteOptions, voteData, prevVoteData]);

    useEffect(() => {
        timeSetter.current.click();

        if (voteData.timerValue === 1000) {
            onSubmitPress();
        }
    }, [onSubmitPress, voteData.timerValue]);

    const callOverWrite = (voteData, selection) => {
        if (selection === voteOptions.YES) {
            setOverRideDisableNo(true);
        } else {
            setOverRideDisableYes(true);
        }
        if (voteData.voteId) {
            overwrite(voteData.voteId, selection);
        } else if (voteData.id) {
            overwrite(voteData.id, selection);
        }

        Mixpanel.track('Host: Override Vote', { ...voteData, selection });
    };

    const formatTime = value => {
        return `${value < 10 ? `0${value}` : value}`;
    };

    const timerValue = Number.isNaN(voteData?.timerValue) ? 15000 : voteData.timerValue;

    return (
        <div>
            {!authenticatedUser.isPlayer ? (
                <div className="voting-container">
                    <div className="heading host">
                        <span>HAL Control Vote</span>
                    </div>
                    <img src={VoteBorder} alt="chatbox border" />
                    <div className="vote-box">
                        <VoteStatement statement={voteData.statement} />
                        <div className="vote-progress">
                            <span className="vote-title">YES</span>
                            <div className="player-vote" style={yesStyle} />
                            <span className="vote-count yes">
                                {voteCountYes} Vote
                                {voteCountYes !== 1 ? 's' : ''}
                            </span>
                        </div>
                        <div className="vote-progress">
                            <span className="vote-title">NO</span>
                            <div className="player-vote" style={noStyle} />
                            <span className="vote-count no">
                                {voteCountNo} Vote{voteCountNo !== 1 ? 's' : ''}
                            </span>
                        </div>
                    </div>
                    <div className="override-wrapper">
                        <span className="override-heading">OVERRIDE</span>
                        <div className="override-buttons">
                            <button
                                className={cn(
                                    {
                                        'action-not-allowed': disableOverrideYes,
                                    },
                                    'yes override-host'
                                )}
                                onClick={() => callOverWrite(voteData, voteOptions.YES)}
                                disabled={disableOverrideYes || disableOverrideNo}
                                aria-label="Override Vote Yes"
                            />
                            <button
                                className={cn(
                                    {
                                        'action-not-allowed': disableOverrideNo,
                                    },
                                    'no override-host'
                                )}
                                onClick={() => callOverWrite(voteData, voteOptions.NO)}
                                disabled={disableOverrideYes || disableOverrideNo}
                                aria-label="Override Vote No"
                            />
                        </div>
                    </div>
                    <div className="hosts time-remaining">
                        <div className="hosts time-heading">TIME REMAINING</div>
                        <div className="hosts time-value">
                            <Timer
                                initialTime={timerValue}
                                formatValue={formatTime}
                                startImmediately={false}
                            >
                                {({ setTime }) => (
                                    <>
                                        <Timer.Minutes />
                                        :
                                        <Timer.Seconds />
                                        <button
                                            aria-label="Set Time"
                                            aria-hidden="true"
                                            hidden
                                            ref={timeSetter}
                                            onClick={() => setTime(timerValue)}
                                        />
                                    </>
                                )}
                            </Timer>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="voting-container">
                    <div className="heading">
                        <span>HAL Control Vote</span>
                    </div>
                    <img src={VoteBorder} alt="chatbox border" />
                    <div className="vote-box">
                        <VoteStatement statement={voteData.statement} />
                        {voteNotDone ? (
                            <div className="new-voting-buttons">
                                <label className="vote-button" htmlFor="yes-vote">
                                    <input
                                        id="yes-vote"
                                        type="radio"
                                        name="vote"
                                        onChange={() => setCurrentVote(voteOptions.YES)}
                                    />
                                    <span className="checkmark" />
                                    <span className="player vote-title">YES</span>
                                </label>
                                <label className="vote-button" htmlFor="no-vote">
                                    <input
                                        id="no-vote"
                                        type="radio"
                                        name="vote"
                                        onChange={() => setCurrentVote(voteOptions.NO)}
                                    />
                                    <span className="checkmark" />
                                    <span className="player vote-title">NO</span>
                                </label>
                                <button
                                    className="submit"
                                    disabled={!currentVote}
                                    onClick={onSubmitPress}
                                >
                                    Submit
                                </button>
                            </div>
                        ) : (
                            <div className="new-voting-results">
                                <div className="vote-progress new">
                                    <span className="vote-title">YES</span>
                                    <div className="player-vote" style={yesStyle} />
                                    <span className="vote-count yes">
                                        {voteCountYes} Vote
                                        {voteCountYes !== 1 ? 's' : ''}
                                    </span>
                                </div>
                                <div className="vote-progress new">
                                    <span className="vote-title">NO</span>
                                    <div className="player-vote" style={noStyle} />
                                    <span className="vote-count no">
                                        {voteCountNo} Vote
                                        {voteCountNo !== 1 ? 's' : ''}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="time-remaining">
                        <div className="time-heading">TIME REMAINING</div>
                        <div className="time-value">
                            <Timer
                                initialTime={timerValue}
                                formatValue={formatTime}
                                startImmediately={false}
                            >
                                {({ setTime }) => (
                                    <>
                                        <Timer.Minutes />
                                        :
                                        <Timer.Seconds />
                                        <button
                                            aria-label="Set Time"
                                            aria-hidden="true"
                                            hidden
                                            ref={timeSetter}
                                            onClick={() => setTime(timerValue)}
                                        />
                                    </>
                                )}
                            </Timer>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ authReducer, voteReducer, interactionReducer }) => ({
    authenticatedUser: authReducer.user,
    voteData: voteReducer.voteData,
    voteHistory: voteReducer.voteHistory,
    selfVote: interactionReducer.selfVote,
    voteCountYes: voteReducer.yesCount,
    voteCountNo: voteReducer.noCount,
});

const mapDispatchToProps = {
    castVote: (voteID, selection) => castVote(voteID, selection),
    checkWin: voteID => checkWin(voteID),
    overwrite: (voteId, selection) => overwrite(voteId, selection),
    setMyStatus: status => setMyStatus(status),
    setVoteCallback: callback => setVoteCallback(callback),
    incrementVote: selection => incrementVote(selection),
};

export default connect(mapStateToProps, mapDispatchToProps)(VoteScreen);

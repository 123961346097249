const VOTE_SERVICE = '/votes';

const CAST = `${VOTE_SERVICE}/cast`;
const OVERWRITE = `${VOTE_SERVICE}/overwrite`;
const FETCH = `${VOTE_SERVICE}/game`;
const RESULT = `${VOTE_SERVICE}/result`;
const GET_CURRENT_SESSION_VOTE_DATA = `${VOTE_SERVICE}`;

const vote = {
    CAST,
    OVERWRITE,
    FETCH,
    RESULT,
    GET_CURRENT_SESSION_VOTE_DATA,
};

export default vote;

import React, { useEffect, useState, useLayoutEffect } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import { getFontSize } from 'utils/stringUtils';
import { isArrayNotEqual } from 'utils/arrayUtils';

import { fetchAllInventory } from 'store/inventoryReducer/actions';
import { setVoteOnResult, setVoteScreenVisible } from 'store/interactionReducer/actions';
import { setVotingData, incrementVote } from 'store/voteReducer/actions';

import { inventorySocket, voteSocket } from 'services/websockets/index';
import { Mixpanel } from 'services/mixpanel';
import { usePrevious } from 'services/customHooks';

import UnexpandedBorder from 'assets/dashboardScreen/unexpanded_Inventory_ContainerBorder.png';
import OpenItemSound from 'assets/sounds/open-item.wav';
import CloseItemSound from 'assets/sounds/close-item.wav';
import ItemAddedSound from 'assets/sounds/item-added.wav';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const UnexpandedInventory = ({
    authenticatedUser,
    inventoryItems,
    fetchInventory,
    isVoteSocketReady,
    isInventorySocketReady,
    setVoteOnResult,
    setVotingData,
    setVoteScreenVisible,
    incrementVote,
}) => {
    const [openItemSound] = useSound(OpenItemSound, { volume: 0.7 });
    const [closeItemSound] = useSound(CloseItemSound, { volume: 0.7 });
    const [itemAddedSound] = useSound(ItemAddedSound, { volume: 0.7 });
    const prevInventory = usePrevious(inventoryItems);

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
    };

    const resizeFont = () => {
        const actions = document.querySelectorAll('.selectable-buttons button');
        actions.forEach(action => {
            action.style.fontSize = getFontSize(action.textContent.length, 15, 1.05);
        });

        const overlay = document.querySelectorAll('.item-overlay span');
        overlay.forEach(item => {
            const longestText = item.textContent
                .split(' ')
                .reduce((a, b) => (a.length > b.length ? a : b));
            item.style.fontSize = getFontSize(longestText.length, 8, 1.25);
        });
    };

    useLayoutEffect(() => {
        if (isArrayNotEqual(inventoryItems, prevInventory)) {
            resizeFont();
            if (inventoryItems.length - prevInventory?.length === 1) {
                itemAddedSound();
            }
        }
    }, [inventoryItems, itemAddedSound, prevInventory]);

    useEffect(() => {
        if (isVoteSocketReady) {
            voteSocket.onVoteTimerUpdate(data => {
                if (authenticatedUser.id !== data.startedBy) {
                    setVoteOnResult(true);
                }
                setVotingData(data);
                setVoteScreenVisible();
            });

            voteSocket.onNewVote(data => {
                incrementVote(data.selection);
            });

            voteSocket.onDisconnect();
        }
    }, [
        authenticatedUser,
        incrementVote,
        isVoteSocketReady,
        setVoteOnResult,
        setVoteScreenVisible,
        setVotingData,
    ]);

    useEffect(() => {
        if (isInventorySocketReady) {
            inventorySocket.onUpdate(fetchInventory);
            inventorySocket.onDisconnect();
        }
    }, [fetchInventory, isInventorySocketReady]);

    const [popup, setPopup] = useState(false);
    const [itemZoomed, setItemZoomed] = useState({ url: '', title: '' });

    const expandItem = item => {
        openItemSound();

        const { url } = item;
        const imageName = url.split('.').splice(-2)[0].split('/').splice(-1)[0];

        setItemZoomed({
            url: url.replace(imageName, `${imageName}_HD`),
            title: item.title,
        });
        setPopup(true);

        Mixpanel.track('Inventory View', { ...item });
    };

    const contractItem = () => {
        closeItemSound();
        setPopup(false);
    };

    useEffect(() => {
        fetchInventory();
        window.addEventListener('resize', resizeFont);

        return () => {
            window.removeEventListener('resize', resizeFont);
        };
    }, [fetchInventory]);

    return (
        <div className="unexpanded-inventory-container">
            <div className="unexpanded-items-container">
                <img src={UnexpandedBorder} alt="Unexpanded container border" />
                <div className="items-buttons">
                    <Slider {...settings}>
                        {inventoryItems.map((item, index) =>
                            item.item ? (
                                <button
                                    className="item"
                                    key={index}
                                    onClick={() => expandItem(item.item)}
                                >
                                    <div className="item-text">
                                        <span>{item.item.title}</span>
                                    </div>
                                    <div className="item-image">
                                        <img src={item.item.url} alt={item.item.title} />
                                    </div>
                                    <div className="item-overlay">
                                        <div>
                                            <span>{item.item.title}</span>
                                        </div>
                                    </div>
                                </button>
                            ) : (
                                <div key={index} />
                            )
                        )}
                    </Slider>
                </div>
            </div>
            {popup && (
                <div className="popup">
                    <img src={itemZoomed.url} alt={itemZoomed.title} />
                    <button
                        className="cross"
                        onClick={contractItem}
                        aria-label="Close Popup"
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ inventoryReducer, authReducer }) => ({
    inventoryItems: inventoryReducer.inventoryItems,
    authenticatedUser: authReducer.user,
});

const mapDispatchToProps = {
    fetchInventory: () => fetchAllInventory(),
    setVoteOnResult: value => setVoteOnResult(value),
    setVotingData: data => setVotingData(data),
    setVoteScreenVisible: () => setVoteScreenVisible(),
    incrementVote: selection => incrementVote(selection),
};

export default connect(mapStateToProps, mapDispatchToProps)(UnexpandedInventory);

import PHASER from 'phaser';

import { PUZZLE_VIDEOS_URL, ITEM_ID_MAP } from 'constants.js';

import store from 'store';
import { setBackButtonCb } from 'store/puzzleReducer/actions';
import { setCurrentVideos } from 'store/videosReducer/actions';
import { unlockItem } from 'store/inventoryReducer/actions';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';

import CONSTANTS from '../../../constants';

export default class LifeSupportSystems extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.COMMAND_DECK.COMMAND_CENTER,
        });

        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('cc_bg', '/assets/puzzles/command-center/cc-bg.png');

        this.load.image(
            'cc_comms_unsolved',
            '/assets/puzzles/command-center/cc-comms-unsolved.png'
        );
        this.load.image(
            'cc_power_unsolved',
            '/assets/puzzles/command-center/cc-power-unsolved.png'
        );
        this.load.image(
            'cc_oxygen_unsolved',
            '/assets/puzzles/command-center/cc-oxygen-unsolved.png'
        );
        this.load.image(
            'cc_comms_solved',
            '/assets/puzzles/command-center/cc-comms-solved.png'
        );
        this.load.image(
            'cc_power_solved',
            '/assets/puzzles/command-center/cc-power-solved.png'
        );
        this.load.image(
            'cc_oxygen_solved',
            '/assets/puzzles/command-center/cc-oxygen-solved.png'
        );
        this.load.image(
            'cc_oxygen_status4',
            '/assets/puzzles/command-center/cc-oxygen-status4.png'
        );

        this.load.image('lc_small', '/assets/puzzles/command-center/lc-small.png');
        this.load.image('ls_small', '/assets/puzzles/command-center/ls-small.png');
        this.load.image('rover_small', '/assets/puzzles/command-center/rover-small.png');
        this.load.image('rover_big', '/assets/puzzles/command-center/rover-big.png');

        this.load.image(
            'gauges_small',
            '/assets/puzzles/command-center/gauges-small.png'
        );
        this.load.image(
            'gauges_small_non_glow',
            '/assets/puzzles/command-center/gauges-small-non-glow.png'
        );
        this.load.image('gauges_big', '/assets/puzzles/command-center/gauges-big.png');

        this.load.image('trans_small', '/assets/puzzles/command-center/trans-small.png');
        this.load.image('trans_big', '/assets/puzzles/command-center/trans-big.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.cc_bg = this.add
            .image(this.center.x, this.center.y, 'cc_bg')
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // comms
        this.cc_comms_unsolved = this.add
            .image(
                this.center.x + this.game_size.x * 0.29,
                this.center.y - this.game_size.y * 0.21,
                'cc_comms_unsolved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.cc_comms_solved = this.add
            .image(
                this.center.x + this.game_size.x * 0.29,
                this.center.y - this.game_size.y * 0.21,
                'cc_comms_solved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // power
        this.cc_power_unsolved = this.add
            .image(
                this.center.x - this.game_size.x * 0.29,
                this.center.y - this.game_size.y * 0.21,
                'cc_power_unsolved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.cc_power_solved = this.add
            .image(
                this.center.x - this.game_size.x * 0.29,
                this.center.y - this.game_size.y * 0.21,
                'cc_power_solved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // oxygen
        this.cc_oxygen_unsolved = this.add
            .image(
                this.center.x - this.game_size.x * 0.275,
                this.center.y + this.game_size.y * 0.21,
                'cc_oxygen_unsolved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        this.cc_oxygen_solved = this.add
            .image(
                this.center.x - this.game_size.x * 0.275,
                this.center.y + this.game_size.y * 0.21,
                'cc_oxygen_solved'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y);

        // launch
        this.lc_small = this.add
            .image(this.center.x, this.center.y + this.game_size.y * 0.19, 'lc_small')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.openLaunchCoordinates();
            });

        this.ls_small = this.add
            .image(this.center.x, this.center.y + this.game_size.y * 0.19, 'ls_small')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(false)
            .on('pointerdown', () => {
                this.openLaunchSequence();
            });

        // rover
        this.rover_small = this.add
            .image(this.center.x, this.center.y - this.game_size.y * 0.12, 'rover_small')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(true)
            .on('pointerdown', () => {
                this.showRoverStatus();
            });

        this.rover_big = this.add
            .image(this.center.x, this.center.y, 'rover_big')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false)
            .setDepth(1);

        // status4 oxygen
        this.cc_status4_oxygen = this.add
            .image(
                this.center.x + this.game_size.x * 0.275,
                this.center.y + this.game_size.y * 0.21,
                'cc_oxygen_status4'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        // status4 gauges
        this.cc_status4_gauges_small = this.add
            .image(
                this.center.x + this.game_size.x * 0.275,
                this.center.y + this.game_size.y * 0.21,
                'gauges_small'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(true)
            .on('pointerdown', () => {
                this.showGaugesLargeView();
            });

        this.cc_status4_gauges_big = this.add
            .image(this.center.x, this.center.y, 'gauges_big')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false)
            .setDepth(1);

        // status4 translation
        this.cc_status4_trans_small = this.add
            .image(
                this.center.x + this.game_size.x * 0.275,
                this.center.y + this.game_size.y * 0.21,
                'trans_small'
            )
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setInteractive()
            .setVisible(true)
            .on('pointerdown', () => {
                this.showTranslationLargeView();
            });

        this.cc_status4_trans_big = this.add
            .image(this.center.x, this.center.y, 'trans_big')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false)
            .setDepth(1);

        this.setLaunchStatus();
        this.setPowerStatus();
        this.setCommsStatus();
        this.setOxygenStatus();
        this.setStatus4();

        store.dispatch(setBackButtonCb());

        this.timedEvent = this.time.addEvent({
            delay: 500, // ms
            callback: this.updateStatuses,
            callbackScope: this,
            repeat: -1,
        });
    }

    setLaunchStatus() {
        const state = getPuzzleByKey('launch-coordinates', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.ls_small.setVisible(true);
            this.lc_small.setVisible(false);
        } else {
            this.ls_small.setVisible(false);
            this.lc_small.setVisible(true);
        }
    }

    openLaunchCoordinates() {
        const state = getPuzzleByKey('launch-coordinates', this.puzzle_data.room);
        this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_COORDINATES, state);
    }

    openLaunchSequence() {
        const state = getPuzzleByKey('launch-sequence', this.puzzle_data.room);
        this.scene.start(CONSTANTS.SCENES.COMMAND_DECK.LAUNCH_SEQUENCE, state);
    }

    showRoverStatus() {
        const state = getPuzzleByKey('rover-interior-keypad', 'Mars');

        if (state.status === 'completed') {
            const morseCodeVideoUrl = `${PUZZLE_VIDEOS_URL}Video-14_hls/index.m3u8`;
            store.dispatch(setCurrentVideos([morseCodeVideoUrl]));
        } else {
            this.rover_big.setVisible(true);
            store.dispatch(
                setBackButtonCb(() => {
                    this.rover_big.setVisible(false);
                    store.dispatch(setBackButtonCb());
                })
            );
        }
    }

    showGaugesLargeView() {
        store.dispatch(unlockItem(ITEM_ID_MAP.PRESSURE_GAUGES_MAP));
        this.cc_status4_gauges_big.setVisible(true);
        store.dispatch(
            setBackButtonCb(() => {
                this.cc_status4_gauges_big.setVisible(false);
                store.dispatch(setBackButtonCb());
            })
        );
    }

    showTranslationLargeView() {
        this.cc_status4_trans_big.setVisible(true);
        store.dispatch(
            setBackButtonCb(() => {
                this.cc_status4_trans_big.setVisible(false);
                store.dispatch(setBackButtonCb());
            })
        );
    }

    setPowerStatus() {
        const state = getPuzzleByKey('power-keypad', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.cc_power_solved.setVisible(true);
            this.cc_power_unsolved.setVisible(false);
        } else {
            this.cc_power_solved.setVisible(false);
            this.cc_power_unsolved.setVisible(true);
        }
    }

    setCommsStatus() {
        const state = getPuzzleByKey('comms-keypad', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.cc_comms_solved.setVisible(true);
            this.cc_comms_unsolved.setVisible(false);
        } else {
            this.cc_comms_solved.setVisible(false);
            this.cc_comms_unsolved.setVisible(true);
        }
    }

    setOxygenStatus() {
        const state = getPuzzleByKey('oxygen-keypad', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.cc_oxygen_solved.setVisible(true);
            this.cc_oxygen_unsolved.setVisible(false);
        } else {
            this.cc_oxygen_solved.setVisible(false);
            this.cc_oxygen_unsolved.setVisible(true);
        }
    }

    setStatus4() {
        this.cc_status4_oxygen.setVisible(true);
        this.cc_status4_gauges_small.setVisible(false);
        this.cc_status4_trans_small.setVisible(false);

        let state = getPuzzleByKey('oxygen-keypad', this.puzzle_data.room);

        if (state.status === 'completed') {
            this.cc_status4_gauges_small.setVisible(true);
            this.cc_status4_oxygen.setVisible(false);
            this.cc_status4_trans_small.setVisible(false);

            state = getPuzzleByKey('pressure-gauges', this.puzzle_data.room);
            if (state.status === 'completed') {
                this.cc_status4_gauges_small.setTexture('gauges_small_non_glow');
                this.cc_status4_gauges_small.disableInteractive();
            }

            state = getPuzzleByKey('power-keypad', this.puzzle_data.room);

            if (state.status === 'completed') {
                this.cc_status4_trans_small.setVisible(true);
                this.cc_status4_oxygen.setVisible(false);
                this.cc_status4_gauges_small.setVisible(false);
            }
        }
    }

    updateStatuses() {
        this.setLaunchStatus();
        this.setPowerStatus();
        this.setCommsStatus();
        this.setOxygenStatus();
        this.setStatus4();
    }
}

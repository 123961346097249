import store from 'store';
import { updatePuzzleState } from 'store/puzzleReducer/actions';
import { connectSocket, WEBSOCKET_PUZZLES } from '../index';

let puzzlesSocket;

let connectionSubscription = false;
let solveSubscription = false;
let startGameSubscription = false;
let disconnectSubscription = false;
let puzzleUpdateSubscription = false;

const resetAllSubscriptions = () => {
    connectionSubscription = false;
    solveSubscription = false;
    startGameSubscription = false;
    disconnectSubscription = false;
    puzzleUpdateSubscription = false;
};

export const connect = gameId => {
    puzzlesSocket = connectSocket(WEBSOCKET_PUZZLES, gameId);
};

export const onConnect = cb => {
    if (!puzzlesSocket || connectionSubscription) return true;

    puzzlesSocket.on('connect', () => {
        console.log('Puzzles Socket Connected');
        cb();
    });
};

export const isConnected = () => {
    return puzzlesSocket?.connected || false;
};

export const solve = (puzzleId, senderId, user) => {
    if (!puzzlesSocket) return true;

    puzzlesSocket.emit('solve', {
        puzzleId,
        senderId,
        user,
    });
};

export const onSolve = cb => {
    if (!puzzlesSocket || solveSubscription) return true;

    puzzlesSocket.on('solve', data => {
        cb(data);
    });

    solveSubscription = true;
};

export const update = (puzzleId, payload, senderId, user) => {
    if (!puzzlesSocket) return true;

    puzzlesSocket.emit('update', {
        puzzleId,
        payload,
        senderId,
        user,
    });

    store.dispatch(updatePuzzleState(puzzleId, payload));
};

export const onUpdate = (puzzleId, cb) => {
    if (!puzzlesSocket || (!puzzleId && puzzleUpdateSubscription)) return true;

    puzzlesSocket.on('update', data => {
        if (data.puzzleId === puzzleId || !puzzleId) cb(data);
    });

    if (!puzzleId) puzzleUpdateSubscription = true;
};

export const startGame = (senderId, user) => {
    if (!puzzlesSocket) return true;

    puzzlesSocket.emit('start_game', {
        senderId,
        user,
    });
};

export const onStartGame = cb => {
    if (!puzzlesSocket || startGameSubscription) return true;

    puzzlesSocket.on('start_game', data => {
        cb(data);
    });

    startGameSubscription = true;
};

export const onDisconnect = () => {
    if (!puzzlesSocket || disconnectSubscription) return true;

    puzzlesSocket.on('disconnect', () => {
        console.log('Puzzles Socket Disconnected');
        resetAllSubscriptions();
        puzzlesSocket.removeAllListeners();
    });

    disconnectSubscription = true;
};

export const disconnect = () => {
    if (!puzzlesSocket) return true;

    console.log('Puzzles Socket Disconnected');
    resetAllSubscriptions();
    puzzlesSocket.removeAllListeners();
    puzzlesSocket.disconnect();
};

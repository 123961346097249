import * as ACTIONS from './action_types';

const initialState = {
    currentVideos: [],
    current360Videos: null,
};

const videosReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.SET_CURRENT_VIDEOS:
            return {
                ...state,
                currentVideos: payload,
            };
        case ACTIONS.SET_CURRENT_360_VIDEOS:
            return {
                ...state,
                current360Videos: payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default videosReducer;

import api from 'services/apis';
import axios from 'services/axios';

import * as ACTIONS from './action_types';

export const updateTime = (value, state) => {
    const url = api.game.TIMER;
    return (dispatch, getState) => {
        const { gameId } = getState().authReducer;
        axios.put(`${url}/${gameId}`, { value: value, state: state }).catch(error => {
            console.log(error.message);
        });
    };
};

export const setTimerValue = value => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_TIMER_VALUE,
            payload: value,
        });
    };
};

export const setTimerState = value => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_TIMER_STATE,
            payload: value,
        });
    };
};

export const setTimeInReducer = (value, state) => {
    return dispatch => {
        dispatch(setTimerValue(value));
        dispatch(setTimerState(state));
    };
};

import * as ACTIONS from './action_types';

const initialState = {
    voteData: {},
    currentSelection: null,
    voteHistory: [],
    win: false,
    yesCount: 0,
    noCount: 0,
    callback: null,
};

const voteReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.SET_CURRENT_VOTE:
            return {
                ...state,
                voteData: payload,
            };
        case ACTIONS.CURRENT_SELECTION:
            return {
                ...state,
                currentSelection: payload,
            };
        case ACTIONS.CLEAR_VOTE:
            return {
                ...state,
                currentSelection: null,
                yesCount: 0,
                noCount: 0,
            };
        case ACTIONS.CURRENT_VOTE_SELECTIONS:
            return {
                ...state,
                voteHistory: payload,
            };
        case ACTIONS.WIN:
            return {
                ...state,
                win: payload,
            };
        case ACTIONS.SET_CALLBACK:
            return {
                ...state,
                callback: payload,
            };
        case ACTIONS.INCREMENT_YES:
            return {
                ...state,
                yesCount: state.yesCount + 1,
            };
        case ACTIONS.INCREMENT_NO:
            return {
                ...state,
                noCount: state.noCount + 1,
            };
        default:
            return {
                ...state,
            };
    }
};

export default voteReducer;

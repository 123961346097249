import PHASER from 'phaser';

import store from 'store';
import { getPuzzleByKey } from 'store/puzzleReducer/selectors';
import { setBackButtonCb } from 'store/puzzleReducer/actions';

import CONSTANTS from '../../../constants';

export default class FuseBox extends PHASER.Scene {
    constructor() {
        super({
            key: CONSTANTS.SCENES.MARS.FUSE_BOX,
        });
        this.scale_factor = { x: 1, y: 1 };
        this.game_size = { x: 1, y: 1 };
    }

    init(data) {
        this.center = new PHASER.Geom.Point(
            this.game.renderer.width / 2,
            this.game.renderer.height / 2
        );
        this.puzzle_data = data;
    }

    preload() {
        this.load.image('fb_solved', '/assets/puzzles/fuse-box/fb-solved.png');
        this.load.image('fb_unsolved', '/assets/puzzles/fuse-box/fb-unsolved.png');
        this.load.image('fb_outer_view', '/assets/puzzles/fuse-box/fb-outer-view.png');
    }

    create() {
        this.main_background = this.add
            .image(this.center.x, this.center.y, 'main_background')
            .setDepth(1);

        this.game_size.x = this.game.renderer.width;
        this.game_size.y = this.game.renderer.height;
        this.scale_factor.x = this.game.renderer.width / this.main_background.width;
        this.scale_factor.y = this.game.renderer.height / this.main_background.height;

        this.main_background.setScale(this.scale_factor.x, this.scale_factor.y);

        this.fb_outer_view = this.add
            .image(this.center.x, this.center.y, 'fb_outer_view')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(true)
            .setDepth(1);

        this.fb_unsolved = this.add
            .image(this.center.x, this.center.y, 'fb_unsolved')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.fb_solved = this.add
            .image(this.center.x, this.center.y, 'fb_solved')
            .setScale(this.scale_factor.x, this.scale_factor.y)
            .setVisible(false);

        this.setFuseBoxStatus();
        this.timedEvent = this.time.addEvent({
            delay: 500, // ms
            callback: this.setFuseBoxStatus,
            callbackScope: this,
            repeat: -1,
        });

        store.dispatch(setBackButtonCb());
    }

    setFuseBoxStatus() {
        const state = getPuzzleByKey('satellite-control', this.puzzle_data.room);
        if (state) {
            if (state.status === 'completed') {
                this.fb_outer_view.setVisible(false);

                // need to know the status of fuse box robot command and then display accordingly
                const fuseBoxState = getPuzzleByKey('fuse-box', this.puzzle_data.room);

                if (fuseBoxState.status === 'completed') {
                    this.fb_solved.setVisible(true);
                    this.fb_unsolved.setVisible(false);
                } else {
                    this.fb_solved.setVisible(false);
                    this.fb_unsolved.setVisible(true);
                }
            } else {
                this.fb_outer_view.setVisible(true);
            }
        }
    }
}

import * as ACTIONS from './action_types';

const initialState = {
    isChatScreenVisible: true,
    isVoteScreenVisible: false,
    isResultScreenVisible: false,
    isVoteSubmit: true,
    selfVote: false,
    voteOnResult: false,
    voteResultTime: null,
    isInteractionBlocked: false,
    isSelfDestructInitialized: false,
};

const interactionReducer = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case ACTIONS.DISPLAY_CHAT_SCREEN:
            return {
                ...state,
                isChatScreenVisible: payload,
            };
        case ACTIONS.DISPLAY_VOTE_SCREEN:
            return {
                ...state,
                isVoteScreenVisible: payload,
            };
        case ACTIONS.SET_VOTE_RESULT_TIME:
            return {
                ...state,
                voteResultTime: payload,
            };
        case ACTIONS.DISPLAY_RESULT_SCREEN:
            return {
                ...state,
                isResultScreenVisible: payload,
            };
        case ACTIONS.DISPLAY_VOTE_ON_RESULT:
            return {
                ...state,
                voteOnResult: payload,
            };
        case ACTIONS.CAN_SUBMIT_VOTE:
            return {
                ...state,
                isVoteSubmit: payload,
            };
        case ACTIONS.SELF_SUBMIT_TRUE:
            return {
                ...state,
                selfVote: payload,
            };
        case ACTIONS.SET_INTERACTION_BLOCKED:
            return {
                ...state,
                isInteractionBlocked: payload,
            };
        case ACTIONS.SET_SELF_DESTRUCT_INIT:
            return {
                ...state,
                isSelfDestructInitialized: payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default interactionReducer;
